/* eslint-disable @typescript-eslint/naming-convention */
import { EightDReportStep, PbdModule } from "../../generatedCode/pbd-core/pbd-core-api";

export interface EightDReportStepDataValue {
  title: string;
  app: PbdModule;
  dimension: number;
  step: EightDReportStep;
}

export const EightDReportStepData: Record<EightDReportStep, EightDReportStepDataValue> = {
  Team: { title: "Team", app: PbdModule.Employees, dimension: 1, step: EightDReportStep.Team },
  Problem: { title: "Problem", app: PbdModule.ClaimManagement, dimension: 2, step: EightDReportStep.Problem },
  ImmediateActions: {
    title: "Immediate actions",
    app: PbdModule.ToDoManagement,
    dimension: 3,
    step: EightDReportStep.ImmediateActions,
  },
  RootCauses: {
    title: "Root causes",
    app: PbdModule.DefectManagement,
    dimension: 4,
    step: EightDReportStep.RootCauses,
  },
  CorrectiveActions: {
    title: "Corrective actions",
    app: PbdModule.ToDoManagement,
    dimension: 5,
    step: EightDReportStep.CorrectiveActions,
  },
  VerificationAndResult: {
    title: "Verification and result",
    app: PbdModule.ToDoManagement,
    dimension: 6,
    step: EightDReportStep.VerificationAndResult,
  },
  PreventiveActions: {
    title: "Preventive actions",
    app: PbdModule.ToDoManagement,
    dimension: 7,
    step: EightDReportStep.PreventiveActions,
  },
  Congratulations: {
    title: "Congratulations",
    app: PbdModule.None,
    dimension: 8,
    step: EightDReportStep.Congratulations,
  },
};
