import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AccountRequest } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import GenericAlert from "../../shared/components/alerts/genericAlert";
import { useExternalLoginHook } from "../../shared/hooks/useExternalLoginHook";
import { AccountRoutePaths } from "../accountRoutePaths";
import AccountRequestForm from "../components/accountRequestForm";
import CardFooterAccount from "../components/cardFooterAccount";

function AccountRequestPage() {
  const { t } = useTranslation();
  const { data } = useExternalLoginHook();
  const { accountRequestsApi } = useAPIs();
  const [success, setSuccess] = React.useState(false);

  return (
    <Card className="accountCard">
      <Card.Body>
        <h1 className="text-center">{t("Request account")}</h1>
        <hr />
        {!data?.isAccountRequestActivated && (
          <GenericAlert type="info">
            {t("Requesting accounts in self service is disabled. Please contact your administrator.")}
          </GenericAlert>
        )}
        {data?.isAccountRequestActivated && (
          <AccountRequestForm
            onSubmit={(x) => accountRequestsApi.create(new AccountRequest(x))}
            onSuccess={() => setSuccess(true)}
          />
        )}
        {success && (
          <GenericAlert type="success" heading={t("Registration successful!")}>
            {data?.accountRequestDescriptionHint && <p>{data.accountRequestSuccessInfo}</p>}
          </GenericAlert>
        )}
        <div className="text-center">
          <p>
            {t("Already have an account?")} <Link to={AccountRoutePaths.Login}>{t("Sign in!")}</Link>
          </p>
          {data?.isSelfServiceAccountCreateActivated && (
            <p>
              {t("Have a company email?")} <Link to={AccountRoutePaths.Register}>{t("Register!")}</Link>
            </p>
          )}
        </div>
        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default AccountRequestPage;
