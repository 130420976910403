import React from "react";
import { Popover, PopoverProps } from "react-bootstrap";
import useSWR from "swr";

import { ITenantMinDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useAppContext } from "../../contexts/appContext";
import TenantHoverCardContent from "./tenantHoverCardContent";

interface IProps extends PopoverProps {
  item: ITenantMinDTO;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const TenantHoverCard: React.FC<IProps> = React.forwardRef<HTMLDivElement, IProps>((props: IProps, ref) => {
  const { item } = props;
  const { appSettings } = useAppContext();
  const { tenantsApi } = useAPIs();
  const { data } = useSWR(`/api/tenants/${item.id}`, () => tenantsApi.getById(item.id));
  return (
    <Popover ref={ref} {...props}>
      {data && <TenantHoverCardContent item={data} externalIdSetting={appSettings.Employees.externalIdSetting} />}
    </Popover>
  );
});

export default TenantHoverCard;
