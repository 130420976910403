import { PbdModule } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../../Constants/app-ui-data";

interface IProps {
  app: PbdModule;
}

function SearchBoxAppIcon({ app }: IProps) {
  const navbarItem = AppUiData[app];
  return <i className={navbarItem.iconClassName} />;
}

export default SearchBoxAppIcon;
