import { DateTime, Duration } from "luxon";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { PbdStatus } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";

interface IProps {
  deadline: DateTime;
  /**Example P1Y */
  warningTimeTimeSpan?: string;
  warningTime?: DateTime;
  status?: PbdStatus;
  className?: string;
}

function DeadlineComponent(props: IProps) {
  const { status, warningTimeTimeSpan, className, deadline } = props;
  const { t } = useTranslation();
  const today = DateTime.now();

  let warningTime = null;
  if (warningTimeTimeSpan) {
    warningTime = deadline.minus(Duration.fromISO(warningTimeTimeSpan));
  }
  if (props.warningTime) {
    warningTime = props.warningTime;
  }
  if (status && status == PbdStatus.Completed) {
    return (
      <span title={t("Deadline")} className={className}>
        {DateTimeLuxonHelpers.convertUtcToDate(deadline)}
      </span>
    );
  } else if (deadline < today) {
    return (
      <Badge bg="danger" title={t("Deadline")} className={className}>
        {DateTimeLuxonHelpers.convertUtcToDate(deadline)}
      </Badge>
    );
  } else if (warningTime && warningTime < today) {
    return (
      <Badge bg="warning" title={t("Warning time expired")} className={className}>
        {DateTimeLuxonHelpers.convertUtcToDate(deadline)}
      </Badge>
    );
  } else {
    return (
      <span title={t("Deadline")} className={className}>
        {DateTimeLuxonHelpers.convertUtcToDate(deadline)}
      </span>
    );
  }
}

export default DeadlineComponent;
