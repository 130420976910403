import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { ITenantDTO, ITenantMinDTO, TenantsQueryField } from "../../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import {
  TenantQueries,
  TenantQueryParameters,
} from "../../../../../pbdServices/services/Tenants/models/query-parameters";
import { CrmRoutePaths } from "../../../../crm/crmRoutePaths";
import { EmployeeRoutePaths } from "../../../../employees/employeeRoutePaths";
import TenantFilterComponent from "../../../../tenants/components/tenantFilterComponent";
import { TenantTable } from "../../../../tenants/components/tenantTable";
import { useAppContext } from "../../../contexts/appContext";
import { useLocalQueryHook } from "../../../hooks/useLocalQueryHook";
import GenericAlert from "../../alerts/genericAlert";
import EnhancedSubmitButton from "../../buttons/enhancedSubmitButton";
import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { ConnectModalProps } from "../connectModalProps";

interface IProps extends ConnectModalProps<ITenantMinDTO> {
  query?: TenantQueryParameters;
  preloadedData?: ITenantDTO[];
  toggleNewModal?: () => void;
  canCreateNew?: boolean;
  /**This will be displayed below the search box. For instance if only people in a specific role are available. */
  infoAlert?: React.ReactNode;
  submitButtonLabel?: string;
}

/**
 * Standard Modal for connecting tenants to something
 * @param props
 */
function ConnectTenantsModal(props: IProps) {
  const {
    preloadedData,
    modal,
    toggle,
    submitButtonLabel = "Save",
    connectedElements,
    query = TenantQueries.defaultConnectionModal,
    infoAlert,
    refreshParent,
  } = props;
  const { t } = useTranslation();
  const { tenantsApi } = useAPIs();
  const navigate = useNavigate();
  const { handleApiError } = useAppContext();
  const [submitting, setSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  // const [data, setData] = React.useState<ITenantDTO[]>();
  // const [query, setQuery] = React.useState<TenantQueryParameters>();
  const { localQuery, setLocalQuery, deleteFilterProp } = useLocalQueryHook<TenantQueryParameters>(query);
  const { data } = useSWR(modal ? ["/api/tenants", localQuery] : null, () =>
    tenantsApi.getAllQuery({
      fields: [TenantsQueryField.DepartmentPositions, TenantsQueryField.ApplicationUser],
      ...localQuery,
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    }),
  );

  const filtered = React.useMemo(() => {
    if (data && connectedElements && !preloadedData) {
      return data.data.filter((x) => !connectedElements.map((y) => y.id).includes(x.id));
    } else if (preloadedData) {
      return preloadedData;
    } else {
      return undefined;
    }
  }, [connectedElements, data, preloadedData]);

  const onSubmit = async () => {
    setSubmitting(true);
    const resp = await wrapApiCallWithToast(() => props.onSubmit(selected), { handleApiError });
    if (resp.isOk) {
      setSubmitting(false);
      setSelected([]);
      toggle();
      refreshParent();
    }
  };
  const onCreateButtonClick = () => {
    localQuery.isEmployee ? navigate(EmployeeRoutePaths.CreatePage) : navigate(CrmRoutePaths.CreatePageTenant);
  };

  const filterBox = <TenantFilterComponent onChange={setLocalQuery} defaultValue={localQuery} />;

  return (
    <Modal show={modal} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Persons")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {infoAlert && <GenericAlert type="info">{infoAlert}</GenericAlert>}
        <TenantTable
          tableRows={filtered}
          filterBox={filterBox}
          setSelected={(ids) => setSelected(ids.map((x) => x.id))}
          hideTitleLink
          localStorageStateKey={"ConnectTenantsModal"}
          isAlwaysSelectable
          onCreateClick={onCreateButtonClick}
          activeFilters={localQuery}
          onDeleteFilterKey={deleteFilterProp}
        />
      </Modal.Body>
      <Modal.Footer>
        {props.canCreateNew && (
          <Button variant="outline-secondary" onClick={props.toggleNewModal} className="me-1">
            <qmBaseIcons.Plus /> {t("New person")}
          </Button>
        )}
        <EnhancedSubmitButton
          onClick={onSubmit}
          submitting={submitting}
          disabled={selected.length == 0}
          canSubmit={props.canSubmit ?? false}
          label={submitButtonLabel}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConnectTenantsModal;
