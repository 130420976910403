import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import "./layoutAccount.css";

function LayoutAccount() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <div className="min-vh-100 w-100 account-background row justify-content-md-center align-items-center m-0">
        <Col md={4} className="d-none d-lg-block" />
        <Col>
          <Outlet />
        </Col>
        <Col md={4} className="d-none d-lg-block" />
      </div>
    </QueryParamProvider>
  );
}
export default LayoutAccount;
