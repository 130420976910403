import { Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import {
  ArticleRevisionCreateDTO,
  ArticleRevisionEditDTO,
  IArticleDetailsDTO,
  IArticleRevisionCreateDTO,
  IArticleRevisionDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { LoadingComponent } from "../../shared/components/loading/loadingComponent";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import { ArticleRoutePaths } from "../articleRoutePaths";
import EditBpmnForm from "./editBpmnForm";

function EditPageBpmn() {
  const { t } = useTranslation();
  const { articleRevisionsApi } = useAPIs();
  const { articleService } = useAPIServices();
  const { id } = useTypedParams(["id"]);
  const navigate = useNavigate();
  const [query, setQuery] = useQueryParams({
    parentMenuItemId: withDefault(NumberParam, undefined),
    articleRevisionId: withDefault(NumberParam, undefined),
  });
  const { data, mutate } = useSWR(["/api/articles", id, "vm"], () =>
    articleService.getByIdAsVm(id, query.articleRevisionId),
  );

  const handleCancel = (dto: IArticleDetailsDTO) => {
    if (query.articleRevisionId) {
      navigate(
        `${ArticleRoutePaths.EditOverviewPage(dto.module).replace(":id", id.toString())}&articleRevisionId=${
          query.articleRevisionId
        }`,
      );
    } else {
      navigate(ArticleRoutePaths.HomePage(dto.module));
    }
  };

  const handleSuccess = (dto: IArticleDetailsDTO, revision: IArticleRevisionDTO | null) => {
    mutate();
    navigate(
      `${ArticleRoutePaths.EditOverviewPage(dto.module).replace(":id", dto.id.toString())}&articleRevisionId=${
        revision?.id ?? query.articleRevisionId
      }`,
    );
  };

  const handleSubmit = async (dto: IArticleRevisionCreateDTO, rev?: IArticleRevisionDTO) => {
    if (rev?.isWorkingCopy) {
      await articleRevisionsApi.edit(rev.id, new ArticleRevisionEditDTO({ ...dto, id: rev.id }));
      return null;
    } else {
      return articleRevisionsApi.create(new ArticleRevisionCreateDTO(dto));
    }
  };

  if (!data?.article) return <LoadingComponent />;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: ArticleRoutePaths.HomePage(data.article.module) }}>
          {t(data.article.module)}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{
            to: ArticleRoutePaths.EditOverviewPage(data.article.module).replace(":id", data.article.id.toString()),
          }}
        >
          {t("Edit overview")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{t("BPMN chart")}</Breadcrumb.Item>
      </Breadcrumb>
      <EditBpmnForm
        article={data.article}
        articleRevision={data.articleRevision}
        onCancel={() => handleCancel(data.article)}
        onSubmit={(bpmn) => handleSubmit(bpmn, data.articleRevision)}
        onSuccess={(revision) => handleSuccess(data.article, revision)}
      />
    </>
  );
}
export default EditPageBpmn;
