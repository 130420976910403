import { AppSettings } from "../../Models/Settings/AppSettings";
import { Setting } from "../../Models/Settings/Setting";
import JsonHelpers from "../Json/jsonHelpers";
import RestUtilities from "../restClients/restUtilities";
import { SettingKeyNames } from "./SettingKeyNames";

const baseUrl = "/api/settings";

export default class SettingsService {
  private _getByKey(key: keyof typeof SettingKeyNames) {
    return RestUtilities.get<Setting>(`${baseUrl}/${key}`);
  }

  async getValueByKey<T = string>(key: keyof typeof SettingKeyNames, parseValue = true, defaultValue?: T) {
    const resp = await this._getByKey(key);
    if (
      key == "DefaultPermissions" ||
      key == "AllowedDomains" ||
      key == "DefaultPermissions_Readonly" ||
      key == "Global_PrintLogo"
    ) {
      parseValue = false;
    }
    // console.log(T);
    if (resp.content?.value) {
      if (parseValue) {
        return JsonHelpers.parseToCamelCase<T>(resp.content.value);
      } else {
        let value = resp.content.value;
        if (key == "AllowedDomains") {
          throw Error("Not allowed use new api");
        } else {
          value = JSON.stringify(resp.content.value);
        }
        return JsonHelpers.parseToCamelCase<T>(value);
      }
    } else {
      return undefined ?? defaultValue;
    }
  }

  updateAppSettings(appSetting: AppSettings) {
    return RestUtilities.put(`${baseUrl}/${appSetting.key}`, appSetting);
  }
}
