import React from "react";

import { DefaultLayoutSubsidebar } from "../shared/layouts/defaultLayoutSubsidebar";
import AdminSubsidebar from "./components/adminSubsidebar";

interface IProps {
  Component: React.ComponentType;
}

/**Special layout for admin page with sub sidebar */
export const AdminLayoutWithSubsidebar: React.FC<IProps> = (props) => (
  <DefaultLayoutSubsidebar SubSidebar={AdminSubsidebar} {...props} />
);
