import React from "react";
import { Button, Collapse, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Row } from "react-table";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import { useToggle } from "../../../hooks/useToggle";
import { qmBaseIcons } from "../../icons/qmBaseIcons";
import SearchCriteria from "../../search/searchCriteria";
import { ActiveFilters } from "./activeFilters";
import { IFilterProps } from "./filterKey";
import GlobalFilter from "./globalFilter";

interface IProps<T extends object> extends IFilterProps {
  onCreateClick?: () => void;
  onCreateButtonTitle?: React.ReactNode;
  preGlobalFilteredRows: Row<T>[];
  setGlobalFilter: (filterValue: any) => void;
  globalFilter: any;
  filterBox?: React.ReactNode;
  smartViewBox?: React.ReactNode;
  toggleShowAdvancedTableOptions: () => void;
  showAdvancedTableOptions: boolean;
  hideAdvancedTableOptionsButton?: boolean;
  showSearchBox: boolean;
  onSearch?: (input: string) => void;
  createButtonText?: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function TableToolbar<T extends object>(props: IProps<T>) {
  const {
    preGlobalFilteredRows,
    setGlobalFilter,
    globalFilter,
    onCreateClick,
    onCreateButtonTitle,
    filterBox,
    activeFilters,
    smartViewBox,
    toggleShowAdvancedTableOptions,
    showAdvancedTableOptions,
    hideAdvancedTableOptionsButton,
    showSearchBox,
    onSearch,
    createButtonText = "New",
  } = props;
  const { t } = useTranslation();
  const [showFilter, toggleFilter] = useToggle();
  const [showSmartViews, toggleSmartViews] = useToggle();
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          {onCreateClick && (
            <Button className="rounded-pill shadow-sm" variant="outline-primary" onClick={onCreateClick}>
              <qmBaseIcons.Plus /> {onCreateButtonTitle ?? t(createButtonText)}
            </Button>
          )}
        </div>
        <div className="d-flex">
          {!showSearchBox && onSearch && (
            <div>
              <SearchCriteria doSearch={onSearch} placeholder={t("Type to search...")} showFilterButton={false} />
            </div>
          )}
          <div>
            <InputGroup>
              {preGlobalFilteredRows.length > GlobalQmBaseConstants.MinEntriesForFilter && showSearchBox && (
                <GlobalFilter value={globalFilter ?? ""} onChange={(value) => setGlobalFilter(value)} />
              )}
              {filterBox && (
                <Button variant="outline-secondary" onClick={toggleFilter} active={showFilter}>
                  <qmBaseIcons.Filter /> {t("Filter")}
                </Button>
              )}
              {!hideAdvancedTableOptionsButton && (
                <Button
                  variant="outline-secondary"
                  active={showAdvancedTableOptions}
                  onClick={toggleShowAdvancedTableOptions}
                  title={t("Advanced table options")}
                >
                  <qmBaseIcons.EllipsisVertical />
                </Button>
              )}
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div>
          {showFilter ? (
            <h6>
              <qmBaseIcons.Filter /> {t("Filter")}
            </h6>
          ) : (
            <ActiveFilters toggleFilter={toggleFilter} activeFilters={activeFilters} onDelete={props.onDelete} />
          )}
        </div>
        <div>
          {smartViewBox && (
            <Button variant="link" onClick={toggleSmartViews}>
              <qmBaseIcons.SmartView /> {t("Smart Views")}
              {" - "}
              {t("Get insights in your data")}
            </Button>
          )}
        </div>
      </div>
      <Collapse in={showFilter} data-testid="table-filter-box" mountOnEnter>
        <div>{filterBox}</div>
      </Collapse>
      <Collapse in={showSmartViews} mountOnEnter>
        <div>{smartViewBox}</div>
      </Collapse>
    </>
  );
}
export default TableToolbar;
