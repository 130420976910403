import React from "react";
import { Card, Form, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ClaimType, ITimeIntervalDTO, PbdModule, SettingType } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../../pbdServices/services/service-context";

import { ClaimDeadlineEntry } from "../../../../Models/Claims/ClaimDeadlineEntry";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import JsonHelpers from "../../../../services/Json/jsonHelpers";
import ClaimTypeAvatar from "../../../claims/components/claimTypeAvatar";
import EnhancedSubmitButton from "../../../shared/components/buttons/enhancedSubmitButton";
import TimeIntervalPicker from "../../../shared/components/inputControl/timeInterval/timeIntervalPicker";
import SettingsCardHeader from "../../components/settingsCardHeader";

const claimTypes = Object.values(ClaimType);

function IndexPageClaimDeadline() {
  const { moduleSettingsService } = useAPIServices();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<ClaimDeadlineEntry[]>([]);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    async function getData() {
      const setting = await moduleSettingsService.getByModuleAndType(PbdModule.ClaimManagement, SettingType.Deadlines);
      if (typeof setting.value == "string") {
        const deadlines = JsonHelpers.parse<ClaimDeadlineEntry[]>(setting.value);
        setData(deadlines);
      }
      setLoading(false);
    }
    getData();
  }, []);

  async function handleSubmit() {
    setSubmitting(true);
    const resp = await wrapApiCallWithToast(() =>
      moduleSettingsService.save(PbdModule.ClaimManagement, SettingType.Deadlines, data),
    );
    if (resp.isOk) {
      setSubmitting(false);
    }
  }

  function reset() {
    data.splice(0, data.length);
    handleSubmit();
  }

  function handleChange(claimType: ClaimType, timeInterval: ITimeIntervalDTO, timeToSet: "warning" | "deadline") {
    const isWarning = timeToSet == "warning";
    const isDeadline = timeToSet == "deadline";
    const exists = data.find((x) => x.claimType == claimType);
    if (exists) {
      const existsIndex = data.indexOf(exists);
      if (isWarning) {
        exists.warningTime = timeInterval;
      }
      if (isDeadline) {
        exists.deadline = timeInterval;
      }
      const newArray = [...data];
      newArray[existsIndex] = exists;
      setData(newArray);
    } else {
      const entry: ClaimDeadlineEntry = {
        claimType: claimType,
        isActive: false,
      };
      if (isWarning) {
        entry.warningTime = timeInterval;
      }
      if (isDeadline) {
        entry.deadline = timeInterval;
      }
      setData([...data, entry]);
    }
  }

  function changeActive(event: React.ChangeEvent<HTMLInputElement>, claimType: ClaimType) {
    const exists = data.find((x) => x.claimType == claimType);
    if (exists) {
      const existsIndex = data.indexOf(exists);
      exists.isActive = !exists.isActive;
      const newArray = [...data];
      newArray[existsIndex] = exists;
      setData(newArray);
    } else {
      const entry: ClaimDeadlineEntry = {
        claimType: event.target.id as ClaimType,
        isActive: true,
      };
      setData([...data, entry]);
    }
  }

  return (
    <Card>
      <SettingsCardHeader cardTitle={t("Deadlines")} />
      <Card.Body>
        <Card.Text>
          {t("Deadlines for claims can be considered as targets for handling claims.")}{" "}
          {t("These deadlines can be individualized per claim type.")}{" "}
          {t("These deadlines can also be deactivated. This might be useful under specific circumstances.")}
        </Card.Text>

        {!loading && (
          <Table responsive>
            <thead>
              <tr>
                <th>{t("Active")}</th>
                <th>{t("Type")}</th>
                <th>{t("Deadline")}</th>
                <th>{t("Warning time")}</th>
              </tr>
            </thead>
            <tbody>
              {claimTypes.map((x) => {
                const settingForType = data.find((y) => y.claimType == x);
                return (
                  <tr key={x}>
                    <td>
                      <Form.Group className="mb-3">
                        <Form.Check
                          id={x}
                          onChange={(e) => changeActive(e, x)}
                          checked={settingForType == null ? false : settingForType.isActive}
                        />
                      </Form.Group>
                    </td>
                    <td>
                      <div className="d-flex align-content-center">
                        <div className="me-1">
                          <ClaimTypeAvatar type={x} />
                        </div>
                        <div>{t(x)}</div>
                      </div>
                    </td>
                    <td>
                      <TimeIntervalPicker
                        id={`${x}_deadline`}
                        onChange={(id, dto) => handleChange(x, dto, "deadline")}
                        timeInterval={settingForType?.deadline}
                      />
                    </td>
                    <td>
                      <TimeIntervalPicker
                        id={`${x}_warningTime`}
                        onChange={(id, dto) => handleChange(x, dto, "warning")}
                        timeInterval={settingForType?.warningTime}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
        <EnhancedSubmitButton
          className="m-1"
          canSubmit={true}
          color="outline-danger"
          submitting={submitting}
          onClick={reset}
          label={t("Reset")}
        />
        <EnhancedSubmitButton canSubmit={true} submitting={submitting} onClick={handleSubmit} />
      </Card.Body>
    </Card>
  );
}

export default IndexPageClaimDeadline;
