import i18next from "i18next";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Column } from "react-table";

import { IScaleSection } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../../shared/components/tables/BaseTableProps";
import { getDescriptionColumn } from "../../../shared/components/tables/columns/descriptionColumn";
import { ReactstrapTable } from "../../../shared/components/tables/reactstrapTable";

type IProps = BaseTableProps<IScaleSection>;

export function ScaleSectionTable(props: IProps) {
  const { onRowClick } = props;
  const columns = React.useMemo<Column<IScaleSection>[]>(
    () => [
      {
        id: "id",
        Header: <>{i18next.t("ID")}</>,
        accessor: "scaleSectionId",
        Cell: ({ row }) => <>{row.original.scaleSectionId}</>,
      },
      getDescriptionColumn(),
      {
        id: "from",
        Header: <>{i18next.t("Minimum")}</>,
        accessor: "from",
        Cell: ({ row }) => <>{row.original.from}</>,
      },
      {
        id: "to",
        Header: <>{i18next.t("Maximum")}</>,
        accessor: "to",
        Cell: ({ row }) => <>{row.original.to}</>,
      },
      {
        Header: <></>,
        accessor: "scaleSectionId", // accessor is the "key" in the data
        disableSortBy: true,
        Cell: ({ row }) => (
          <>
            {onRowClick && (
              <ButtonGroup size="sm">
                <Button variant="link" onClick={() => onRowClick({ row: row.original, action: "Select" })}>
                  <qmBaseIcons.Pencil /> {i18next.t("Edit")}
                </Button>
                <Button variant="danger" onClick={() => onRowClick({ row: row.original, action: "Delete" })}>
                  <qmBaseIcons.Delete />
                </Button>
              </ButtonGroup>
            )}
          </>
        ),
      },
    ],
    [],
  );

  return (
    <ReactstrapTable<IScaleSection>
      columns={columns}
      columnsVisibleDefault={["description", "from", "to"]}
      localStorageStateKey={"BoundariesTable"}
      {...props}
    />
  );
}
