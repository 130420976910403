import React from "react";
import { useLocation } from "react-router-dom";
import { Column, IdType } from "react-table";

import { useFeatureFlagHook } from "../../hooks/useFeatureFlagHook";

export function filterExcludedColumns<T extends object>(columns: Column<T>[], columnsToExclude?: IdType<T>[]) {
  if (columnsToExclude) {
    return columns.filter((x) => !columnsToExclude.includes(x.id ?? ""));
  } else {
    return columns;
  }
}

export const useFilteredColumnsMemo = <T extends object>(col: Column<T>[], columnsToExclude?: IdType<T>[]) => {
  return React.useMemo(() => {
    return filterExcludedColumns(col, columnsToExclude);

    // This is disabled on purpose to prevent rerendering and problems with the select column. PP
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useFilteredColumnsMemoTodoTable = <T extends object>(col: Column<T>[], columnsToExclude?: IdType<T>[]) => {
  const { data } = useFeatureFlagHook();
  return React.useMemo(() => {
    let filtered = filterExcludedColumns(col, columnsToExclude);
    if (!data?.isLegacyProjectsAvailable) {
      filtered = filtered.filter((x) => x.id != "project");
    }
    return filtered;
    // This is disabled on purpose to prevent rerendering and problems with the select column. PP
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};

/**
 *
 * @param keyAppendix customize the key
 * @returns `qmBaseTable_${getTableKeyPrefix()}_${keyAppendix}`
 */
export const useTableKeyName = (keyAppendix: string) => {
  const location = useLocation();
  return React.useMemo(() => {
    return getTableKey(location.pathname, keyAppendix);
  }, [keyAppendix, location.pathname]);
};

function getTableKey(pathname: string, keyAppendix: string) {
  let genericKey = "";
  if (pathname.includes("?")) {
    throw new Error("Pathname must not include ?");
  }
  const fragments = removeTrailingSlash(pathname).split("/");
  if (Number(fragments[fragments.length - 1])) {
    fragments.pop();
    genericKey = fragments.join("/");
  } else {
    genericKey = fragments.join("/");
  }
  genericKey = genericKey.trim();
  return `qmBaseTable_${genericKey}_${keyAppendix}`;
}

function removeTrailingSlash(str: string) {
  return str.replace(/\/+$/, "");
}
