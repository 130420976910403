import React from "react";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BaseIdDTO } from "../../../../Models/BaseClasses/BaseIdDTO";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import RequiredRolesComponent from "../../../admin/roles/components/requiredRolesComponent";
import { useAppContext } from "../../contexts/appContext";
import GenericAlert from "../alerts/genericAlert";
import CancelButton from "../buttons/cancelButton";
import IdComponent from "../id/idComponent";

export interface ItemToDelete {
  id: string | number;
  title?: string;
  fullName?: string;
}

export interface IBaseDTOToDelete<TKey = number> extends BaseIdDTO<TKey> {
  title?: string;
  name?: string;
}

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  /**Submit button color */
  variant?: "danger" | "info";
  /**Untranslated title */
  title?: string;
  description?: string;
  additionalDescription?: string;
  itemsToDelete?: readonly ItemToDelete[];
  confirmButtonLabel?: string;
  mustUseAdminRights?: boolean;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const maxVisible = 10;

function ConfirmDeleteModalGlobal({
  open,
  title = "Delete",
  confirmButtonLabel = "Delete",
  variant = "danger",
  additionalDescription,
  description = "This action cannot be undone do you want to continue?",
  onSubmit,
  onClose,
  itemsToDelete,
  mustUseAdminRights,
}: ConfirmationDialogProps) {
  const { t } = useTranslation();
  const { meAsUser } = useAppContext();
  const [showAll, setShowAll] = React.useState(false);
  const toggleShowAll = () => setShowAll(!showAll);
  const [isChecked, setIsChecked] = React.useState(false);

  const isAdmin = hasRole(meAsUser, [PbdRoles.Admin]);
  let itemsToView = itemsToDelete ? [...itemsToDelete] : [];
  if (!showAll) {
    itemsToView = itemsToView.slice(0, maxVisible);
  }
  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{t(title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t(description)}</p>
        {additionalDescription && <p>{additionalDescription}</p>}

        {itemsToDelete && (
          <>
            <h6>{t("Items to delete")}</h6>
            <ul>
              {itemsToView.map((x) => (
                <li key={x.id}>
                  {(x.title ?? x.fullName) && <b>{x.title ?? x.fullName}</b>} <IdComponent id={x.id} />
                </li>
              ))}
              {itemsToDelete.length > maxVisible && !showAll && (
                <li>
                  <Button variant="link" onClick={toggleShowAll}>
                    + {itemsToDelete.length - 9} {t("More")} - {t("View")}
                  </Button>
                </li>
              )}
            </ul>
          </>
        )}
        {mustUseAdminRights && isAdmin && (
          <GenericAlert type="danger" heading={t("Bypass protections")}>
            <FormGroup className="mb-3">
              <Form.Check
                id="byPassProtections"
                label={t("Bypass protections as admin")}
                disabled={!isAdmin}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <Form.Text>
                <RequiredRolesComponent roles={[PbdRoles.Admin]} />
              </Form.Text>
            </FormGroup>
          </GenericAlert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CancelButton onClick={onClose} />
        <Button variant={variant} onClick={onSubmit} disabled={mustUseAdminRights && !isChecked}>
          {t(confirmButtonLabel)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteModalGlobal;
