import React from "react";
import useSWR from "swr";

import { IInventoryItemDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { BaseSelectProps } from "../../shared/components/inputControl/select/BaseSelectProps";
import CustomSelect from "../../shared/components/inputControl/select/customSelect";

interface IProps extends BaseSelectProps<IInventoryItemDTO> {
  onChange: (id?: number | number[]) => void;
}

const InventoryItemSelect: React.FC<IProps> = (props) => {
  const { selectedIds, onChange, isMulti = true, isClearable = true } = props;
  const { inventoryItemsApi } = useAPIs();
  const { data } = useSWR("api/inventoryItems", () => inventoryItemsApi.getAll());

  if (!data) return null;

  return (
    <CustomSelect
      data={data}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={onChange}
      name="inventoryItemId"
      invalid={false}
      selectedIds={selectedIds}
      onBlur={undefined}
    />
  );
};

export default InventoryItemSelect;
