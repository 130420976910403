import { DateTime } from "luxon";

import {
  CustomField,
  CustomFormDTO,
  CustomFormUsageType,
  EntityKey,
  ICustomFormDTO,
  PbdModule,
  PbdStatus,
  TenantMinDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

import { DTOMap } from "../../../../Helpers/filterMap";
import { CustomFieldType } from "../../../../Models/Enums/CustomFieldType";
import { MockMeAsUser_JohnDoe } from "./applicationUserMockData";

export class CustomFormMockData extends DTOMap<ICustomFormDTO> {
  constructor() {
    const responsible = new TenantMinDTO(MockMeAsUser_JohnDoe.tenant);
    super([
      {
        id: 1,
        responsible,
        createdById: responsible.id,
        description: undefined,
        isPublished: true,
        uniqueSubmitPerTenant: true,
        editableAfterSubmit: true,
        tags: [],
        customFields: [],
        capabilities: undefined,
        revision: 4,
        invitations: undefined,
        isPrivate: false,
        lastUpdatedById: responsible.id,
        lastUpdatedAt: DateTime.fromISO("2020-09-14T13:10:24.5700445"),
        isDeleted: false,
        createdAt: DateTime.fromISO("2020-09-14T13:09:08.4714686"),
        createdBy: responsible,
        title: "Neues Formular für einmaliges ausfüllen",
        links: undefined,
        status: PbdStatus.Open,
        customFormUsageType: CustomFormUsageType.Internal,
        accessOnlyOnInvite: false,
        submitterCanSeeResults: false,
        entityKey: EntityKey.CustomForm,
        type: PbdModule.CustomForms,
        linkedResources: [],
      },
      {
        id: 1003,
        responsible,
        createdById: responsible.id,
        description: undefined,
        isPublished: true,
        uniqueSubmitPerTenant: false,
        editableAfterSubmit: true,
        tags: [],
        customFields: [
          new CustomField({
            id: "e440fabd-ea5d-4de2-9777-789d8bb9a98b",
            name: "Vorname",
            type: CustomFieldType.Text,
            choices: undefined,
            createdAt: DateTime.fromISO("2018-12-18T14:11:10.7422602Z"),
            createdById: 1,
            lastUpdatedAt: undefined,
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
          new CustomField({
            id: "ede3f362-5e53-42d6-b2a4-8372dd187906",
            name: "Geburtsdatum2",
            type: CustomFieldType.Date,
            choices: undefined,
            createdAt: DateTime.fromISO("2018-12-18T14:11:24.975325Z"),
            createdById: 1,
            lastUpdatedAt: DateTime.fromISO("2020-02-03T10:33:25.932818Z"),
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
          new CustomField({
            id: "45382063-74cb-4df7-bc68-3446c789046a",
            name: "Nachname",
            type: CustomFieldType.Text,
            choices: undefined,
            createdAt: DateTime.fromISO("2020-02-03T10:33:37.2767543Z"),
            createdById: 1,
            lastUpdatedAt: undefined,
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
        ],
        capabilities: undefined,
        revision: 2,
        invitations: undefined,
        isPrivate: false,
        lastUpdatedById: responsible.id,
        lastUpdatedAt: DateTime.fromISO("2021-01-07T15:00:53.3658584"),
        isDeleted: false,
        createdAt: DateTime.fromISO("2018-12-18T14:10:54.2311531"),
        createdBy: responsible,
        title: "Test Formular nach Änderungen",
        links: undefined,
        status: PbdStatus.Open,
        customFormUsageType: CustomFormUsageType.Internal,
        accessOnlyOnInvite: false,
        submitterCanSeeResults: false,
        entityKey: EntityKey.CustomForm,
        type: PbdModule.CustomForms,
        linkedResources: [],
      },
      {
        responsible,
        createdById: responsible.id,

        description: "<p>Shared</p>",
        isPublished: true,
        uniqueSubmitPerTenant: false,
        editableAfterSubmit: false,
        tags: [],
        customFields: [],
        capabilities: undefined,
        revision: 1,
        invitations: undefined,
        isPrivate: false,
        lastUpdatedById: responsible.id,
        lastUpdatedAt: DateTime.fromISO("2020-04-26T16:10:21.7222095"),
        isDeleted: false,
        createdAt: DateTime.fromISO("2020-04-25T13:53:06.3482222"),
        createdBy: responsible,
        title: "Neues Formular",
        links: undefined,
        id: 1004,
        status: PbdStatus.Open,
        customFormUsageType: CustomFormUsageType.Internal,
        accessOnlyOnInvite: false,
        submitterCanSeeResults: false,
        entityKey: EntityKey.CustomForm,
        type: PbdModule.CustomForms,
        linkedResources: [],
      },
      {
        id: 1006,
        responsible,
        createdById: responsible.id,
        description: undefined,
        isPublished: true,
        uniqueSubmitPerTenant: true,
        editableAfterSubmit: true,
        tags: [],
        customFields: [
          new CustomField({
            id: "1a415f60-6ecd-4645-afa5-8cc02ac1489f",
            name: "Wie heißen Sie?",
            type: CustomFieldType.Text,
            choices: "[]",
            createdAt: DateTime.fromISO("2020-09-14T13:09:18.6231199Z"),
            createdById: 1,
            lastUpdatedAt: undefined,
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: "",
            permissions: undefined,
            isQuiz: false,
          }),
        ],
        capabilities: undefined,
        revision: 4,
        invitations: undefined,
        isPrivate: false,
        lastUpdatedById: responsible.id,
        lastUpdatedAt: DateTime.fromISO("2020-09-14T13:10:24.5700445"),
        isDeleted: false,
        createdAt: DateTime.fromISO("2020-09-14T13:09:08.4714686"),
        createdBy: responsible,
        title: "Neues Formular für einmaliges ausfüllen",
        links: undefined,
        status: PbdStatus.Open,
        customFormUsageType: CustomFormUsageType.Internal,
        accessOnlyOnInvite: false,
        submitterCanSeeResults: false,
        entityKey: EntityKey.CustomForm,
        type: PbdModule.CustomForms,
        linkedResources: [],
      },
      {
        id: 1088,
        responsible,
        createdById: responsible.id,
        description: undefined,
        isPublished: false,
        uniqueSubmitPerTenant: false,
        editableAfterSubmit: true,
        tags: [],
        customFields: [
          new CustomField({
            id: "e440fabd-ea5d-4de2-9777-789d8bb9a98b",
            name: "TextInput",
            type: CustomFieldType.Text,
            choices: undefined,
            createdAt: DateTime.fromISO("2018-12-18T14:11:10.7422602Z"),
            createdById: 1,
            lastUpdatedAt: undefined,
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
          new CustomField({
            id: "ede3f362-5e53-42d6-b2a4-8372dd187906",
            name: "NumberInput",
            type: CustomFieldType.Number,
            choices: undefined,
            createdAt: DateTime.fromISO("2018-12-18T14:11:24.975325Z"),
            createdById: 1,
            lastUpdatedAt: DateTime.fromISO("2020-02-03T10:33:25.932818Z"),
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
          new CustomField({
            id: "45382063-74cb-4df7-bc68-3446c789046a",
            name: "ChoicesInput",
            type: CustomFieldType.Choices,
            choices: undefined,
            createdAt: DateTime.fromISO("2020-02-03T10:33:37.2767543Z"),
            createdById: 1,
            lastUpdatedAt: undefined,
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
          new CustomField({
            id: "e440fabd-ea5d-4de2-9777-789d8bb9a98b",
            name: "textarea",
            type: CustomFieldType.Textarea,
            choices: undefined,
            createdAt: DateTime.fromISO("2018-12-18T14:11:10.7422602Z"),
            createdById: 1,
            lastUpdatedAt: undefined,
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
          new CustomField({
            id: "ede3f362-5e53-42d6-b2a4-8372dd187906",
            name: "DateInput",
            type: CustomFieldType.Date,
            choices: undefined,
            createdAt: DateTime.fromISO("2018-12-18T14:11:24.975325Z"),
            createdById: 1,
            lastUpdatedAt: DateTime.fromISO("2020-02-03T10:33:25.932818Z"),
            lastUpdatedById: undefined,
            orderNumber: 0,
            isRequired: false,
            optionalHelpText: undefined,
            permissions: undefined,
            isQuiz: false,
          }),
        ],
        capabilities: undefined,
        revision: 2,
        invitations: undefined,
        isPrivate: false,
        lastUpdatedById: responsible.id,
        lastUpdatedAt: DateTime.fromISO("2021-01-07T15:00:53.3658584"),
        isDeleted: false,
        createdAt: DateTime.fromISO("2018-12-18T14:10:54.2311531"),
        createdBy: responsible,
        title: "Form with different custom fields",
        links: undefined,
        status: PbdStatus.Open,
        customFormUsageType: CustomFormUsageType.Internal,
        accessOnlyOnInvite: false,
        submitterCanSeeResults: false,
        entityKey: EntityKey.CustomForm,
        type: PbdModule.CustomForms,
        linkedResources: [],
      },
    ]);
  }

  static deletedFormPlaceholder(id: number): ICustomFormDTO {
    //@ts-expect-error No need to define more. PP
    return new CustomFormDTO({ id, isDeleted: true, title: "Deleted" });
  }
}
