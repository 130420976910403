import i18next from "i18next";
import React from "react";
import { CellProps, Column } from "react-table";
import { Badge } from "reactstrap";

import { ICompanyFunctionDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { CompanyFunctionsConnectedToTenant } from "../../../Models/CompanyFunctions/CompanyFunctionsConntectedToTenant";
import { QualificationMatrixRoutePaths } from "../../qualificationMatrix/qualificationMatrixRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getCreatedAtColumn } from "../../shared/components/tables/columns/createdAtColumn";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getResponsibleColumn } from "../../shared/components/tables/columns/responsibleColumn";
import { getTagColumn } from "../../shared/components/tables/columns/tagsColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemo } from "../../shared/components/tables/tableHelperFunctions";

type TableType = ICompanyFunctionDTO | CompanyFunctionsConnectedToTenant;

type IProps = BaseTableProps<TableType>;

export function CompanyFunctionTable(props: IProps) {
  const {
    titleColumnBaseLink = QualificationMatrixRoutePaths.DetailsPageCompanyFunction,
    columnsVisibleDefault = ["title"],
    columnsToExclude = ["isManuallyConnected", "requiredQualificationsCount", "requiredQualifications"],
    includeTitleLink = true,
  } = props;

  const columns = React.useMemo<Column<TableType>[]>(
    () => [
      getIdColumn(includeTitleLink, titleColumnBaseLink),
      getTitleColumn(includeTitleLink, titleColumnBaseLink),
      {
        id: "isManuallyConnected",
        Header: <qmBaseIcons.HandLizard color="success" title={i18next.t("Manually connected")} />,
        // accessor: "isManuallyConnected",
        disableSortBy: true,
        Cell: ({ row }: CellProps<TableType>) => (
          <>
            {(row.original as CompanyFunctionsConnectedToTenant).isManuallyConnected && (
              <qmBaseIcons.HandLizardRegular className=" text-success me-1" title={i18next.t("Manually connected")} />
            )}
          </>
        ),
      },
      getTagColumn(),
      getResponsibleColumn(),
      {
        id: "formula",
        Header: <>{i18next.t("Formula")}</>,
        accessor: (x) => x.formula,
        Cell: ({ row }: CellProps<ICompanyFunctionDTO>) => (
          <Badge color="primary">{row.original.formula ? i18next.t("Custom") : i18next.t("Default")}</Badge>
        ),
      },
      {
        id: "requiredQualificationsCount",
        Header: <>{i18next.t("Count of connected qualifications")}</>,
        accessor: (x) => x.requiredQualifications?.length,
        // disableSortBy: true,
        Cell: ({ row }: CellProps<ICompanyFunctionDTO>) => <>{row.original.requiredQualifications?.length}</>,
      },
      {
        id: "requiredQualifications",
        Header: <>{i18next.t("Connected qualifications")}</>,
        accessor: "requiredQualifications",
        disableSortBy: true,
        Cell: ({ row }: CellProps<ICompanyFunctionDTO>) => (
          <>{row.original.requiredQualifications?.map((x) => x.title).join(", ")}</>
        ),
      },
      getCreatedAtColumn(),
    ],
    [],
  );
  const filteredColumns = useFilteredColumnsMemo(columns, columnsToExclude);

  return (
    <ReactstrapTable<TableType>
      columns={filteredColumns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={"CompanyFunctionDTO"}
      {...props}
    />
  );
}
