import { nanoid } from "nanoid";
import React, { useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  MiniMap,
  Node,
  ReactFlowInstance,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import { useToggle } from "../../hooks/useToggle";
import CancelButton from "../buttons/cancelButton";
import circleNode from "./customNodes/circleNode";
import CustomInputNode from "./customNodes/customInputNode";
import CustomOutputNode from "./customNodes/customOutputNode";
import CustomProcessNode from "./customNodes/customProcessNode";
import DatabaseNode from "./customNodes/databaseNode";
import DiamondNode from "./customNodes/diamondNode";
import ParallelogramNode from "./customNodes/parallelogramNode";
import rhombusNode from "./customNodes/rhombusNode";
import EditNodeOrEdgeModal from "./editNodeOrEdgeModal";
import FlowEditorToolbox from "./flowEditorToolbox";
import { defaultEdgeMarkerEnd, defaultFlowData, defaultNodeStyle } from "./models/defaultFlowData";
import { IFlow } from "./models/pbd-flow-dto";
import { EdgeTypes, NodeOrEdgeData, NodeTypes } from "./models/pbd-flow-utils";

const getNodeId = () => nanoid();

const graphStyles = { width: "100%", height: "69vh" };

const nodeTypes = {
  customProcess: CustomProcessNode,
  customInput: CustomInputNode,
  customOutput: CustomOutputNode,
  circle: circleNode,
  rhombus: rhombusNode,
  diamond: DiamondNode,
  parallelogram: ParallelogramNode,
  database: DatabaseNode,
};

interface IProps {
  showToolbox?: boolean;
  value?: IFlow;
  onChange?: (type: IFlow) => void;
  isEditable?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
}

function FlowEditor(props: IProps) {
  return (
    <ReactFlowProvider>
      <FlowEditorInner {...props} />
    </ReactFlowProvider>
  );
}

function FlowEditorInner(props: IProps) {
  const { showToolbox, value = defaultFlowData, onChange, isEditable = false, onCancel, onSubmit } = props;
  const { t } = useTranslation();

  const [nodes, setNodes, onNodesChange] = useNodesState<NodeOrEdgeData>(value.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<NodeOrEdgeData>(value.edges);
  const { setViewport } = useReactFlow();
  const [selected, setSelected] = React.useState<NodeOrEdgeData>();
  const [showModal, toggleShowModal] = useToggle();
  const [rfInstance, setRfInstance] = React.useState<ReactFlowInstance>();
  const [edgeType, setEdgeType] = React.useState<EdgeTypes>("default");
  const [edgeIsAnimated, setEdgeIsAnimated] = React.useState<boolean>(false);

  // React.useEffect(() => {
  //   if (rfInstance && onChange) {
  //     const flow = rfInstance.toObject();
  //     console.log(flow);
  //     onChange(flow);
  //   }
  // }, [edges, nodes]);

  const handleSubmit = () => {
    if (rfInstance && onChange && onSubmit) {
      const flow = rfInstance.toObject();
      onChange(flow);
      onSubmit();
    } else {
      throw Error("Not implemented");
    }
  };

  const handleInit = (dto: ReactFlowInstance) => {
    setRfInstance(dto);
    onChange?.(dto.toObject());
  };

  const updateNode = (dto: NodeOrEdgeData) => {
    setNodes((current) =>
      current.map((obj) => {
        if (obj.id == dto.id) {
          console.log(dto);
          obj.data = {
            ...obj.data,
            ...dto,
          };
          obj.type = dto.type;
          obj.style = {
            ...obj.style,
            backgroundColor: dto.backgroundColor,
            color: dto.fontColor,
            borderColor: dto.borderColor,
          };
          return obj;
        }
        return obj;
      }),
    );
  };

  const updateEdge = (dto: NodeOrEdgeData) => {
    setEdges((current) =>
      current.map((edge) => {
        if (edge.id == dto.id) {
          edge.label = dto.label;
          edge.animated = dto.isAnimated;
          edge.labelBgStyle = { fill: dto.backgroundColor };
          edge.labelStyle = { fill: dto.fontColor };
          edge.style = {
            stroke: dto.borderColor,
          };
          edge.type = dto.type;
          edge.data = { ...edge.data, ...dto };
        }
        return edge;
      }),
    );
  };

  const onConnect = useCallback(
    (connection: Connection) =>
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            type: edgeType,
            animated: edgeIsAnimated,
            markerEnd: defaultEdgeMarkerEnd,
          },
          eds,
        ),
      ),
    [edgeIsAnimated, edgeType, setEdges],
  );

  const onSetEdgeAnimation = (_checked: boolean) => setEdgeIsAnimated(_checked);

  const onSetEdgeType = (type: EdgeTypes) => setEdgeType(type);

  const onAdd = useCallback(
    (type: NodeTypes) => {
      const newNode: Node = {
        id: getNodeId(),
        data: { label: "Added node" },
        position: { x: Math.max(Math.random(), 0.4) * 200, y: Math.max(Math.random(), 0.4) * 200 },
        type: type,
        style: defaultNodeStyle,
        // type: type == "Process" ? "customProcess" : type,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes],
  );

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = value;
      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport ?? { x: 0, y: 0, zoom: 1 };
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
  }, [value, setEdges, setNodes, setViewport]);

  const handleEdit = async (dto: NodeOrEdgeData) => {
    console.log(dto);
    if (dto.kind == "Node") {
      updateNode(dto);
    } else if (dto.kind == "Edge") {
      updateEdge(dto);
    } else {
      throw Error("Not implemented");
    }
    setSelected(undefined);
    toggleShowModal();
  };

  const handleDelete = (dto: NodeOrEdgeData) => {
    if (dto.kind == "Node") {
      setNodes((current) =>
        current.filter((obj) => {
          return obj.id !== dto.id;
        }),
      );
    } else if (dto.kind == "Edge") {
      setEdges((current) =>
        current.filter((obj) => {
          return obj.id !== dto.id;
        }),
      );
    }
    setSelected(undefined);
    toggleShowModal();
  };

  const onNodeClick = (_event: React.MouseEvent, node: Node<NodeOrEdgeData>) => {
    if (!isEditable) return;
    setSelected({
      ...node.data,
      kind: "Node",
      type: node.type ?? "process",
      id: node.id,
      backgroundColor: node.style?.backgroundColor ?? "white",
      borderColor: node.style?.borderColor,
    });
    toggleShowModal();
  };

  const onEdgeClick = (_event: React.MouseEvent, edge: Edge<NodeOrEdgeData>) => {
    if (!isEditable) return;
    setSelected({ ...edge.data, kind: "Edge", id: edge.id, label: edge.label?.toString(), type: edge.type });
    toggleShowModal();
  };

  return (
    <>
      {showToolbox && (
        <FlowEditorToolbox
          onAdd={onAdd}
          onRestore={onRestore}
          onSetEdgeAnimation={onSetEdgeAnimation}
          onSetEdgeType={onSetEdgeType}
        />
      )}
      <div style={graphStyles}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeDoubleClick={onNodeClick}
          onEdgeDoubleClick={onEdgeClick}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={handleInit}
          nodeTypes={nodeTypes}
          nodesConnectable={isEditable}
          nodesDraggable={isEditable}
          fitView
          // Required to make links clickable
          // elementsSelectable={isEditable}
        >
          <Controls showInteractive={isEditable} />
          <Background />
          <MiniMap />
        </ReactFlow>
      </div>
      {isEditable && onCancel && (
        <Form.Group className="mb-3">
          <CancelButton onClick={onCancel} />
          <Button onClick={handleSubmit}>{t("Save")}</Button>
        </Form.Group>
      )}
      {selected && (
        <EditNodeOrEdgeModal
          modal={showModal}
          toggle={toggleShowModal}
          onEdit={handleEdit}
          selectedObject={selected}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}

export default FlowEditor;
