import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Tooltip } from "reactstrap";

import { IBaseManyToManyDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import { ConnectionDirection, ConnectionTypes } from "../../../../../Models/BaseClasses/BaseManyToMany";
import JsonHelpers from "../../../../../services/Json/jsonHelpers";
import FormattedUserInput from "../../text/formattedUserInput";

interface IProps {
  manyToMany: IBaseManyToManyDTO;
  to: ConnectionTypes;
  handleClick?: (id: number) => void;
  id: number;
}

function ConnectionTypeDiv(props: IProps) {
  const { t } = useTranslation();
  const { manyToMany, to, handleClick, id } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const connectionDirection = React.useMemo(() => {
    if (manyToMany.connectionDirection) {
      return JsonHelpers.parse<ConnectionDirection>(manyToMany.connectionDirection);
    }
    return undefined;
  }, [manyToMany.connectionDirection]);
  const tooltipId = `${to}_${id}`;

  if (!connectionDirection) return null;

  return (
    <>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={tooltipId}
        id={tooltipId}
        toggle={toggle}
        autohide={false}
      >
        {t("Source")}: {t(connectionDirection.from.toString())}
        {manyToMany.category && (
          <div>
            {t("Category")}: {manyToMany.category}
          </div>
        )}
        {manyToMany.description && (
          <div>
            {t("Description")}:<FormattedUserInput content={manyToMany.description} />
          </div>
        )}
      </Tooltip>

      <Badge onClick={() => (handleClick ? handleClick(id) : undefined)} className="me-1" id={tooltipId}>
        <i className={`fas fa-long-arrow-alt-${connectionDirection.to == to ? "right" : "left"}`} />
      </Badge>
    </>
  );
}

export default ConnectionTypeDiv;
