import { EntityKey, ProductId } from "../../../generatedCode/pbd-core/pbd-core-api";

import { IProductConfigInterface } from "../../../Constants/productConfig";
import StringHelpers from "../../../Helpers/StringHelpers";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import { MeAsUser } from "../UserSettings/models/me-as-user";

const devDomains = ["dev.", "localhost"];
const morgaDomain = "172.16.0.37";
const bvsAGDomain = "192.168.0.48";
const bvsGmbh = "qmbasegmbh";
const bisAuktion = "bisauktion";
const netzlinkDomains = ["qm.netzlink.com", "test462.qmbase.com"];
const rlbDocuPro = "rlb-eg.docupro.de";
const drkUrl = "drk-betreuungsdienste-westfalen.";
const unitedCapsUrl = "unitedcaps.";
const panocap = "panocap.";
const kwkBraun = "kwk-braun.";
const hanssack = "hanssack";
const ancora = "sam.";
const gwwDomains = [
  "@gww-netz.de",
  "@cm-industrie.de",
  "@1a-zugang.de",
  "@femos-zenit.de",
  "@stiftung-zenit.org",
  "@xcyc.de",
];
const gwwUrl = "://qmbase";
const testDomain = "test";
const maximatorDomain = "maximator.";
const sitaUrl = "qmbase.sita-bauelemente.de";
const manitowoc = "manitowoc.";
const TWOcommunicate = "2communicate.";
const fibexus = "fibexus.";
const pslgmbh = "pslgmbh.";
const mubea = "mubea.";
const wuellnerKaiser = "wuellner-kaiser.";
const weerulin = "weerulin.";
const thermsys = "thermsys.";
const senostic = "senostic.";
const selatec = "selatec.";
const helmedica = "helmedica.";
const fairPlay = "fairplay-service.";
const richterOnPrem = "qmbase.richter-aluminium.com";
const mobilog = "mobilog.";
const hackenberg = "hackenberg-group.qmbase.com";

export default class FeatureFlagService {
  static isAvailableForDev() {
    if (devDomains.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isDocumentManagementTaskAvailable() {
    const availableFor = [...devDomains];
    availableFor.push(morgaDomain);
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isWorkflowRuleAvailable() {
    const availableFor = [...devDomains];
    availableFor.push(morgaDomain);
    availableFor.push(bvsAGDomain);
    availableFor.push(...netzlinkDomains);
    availableFor.push(weerulin);
    availableFor.push("qmbase.richter-aluminium.com");
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isTaskPrioritizationAvailable() {
    const availableFor = ["dev.", "intern.", "localhost"];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isTrainingDemandAvailable() {
    const availableFor = ["dev.", "localhost"];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isMaximator() {
    const availableFor = ["maximator.", "localhost"];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isImsBaseServer() {
    const availableFor = ["imsbase.de"];
    if (availableFor.find((x) => window.location.host.toLowerCase().includes(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  /**Redirect uri in registered app in Azure needs to be adjusted as well. */
  static isExternalLoginAvailable() {
    //qm => qm.netzlink.com
    // host => qmbase => Gemeinnützige Werkstätten und Wohnstätten GmbH Sindelfingen
    const availableFor = [
      "rhb.",
      "localhost",
      "dev.",
      "qm.",
      rlbDocuPro,
      "weidling.",
      "eos-immobilienworkout.",
      "aargauverkehr.qmbase.com",
      "qmbase.ssv-kroschke.de",
      "veith-it.qmbase.com",
      "test",
      manitowoc,
      mobilog,
      weerulin,
    ];
    if (
      availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null ||
      window.location.host.toLowerCase() == "qmbase"
    ) {
      return true;
    } else {
      return false;
    }
  }

  static is2FaAvailable() {
    //qm => qm.netzlink.com
    const availableFor = ["localhost", "dev.", "intern.", "choice."];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isRhb() {
    const availableFor = ["rhb.", "localhost"];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Checks if it is inside the mubea domain
   * @param host window.location.host
   *
   */
  static isMubea(host: string) {
    const availableFor = ["mubea."];
    if (availableFor.find((x) => host.toLowerCase().includes(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isGroupsAvailable() {
    const availableFor = ["localhost", "dev", "test584", "mubea"];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isSpecialGoalExportAvailable(meAsUser: MeAsUser) {
    if (hasRole(meAsUser, ["cr:GwwExport"], false, false)) return true;
    return StringHelpers.endsWithAny(gwwDomains, meAsUser.user.email ?? "");
  }

  static isConsumptionPlanAvailable() {
    //qm => qm.netzlink.com
    const availableFor = ["localhost", "dev.", "intern."];
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isApiCustomFieldAvailable(meAsUser: MeAsUser) {
    const availableFor = ["localhost", "dev.", "intern."];
    if (StringHelpers.endsWithAny(gwwDomains, meAsUser.user.email ?? "")) {
      return true;
    }
    if (availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null) {
      return true;
    } else {
      return false;
    }
  }

  static isCompanyFunctionFormulaAvailable() {
    const availableFor = devDomains.concat([maximatorDomain, weerulin]);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isTenantJobDescriptionAvailable() {
    const availableFor = devDomains.concat([maximatorDomain]);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isCarenavigatorCertificateAvailable(meAsUser: MeAsUser, productConfig: IProductConfigInterface) {
    return productConfig.productId == ProductId.CareNavigator || hasRole(meAsUser, [PbdRoles.Dev]);
  }

  static isFlowChartAvailable() {
    const availableFor = devDomains
      .concat(
        ...[
          "weidling.",
          "crosspack.",
          "bremi.",
          pslgmbh,
          "intern.",
          "eos-immobilienworkout.",
          "kamondy.",
          weerulin,
          thermsys,
          "gebetsroither.",
          "mmw-hamm.",
          "selatec.",
          "wettstein.",
          "drk-betreuungsdienste-westfalen",
          unitedCapsUrl,
          sitaUrl,
          hanssack,
        ],
      )
      .concat(...netzlinkDomains);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isMarkdownAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isAccountRequestAvailable() {
    const availableFor = devDomains;
    availableFor.push(mubea);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isOrganizationFlowChartAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isCustomFormRatingAvailable() {
    const availableFor = devDomains.concat(
      ...["helmedica.", "test", weerulin, thermsys, bvsGmbh, bisAuktion, senostic],
    );
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isCustomFormUsageTypeAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isDocumentImportAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isUserImportAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isIntegrationAvailable() {
    const availableFor = devDomains.concat("weidling.");
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isQualificationConnectionRequirementAvailable() {
    const availableFor = devDomains.concat(...["intern.", helmedica, "test", thermsys]);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isGenericConnectionAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isProjectMigrationAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isWatchingAvailable(meAsUser: MeAsUser, entityKey?: EntityKey) {
    // return true;
    const allowedKeys = [EntityKey.ToDo, EntityKey.Goal, EntityKey.Claim];
    const availableFor = devDomains.concat(
      ...[
        weerulin,
        "test",
        gwwUrl,
        "intern.",
        drkUrl,
        unitedCapsUrl,
        panocap,
        kwkBraun,
        wuellnerKaiser,
        sitaUrl,
        hackenberg,
        richterOnPrem,
      ],
    );
    const availableForResult =
      availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null ||
      StringHelpers.endsWithAny(gwwDomains, meAsUser.user.email ?? "");
    return entityKey && allowedKeys.includes(entityKey) && availableForResult;
  }

  static isCostTemplateAvailable() {
    const availableFor = devDomains;
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isCustomFormTemplateAvailable() {
    const availableFor = devDomains.concat(...["helmedica.", "test", weerulin, bisAuktion, ancora]);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isEmailTemplateAvailable() {
    const availableFor = devDomains.concat(mubea);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static canChangeEmailService() {
    const availableFor = devDomains.concat(...["neska.", mobilog]);
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isOverviewQMonitorAvailable() {
    const availableFor = [...devDomains];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static canAdminConnectUserToTenant() {
    const availableFor = [...devDomains, "weidling."];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static canSellToStore() {
    const availableFor = [...devDomains, "intern."];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static kpiDocNeckel() {
    const availableFor = [...devDomains, "intern.", mubea];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static ideaNewCosts() {
    const availableFor = [...devDomains, "intern."];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isFieldKeysAvailable() {
    const availableFor = [...devDomains, "intern.", mubea];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isArticleReviewAvailable() {
    const availableFor = [...devDomains, helmedica, weerulin];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }

  static isPasswordSetAvailable() {
    const availableFor = [...devDomains, manitowoc];
    return availableFor.find((x) => window.location.host.toLowerCase().startsWith(x)) != null;
  }
}
