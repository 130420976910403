import { Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredStringSchema } from "../../../../services/validation/stringSchemas";
import FormikCustomForm from "../forms/formik/formikCustomForm";
import { FormikTextInputGroup } from "../forms/formik/formikTextInput";

interface MyFormValues {
  title: string;
}

const nameof = nameofFactory<MyFormValues>();

const getValidationSchema = (t: TFunction) => {
  const TitleSchema: yup.ObjectSchema<MyFormValues> = yup.object({
    title: requiredStringSchema(t),
  });
  return TitleSchema;
};

interface IProps {
  title: string;
  toggleEditMode: () => void;
  onSubmit: (title: string) => Promise<unknown>;
  onRefresh?: () => void;
  children?: React.ReactNode;
}

const CardTitleForm = (props: IProps) => {
  const { title, toggleEditMode, onSubmit, onRefresh } = props;
  const { t } = useTranslation();
  const initialValues: MyFormValues = { title };

  const submitter = useFormikAPISubmitter<MyFormValues, unknown>(
    (val) => onSubmit(val.title),
    [onSubmit],
    () => {
      onRefresh?.();
      toggleEditMode();
    },
  );

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
      {(formikBag) => (
        <FormikCustomForm onCancel={toggleEditMode} formikBag={formikBag}>
          <FormikTextInputGroup
            name={nameof("title")}
            hideLabel
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
          />
        </FormikCustomForm>
      )}
    </Formik>
  );
};

export default CardTitleForm;
