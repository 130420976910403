import React from "react";
import { Button } from "react-bootstrap";

import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  size?: "sm" | "lg";
  title?: string;
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomDropdownToggleButton = React.forwardRef(({ onClick, size, title }: IProps, ref) => (
  <Button
    variant="outline-secondary"
    size={size}
    //@ts-expect-error This is from an example from the docs https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    title={title}
  >
    <qmBaseIcons.EllipsisVertical />
  </Button>
));

export default CustomDropdownToggleButton;

export const CustomDropdownToggleButtonPlus = React.forwardRef(({ onClick, size, title }: IProps, ref) => (
  <Button
    variant="primary"
    className="rounded-circle"
    size={size}
    //@ts-expect-error This is from an example from the docs https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    title={title}
  >
    <qmBaseIcons.Plus />
  </Button>
));
