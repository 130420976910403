import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import {
  EntityFieldTemplateAddOrUpdateRequest,
  IEntityFieldTemplateDTO,
  PbdModule,
} from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import IdeaManagementService from "../../../pbdServices/services/IdeaManagement/ideaManagementService";
import { LoadingComponent } from "../../shared/components/loading/loadingComponent";
import { useConfirmation } from "../../shared/contexts/modalConfirmationContext";
import { useToggle } from "../../shared/hooks/useToggle";
import EntityFieldTemplateForm from "./entityFieldTemplateForm";
import EntityFieldTemplateTable from "./entityFieldTemplateTable";

interface IProps {
  app: PbdModule;
}

function EntityFieldTemplateComponent(props: IProps) {
  const { t } = useTranslation();
  const { moduleSettingsApi } = useAPIs();
  const [editMode, toggleEditMode] = useToggle();
  const [selected, setSelected] = useState<IEntityFieldTemplateDTO>();
  const { app } = props;
  const { data, isLoading, mutate } = useSWR(["/api/moduleSettings/", app, "fieldTemplates"], () =>
    moduleSettingsApi.getFieldTemplatesByModule(app),
  );
  const confirm = useConfirmation();

  const tryToDelete = (dto: IEntityFieldTemplateDTO) => {
    confirm({ itemsToDelete: [dto] })
      .then(() => moduleSettingsApi.deleteFieldTemplateByModule(app, dto.id))
      .then(() => mutate());
  };

  if (isLoading) return <LoadingComponent />;

  const handleSelect = (dto: IEntityFieldTemplateDTO) => {
    setSelected(dto);
    toggleEditMode();
  };

  return (
    <>
      <h5>{t("Field templates")}</h5>
      <hr />
      <Button onClick={toggleEditMode}>{t("Add")}</Button>
      {editMode && (
        <EntityFieldTemplateForm
          itemToUpdate={selected}
          onCancel={toggleEditMode}
          onSubmit={(x) =>
            moduleSettingsApi.addOrUpdateFieldTemplateByModule(app, new EntityFieldTemplateAddOrUpdateRequest(x))
          }
          fieldKeys={IdeaManagementService.entityFieldKeys}
          onSuccess={() => {
            toggleEditMode();
            mutate();
          }}
        />
      )}
      {data && <EntityFieldTemplateTable tableRows={data} onRowClickNew={handleSelect} onDeleteClick={tryToDelete} />}
    </>
  );
}
export default EntityFieldTemplateComponent;
