interface IProps {
  title?: string;
}

function AppWrapperFallbackLoading(props: IProps) {
  return (
    <div className="min-vh-100 d-flex h-100 justify-content-center align-items-center bg-primary-subtle">
      <span>{props.title ?? "Downloading App..."}</span>
    </div>
  );
}

export default AppWrapperFallbackLoading;
