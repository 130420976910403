import React from "react";

import { ServiceContextData } from "./service-context-data";

import config from "../../services/config";
import { IConfigApi } from "./apiBase/configApi";

export const ServiceContext = React.createContext({} as ServiceContextData);

export const useAPIServices = () => React.useContext(ServiceContext);
export const useAPIs = () => React.useContext(ServiceContext).apis;
export const useAdminAPIs = () => React.useContext(ServiceContext).adminApis;
export const useStoreAPIs = () => React.useContext(ServiceContext).storeApis;

export interface IProps {
  value: IConfigApi | ServiceContextData;
  adminUrl?: string;
  storeUrl?: string;
  children: React.ReactNode;
}
export function ServiceContextProvider(props: IProps) {
  const { value, adminUrl = config.adminApi.url, storeUrl = config.store.baseUrl.url } = props;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const services = React.useMemo(() => {
    if (value instanceof ServiceContextData) return value;

    return new ServiceContextData(value, adminUrl, storeUrl);
  }, [value, adminUrl, storeUrl]);

  return <ServiceContext.Provider value={services}>{props.children}</ServiceContext.Provider>;
}
