import { DateTime } from "luxon";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import NewBadge from "./newBadge";

interface IProps {
  createdAt: DateTime;
  lastUpdatedAt?: DateTime;
  className?: string;
}

function UpdatedBadge(props: IProps) {
  const { lastUpdatedAt, createdAt, className = "ms-2" } = props;
  const { t } = useTranslation();
  if (!lastUpdatedAt) {
    return <NewBadge createdAt={createdAt} />;
  } else {
    const recentlyUpdated = lastUpdatedAt > DateTime.now().minus({ hours: GlobalQmBaseConstants.MaxHoursUpdatedBadge });

    return (
      <>
        {recentlyUpdated && (
          <Badge className={className} pill title={lastUpdatedAt.toRelative() ?? undefined}>
            <span role="img" aria-label="On Fire">
              🔥
            </span>{" "}
            {t("Updated")}
          </Badge>
        )}
      </>
    );
  }
}

export default UpdatedBadge;
