import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import { PbdModule, SettingType } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import TemplateConnectedCard from "../../components/templateConnectedCard";

function AppConfigurationPage() {
  const { t } = useTranslation();
  const { pbdModule } = useParams<{ pbdModule: PbdModule }>() as { pbdModule: PbdModule };
  const { moduleSettingsApi } = useAPIs();
  const { data, mutate, isLoading } = useSWR([`/api/moduleSettings/${pbdModule}/`, SettingType.Templates], () =>
    moduleSettingsApi.getTemplateSettings(pbdModule),
  );

  return (
    <>
      <h2>{t(pbdModule)}</h2>
      <hr />
      <TemplateConnectedCard module={pbdModule} data={data} refreshParent={mutate} cardTitle={t("Templates")} />
    </>
  );
}
export default AppConfigurationPage;
