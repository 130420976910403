import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { NumberHelpers } from "../../../Helpers/NumberHelpers";

const backEndOptions: HttpBackendOptions = {
  // the returned path will interpolate lng, ns if provided like giving a static path
  loadPath: "/locales/{{lng}}/{{ns}}.json",

  // path to post missing resources
  addPath: "/locales/add/{{lng}}/{{ns}}",

  // allow cross domain requests
  crossDomain: false,

  // allow credentials on cross domain requests
  withCredentials: false,
  queryStringParams: { v: "1.0.0" },
};

void i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next) // Disabled to make i18next work without react
  .init(
    {
      fallbackLng: "en",
      // debug: process.env.NODE_ENV == "development" ? true : false,
      debug: false,
      // TODO: Switch to lazy loading by loading the correct namespaces where they are needed.
      ns: ["translation", "moduleInfo", "validationResult", "zod", "errorCodes", "entityKeys"],
      interpolation: {
        // React already does escaping
        escapeValue: false,
        format: function (value, format, lng) {
          if (format == "number") return NumberHelpers.convertToFractionNumber(value, 0);
          return value;
        },
      },
      supportedLngs: [
        "en",
        "de",
        // "tr", "th", "es"
      ],
      nsSeparator: "::",
      //saveMissing: true,
      //saveMissingTo: "current",
      backend: backEndOptions,
      // This is needed to make "." work in translations
      keySeparator: false,
      load: "languageOnly",
      react: { useSuspense: true },
    },
    (err, t) => {
      // initialized and ready to go!
      //document.getElementById('output').innerHTML = i18n.t('key');
    },
  );

export default i18n;
