import React from "react";
import ReactDOM from "react-dom/client";

import App from "./ClientApp/app";
import { getReactAppNode } from "./services/Apps/getReactAppNode";

ReactDOM.createRoot(getReactAppNode()).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
