import React from "react";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import SidebarNew from "../../shared/components/sidebar/sidebarNew";

/**
 * Store Sidebar
 */
const SidebarStore: React.FC = () => {
  return <SidebarNew app={PbdModule.Store} items={[]} />;
};

export default SidebarStore;
