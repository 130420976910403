import React from "react";
import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";

import { IDepartmentPositionWithChildrenDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import { LoadingComponent } from "../../loading/loadingComponent";

export const mapToOptionData = (array: BaseItem[]) => array.map((x) => new OptionData(x));
export const mapToOptionDataDepartmentPosition = (array: IDepartmentPositionWithChildrenDTO[]) =>
  array.map((x) => new OptionData({ ...x, title: `${x.title} - ${x.department.title}` }));

interface BaseItem {
  id: number | string;
  title?: string | undefined;
  color?: string;
}

export class OptionData {
  constructor(data: BaseItem) {
    this.value = data.id.toString();
    this.label = data.title ?? "undefined";
    this.color = data.color;
  }
  value: string;
  label: string;
  color?: string;
  isFixed?: boolean;
  isDisabled?: boolean;
}

interface IProps {
  /**If id is not set the name will be used */
  id?: string;
  name: string;
  data?: OptionData[];
  selected?: (string | number)[] | number;
  onChange: (selected?: OptionData[]) => void;
  isMulti?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
}

export function GenericSelectMulti(props: IProps & { onChange: (selected?: OptionData[]) => void }) {
  return <GenericSelect {...props} />;
}

interface IPropsSingle extends Omit<IProps, "onChange"> {
  onChange: (selected?: OptionData) => void;
}

export function GenericSelectSingle(props: IPropsSingle) {
  return (
    <GenericSelect
      {...props}
      onChange={(e) => {
        if (e && e.length > 1) {
          throw Error("Multiselect is not supported in this case");
        } else if (e) {
          props.onChange(e[0]);
        }
      }}
    />
  );
}

function GenericSelect(props: IProps) {
  const { id, data, name, selected, onChange, isMulti, isDisabled, isClearable } = props;
  const { t } = useTranslation();
  const [defaultValue, setDefaultValue] = React.useState<OptionData[]>();

  React.useEffect(() => {
    if (selected && data) {
      const def: OptionData[] = [];
      if (Array.isArray(selected)) {
        for (const element of selected) {
          const opt = data.find((x) => x.value == element);
          if (opt) {
            def.push(opt);
          }
        }
      } else {
        const opt = data.find((x) => x.value == selected.toString());
        if (opt) def.push(opt);
      }
      setDefaultValue(def);
    } else {
      setDefaultValue(undefined);
    }
  }, [data, selected]);

  const handleChange = (value: MultiValue<OptionData> | SingleValue<OptionData>) => {
    if (!value) {
      onChange(undefined);
    } else if (Array.isArray(value)) {
      onChange(value);
    } else {
      onChange([value as OptionData]);
    }
  };

  if (!data) return <LoadingComponent />;

  return (
    <Select
      id={id ?? name}
      aria-label={id ?? name}
      menuPosition="fixed"
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      value={defaultValue}
      isMulti={isMulti}
      name={name}
      options={data}
      onChange={handleChange}
      placeholder={`${t("Search by name")}...`}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  );
}
export default GenericSelect;
