import useSWR from "swr";

import { useAPIs } from "../../../../pbdServices/services/service-context";

import DeletedComponent from "../../../shared/components/alerts/deletedComponent";
import { useTypedParams } from "../../../shared/hooks/useTypedParams";
import EditPageAbsencePolicyContent from "../components/editPageAbsencePolicyContent";

function EditPageAbsencePolicy() {
  const { id } = useTypedParams(["id"]);
  const { absencePoliciesApi } = useAPIs();

  const { data, mutate } = useSWR(["/api/absencePolicies", id], () => absencePoliciesApi.getById(Number(id)));

  return (
    <>
      <DeletedComponent
        baseObject={data}
        refreshParent={mutate}
        onRestore={(x) => absencePoliciesApi.restore(x)}
        {...data}
      />
      {data && <EditPageAbsencePolicyContent itemToUpdate={data} refreshParent={mutate} />}
    </>
  );
}
export default EditPageAbsencePolicy;
