import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { DateTime } from "luxon";
import { CellProps, Column } from "react-table";

import { PbdStatus } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import DeadlineComponent from "../../dateTime/deadlineComponent";

interface IProps {
  deadline?: DateTime;
  status: PbdStatus;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getDeadlineColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "deadline",
    Header: <>{i18next.t("Deadline")}</>,
    accessor: (x) => x.deadline?.toMillis(),
    Cell: ({ row }: CellProps<T>) => (
      <>
        {row.original.deadline && <DeadlineComponent deadline={row.original.deadline} status={row.original.status} />}
      </>
    ),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const deadlineColumn = columnHelper.accessor("deadline.millisecond", {
  id: "deadline",
  header: () => <>{i18next.t("Deadline")}</>,
  cell: ({ row }) => (
    <>{row.original.deadline && <DeadlineComponent deadline={row.original.deadline} status={row.original.status} />}</>
  ),
});
