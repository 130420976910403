import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { IBaseIdDTO } from "../BaseClasses/BaseIdDTO";
import { NotificationItem } from "./ModuleNotification";

/**This hold all the notifications that are available for a given app */
export class AppNotifications<T = IBaseIdDTO> {
  app: PbdModule;
  totalCount: number;
  notifications: NotificationItem<T>[];
  constructor(app: PbdModule, notifications: NotificationItem<T>[]) {
    this.app = app;
    this.notifications = notifications;
    this.totalCount = notifications.reduce((pv, cv) => cv.count + pv, 0);
  }
}
