import { IEmployeeIdeaDTO, IEmployeeIdeaReportingDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { BaseKpis } from "../../Models/BaseClasses/BaseKpis";

type LocalType = IEmployeeIdeaDTO | IEmployeeIdeaReportingDTO;

export default class IdeaManagementReportingService {
  static getKpis<T extends LocalType>(all: T[], totalUrl?: string) {
    const kpis = new BaseKpis<T>(all, totalUrl);
    return kpis;
  }
}
