import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { IAddressCreateDTO, IAddressDetailsDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import CancelButton from "../../../shared/components/buttons/cancelButton";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import BaseSettingsFormHeader from "../../components/editPage/baseSettingsFormHeader";

const ValidationSchema: yup.ObjectSchema<IAddressCreateDTO> = yup.object({
  line1: yup.string().required(),
  line2: yup.string(),
  line3: yup.string(),
  zipCode: yup.string(),
  city: yup.string(),
  country: yup.string(),
  countryCode: yup.string(),
});

interface IProps {
  itemToUpdate?: IAddressDetailsDTO;
  onSubmit: (values: IAddressCreateDTO) => Promise<void>;
  onDelete?: () => void;
  onRestore?: () => void;
  onCancel?: () => void;
  backToIndexUrl?: string;
  children?: React.ReactNode;
}

function PlacesForm(props: IProps) {
  const { itemToUpdate, onSubmit, backToIndexUrl, onDelete, onCancel } = props;
  const { t } = useTranslation();
  const [addLine, setAddLine] = React.useState(0);

  function handleAddLineClick() {
    setAddLine((x) => x + 1);
  }

  const submitter = useFormikAPISubmitter<IAddressCreateDTO>((values) => onSubmit(values), [onSubmit]);

  const initialValues: IAddressCreateDTO = {
    line1: itemToUpdate?.line1,
    line2: itemToUpdate?.line2 ?? "",
    line3: itemToUpdate?.line3 ?? "",
    country: itemToUpdate?.country ?? "",
    countryCode: itemToUpdate?.countryCode ?? "",
    zipCode: itemToUpdate?.zipCode ?? "",
    city: itemToUpdate?.city ?? "",
  };

  return (
    <Card className="mb-3">
      <BaseSettingsFormHeader
        itemToUpdate={itemToUpdate}
        onDelete={itemToUpdate && itemToUpdate.connectedObjectsCount == 0 ? onDelete : undefined}
      />
      <CardBody>
        <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
          {(formikBag) => (
            <Form>
              <FormGroup>
                <Label for="line1">{t("Street, house number")}</Label>
                <Field name="line1" component={FormikTextInput} />
              </FormGroup>
              {addLine > 0 && (
                <FormGroup>
                  <React.Fragment>
                    <Label for="line2">{`${t("Line")} 2`}</Label>
                    <Field name="line2" component={FormikTextInput} placeholder={t("Optional")} />
                  </React.Fragment>
                </FormGroup>
              )}
              {addLine > 1 && (
                <FormGroup>
                  <React.Fragment>
                    <Label for="line3">{`${t("Line")} 3`}</Label>
                    <Field name="line3" component={FormikTextInput} placeholder={t("Optional")} />
                  </React.Fragment>
                </FormGroup>
              )}

              {addLine < 2 && (
                <Button color="link" size="sm" onClick={handleAddLineClick} className="p-0">
                  <qmBaseIcons.Plus /> {t("Add line")}
                </Button>
              )}

              <FormGroup>
                <React.Fragment>
                  <Label for="city">{t("City")}</Label>
                  <Field name="city" component={FormikTextInput} />
                </React.Fragment>
              </FormGroup>
              <FormGroup>
                <React.Fragment>
                  <Label for="zipCode">{t("Zip code")}</Label>
                  <Field name="zipCode" component={FormikTextInput} />
                </React.Fragment>
              </FormGroup>
              <FormGroup>
                <React.Fragment>
                  <Label for="country">{t("Country")}</Label>
                  <Field name="country" component={FormikTextInput} />
                </React.Fragment>
              </FormGroup>
              {onCancel && <CancelButton onClick={onCancel} />}
              <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {t("Save")}
              </Button>
              <FormikValidationSummary formikBag={formikBag} />
            </Form>
          )}
        </Formik>
        {props.children}
        {backToIndexUrl && <Link to={backToIndexUrl}>{t("Back to index")}</Link>}
      </CardBody>
    </Card>
  );
}

export default PlacesForm;
