import { StringSchema, addMethod, string } from "yup";

import { IOpportunityCategoryVariable, IRequiredQualificationDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { FormulaHelpers } from "../../../Helpers/formula-helpers";
import { CompanyFunctionFitnessService } from "../../../pbdServices/services/CompanyFunctions/companyFunctionFitnessService";

addMethod<StringSchema>(
  string,
  "formulaValid",
  function formulaValid(variables: IOpportunityCategoryVariable[], errorMessage: string) {
    return this.test(`formulaTest`, errorMessage, function (value) {
      const { path, createError } = this;
      if (value && FormulaHelpers.isValid(value, variables).isValid) {
        return true;
      } else {
        return createError({ path, message: errorMessage });
      }
    });
  },
);

addMethod<StringSchema>(
  string,
  "companyFunctionFormulaValid",
  function formulaValid(qualifications: IRequiredQualificationDTO[], msg: string) {
    return this.test(`formulaTest`, msg, function (value) {
      const { path, createError } = this;

      if (!value || CompanyFunctionFitnessService.isValid(value, qualifications)) {
        return true;
      } else {
        return createError({ path, message: msg });
      }
    });
  },
);
