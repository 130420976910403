import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";

import {
  CostTemplateEditDTO,
  EntityKey,
  ICostTemplateDTO,
  ICostTemplateEditDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import FormikDebugInfo from "../../shared/components/forms/formik/formikDebugInfo";
import { FormikSelectInput } from "../../shared/components/forms/formik/formikSelectInput";
import FormikSubmitButton from "../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInput } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";

const nameof = nameofFactory<ICostTemplateEditDTO>();

interface IProps {
  onSubmit: (body: CostTemplateEditDTO) => Promise<void>;
  onSuccess: () => void;
  itemToUpdate: ICostTemplateDTO;
}

const EditFormCostTemplate: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onSuccess, itemToUpdate } = props;

  const submitter = useFormikAPISubmitter<ICostTemplateEditDTO>(
    (val) => onSubmit(new CostTemplateEditDTO(val)),
    [onSubmit],
    onSuccess,
  );

  const initialValues: ICostTemplateEditDTO = {
    id: itemToUpdate.id,
    title: itemToUpdate.title,
    description: itemToUpdate.title,
    connectedEntities: itemToUpdate.connectedEntities,
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={submitter}
        // validationSchema={ValidationSchema}
      >
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />

            <FormGroup>
              <Label for={nameof("title")}>{t("Title")}</Label>
              <Field name={nameof("title")} component={FormikTextInput} />
            </FormGroup>
            <FormGroup>
              <Label for={nameof("connectedEntities")}>{t("Connected entities")}</Label>
              <Field name={nameof("connectedEntities")} component={FormikSelectInput} multiple>
                {Object.keys(EntityKey).map((x) => (
                  <option key={x} value={x}>
                    {x}
                  </option>
                ))}
              </Field>
            </FormGroup>
            <FormGroup>
              {/* <CancelButton onClick={toggleEditMode} /> */}
              <FormikSubmitButton formikBag={formikBag} />
            </FormGroup>
            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default EditFormCostTemplate;
