import randomColor from "randomcolor";

import { BoundaryLevel } from "../../../generatedCode/pbd-core/pbd-core-api";

type BsVariantNames = (typeof bsVariantArray)[number];

const bsVariantArray = ["Primary", "Secondary", "Success", "Danger", "Warning", "Info", "Light", "Dark"] as const;

const BORDER_COLORS = [
  "rgb(54, 162, 235)", // blue
  "rgb(255, 99, 132)", // red
  "rgb(255, 159, 64)", // orange
  "rgb(255, 205, 86)", // yellow
  "rgb(75, 192, 192)", // green
  "rgb(153, 102, 255)", // purple
  "rgb(201, 203, 207)", // grey
  "rgb(41, 55, 95)", // Primary
  "rgb(108, 117, 125)", // Secondary
  "rgb(40, 167, 69)", // Success
  "rgb(23, 162, 184)", // Info
  "rgb(255, 193, 7)", // Warning
  "rgb(220, 53, 69)", // danger
  "rgb(52, 58, 64)",
  "rgb(116, 47, 153)",
  "rgb(225, 244, 83)",
  "rgb(221, 133, 102)",
  "rgb(252, 136, 202)",
  "rgb(229, 75, 229)",
  "rgb(105, 145, 13)",
];

const BACKGROUND_COLORS = BORDER_COLORS.map((color) => color.replace("rgb(", "rgba(").replace(")", ", 0.5)"));

export default class ColorService {
  static getColorByBoundaryLevel(boundaryLevel: BoundaryLevel) {
    switch (boundaryLevel) {
      case BoundaryLevel.None:
        return this.getThemeColor("Secondary");
      case BoundaryLevel.Information:
        return this.getThemeColor("Info");
      case BoundaryLevel.Warning:
        return this.getThemeColor("Warning");
      default:
        throw Error("Not implemented exception");
    }
  }
  static getThemeColor(colorName: BsVariantNames) {
    const style = getComputedStyle(document.body);
    const colorToSearch = "--" + colorName.toLowerCase();
    return style.getPropertyValue(colorToSearch);
  }

  /**This gets a list of colors */
  static getColorMap(count: number): string[] {
    const baseColors = BORDER_COLORS;
    if (count > BORDER_COLORS.length) {
      const additional = count - BORDER_COLORS.length;
      const randomColors = randomColor({ count: additional, format: "rgba", alpha: 0.8 });
      baseColors.push(...randomColors);
    }
    return baseColors.slice(0, count);
  }
}
