import { useSearchParams } from "react-router-dom";
import useSWR from "swr";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../pbdServices/services/service-context";

import { useAppContext } from "../contexts/appContext";

export function useSmartViewsHook(app: PbdModule) {
  const { smartViewService } = useAPIServices();
  const { meAsUser, currentApp } = useAppContext();
  const [searchParams] = useSearchParams();
  const smartViewId = searchParams.get("smartViewId");
  const { data, mutate } = useSWR(
    [`/api/smartViews/`, currentApp, "mapped", app],
    () => smartViewService.getAllMapped(app, meAsUser),
    { revalidateOnFocus: false },
  );

  return {
    data,
    mutate,
    activeSmartViewId: smartViewId,
    activeSmartView: data?.allVisible.find((x) => smartViewId != undefined && x.id == smartViewId),
  };
}
