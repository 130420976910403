import { Button, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { useAPIServices } from "../../../pbdServices/services/service-context";

import { IProductConfigInterface } from "../../../Constants/productConfig";
import { MeAsUser } from "../../../pbdServices/services/UserSettings/models/me-as-user";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { useNotificationContext } from "../../shared/contexts/notificationContext";

interface IProps {
  productConfig: IProductConfigInterface;
  meAsUser: MeAsUser;
}

function InviteEntry({ productConfig, meAsUser }: IProps) {
  const { t } = useTranslation();
  const { addNotification } = useNotificationContext();
  const { notificationService } = useAPIServices();
  const { data } = useSWR("inviteTemplate", () =>
    notificationService.getInviteUserTemplate(productConfig, meAsUser.tenant),
  );
  return (
    <ListGroup.Item className="bg-body-tertiary">
      <p>
        <small>
          <qmBaseIcons.UserGroup className="text-muted" /> {t("Invite your team and start collaborating!")}
        </small>
      </p>
      {data && (
        <Button variant="outline-primary" onClick={() => addNotification(data)} size="sm">
          {t("Invite a colleague")}
        </Button>
      )}
    </ListGroup.Item>
  );
}

export default InviteEntry;
