import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { HttpVerbs, ITagDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import TagService from "../../../../pbdServices/services/Tags/tagService";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import RequiredRolesComponent from "../../../admin/roles/components/requiredRolesComponent";
import GenericAlert from "../../../shared/components/alerts/genericAlert";
import EntryCleanUpComponent from "../../../shared/components/buttons/entryCleanUp/entryCleanUpComponent";
import ExternalLink from "../../../shared/components/links/externalLink";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function mapDataToSettingsDTO(data?: ITagDTO[]) {
  if (!data) return undefined;
  const tableData: SettingsIndexDTO[] = [];
  data.forEach((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      color: d.color,
      backgroundColor: d.backgroundColor,
      links: [
        {
          rel: "self",
          href: SettingsRoutePaths.EditPageTags.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    tableData.push(item);
  });
  return tableData;
}
function IndexPageTags() {
  const { meAsUser, setErrorMessage, handleApiError, productConfig } = useAppContext();
  const { tagsApi } = useAPIs();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isValidating, mutate } = useSWR(
    "/api/tags",
    () =>
      tagsApi
        .getAllQuery({ includeCount: true, pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration })
        .then((resp) => resp.data),
    SwrApiHelpers.defaultIndexPageOptions(),
  );
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();

  const mappedData = React.useMemo(() => mapDataToSettingsDTO(data), [data]);

  const adminActions = TagService.getAdministrativeActions(data);

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Settings_Standard])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => tagsApi.delete(Number(s)), { handleApiError });
      }
      mutate();
    }
  };

  const handleCleanUp = async () => {
    const tags = (await tagsApi.getAllQuery({ includeCount: true })).data.filter(
      (x) => x.count != undefined && x.count == 0,
    );
    confirm({ itemsToDelete: tags }).then(() => submitDeleteRequest(tags.map((x) => x.id)));
  };

  const handleCleanUpUnusable = () => {
    const unusableEntries = data?.filter((x) => x.title == "") ?? [];
    confirm({ itemsToDelete: unusableEntries }).then(() => submitDeleteRequest(unusableEntries.map((x) => x.id)));
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Tags"
      pathToCreate={SettingsRoutePaths.CreatePageTags}
      loading={isValidating}
    >
      <GenericAlert type="info">
        {t("Tags help you to organize and structure your data.")}{" "}
        <ExternalLink href={productConfig.helpLinks.tags.url} label={t("To find out more click here.")} />
      </GenericAlert>
      <EntryCleanUpComponent
        name="tags"
        availableAuthority={adminActions.available}
        onClickUnconnectedCleanUp={handleCleanUp}
        unconnectedCleanUpPossible={adminActions.actions.cleanUpPossible}
        unconnectedDisabled={(data?.filter((x) => x.count == 0).length ?? 0) == 0}
        onClickUnusableCleanUp={handleCleanUpUnusable}
        unusableCleanUpPossible={true}
        unusableDisabled={(data?.filter((x) => x.title == "").length ?? 0) == 0}
      />

      <div className="d-flex justify-content-between">
        <h3>{t("Manage your tags")}</h3>
        <RequiredRolesComponent roles={[PbdRoles.Settings_Standard]} />
      </div>
      <hr />

      <BaseSettingsTable
        tableRows={mappedData}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePageTags)}
        setSelected={(ids) => setSelected(ids.map((x) => x))}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPageTags;
