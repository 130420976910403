import React from "react";
import { Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { IUnitCreateDTO, IUnitDTO, UnitCreateDTO, UnitEditDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import DeletedComponent from "../../../shared/components/alerts/deletedComponent";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsFormHeader from "../../components/editPage/baseSettingsFormHeader";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import UnitsForm from "../components/unitsForm";

function EditPageUnits() {
  const params = useParams<{ id: string }>();
  const { unitsApi } = useAPIs();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IUnitDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const navigate = useNavigate();

  React.useEffect(() => {
    async function getData() {
      if (params.id) {
        const item = await unitsApi.getById(Number(params.id));
        setItemToUpdate(item);
      }
      setLoading(false);
    }
    getData();
  }, [params.id, refresh, unitsApi]);

  const handleSubmit = async (itemToCreate: IUnitCreateDTO) => {
    if (params.id == null) {
      const resp = await unitsApi.create(new UnitCreateDTO(itemToCreate));
    } else {
      if (!itemToUpdate) throw new Error("Missing data");
      const resp = await unitsApi.edit(itemToUpdate.id, new UnitEditDTO({ id: itemToUpdate.id, ...itemToCreate }));
    }
    navigate(SettingsRoutePaths.IndexPagesUnits);
  };

  const submitDelete = async () => {
    if (!itemToUpdate) throw new Error("Missing data");
    const resp = await unitsApi.delete(itemToUpdate.id);
    //TODO: Error handling
    navigate(SettingsRoutePaths.IndexPagesUnits);
  };

  return (
    <>
      {!loading && (
        <Card>
          <BaseSettingsFormHeader itemToUpdate={itemToUpdate} onDelete={submitDelete} />
          <CardBody>
            <DeletedComponent
              baseObject={itemToUpdate}
              refreshParent={handleRefresh}
              onRestore={(id) => unitsApi.restore(id)}
              {...itemToUpdate}
            />
            <UnitsForm
              onSubmit={handleSubmit}
              itemToUpdate={itemToUpdate}
              onCancel={() => navigate(SettingsRoutePaths.IndexPagesUnits)}
            />
            <Link to={SettingsRoutePaths.IndexPagePlaces}>{t("Back to index")}</Link>
          </CardBody>
        </Card>
      )}
    </>
  );
}

export default EditPageUnits;
