import { HttpVerbs, PbdModule } from "../generatedCode/pbd-core/pbd-core-api";

import { ArticleRoutePaths } from "../ClientApp/articles/articleRoutePaths";
import { BaseDTO } from "../Models/BaseClasses/BaseDTO";
import { PbdModuleString } from "../Models/Enums/PbdModule";
import { LinkEnriched } from "../Models/Links/LinkEnriched";

export default class LinkHelpers {
  static httpsRegex = /^(?:[a-z]+:)?\/\//i;
  /**Checks if the link is external */
  static isExternal(url: string) {
    const tmp = document.createElement("a");
    tmp.href = url;
    return tmp.host !== window.location.host;
  }

  static sanitizeUrl(url: string) {
    if (this.isAbsolute(url)) return url;
    if (url.startsWith("/")) return url;

    return `https://${url}`;
  }

  static makeInternalUrlRelative(url: string) {
    const tmp = document.createElement("a");
    tmp.href = url;
    return tmp.href.replace(tmp.host, "").replace(this.httpsRegex, "");
  }

  /**
   * This will test against the following regex
   *  ^(?:[a-z]+:)?//
      ^ - beginning of the string
      (?: - beginning of a non-captured group
      [a-z]+ - any character of 'a' to 'z' 1 or more times
      : - string (colon character)
      )? - end of the non-captured group. Group appearing 0 or 1 times
      // - string (two forward slash characters)
      'i' - non case-sensitive flag
   * @param url 
   * @returns 
   */
  static isAbsolute(url: string) {
    const r = /^(?:[a-z]+:)?\/\//i;
    return r.test(url);
  }

  /**
   *
   * @param href https://localhost:44337/_DocumentManagement/Details/32344; https://localhost:44337/_DocumentManagement/Details/32344?_a=Edit-overview
   * @param idIsNumber
   * @returns
   */
  static getIdFromUrl(href: string, idIsNumber = true) {
    //TODO
    const articleRouteToCompare = ArticleRoutePaths.DetailsPage(PbdModule.DocumentManagement)
      .replace(":id", "")
      .replace("_", "")
      .toLowerCase();
    let linkToExtractFrom = href.replace("_", "").toLowerCase().replace("https://localhost:44337", "");

    if (this.isAbsolute(linkToExtractFrom)) {
      linkToExtractFrom = linkToExtractFrom.replace("https://", "").replace("http://", "");
      if (linkToExtractFrom.includes("/")) {
        linkToExtractFrom = linkToExtractFrom.substring(linkToExtractFrom.indexOf("/"));
      }
    }
    linkToExtractFrom = linkToExtractFrom.replace(articleRouteToCompare, "");
    if (linkToExtractFrom.includes("?")) {
      linkToExtractFrom = linkToExtractFrom.split("?")[0];
    }

    const id = linkToExtractFrom;
    return idIsNumber ? Number(id) : id;
  }

  static extractLinksHtmlString(content: string) {
    const links: HTMLAnchorElement[] = [];
    const tempHtml = document.createElement("div");
    tempHtml.innerHTML = content;
    const listOfLinks = tempHtml.querySelectorAll("a");

    for (let index = 0; index < listOfLinks.length; index++) {
      const element = listOfLinks[index];
      links.push(element);
    }

    return links;
  }

  static mapLinkListToEnrichedLink(urls: HTMLAnchorElement[]) {
    const dto: LinkEnriched[] = [];
    for (const element of urls) {
      dto.push(this.mapLinkToEnrichedLink(element));
    }

    return dto;
  }

  static mapLinkToEnrichedLink(url: HTMLAnchorElement) {
    const dto = new LinkEnriched(url);
    dto.isExternal = this.isExternal(url.href);
    dto.app = url.href.toLowerCase().includes("documentmanagement") ? PbdModuleString.DocumentManagement : null;
    dto.id = this.getIdFromUrl(url.href);
    return dto;
  }

  static addDetailsLinkList(items: BaseDTO[], linkToDetails: string) {
    for (const element of items) {
      this.addDetailsLink(element, linkToDetails);
    }
  }

  /**
   * This add or creates the list of links. It must only be used for links that life on the client. Ex. link to details page.
   * Links to the api must be handled by the backend.
   * @param item the object itself
   * @param linkToDetails link/to/details/:id
   */
  static addDetailsLink(item: BaseDTO, linkToDetails: string) {
    if (!item.id) throw new Error("Missing id");
    if (!linkToDetails.includes(":id")) throw new Error("Missing :id param in linkToDetails");
    if (!item.links) {
      item.links = [];
    }
    item.links.push({ rel: "Details", method: HttpVerbs.GET, href: linkToDetails.replace(":id", item.id.toString()) });
  }

  static sanitizeLink(link: string) {
    let sanitizedUrl = LinkHelpers.sanitizeUrl(link);
    const isExternal = LinkHelpers.isExternal(sanitizedUrl);
    if (!isExternal && LinkHelpers.isAbsolute(sanitizedUrl)) {
      sanitizedUrl = LinkHelpers.makeInternalUrlRelative(sanitizedUrl);
    }
    return { url: sanitizedUrl, isExternal: isExternal };
  }
}
