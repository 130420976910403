import { Formik } from "formik";
import { TFunction } from "i18next";
import { nanoid } from "nanoid";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IEntityTemplateDTO, IModuleSettingDTO, PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useAPISubmitter } from "../../../pbdServices/services/Api/api-submitter";
import { nullableString, requiredTitleStringSchema } from "../../../services/validation/stringSchemas";
import { FormikDTOSelect } from "../../shared/components/forms/components/formik-dto-select";
import FormikCustomForm from "../../shared/components/forms/formik/formikCustomForm";
import { FormikHtmlInputGroup } from "../../shared/components/forms/formik/formikHtmlInput";
import { FormikInputGroupWrapper } from "../../shared/components/forms/formik/formikInputGroupWrapper";
import { FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import { TagTitleKeySelectMulti } from "../../shared/components/inputControl/select/v2/dto-select";
import FormikStringArrayInput from "../../todos/components/formikStringArrayInput";

const nameof = nameofFactory<IEntityTemplateDTO>();

const getValidation = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IEntityTemplateDTO> = yup.object({
    title: requiredTitleStringSchema(t),
    id: yup.string().required(),
    description: nullableString,
    contentTitle: nullableString,
    content: nullableString,
    tags: yup.array(yup.string().required()),
    checkListItems: yup.array(yup.string().required()),
  });
  return ValidationSchema;
};

interface IProps {
  onCancel: () => void;
  itemToUpdate?: IEntityTemplateDTO;
  onSubmit: (values: IEntityTemplateDTO) => Promise<IModuleSettingDTO>;
}

function EditFormTemplate(props: IProps) {
  const { itemToUpdate, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const submitter = useAPISubmitter<IEntityTemplateDTO, IModuleSettingDTO>(onSubmit, [], () => onCancel());

  const initialValues: IEntityTemplateDTO = {
    id: itemToUpdate?.id ?? nanoid(),
    title: itemToUpdate?.title ?? "",
    description: itemToUpdate?.description ?? "",
    contentTitle: itemToUpdate?.contentTitle ?? "",
    content: itemToUpdate?.content ?? "",
    tags: itemToUpdate?.tags,
    checkListItems: itemToUpdate?.checkListItems,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidation(t)}>
      {(formikBag) => (
        <FormikCustomForm formikBag={formikBag} onCancel={onCancel} submitLabel={t("Save")}>
          <FormikTextInputGroup name={nameof("title")} />
          <FormikTextInputGroup name={nameof("description")} textarea />
          <FormikTextInputGroup
            name="contentTitle"
            label={t("Template title")}
            formText={t("This will be the title of the newly created item")}
          />
          <FormikHtmlInputGroup name={nameof("content")} label={t("Template content")} />
          <Form.Group className="mb-3">
            <Form.Label>{t("Tags")}</Form.Label>
            <FormikDTOSelect
              fieldName="tags"
              SelectComponent={TagTitleKeySelectMulti}
              query={PbdModule.None}
              required
            />
            <Form.Text color="muted">{t("This tags will be directly added to the created item")}</Form.Text>
          </Form.Group>
          <FormikInputGroupWrapper name={nameof("checkListItems")} label={t("Checklist")}>
            <FormikStringArrayInput name={nameof("checkListItems")} />
          </FormikInputGroupWrapper>
        </FormikCustomForm>
      )}
    </Formik>
  );
}

export default EditFormTemplate;
