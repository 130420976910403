import { Field, Form as FormFormik, Formik } from "formik";
import { nanoid } from "nanoid";
import React from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import useSWR from "swr";
import * as yup from "yup";

import { IRoutingRuleItem, PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { nameofFactory } from "../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import CancelButton from "../../shared/components/buttons/cancelButton";
import FormikDebugInfo from "../../shared/components/forms/formik/formikDebugInfo";
import { FormikSelectInput } from "../../shared/components/forms/formik/formikSelectInput";
import { FormikTextInput, FormikTextInputGroup } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import GenericSelect, { mapToOptionData } from "../../shared/components/inputControl/select/genericSelect";
import { useAppContext } from "../../shared/contexts/appContext";

const nameof = nameofFactory<IRoutingRuleItem>();

const ValidationSchema: yup.ObjectSchema<IRoutingRuleItem> = yup.object({
  id: yup.string().required(),
  app: yup.mixed<PbdModule>().oneOf(Object.values(PbdModule)).required(),
  appId: yup.string().when("href", {
    is: (val: string) => val && val != "",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required().min(1).max(250),
  }),
  href: yup.string(),
});

interface IProps {
  itemToUpdate?: Partial<IRoutingRuleItem>;
  onSubmit: (dto: IRoutingRuleItem) => Promise<IRoutingRuleItem>;
  onSuccess: () => void;
  onCancel: () => void;
  routingRules: IRoutingRuleItem[];
}

function RoutingRuleForm(props: IProps) {
  const { meAsUser } = useAppContext();
  const { t } = useTranslation();
  const { inventoryItemsApi } = useAPIs();
  const { itemToUpdate, onSubmit, onSuccess, onCancel, routingRules } = props;
  const [onlyNotConnected, setOnlyNotConnected] = React.useState(true);
  const { data: inventoryItems } = useSWR("/api/inventoryItems", () => inventoryItemsApi.getAll());

  const mappedAndFiltered = React.useMemo(() => {
    if (!inventoryItems) return undefined;
    const resp = mapToOptionData(
      inventoryItems.filter((x) =>
        onlyNotConnected ? !routingRules.map((r) => r.appId).includes(x.id.toString()) : true,
      ),
    );
    return resp;
  }, [inventoryItems, onlyNotConnected, routingRules]);

  const submitter = useFormikAPISubmitter<IRoutingRuleItem, IRoutingRuleItem>(
    (val) => onSubmit(val),
    [onSubmit],
    onSuccess,
  );

  const initialValues: IRoutingRuleItem = {
    id: itemToUpdate?.id ? itemToUpdate.id : nanoid(),
    appId: itemToUpdate?.appId ?? "",
    app: itemToUpdate?.app ?? PbdModule.MaintenanceManagement,
    href: itemToUpdate?.href ?? "",
  };
  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <FormFormik>
          <FormikDebugInfo formikBag={formikBag} />
          {hasRole(meAsUser, [PbdRoles.Dev]) && (
            <>
              <FormGroup>
                <Label for="id">{t("ID")}</Label>
                <Field name={nameof("id")} component={FormikTextInput} readOnly />
              </FormGroup>
              <FormGroup>
                <Label for="app">{t("App")}</Label>
                <Field name={nameof("app")} component={FormikSelectInput}>
                  {Object.keys(PbdModule).map((x) => (
                    <option key={x} value={x} defaultValue={formikBag.initialValues.app}>
                      {t(x)}
                    </option>
                  ))}
                </Field>
              </FormGroup>
              <FormikTextInputGroup
                name={nameof("appId")}
                label={
                  <>
                    {t("App")} {t("ID")}
                  </>
                }
              />
              <FormikTextInputGroup name={nameof("href")} label={t("Url")} />
              <hr />
            </>
          )}

          <FormGroup>
            <Form.Check
              id="exampleCustomCheckbox"
              label={t("Show only entries that are not already connected")}
              checked={onlyNotConnected}
              onChange={(e) => setOnlyNotConnected(e.target.checked)}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              {t("Inventory item")} ({mappedAndFiltered?.length})
            </Label>
            <GenericSelect
              name="inventoryItem"
              onChange={(x) => {
                const id = x == undefined ? "" : x[0].value;
                formikBag.setFieldValue("appId", id);
              }}
              data={mappedAndFiltered}
            />
          </FormGroup>

          <FormGroup>
            <CancelButton onClick={onCancel} />
            <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
              {t("Save")}
            </Button>
          </FormGroup>
          <FormikValidationSummary formikBag={formikBag} />
        </FormFormik>
      )}
    </Formik>
  );
}

export default RoutingRuleForm;
