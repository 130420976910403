import useSWR from "swr";

import { useAPIs } from "../../../../../pbdServices/services/service-context";

import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
}
function InventoryInspectionSelect(props: IProps) {
  const { inventoryInspectionsApi } = useAPIs();
  const { data } = useSWR("api/inventoryInspections", () => inventoryInspectionsApi.getAll());

  if (!data) return null;

  return <CustomSelect data={data} name="inventoryInspectionId" {...props} />;
}

export default InventoryInspectionSelect;
