import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { DateTime } from "luxon";
import { CellProps, Column } from "react-table";

import { DateTimeLuxonHelpers } from "../../../../../Helpers/DateTimeLuxonHelpers";

interface IProps {
  createdAt: DateTime;
}

/**
 * This is the luxon datetime column
 * TODO: Is the accessor correct?
 */
export function getCreatedAtColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "createdAt",
    Header: <>{i18next.t("Created")}</>,
    accessor: (x) => x.createdAt.toMillis(),
    //@ts-expect-error: Not included in default typing but required for align right for date and number columns
    className: "text-end",
    Cell: ({ row }: CellProps<T>) => DateTimeLuxonHelpers.convertUtcToDateTime(row.original.createdAt),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const createdAtColumn = columnHelper.accessor("createdAt", {
  id: "createdAt",
  header: () => <>{i18next.t("Created")}</>,
  cell: (x) => DateTimeLuxonHelpers.convertUtcToDateTime(x.getValue()),
  meta: { className: "text-end" },
});
