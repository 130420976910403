import { uniqBy } from "lodash";
import { JsonStructureItem } from "react-cmdk";

import { PbdModule } from "../../generatedCode/pbd-core/pbd-core-api";

import SearchBoxAppIcon from "../../ClientApp/shared/components/navbar/searchBoxAppIcon";
import { PbdModuleString } from "../../Models/Enums/PbdModule";
import { AppVM } from "../../pbdServices/Models/App/AppVM";

export type SearchPages = PbdModule | "Root" | "DynamicSearch";
const excludedApps: PbdModule[] = [PbdModule.Admin, PbdModule.Settings, PbdModule.None, PbdModule.Store];

export type RedirectSearchType = (page: string) => void;

export type GlobalSearchCategories = "Admin" | "Settings" | PbdModuleString;

export class SearchService {
  static mapApps(available: AppVM[] | undefined, redirectToSomething: RedirectSearchType): JsonStructureItem[] {
    if (!available) return [];
    const uniqueList = uniqBy(available, (x) => x.type).sort((a, b) => a.displayName.localeCompare(b.displayName));
    return uniqueList
      .filter((x) => x.showElement && x.href != "#" && !excludedApps.includes(x.type) && x.parentNavbarItemId != 1)
      .map((x) => {
        return {
          id: x.id.toString(),
          title: x.displayName,
          children: x.displayName,
          onClick: () => redirectToSomething(x.href),
          icon: () => SearchBoxAppIcon({ app: x.type }),
          showType: false,
          // typeof: "tests",
        };
      });
  }

  static getAppsByPage(page: SearchPages) {
    switch (page) {
      case "DynamicSearch":
      case "Root":
        return [
          PbdModule.AuditManagement,
          PbdModule.Blog,
          PbdModule.ClaimManagement,
          PbdModule.Crm,
          PbdModule.CustomForms,
          PbdModule.DefectManagement,
          PbdModule.DocumentManagement,
          PbdModule.GoalManagement,
          PbdModule.KnowledgeBase,
          PbdModule.MaintenanceManagement,
          PbdModule.OpportunityManagement,
          PbdModule.Products,
          PbdModule.ProjectAndTaskManagement,
          PbdModule.TrainingManagement,
        ];
      default:
        return [page];
    }
  }
}
