import React from "react";
import { Outlet } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import AppWrapperFallbackLoading from "./shared/components/loading/appWrapperFallbackLoading";
import { AppContextProvider } from "./shared/contexts/appContext";
import { AuthenticationContextProvider } from "./shared/contexts/authenticationContext";
import AuthorizationWrapper from "./shared/contexts/authorizationWrapper";
import { CustomHelmetContextProvider } from "./shared/contexts/customHelmetContext";

export function Root() {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <CustomHelmetContextProvider>
        <AuthenticationContextProvider>
          <AuthorizationWrapper>
            <React.Suspense fallback={<AppWrapperFallbackLoading />}>
              <AppContextProvider>
                <Outlet />
              </AppContextProvider>
            </React.Suspense>
          </AuthorizationWrapper>
        </AuthenticationContextProvider>
      </CustomHelmetContextProvider>
    </QueryParamProvider>
  );
}
