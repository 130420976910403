import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { DevRoutePaths } from "./devRoutePaths";

export const devLazyRoutes: RouteObject[] = [
  {
    path: DevRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageDev")),
  },
  {
    path: DevRoutePaths.EntityDefinitions,
    Component: lazy(() => import("./pages/indexPageEntityDefinitions")),
  },
  {
    path: DevRoutePaths.AppSettings,
    async lazy() {
      const { AppSettingsPage } = await import("./pages/appSettingsPage");
      return { Component: AppSettingsPage };
    },
  },
];
