import { WorkflowActionType } from "../../../generatedCode/pbd-core/pbd-core-api";

import { PbdModuleString } from "../../Enums/PbdModule";

interface IWorkflowActionDropdown {
  value: WorkflowActionType;
  label: string;
}

export const AvailableWorkflowActions: IWorkflowActionDropdown[] = [
  {
    value: WorkflowActionType.RestrictConnectionOf,
    label: "Restrict connection of ...",
  },
  { value: WorkflowActionType.RestrictChangeStatusTo, label: "Restrict change to" },
];

export const RestrictConnectionOfValues: string[] = [
  `${PbdModuleString.ClaimManagement}_${PbdModuleString.ToDoManagement}`,
];
