import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { NumberHelpers } from "../../../Helpers/NumberHelpers";
import { MaintenanceManagementRoutePaths } from "../../maintenanceManagement/maintenanceManagementRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import IdComponent from "../../shared/components/id/idComponent";
import DescriptionList from "../../shared/components/lists/descriptionList";
import { IDescriptionListItemProps } from "../../shared/components/lists/descriptionListItem";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import DetailsPrintPageLayout from "../../shared/layouts/print/detailsPrintPageLayout";

function PrintInventoryInspectionsDonePage() {
  const { t } = useTranslation();
  const { inventoryInspectionsDoneApi } = useAPIs();
  const { inventoryInspectionDoneService } = useAPIServices();
  const { id } = useTypedParams(["id"]);
  //   const [inspectionDone, setInspectionDone] = React.useState<InventoryInspectionDoneDTO>();
  const { data: attachments } = useSWR(`api/InventoryInspectionsDone/${id}/files`, () =>
    inventoryInspectionsDoneApi.getConnectedFiles(Number(id)),
  );

  const { data } = useSWR([`api/InventoryInspectionsDone/${id}`, "vm"], () =>
    inventoryInspectionDoneService.getById(Number(id)),
  );

  const customFieldList = React.useMemo(() => {
    if (data) {
      const list: IDescriptionListItemProps[] = [];
      for (const p in data.propertiesDictionary) {
        list.push({
          label: p,
          value: data.propertiesDictionary[p].toString(),
        });
      }
      return list;
    } else {
      return [];
    }
  }, [data]);

  return (
    <DetailsPrintPageLayout
      availableOptions={["showAttachments", "showQrCode", "includeImages"]}
      title={t("Inventory inspection done report")}
      attachments={attachments}
      urlToDetailsPage={MaintenanceManagementRoutePaths.InspectionsDoneEdit.replace(":id", id)}
    >
      {data && (
        <div>
          <h5>
            {t("Inspection")}: <IdComponent id={data.id} />
          </h5>
          <h5>
            {t("Inventory Item")}: #{data.inventoryItemId} {data.inventoryItem.title}
          </h5>

          <h5>
            {t("Created")}:{" "}
            <small>
              <span className="me-1">{data.createdBy?.fullName}</span>
              {DateTimeLuxonHelpers.convertUtcToTimeFromUnknown(data.createdAt)}
            </small>
          </h5>
          <h5>
            {t("Done by")}: <small>{data.doneBy?.fullName}</small>
          </h5>
          <h5>
            {t("Done at")}: <small>{DateTimeLuxonHelpers.convertUtcToTimeFromUnknown(data.doneAt)}</small>
          </h5>
          <h5>
            {t("Category")}: <small>{data.inventoryInspection.title}</small>
          </h5>

          <h5>
            {t("Cost")}: <small>{NumberHelpers.convertToMoney(data.cost)}</small>
          </h5>

          <h5>
            {t("Inspection Ok")}:{" "}
            <small>
              {data.isInspectionOk ? <qmBaseIcons.CheckSquare style={{ color: "green" }} /> : <qmBaseIcons.Square />}
            </small>
          </h5>

          <h5>{t("Description")}</h5>
          <FormattedUserInput content={data.description} />
          {customFieldList.length > 0 && (
            <>
              <h5>{t("Custom Fields")}</h5>
              <DescriptionList list={customFieldList} />
            </>
          )}
        </div>
      )}
    </DetailsPrintPageLayout>
  );
}

export default PrintInventoryInspectionsDonePage;
