import { sortBy } from "lodash";

import {
  CategoryDTO,
  HttpVerbs,
  IAuditRequirementDTO,
  IAuditRequirementsControllerClient,
  IAuditTypesControllerClient,
  IAuditsControllerClient,
  PbdStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";
import { ControllerContextData } from "../../../generatedCode/pbd-core/pbd-core-api-services";

import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { DTOMap } from "../../../Helpers/filterMap";
import { SettingsIndexDTO } from "../../../Models/Settings/SettingsIndexDTO";
import { AuditMatrixVM, IAuditMatrixColumn, IAuditsWithRequirements } from "./models/audit-matrix-dto";
import { IAuditMatrixQueryParameters } from "./models/query-parameters";

export default class AuditMatrixService {
  auditsApi: IAuditsControllerClient;
  auditRequirementsApi: IAuditRequirementsControllerClient;
  auditsTypeApi: IAuditTypesControllerClient;

  constructor(apis: ControllerContextData) {
    this.auditsApi = apis.auditsApi;
    this.auditRequirementsApi = apis.auditRequirementsApi;
    this.auditsTypeApi = apis.auditTypesApi;
  }

  async getViewModel(query: IAuditMatrixQueryParameters) {
    const { auditRequirements, auditTypes, allAudits } = await this._getData(query);
    const vm = new AuditMatrixVM();
    if (query.auditRequirements) {
      const childAuditRequirements = auditRequirements.filter(
        (x) => x.parentAuditRequirementId && query.auditRequirements?.includes(x.parentAuditRequirementId.toString()),
      );

      const auditTypesMap = new DTOMap(auditTypes);

      vm.rows = allAudits.map((audit) => ({
        ...audit,
        requirements: auditRequirements.filterMap((x) => auditTypesMap.getById(audit.category?.id ?? -1)),
      }));

      vm.columns = childAuditRequirements.map((auditReq) => ({
        ...auditReq,
        fulfilled: false,
      }));
      vm.footer = this._getFulfilledRequirements(vm.columns, vm.rows);
    }

    return vm;
  }

  private async _getData(query: IAuditMatrixQueryParameters) {
    const audits = await this.auditsApi.getAll();
    const auditRequirements = await this.auditRequirementsApi.getAll();
    const auditTypes = await this.auditsTypeApi.getAll();

    const qInterval = DateTimeLuxonHelpers.intervalFromOpenBoundaries(query.doneFrom, query.doneTo);
    const conductedAudits = audits.filter((x) => x.doneAt && qInterval.contains(x.doneAt));

    // getting the planned audits.
    const allAudits = audits.filter((x) => x.plannedAt && qInterval.contains(x.plannedAt));
    const sortedAllAudits = sortBy(allAudits, (x) => x.plannedAt);
    return { conductedAudits, auditRequirements, auditTypes, allAudits: sortedAllAudits };
  }

  static checkRequirementIsIncluded(requirement: IAuditMatrixColumn, type?: CategoryDTO) {
    if (type) {
      return requirement.auditTypes?.map((x) => x.id).includes(type.id);
    }
    return false;
  }

  static mapAuditRequirementToSettingsDTO(data?: IAuditRequirementDTO[]) {
    if (!data) return undefined;
    return data.map((d) => {
      const item = new SettingsIndexDTO({
        id: d.id,
        title: d.title,
        createdAt: d.createdAt,
        parentAuditRequirement: d.parentAuditRequirement,
        localTags: this.getLocalTags(d),
        links: [
          {
            rel: "self",
            href: SettingsRoutePaths.EditPageAuditRequirements.replace(":id", d.id.toString()),
            method: HttpVerbs.GET,
          },
        ],
      });
      return item;
    });
  }

  static getLocalTags = (dto: IAuditRequirementDTO) => {
    const tags: string[] = [];
    if (!dto.parentAuditRequirementId) {
      tags.push("Top node");
    }
    return tags.length > 0 ? tags : undefined;
  };

  private _getFulfilledRequirements(requirements: IAuditMatrixColumn[], audits: IAuditsWithRequirements[]) {
    requirements.forEach((requirement) => {
      for (const audit of audits) {
        const included = AuditMatrixService.checkRequirementIsIncluded(requirement, audit.category);
        if (included) {
          const checker = audit.status == PbdStatus.Completed ? true : false;
          requirement.fulfilled = checker;
          if (checker) {
            break;
          }
        }
      }
    });

    return requirements;
  }
}
