import { Field, Form as FormFormik, FormikProps } from "formik";
import { TFunction } from "i18next";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { IRegisterDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import UserService from "../../../pbdServices/services/Users/userService";
import PasswordStrengthComponent from "../../profile/components/passwordStrengthComponent";
import { FormikTextInput } from "../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { useToggle } from "../../shared/hooks/useToggle";

export const getRegisterValidationSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IRegisterDTO> = yup.object({
    email: yup.string().required(t("This field is required")).email(t("Must be a valid email")).max(100),
    password: yup
      .string()
      .matches(
        UserService.strongRegex,
        t("Your password must contain lower case uppercase number and special characters"),
      )
      .required(t("This field is required"))
      .min(GlobalQmBaseConstants.PasswordRequiredLength)
      .max(100),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], t("Passwords must match"))
      .required(),
  });
  return ValidationSchema;
};

interface IProps {
  formikBag: FormikProps<IRegisterDTO & { code?: string }>;
}

function RegisterForm(props: IProps) {
  const { t } = useTranslation();
  const { formikBag } = props;
  const [passwordShown, togglePasswordVisibility] = useToggle();
  return (
    <FormFormik>
      <FormikValidationSummary formikBag={formikBag} keysToExclude={["email", "password", "confirmPassword"]} />
      <Form.Group className="mb-3">
        <Form.Label htmlFor="email">{t("Email")}</Form.Label>
        <Field name="email" component={FormikTextInput} type={"email"} autoComplete="username" />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="password">{t("Password")}</Form.Label>
        <InputGroup>
          <Field
            name="password"
            component={FormikTextInput}
            type={passwordShown ? "text" : "password"}
            autoComplete="new-password"
          />

          <Button onClick={togglePasswordVisibility} title={t("Show password")}>
            <qmBaseIcons.NotWatching />
          </Button>
        </InputGroup>
        <PasswordStrengthComponent password={formikBag.values.password} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="confirmPassword">{t("Confirm password")}</Form.Label>
        <Field
          name="confirmPassword"
          component={FormikTextInput}
          type={passwordShown ? "text" : "password"}
          autoComplete="new-password"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <div className="d-grid gap-2">
          <Button variant="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
            {formikBag.isSubmitting ? t("Loading...") : t("Register")}
          </Button>
        </div>
      </Form.Group>
    </FormFormik>
  );
}
export default RegisterForm;
