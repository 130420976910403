import { ListGroup, ListGroupItem } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import ModuleService from "../../../services/ModuleService/moduleService";
import { useAppContext } from "../../shared/contexts/appContext";
import { AdminRoutePaths } from "../adminRoutePaths";

const SubsidebarConfiguration = () => {
  const { t } = useTranslation();
  const { availableModules, meAsUser } = useAppContext();
  return (
    <>
      <h3>{t("Configuration")}</h3>
      <ListGroup>
        <ListGroupItem as={Link} to={AdminRoutePaths.GeneralConfiguration} action>
          {t("General")}
        </ListGroupItem>
        {ModuleService.isAvailable(availableModules, PbdModule.Blog) && (
          <ListGroupItem
            as={Link}
            to={AdminRoutePaths.ArticleConfigurationPage.replace(":pbdModule", PbdModule.Blog)}
            action
          >
            {t("Blog")}
          </ListGroupItem>
        )}
        {ModuleService.isAvailable(availableModules, PbdModule.DocumentManagement) && (
          <ListGroupItem
            as={Link}
            to={AdminRoutePaths.ArticleConfigurationPage.replace(":pbdModule", PbdModule.DocumentManagement)}
            action
          >
            {t("Document management")}
          </ListGroupItem>
        )}

        {ModuleService.isAvailable(availableModules, PbdModule.Employees) && (
          <ListGroupItem as={Link} to={AdminRoutePaths.EmployeeConfiguration} action>
            {t("Employees")}
          </ListGroupItem>
        )}

        {ModuleService.isAvailable(availableModules, PbdModule.Crm) && (
          <ListGroupItem as={Link} to={AdminRoutePaths.CrmConfiguration} action>
            {t("CRM")}
          </ListGroupItem>
        )}

        {ModuleService.isAvailable(availableModules, PbdModule.IdeaManagement) && (
          <ListGroupItem
            as={Link}
            to={AdminRoutePaths.IdeaManagementConfiguration}
            action
            // active={AdminRoutePaths.IdeaManagementConfiguration == location.pathname}
          >
            {t("Idea management")}
          </ListGroupItem>
        )}
        <ListGroupItem as={Link} to={AdminRoutePaths.ConfigurationsStartpage} action>
          {t("Start page")}
        </ListGroupItem>
        {ModuleService.isAvailable(availableModules, PbdModule.ProjectAndTaskManagement) && (
          <ListGroupItem
            as={Link}
            to={AdminRoutePaths.AppConfigurationPage.replace(":pbdModule", PbdModule.ToDoManagement)}
            action
          >
            {t(PbdModule.ToDoManagement)}
          </ListGroupItem>
        )}
        {ModuleService.isAvailable(availableModules, PbdModule.ClaimManagement) && (
          <ListGroupItem
            as={Link}
            to={AdminRoutePaths.AppConfigurationPage.replace(":pbdModule", PbdModule.ClaimManagement)}
            action
          >
            {t(PbdModule.ClaimManagement)}
          </ListGroupItem>
        )}
        {ModuleService.isAvailable(availableModules, PbdModule.KnowledgeBase) && (
          <ListGroupItem
            as={Link}
            to={AdminRoutePaths.ArticleConfigurationPage.replace(":pbdModule", PbdModule.KnowledgeBase)}
            action
          >
            {t("Wiki")}
          </ListGroupItem>
        )}
        {hasRole(meAsUser, [PbdRoles.Dev]) && (
          <ListGroupItem color="warning" as={Link} to={AdminRoutePaths.IndexPageModuleSettings} action>
            {t("Module settings (DEV)")}
          </ListGroupItem>
        )}
      </ListGroup>
    </>
  );
};

export default SubsidebarConfiguration;
