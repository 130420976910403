import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { BooleanParam, NumberParam, useQueryParams, withDefault } from "use-query-params";

import { DepartmentPositionsQueryField, IDepartmentPositionDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { LuxonDateTimeParam } from "../../../Helpers/QueryHelpers";
import { ErrorMessage } from "../../../Models/Errors/ErrorMessage";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { IDepartmentPositionVM } from "../../../pbdServices/services/DepartmentPositions/models/department-position-vm";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import BaseSettingsIndexCard from "../../settings/components/baseSettingsIndexCard";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { TableClickCommand } from "../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../shared/contexts/appContext";
import { useConfirmation } from "../../shared/contexts/modalConfirmationContext";
import { useFuzzy } from "../../shared/hooks/useFuzzy";
import { DepartmentPositionTable } from "../components/departmentPositionTable";

function IndexPageDepartmentPositions() {
  const { meAsUser, setErrorMessage } = useAppContext();
  const { t } = useTranslation();
  const { departmentPositionsApi } = useAPIs();
  const { departmentPositionService } = useAPIServices();
  const confirm = useConfirmation();
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<IDepartmentPositionDTO[]>();
  const [query, setQuery] = useQueryParams({
    createdFrom: withDefault(LuxonDateTimeParam, undefined),
    createdTo: withDefault(LuxonDateTimeParam, undefined),
    isDeleted: withDefault(BooleanParam, undefined),
    tenantCount: withDefault(NumberParam, undefined),
    employeeCount: withDefault(NumberParam, undefined),
  });

  const { data, mutate, isValidating } = useSWR(["/api/departmentPositions", query], async () => {
    const dpFromApi = await departmentPositionsApi.getAllQuery({
      fields: [
        DepartmentPositionsQueryField.Tenants,
        DepartmentPositionsQueryField.ChildDepartmentPositions,
        DepartmentPositionsQueryField.ParentDepartmentPositions,
        DepartmentPositionsQueryField.CompanyFunctions,
      ],
      createdFrom: query.createdFrom,
      createdTo: query.createdTo,
      isDeleted: query.isDeleted,
      tenantCount: query.tenantCount,
      employeeCount: query.employeeCount,
    });
    return departmentPositionService.mapToVM(dpFromApi);
  });

  const { result, keyword, search } = useFuzzy<IDepartmentPositionVM>(data ?? [], {
    keys: ["title", "description", "id"],
  });

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;

    switch (action) {
      case "Export":
        if (data) {
          departmentPositionService.exportToCsv(selected ?? data);
        }
        break;
      case "Delete":
        if (selected) {
          confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected));
        }
        break;
      default:
        throw new Error("Not implemented");
    }
  };

  const submitDeleteRequest = async (items: IDepartmentPositionDTO[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Admin])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => departmentPositionsApi.hardDelete(Number(s.id)));
      }
      mutate();
    }
  };

  const handleCreateClick = () => navigate(SettingsRoutePaths.CreatePageDepartmentPositions);

  return (
    <BaseSettingsIndexCard
      data={result}
      cardTitle={t("Department positions")}
      pathToCreate={SettingsRoutePaths.CreatePageDepartmentPositions}
      loading={isValidating}
      showRecycleBin={false}
    >
      <DepartmentPositionTable
        tableRows={result}
        columnsVisibleDefault={["title", "department"]}
        setSelected={setSelected}
        availableTableActions={["Delete", "Export"]}
        columnsToExclude={["isPrimary"]}
        onClick={handleTableClick}
        onCreateClick={handleCreateClick}
        onCreateButtonTitle={t("New")}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPageDepartmentPositions;
