import { FocusEventHandler } from "react";
import useSWR from "swr";

import { useAPIs } from "../../../../../pbdServices/services/service-context";

import CustomSelect from "./customSelect";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selectedIds?: number[];
  isMulti?: boolean;
  isClearable?: boolean;
  onBlur?: FocusEventHandler;
}

function AbsenceTypeSelect(props: IProps) {
  const { selectedIds, isMulti, isClearable, onChange, onBlur } = props;
  const { absenceTypesApi } = useAPIs();
  const { data } = useSWR("api/absenceTypes", () => absenceTypesApi.getAll());

  if (!data) return null;

  return (
    <CustomSelect
      data={data}
      onChange={onChange}
      name="absenceTypeId"
      isMulti={isMulti}
      isClearable={isClearable}
      onBlur={onBlur}
      selectedIds={selectedIds}
      invalid={false}
    />
  );
}

export default AbsenceTypeSelect;
