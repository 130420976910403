import { DateTime } from "luxon";

import {
  ApplicationGroupAssignedDTO,
  ApplicationUserClaimDTO,
  ApplicationUserDTO,
  EntityKey,
  IApplicationUserDTO,
  IMeDTO,
  TenantDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

import { ClaimTypeNames } from "../../../../Constants/ClaimTypeNames";
import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { ProductNames } from "../../../../Constants/ProductNames";
import { DTOMap } from "../../../../Helpers/filterMap";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { MeAsUser } from "../../../services/UserSettings/models/me-as-user";
import { TenantsMockData } from "./tenantMockData";

const MockClaims: ApplicationUserClaimDTO[] = [
  new ApplicationUserClaimDTO({
    claimType: ClaimTypeNames.qmBase_ProductName,
    claimValue: ProductNames.qmBase,
  }),
  new ApplicationUserClaimDTO({
    claimType: ClaimTypeNames.TenantId,
    claimValue: "1",
  }),
];

const MockAssignedGroups: ApplicationGroupAssignedDTO[] = [
  new ApplicationGroupAssignedDTO({
    id: "myGroupId",
    name: "myGroup",
  }),
];

const johnDoeMockUser = new ApplicationUserDTO({
  userName: "john@doe.com",
  email: "john@doe.com",
  createdAt: DateTime.fromISO("2022-01-01"),
  lastSuccessfulLogin: DateTime.fromISO("2022-01-01"),
  accessFailedCount: 0,
  id: "b77d409a-10cd-4a47-8e94-b0cd0ab50aa1",
  roles: [],
  claims: MockClaims,
  logins: [],
  emailConfirmed: true,
  hasLocalAccount: true,
  groups: [],
  twoFactorEnabled: false,
  entityKey: EntityKey.ApplicationUser,
});

export class ApplicationUserMockData extends DTOMap<IApplicationUserDTO, string> {
  constructor() {
    super([
      johnDoeMockUser,
      {
        userName: "max@mustermann.com",
        email: "max@mustermann.com",
        createdAt: DateTime.now(),
        lastSuccessfulLogin: DateTime.now(),
        accessFailedCount: 0,
        id: "b77d409a-10cd-4a47-8e94-b0cd0ab50999",
        roles: [],
        claims: [],
        logins: [],
        emailConfirmed: true,
        hasLocalAccount: true,
        groups: MockAssignedGroups,
        twoFactorEnabled: false,
        entityKey: EntityKey.ApplicationUser,
      },
    ]);
  }

  getJohnDoe() {
    return johnDoeMockUser;
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MockMeAsUser_JohnDoe_DTO: IMeDTO = {
  tenant: new TenantDTO(new TenantsMockData().getJohnDoe()),
  user: new ApplicationUserMockData().getJohnDoe(),
  settings: [],
  isAuthenticated: true,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MockMeAsUser_JohnDoe: MeAsUser = {
  tenant: new TenantsMockData().getJohnDoe(),
  user: new ApplicationUserMockData().getJohnDoe(),
  roles: [PbdRoles.Admin],
  settings: [],
  groups: [],
  claims: new ApplicationUserMockData().getJohnDoe().claims ?? [],
  isReadOnly: false,
};

const emailToActivateUser: IApplicationUserDTO = {
  userName: "emailToActivateProfile@test.de",
  email: "emailToActivateProfile@test.de",
  createdAt: DateTime.fromISO("2022-01-01"),
  lastSuccessfulLogin: DateTime.fromISO("2022-01-01"),
  accessFailedCount: 0,
  id: "b77d409a-10cd-4a47-8e94-b0cd0ab50999",
  roles: [],
  claims: [],
  logins: [],
  emailConfirmed: true,
  hasLocalAccount: true,
  groups: [],
  twoFactorEnabled: false,
  entityKey: EntityKey.ApplicationUser,
};

export const MockMeAsUser_NoTenant: IMeDTO = {
  user: new ApplicationUserDTO(emailToActivateUser),
  isAuthenticated: true,
  settings: [],
};

export const MockMeAsUser_SupportQmBase: MeAsUser = {
  user: new ApplicationUserDTO({
    ...emailToActivateUser,
    email: GlobalQmBaseConstants.SupportMail,
    userName: GlobalQmBaseConstants.SupportMail,
  }),
  tenant: new TenantDTO(),
  settings: [],
  roles: [PbdRoles.Admin],
  groups: [],
  claims: new ApplicationUserMockData().getJohnDoe().claims ?? [],
  isReadOnly: false,
};
