import { DateTime } from "luxon";

import { ICategoryDTO, ILinkObject, ITimeIntervalDTO } from "../../generatedCode/pbd-core/pbd-core-api";

import { IHaveErrors } from "../BaseClasses/IHaveErrors";
import { CustomFieldType } from "../Enums/CustomFieldType";
import { KeyValue } from "../Shared/KeyValue";
import { TimeIntervalDTO } from "../TimeInterval/TimeIntervalDTO";

interface ISettingsIndexDTO {
  id: number | string;
  title: string;
  color?: string;
  type?: CustomFieldType;
  isRecurring?: boolean;
  /**If the event is recurring the timeInterval must be set */
  monitoringInterval?: TimeIntervalDTO | ITimeIntervalDTO;
  useWarningTime?: boolean;
  warningTimeInterval?: TimeIntervalDTO;
  parentAuditRequirement?: ICategoryDTO;
  backgroundColor?: string;
  createdAt?: DateTime;
  links: ILinkObject[];
  /**Old custom fields for inventoryInspections and inventoryCategories. Do not use for anything else */
  properties?: string[];
  /** for qualification fitness implementation*/
  qualificationWeighting?: number;
  errors?: KeyValue[];
  localTags?: string[];
}

export class SettingsIndexDTO implements IHaveErrors, ISettingsIndexDTO {
  constructor(dto: ISettingsIndexDTO) {
    this.id = dto.id;
    this.title = dto.title;
    this.color = dto.color;
    this.type = dto.type;
    this.isRecurring = dto.isRecurring;
    this.monitoringInterval = dto.monitoringInterval;
    this.useWarningTime = dto.useWarningTime;
    this.warningTimeInterval = dto.warningTimeInterval;
    this.parentAuditRequirement = dto.parentAuditRequirement;
    this.backgroundColor = dto.backgroundColor;
    this.createdAt = dto.createdAt;
    this.links = dto.links;
    this.properties = dto.properties;
    this.qualificationWeighting = dto.qualificationWeighting;
    this.errors = dto.errors ?? [];
    this.localTags = dto.localTags;
  }
  id: number | string;
  title: string;
  color?: string;
  type?: CustomFieldType;
  isRecurring?: boolean;
  /**If the event is recurring the timeInterval must be set */
  monitoringInterval?: TimeIntervalDTO | ITimeIntervalDTO;
  useWarningTime?: boolean;
  warningTimeInterval?: TimeIntervalDTO;
  parentAuditRequirement?: ICategoryDTO;
  backgroundColor?: string;
  createdAt?: DateTime;
  links: ILinkObject[];
  /**Old custom fields for inventoryInspections and inventoryCategories. Do not use for anything else */
  properties?: string[];
  /** for qualification fitness implementation*/
  qualificationWeighting?: number;
  errors: KeyValue[];
  localTags?: string[];
  hasErrors() {
    return this.errors.length > 0;
  }
}
