import { createContext, useContext } from "react";

import { CustomRowData } from "./reactstrap-table-props";

interface CustomTableContextInterface<T extends CustomRowData> {
  onRowClickNew?: (dto: T) => void;
  onDeleteClick?: (dto: T) => void;
}

function createCtx<T extends CustomRowData>() {
  const ctx = createContext<CustomTableContextInterface<T> | null>(null);
  function useCtx() {
    const c = useContext(ctx);
    if (!c) throw new Error("IdentityContext must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

const [useCustomTableContext, CtxProvider] = createCtx();

interface IProps<T extends CustomRowData> {
  children: React.PropsWithChildren<CustomTableContextInterface<T>>;
}

/**
 * Identity context needs to wrap all routes that rely on authenticated users. It maps from the api to vm.
 * It also takes care of redirecting to Activate Profile page.
 */
function CustomTableContextProvider<T extends CustomRowData>({
  children,
  onRowClickNew,
  onDeleteClick,
}: React.PropsWithChildren<CustomTableContextInterface<T>>) {
  return (
    <CtxProvider
      value={{
        //@ts-expect-error I have no clue how to make these types past. 2024-03-04 PP
        onRowClickNew,
        //@ts-expect-error I have no clue how to make these types past. 2024-03-04 PP
        onDeleteClick,
      }}
    >
      {children}
    </CtxProvider>
  );
}

export { CustomTableContextProvider, useCustomTableContext };
