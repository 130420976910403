import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import AuditMatrixService from "../../../../pbdServices/services/Audits/auditMatrixService";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import { useRecycleBinHook } from "../../../shared/hooks/useRecycleBinHook";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function IndexPageAuditRequirements() {
  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const { showRecycleBin, toggleRecycleBin } = useRecycleBinHook();
  const navigate = useNavigate();
  const { auditRequirementsApi } = useAPIs();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();

  const { data, isValidating, mutate } = useSWR(
    [`/api/auditRequirements`, showRecycleBin],
    () =>
      auditRequirementsApi.getAllQuery({
        isDeleted: showRecycleBin,
      }),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const tableRows = React.useMemo(() => AuditMatrixService.mapAuditRequirementToSettingsDTO(data), [data]);

  const handleClick = async (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        await confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
        setSelected([]);
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.AuditManagement_Settings])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => auditRequirementsApi.delete(Number(s)), { handleApiError });
      }
      mutate();
    }
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Audit requirements"
      pathToCreate={SettingsRoutePaths.CreatePageAuditRequirements}
      loading={isValidating}
      showRecycleBin={showRecycleBin}
      toggleRecycleBin={toggleRecycleBin}
    >
      <BaseSettingsTable
        tableRows={tableRows}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePageAuditRequirements)}
        setSelected={(ids) => setSelected(ids.map((x) => x))}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPageAuditRequirements;
