import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import { CompanyFunctionTable } from "../../../../companyFunctions/components/companyFunctionTable";
import { QualificationMatrixRoutePaths } from "../../../../qualificationMatrix/qualificationMatrixRoutePaths";
import EnhancedSubmitButton from "../../buttons/enhancedSubmitButton";
import { BaseModalProps } from "../../modals/baseModalProps";

interface IProps extends BaseModalProps {
  connectedElements?: { id: number }[];
  onSubmit: (selectedIds: number[]) => Promise<void>;
  canSubmit: boolean;
  refreshParent?: () => void;
}
/**
 * Generic Modal to connect department positions with anything else. For example trainings
 * */
function ConnectCompanyFunctionsModal(props: IProps) {
  const navigate = useNavigate();
  const { connectedElements, modal, toggle, onSubmit, refreshParent } = props;
  const { t } = useTranslation();
  const { companyFunctionsApi } = useAPIs();
  const [submitting, setSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const { data } = useSWRImmutable(modal ? "/api/companyFunctions" : null, () => companyFunctionsApi.getAll());

  const filtered = React.useMemo(() => {
    if (data && connectedElements) {
      const diffArray = data.filter((x) => !connectedElements.map((y) => y.id).includes(x.id));
      return diffArray;
    } else {
      return undefined;
    }
  }, [data, connectedElements]);

  async function handleSubmit() {
    setSubmitting(true);
    console.log("selected", selected);
    const resp = await wrapApiCallWithToast(() => onSubmit(selected));
    if (resp.isOk) {
      setSelected([]);
      toggle();
      refreshParent?.();
    }
    setSubmitting(false);
  }

  return (
    <Modal show={modal} onHide={toggle} className="modal-lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Company functions")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CompanyFunctionTable
          tableRows={filtered}
          isAlwaysSelectable
          setSelected={(ids) => setSelected(ids.map((x) => (x ? Number(x.id) : 0)))}
          onCreateClick={() => navigate(QualificationMatrixRoutePaths.CreatePageCompanyFunction)}
          includeTitleLink={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <EnhancedSubmitButton
          onClick={handleSubmit}
          submitting={submitting}
          disabled={selected.length == 0}
          canSubmit={props.canSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConnectCompanyFunctionsModal;
