import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, ListGroup, ListGroupItem } from "reactstrap";

import { IArticleWatermarkSettings, PDFMarkPosition } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { nameofFactory } from "../../../../Helpers/nameof-factory";
import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import { FormikCheckboxInput } from "../../../shared/components/forms/formik/formikCheckboxInput";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikSelectInput } from "../../../shared/components/forms/formik/formikSelectInput";
import FormikSubmitButton from "../../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";

const nameof = nameofFactory<IArticleWatermarkSettings>();

interface IProps extends BaseFormProps<IArticleWatermarkSettings> {
  itemToUpdate: IArticleWatermarkSettings;
}

const ArticleWatermarkForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, itemToUpdate, onSuccess } = props;

  const submitter = useFormikAPISubmitter<IArticleWatermarkSettings>((val) => onSubmit(val), [onSubmit], onSuccess);

  const initialValues: IArticleWatermarkSettings = {
    ...itemToUpdate,
    watermarkText: itemToUpdate.watermarkText ?? "qmBase - Do not print!",
  };

  return (
    <React.Fragment>
      <h4>{t("Watermark")}</h4>
      <hr />
      <Formik
        initialValues={initialValues}
        onSubmit={submitter}
        // validationSchema={ValidationSchema}
      >
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />
            <ListGroup className="mb-3">
              {Object.keys(itemToUpdate)
                .filter((x) => ["showWatermark", "showTimestamp", "showRevisionInfoInPdf"].includes(x))
                .map((x) => (
                  <ListGroupItem key={x}>
                    <Field
                      name={x}
                      id={x}
                      value={itemToUpdate[x as keyof IArticleWatermarkSettings]}
                      component={FormikCheckboxInput}
                      label={t(x)}
                    />
                  </ListGroupItem>
                ))}
              <ListGroupItem>
                <FormGroup>
                  <Label for={nameof("watermarkPositionInPdf")}>{t("Position of watermark in PDF")}</Label>
                  <Field name={nameof("watermarkPositionInPdf")} component={FormikSelectInput}>
                    {Object.keys(PDFMarkPosition).map((x) => (
                      <option key={x}>{t(x)}</option>
                    ))}
                  </Field>
                </FormGroup>
              </ListGroupItem>
              <ListGroupItem>
                <FormGroup>
                  <Label for={nameof("watermarkText")}>{t("Text to display as watermark")}</Label>
                  <Field name={nameof("watermarkText")} component={FormikTextInput} />
                </FormGroup>
              </ListGroupItem>
            </ListGroup>

            <FormGroup>
              <FormikSubmitButton formikBag={formikBag} />
            </FormGroup>
            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};
export default ArticleWatermarkForm;
