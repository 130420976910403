import { useTranslation } from "react-i18next";

import { ICompanyFunctionDTO, IDepartmentPositionDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import FormattedUserInput from "../../shared/components/text/formattedUserInput";
import CompanyFunctionQualificationsList from "./companyFunctionQualificationsList";

interface IProps {
  departmentPosition?: IDepartmentPositionDTO;
  requiredCompanyFunctions: ICompanyFunctionDTO[];
}

function JobDescriptionPrintout(props: IProps) {
  const { t } = useTranslation();
  const { departmentPosition, requiredCompanyFunctions } = props;
  return (
    <>
      {departmentPosition && (
        <>
          <h3>
            {t("Position")}: {departmentPosition.title}
          </h3>
          <FormattedUserInput content={departmentPosition.description} />
          <h4>
            {t("Department")}: {departmentPosition.department.title}
          </h4>

          {/* <div
            // TODO: Fix department.description in api
            dangerouslySetInnerHTML={{ __html: departmentPosition.department.description }}
          /> */}
          <hr />
          <h4>{t("Required functions")}</h4>
          {requiredCompanyFunctions.length > 0 &&
            requiredCompanyFunctions.map((cf) => (
              <div key={cf.id}>
                <h5>{cf.title}</h5>
                <FormattedUserInput content={cf.description} />
                <hr />
                <CompanyFunctionQualificationsList companyFunction={cf} />
                <hr />
              </div>
            ))}
        </>
      )}
    </>
  );
}

export default JobDescriptionPrintout;
