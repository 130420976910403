import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../Helpers/FlowChartHelpers";
import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function DatabaseNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  const { t } = useTranslation();
  return (
    <>
      <NodeResizer isVisible={selected} keepAspectRatio />
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
      <Handle type="source" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
      <div style={FlowChartHelpers.getStylesForCustomNodes(data)} className="text-center">
        <div>
          <qmBaseIcons.Database />
          <div>{data.label}</div>
        </div>
      </div>
    </>
  );
}
export default memo(DatabaseNode);
