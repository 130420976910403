import { Card } from "react-bootstrap";

import { IManyToManyConnectionDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { WithOptionalCount } from "../../../../pbdServices/Models/Shared/with-count";
import { LoadingComponent } from "../loading/loadingComponent";
import ManyToManyConnectionTable from "./manyToManyConnectionTable";
import ManyToManyConnectionsGroupedTable from "./manyToManyConnectionsGroupedTable";

interface IProps {
  /** @description Displays the many to many connection data.
   * @param {IManyToManyConnectionDTO[]} data the data source as array of type ManyToMany.
   * @param {string} queryStringKey   the key of the querystring  to be used in the url.
   * @param {string} queryStringValue the value of the querystring to be used in the url.
   * @param {string} localStorageKey  to save the state inside the local storage.
   * @param {string} routePath the routePath the component is implemented Ex: tags:SettingsRoutePaths.Home
   * @param {string} cardTitle the title of the connection card.
   * @param {string} viewAs "Connection": displays table of connections."Group": displays table of grouped by connections.
   */
  data?: WithOptionalCount<IManyToManyConnectionDTO>[];
  queryStringKey?: string;
  queryStringValue?: string;
  localStorageKey: string;
  routePath: string;
  cardTitle: string;
  viewAs: "Connection" | "Group";
}

function ManyToManyConnectionCard(props: IProps) {
  const { data, queryStringKey, cardTitle, queryStringValue, localStorageKey, routePath, viewAs } = props;

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex justify-content-between">
        <Card.Title as="h5">{cardTitle}</Card.Title>
      </Card.Header>
      {!data && <LoadingComponent />}
      {data && (
        <Card.Body>
          {viewAs == "Connection" ? (
            <ManyToManyConnectionTable tableRows={data} localStorageKey={localStorageKey} routePath={routePath} />
          ) : (
            <ManyToManyConnectionsGroupedTable
              tableRows={data}
              queryStringValue={queryStringValue}
              localStorageKey={localStorageKey}
              queryStringKey={queryStringKey}
              routePath={routePath}
            />
          )}
        </Card.Body>
      )}
    </Card>
  );
}

export default ManyToManyConnectionCard;
