import { ITenantDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { TenantKpis } from "../../Models/Tenants/TenantKpis";

export default class TenantReportingService {
  static getKpis(all: ITenantDTO[], totalUrl: string) {
    const kpis = new TenantKpis(all, totalUrl);
    return kpis;
  }
}
