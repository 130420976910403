import i18next from "i18next";
import React, { DependencyList } from "react";

import { toastSuccess } from "../../../ClientApp/shared/components/toasts/toastSuccess";
import { useAppContext } from "../../../ClientApp/shared/contexts/appContext";
import { ApiCall1, wrapApiCall } from "./api-wrapper";

export type APISubmitterFn<T, Resp> = (values: T) => Promise<Resp>;
//TODO: Does it make sense to allow Promise<unknown>? It is needed for sidebarEditOverview
export type APISuccessFn<Resp> = (resp: Resp) => Promise<void> | void | Promise<unknown>;
export type APISubmitFn<T> = (data: T) => Promise<void>;

/**
 * creates an api submitter function with an already wrapped ApiCall function
 * @param onSubmit
 * @param deps
 * @param onSuccess
 * @returns
 */
export function useAPISubmitterWrapped<T, Resp = void>(
  onSubmit: ApiCall1<Resp, T>,
  deps: DependencyList,
  onSuccess?: APISuccessFn<Resp> | undefined,
  setLoadingState?: (isLoading: boolean) => void,
): APISubmitFn<T> {
  const { handleApiError } = useAppContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submit: APISubmitFn<T> = React.useCallback(
    async (values) => {
      setIsSubmitting(true);
      setLoadingState?.(true);
      const apiResult = await onSubmit(values);
      setIsSubmitting(false);
      setLoadingState?.(false);
      if (apiResult.isOk) {
        if (onSuccess) {
          await onSuccess(apiResult.value);
        }

        return;
      }

      if (apiResult.isErr) handleApiError(apiResult.error);
    },
    //TODO: add custom eslint hook for this deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );

  return submit;
}

/**
 * Api submitter for functions
 * which might throw an ApiException
 * TODO: Can we have a loading state here for long running operations?
 * TODO: How can the dependency list be populated by linting?
 * TODO: How do i pass parameters
 * TODO: When to use api wrapper vs api submitter? We need more explanation and examples
 * @param onSubmit
 * @param deps
 * @param onSuccess
 * @returns
 */
export function useAPISubmitter<T, Resp = void>(
  onSubmit: APISubmitterFn<T, Resp>,
  deps: DependencyList,
  onSuccess?: APISuccessFn<Resp> | undefined,
): APISubmitFn<T> {
  const wrapped = React.useCallback(
    (values: T) => wrapApiCall(() => onSubmit(values)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
  return useAPISubmitterWrapped(wrapped, [wrapped], onSuccess);
}

interface ResponseWithLoadingState<T> {
  submit: APISubmitFn<T>;
  isSubmitting: boolean;
}

function useAPISubmitterWrapped2<T, Resp = void>(
  onSubmit: ApiCall1<Resp, T>,
  deps: DependencyList,
  onSuccess?: APISuccessFn<Resp> | undefined,
): ResponseWithLoadingState<T> {
  const { handleApiError } = useAppContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const submit: APISubmitFn<T> = React.useCallback(
    async (values) => {
      setIsSubmitting(true);
      const apiResult = await onSubmit(values);
      setIsSubmitting(false);
      if (apiResult.isOk) {
        if (onSuccess) {
          await onSuccess(apiResult.value);
          toastSuccess(i18next.t("Saved"));
        }

        return;
      }

      if (apiResult.isErr) handleApiError(apiResult.error);
    },
    //TODO: add custom eslint hook for this deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );

  return { submit, isSubmitting };
}

// TODO: Good idea for loading state?
export function useAPISubmitter2<T, Resp = void>(
  onSubmit: APISubmitterFn<T, Resp>,
  deps: DependencyList,
  onSuccess?: APISuccessFn<Resp> | undefined,
): ResponseWithLoadingState<T> {
  const wrapped = React.useCallback(
    (values: T) => wrapApiCall(() => onSubmit(values)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
  return useAPISubmitterWrapped2(wrapped, [wrapped], onSuccess);
}
