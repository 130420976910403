import { useState } from "react";
import { Button } from "react-bootstrap";
import { useHandleOpenCommandPalette } from "react-cmdk";
import "react-cmdk/dist/cmdk.css";
import { useTranslation } from "react-i18next";

import { IHelpLinks } from "../../../../Constants/productConfig";
import { AppVM } from "../../../../pbdServices/Models/App/AppVM";
import CommandPaletteComponent from "../../../commandPalette/commandPaletteComponent";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  available: AppVM[];
  helpLinks: IHelpLinks;
}

function SearchBoxNavbarCmdk(props: IProps) {
  const { available, helpLinks } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useHandleOpenCommandPalette(setIsOpen);

  return (
    <>
      <Button variant="outline-secondary" aria-label="Search global" onClick={() => setIsOpen(true)}>
        <span className="me-4">
          <qmBaseIcons.Search /> {t("Search")}
        </span>{" "}
        <kbd>{t("ctrl")}</kbd> <kbd>k</kbd>
      </Button>
      <CommandPaletteComponent available={available} helpLinks={helpLinks} isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}
export default SearchBoxNavbarCmdk;
