import { TFunction } from "i18next";
import { sortBy, uniqBy } from "lodash";
import { Duration } from "luxon";
import * as yup from "yup";

import {
  ConnectCompanyFunctionsDTO,
  ConnectTrainingTypesDTO,
  ConnectionConditionType,
  EntityKey,
  ICompanyFunctionDTO,
  ICustomFormDTO,
  IQualificationDTO,
  IQualificationEditDTO,
  IQualificationsControllerClient,
  ITenantMinDTO,
  ITenantQualificationDTO,
  ITenantQualificationDoneDTO,
  LinkedResourceCreateDTO,
  QualificationCreateDTO,
  QualificationDTO,
  RequiredQualificationDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import StringHelpers from "../../../Helpers/StringHelpers";
import { monitoringValidationSchema } from "../../../services/validation/validationMonitoringWarning";
import { KeyValue } from "../../Models/Shared/KeyValue";
import ExportService from "../Export/exportService";
import { AvailableQualificationDTO } from "../QualificationMatrix/models/AvailableQualificationDTO";
import { QualificationStatus } from "../QualificationMatrix/models/qualificationStatus";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import ConnectionService from "../connectionService/connectionService";
import { CopyForm, CopyIncludedOption } from "../copy/copyService";
import { StringOrUndefinedSchema, requiredStringSchema } from "../validation/stringSchemas";
import { QualificationVm } from "./models/qualification-vm";
import { IQualificationForm } from "./models/qualificationForm";

type LocalDateType = Pick<IQualificationForm, "doneAt">;

export type WithErrors<T> = T & {
  errors?: KeyValue[];
};

export type QualificationQueryParameters = Parameters<IQualificationsControllerClient["getAllQuery"]>[0];

export default class QualificationService {
  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.CreatedAt,
    ];
  }

  private _qualificationsApi: IQualificationsControllerClient;
  constructor(qualificationsApi: IQualificationsControllerClient) {
    this._qualificationsApi = qualificationsApi;
  }

  /**
   * Returns validated and sorted result of qualifications
   */
  async getAllAsVm(query: QualificationQueryParameters = {}): Promise<WithErrors<QualificationVm[]>> {
    const resp = await this._qualificationsApi.getAllQuery(query).then((resp) => sortBy(resp, (x) => x.title));
    return this.validateQualifications(resp);
  }

  validateQualifications(items: QualificationDTO[]) {
    return sortBy(
      items.map((q) => this.validateQualification(q)),
      (x) => x.title,
    );
  }

  validateQualification(item: QualificationDTO): WithErrors<QualificationVm> {
    const mapped = item as WithErrors<QualificationVm>;
    mapped.errors = [];
    if (item.isRecurring) {
      if (item.monitoringInterval?.timeSpanISO) {
        mapped.monitoringDuration = Duration.fromISO(item.monitoringInterval.timeSpanISO);
      } else {
        mapped.errors.push(new KeyValue("MonitoringInterval", "Monitoring interval timespan must not be null"));
      }
      if (item.useWarningTime) {
        if (item.warningTimeInterval?.timeSpanISO) {
          mapped.warningDuration = Duration.fromISO(item.warningTimeInterval.timeSpanISO);
        } else {
          mapped.errors.push(new KeyValue("WarningInterval", "Warning interval timespan must not be null"));
        }
      }
    }
    return mapped;
  }

  static calculateNextInspectionDate(
    element: ITenantQualificationDTO,
    qualification: IQualificationDTO,
  ): AvailableQualificationDTO {
    if (!element.lastInspection) throw Error("Missing last inspection");
    const availableQualification: AvailableQualificationDTO = {
      ...qualification,
      actualValue: element.actualValue,
      qualificationStatus: QualificationStatus.Ok,
      description: element.description ?? undefined,
      lastInspection: element.lastInspection,
    };

    if (qualification.isRecurring) {
      if (!qualification.monitoringInterval?.timeSpanISO) {
        throw Error(`Missing monitoring time timeSpan for id: ${qualification.id}`);
      }

      const dur = Duration.fromISO(qualification.monitoringInterval.timeSpanISO);
      availableQualification.nextInspection = element.lastInspection.plus(dur);

      //TODO: element.isExpired
      const isExpired = DateTimeLuxonHelpers.inPast(availableQualification.nextInspection);
      if (isExpired) {
        availableQualification.isExpired = isExpired;
        availableQualification.qualificationStatus = QualificationStatus.Expired;
      }

      if (qualification.useWarningTime) {
        if (!qualification.warningTimeInterval?.timeSpanISO)
          throw Error(`Missing warning time timeSpan for id: ${qualification.id}`);

        const warningDur = Duration.fromISO(qualification.warningTimeInterval.timeSpanISO);
        element.warningTime = availableQualification.nextInspection.minus(warningDur);
        availableQualification.warningTime = availableQualification.nextInspection.minus(warningDur);

        //TODO: element.isWarningTimeExpired
        const isWarningTimeExpired = !isExpired && DateTimeLuxonHelpers.inPast(element.warningTime);
        if (isWarningTimeExpired) {
          availableQualification.isWarningTimeExpired = isWarningTimeExpired;
          availableQualification.qualificationStatus = QualificationStatus.WarningTime;
        }
      }
    }

    return availableQualification;
  }

  getUniqueQualificationsByCompanyFunctions(items: ICompanyFunctionDTO[]) {
    const qualificationsByCF = items.reduce(
      (pv, cv) => (cv.requiredQualifications ?? []).concat(pv),
      new Array<RequiredQualificationDTO>(),
    );
    return uniqBy(qualificationsByCF, (x) => x.id).sort((a, b) => a.title.localeCompare(b.title));
  }

  exportToCsv(items: IQualificationDTO[]) {
    return ExportService.exportCSV("Qualifications", items, (x) => ({
      id: x.id,
      title: x.title,
      description: x.description,
      descriptionText: StringHelpers.stripHtmlFromString(x.description),
      isRecurring: x.isRecurring,
      surveillanceIntervalTimeSpan: x.monitoringInterval?.timeSpanISO,
      warningTimeTimeSpan: x.warningTimeInterval?.timeSpanISO,
      createdAt: x.createdAt.toISO(),
    }));
  }

  static hasConnectionConditionEntries(dto: IQualificationDTO, forms: ICustomFormDTO[]) {
    if (forms.length > 0) return true;
    if (!dto.connectionCondition?.tenantQualificationConnection) return false;
    if (
      dto.connectionCondition.tenantQualificationConnection.articles.length == 0 &&
      dto.connectionCondition.tenantQualificationConnection.customForms.length == 0
    ) {
      return false;
    }
    return true;
  }

  static hasProtections(actor: MeAsUser, dto?: IQualificationDTO, assignee?: ITenantMinDTO): boolean | undefined {
    if (dto == undefined) {
      return undefined;
    }
    if (
      assignee?.id == actor.tenant.id &&
      dto.connectionCondition?.tenantQualificationConnection &&
      dto.connectionCondition.tenantQualificationConnection.isSelfAssignmentEnabled &&
      dto.connectionCondition.tenantQualificationConnection.articles.length == 0 &&
      dto.connectionCondition.tenantQualificationConnection.customForms.length == 0
    ) {
      return false;
    }
    return true;
  }

  static getRequiredCustomForms(dto: IQualificationDTO) {
    const ids = dto.connectionCondition?.tenantQualificationConnection?.customForms
      .filter((x) => x.conditionType == ConnectionConditionType.CustomFormPassed)
      .map((x) => x.id);
    return ids;
  }

  static getRequiredCustomFormsSWRKey(dto: IQualificationDTO) {
    const ids = dto.connectionCondition?.tenantQualificationConnection?.customForms
      .filter((x) => x.conditionType == ConnectionConditionType.CustomFormPassed)
      .map((x) => x.id);
    if (!ids) return undefined;
    return ["qualifications", "fullFilledCustomForms", ids];
  }

  async copyConnectedElements(form: CopyForm, source: IQualificationDTO, item: IQualificationDTO) {
    if (form.options.companyFunctions && source.companyFunctions && source.companyFunctions.length > 0) {
      const companyFunctionIds = new ConnectCompanyFunctionsDTO({
        companyFunctionIds: source.companyFunctions.map((x) => x.id),
      });
      await this._qualificationsApi.connectCompanyFunctions(item.id, companyFunctionIds);
    }

    if (form.options.forms) {
      const connections = await this._qualificationsApi.getConnectedLinkedResourcesLegacy(source.id);
      const ids = ConnectionService.mustGetIdsByKeyAsNumber(connections, EntityKey.CustomForm);
      const createDTOs =
        ids?.map((x) => {
          return new LinkedResourceCreateDTO({
            linkedResourceId: x.toString(),
            entityKey: EntityKey.CustomForm,
          });
        }) ?? [];
      await this._qualificationsApi.connectLinkedResourceLegacy(item.id, createDTOs);
    }

    if (form.options.trainingCategories) {
      const categories = await this._qualificationsApi.getTrainingTypesForQualification(source.id);
      const dto = new ConnectTrainingTypesDTO({
        trainingTypeIds: categories.map((x) => x.id),
      });
      await this._qualificationsApi.connectTrainingType(item.id, dto);
    }
  }

  async copy(form: CopyForm, item: IQualificationDTO, responsibleId: number) {
    const itemToCreate = new QualificationCreateDTO({
      title: form.title,
      description: item.description,
      color: item.color,
      responsibleId: responsibleId,
      isRecurring: item.isRecurring,
      monitoringInterval: item.monitoringInterval,
      useWarningTime: item.useWarningTime,
      warningTimeInterval: item.warningTimeInterval,
    });

    const resp = await this._qualificationsApi.create(itemToCreate);

    await this.copyConnectedElements(form, item, resp);
    return resp;
  }

  static get copyModalOptions(): CopyIncludedOption[] {
    return ["companyFunctions", "forms", "trainingCategories"];
  }
}

export const validationQualificationAssignForm = (
  latestQualificationDone?: ITenantQualificationDoneDTO,
  hasProtections?: boolean,
) => {
  //@ts-expect-error TODO: Fix date problem
  const dateValidation: yup.ObjectSchema<LocalDateType> = yup.object({
    doneAt: yup.date().required(),
  });

  const trueRequired = hasProtections ? yup.bool().oneOf([true]).required() : yup.bool().required();

  const ValidationSchema: yup.ObjectSchema<IQualificationForm> = yup
    .object({
      actualValue: yup.number().required().integer().min(0).max(100),
      description: yup.string(),
      tenantId: yup.number().required(),
      qualificationId: yup.number().required(),
      trainingId: yup.number().notRequired(),
      byPassProtections: trueRequired,
      dryRun: yup.boolean().required(),
      confirmDoneAtImpact: yup.boolean().when("doneAt", {
        is: (value?: Date) => {
          const luxonDate = value ? DateTimeLuxonHelpers.convertFromUnknownToDateTime(value) : undefined;
          if (!luxonDate || !value || !latestQualificationDone) return false;
          return luxonDate.endOf("day") <= latestQualificationDone.doneAt;
        },
        then: (s) => s.required().oneOf([true]),
        otherwise: (s) => s.notRequired(),
      }),
    })
    .concat(dateValidation);
  return ValidationSchema;
};

export const validationQualificationEditForm = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IQualificationEditDTO> = yup
    .object({
      id: yup.number().required(),
      title: requiredStringSchema(t),
      description: StringOrUndefinedSchema,
      color: StringOrUndefinedSchema,
      backgroundColor: StringOrUndefinedSchema,
      responsibleId: yup.number().required(),
    })
    .concat(monitoringValidationSchema);
  return ValidationSchema;
};
