import React from "react";
import { useParams } from "react-router-dom";

import { ICustomFormDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import FormFieldsPrintout from "../components/formFieldsPrintout";
import FormHeaderPrintout from "../components/formHeaderPrintout";

const PrintFormPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { customFormsApi } = useAPIs();
  const [item, setItemToUpdate] = React.useState<ICustomFormDTO>();

  React.useEffect(() => {
    async function getData() {
      const resp = await customFormsApi.getById(Number(id));
      setItemToUpdate(resp);
      setTimeout(async () => {
        window.print();
      }, 1000);
    }
    getData();
  }, [id]);

  return (
    <React.Fragment>
      <div data-testid="divPrintData">
        {item && (
          <React.Fragment>
            <FormHeaderPrintout form={item} />
            <FormFieldsPrintout fields={item.customFields} />
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default PrintFormPage;
