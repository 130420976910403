import { IDefectCategoriesControllerClient, IDefectDTO, PbdStatus } from "../../../generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { CopyIncludedOption } from "../copy/copyService";

export default class DefectsService extends BaseExportService<IDefectDTO> implements IPrerequisitesService {
  defectCategoriesApi: IDefectCategoriesControllerClient;
  constructor(defectCategoriesApi: IDefectCategoriesControllerClient) {
    super("Defects");
    this.defectCategoriesApi = defectCategoriesApi;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const categories = await this.defectCategoriesApi.getAllQuery({ take: 1 });
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "defectCategory",
        title: "Defect categories",
        status: categories.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.DefectManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };

    return resp;
  }

  mapToExport(x: IDefectDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      createdById: x.createdById,
      isDeleted: x.isDeleted,
      status: x.status,
      entityKey: x.entityKey,
      claimsCount: x.claimsCount,
      createdAt: x.createdAt,
    };
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
    ];
  }

  static get copyModalOptions(): CopyIncludedOption[] {
    return ["customFields", "claims", "products", "todos"];
  }
}
