import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { DefectRoutePaths } from "../defectRoutePaths";

const SidebarDefect: React.FC = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(DefectRoutePaths.CreatePage, t),
    SidebarItem.overview(DefectRoutePaths.IndexPage + "/?status=Open&status=InProgress", t),
  ];
  links.push(
    new SidebarItem({
      icon: qmBaseIcons.Graph,
      title: t("Graph"),
      href: DefectRoutePaths.Graph,
    }),
  );
  links.push(SidebarItem.settings(SettingsRoutePaths.DefectManagementHome, t));
  return <SidebarNew app={PbdModule.DefectManagement} items={links} />;
};
export default SidebarDefect;
