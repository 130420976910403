import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IEntityTemplateDTO, PbdModule, SettingType } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { ConnectedCardProps } from "../../shared/components/connectionElements/connectedCardProps";
import ConnectedCardHeader from "../../shared/components/connectionElements/shared/connectedCardHeader";
import { TableClickCommand } from "../../shared/components/tables/TableClickCommand";
import { useConfirmation } from "../../shared/contexts/modalConfirmationContext";
import { AdminRoutePaths } from "../adminRoutePaths";
import TemplateTable from "./templateTable";

interface IProps extends Omit<ConnectedCardProps<IEntityTemplateDTO>, "baseDTO"> {
  module: PbdModule;
}

function TemplateConnectedCard(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, refreshParent, module, cardTitle } = props;
  const { moduleSettingsService } = useAPIServices();
  const [selected, setSelected] = React.useState<IEntityTemplateDTO[]>();
  const confirm = useConfirmation();

  const submitDeleteRequest = async (ids: IEntityTemplateDTO[]) => {
    if (data) {
      const dataNew = [...data];
      ids.forEach((element) => {
        const index = data.findIndex((x) => x.id == element.id);
        dataNew.splice(index, ids.length);
      });
      const resp = await wrapApiCallWithToast(() => moduleSettingsService.save(module, SettingType.Templates, dataNew));
      if (resp.isOk && refreshParent) {
        refreshParent();
      }
    }
  };

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected));
      }
    }
  };

  const handleCreateClick = () => navigate(AdminRoutePaths.CreatePageTemplates.replace(":pbdModule", module));

  return (
    <Card className="mb-3">
      <ConnectedCardHeader title={cardTitle} toggleConnectModal={handleCreateClick} data={data} canAdd />
      <Card.Body>
        <TemplateTable
          tableRows={data}
          setSelected={setSelected}
          availableTableActions={["Delete"]}
          onClick={handleClick}
          onCreateClick={handleCreateClick}
          onCreateButtonTitle={t("Add template")}
          module={module}
        />
      </Card.Body>
    </Card>
  );
}
export default TemplateConnectedCard;
