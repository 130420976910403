import { Axios } from "axios";

import { AdminServiceContextData } from "../../generatedCode/admin/service";
import { ConfigApi } from "../../generatedCode/pbd-core/pbd-core-api";
import { ControllerContextData } from "../../generatedCode/pbd-core/pbd-core-api-services";
import { StoreServiceContextData } from "../../generatedCode/store/storeServiceContextData";

import PrintService from "../../ClientApp/shared/layouts/print/printService";
import ActiveFilterService from "../../services/ActiveFilter/activeFilterService";
import CustomFormService from "../../services/CustomForms/customFormService";
import { EntityTemplateService } from "../../services/EntityTemplates/entityTemplateService";
import PlaceholderService from "../../services/Placeholders/placeholderService";
import { AbsencePlannerService } from "./AbsencePlanner/absencePlannerService";
import AbsenceRequestService from "./AbsencePlanner/absenceRequestService";
import GroupService from "./Account/groupService";
import AdminService from "./Admin/adminService";
import AppService from "./Apps/appService";
import { ArticleRevisionReviewService } from "./Articles/articleRevisionReviewService";
import ArticleRevisionService from "./Articles/articleRevisionService";
import ArticleService from "./Articles/articleService";
import ArticleViewService from "./Articles/articleViewService";
import AuditTrailService from "./AuditTrail/auditTrailService";
import AuditMatrixService from "./Audits/auditMatrixService";
import { AuditService } from "./Audits/auditService";
import ClaimService from "./Claims/claimService";
import CommentService from "./Comments/commentService";
import CompanyFunctionService from "./CompanyFunctions/companyFunctionService";
import CostService from "./Costs/costService";
import CustomFieldService from "./CustomFields/customFieldService";
import CustomFormAnswerService from "./CustomFormAnswers/customFormAnswerService";
import CustomFormLinkService from "./CustomFormLinks/custom-form-link-service";
import DefectsService from "./Defects/defectsService";
import DepartmentPositionService from "./DepartmentPositions/departmentPositionService";
import DepartmentService from "./Departments/departmentService";
import EightDReportService from "./EightDReports/eightDReportsService";
import EmailService from "./Emails/emailService";
import { EntityPermissionService } from "./EntityPermission/entityPermissionService";
import CustomerCustomizationService from "./FeatureFlags/customerCustomizationService";
import FileService from "./Files/fileService";
import GoalService from "./Goals/goalService";
import IdeaManagementService from "./IdeaManagement/ideaManagementService";
import InventoryInspectionDoneService from "./InventoryInspectionDone/inventoryInspectionDoneService";
import InventoryInspectionsConnectedService from "./InventoryInspectionsConnected/inventoryInspectionsConnectedService";
import InventoryItemService from "./InventoryItems/inventoryItemService";
import MaintenanceManagementService from "./MaintenanceManagement/maintenanceManagementService";
import MenuItemService from "./MenuItems/menuItemService";
import ModuleSettingsService from "./ModuleSettings/moduleSettingsService";
import NotificationService from "./Notifications/notificationService";
import OpportunityCategoryService from "./Opportunities/opportunityCategoryService";
import OpportunityEvaluationService from "./Opportunities/opportunityEvaluationService";
import OpportunityService from "./Opportunities/opportunityService";
import OrganisationService from "./Organisations/organisationService";
import ProductService from "./Products/productService";
import ProjectService from "./Projects/projectService";
import { QualificationMatrixService } from "./QualificationMatrix/qualificationMatrixService";
import QualificationService from "./Qualifications/qualificationService";
import SettingsService from "./Settings/settingsService";
import TagService from "./Tags/tagService";
import TenantQualificationDoneService from "./TenantQualificationDone/tenantQualificationDoneService";
import TenantService from "./Tenants/tenantService";
import { TenantServiceInMemory } from "./Tenants/tenantServiceInMemory";
import ToDoService from "./ToDos/todoService";
import TrainingAttendeeService from "./Trainings/trainingAttendeeService";
import TrainingService from "./Trainings/trainingService";
import UserSettingsService from "./UserSettings/userSettingsService";
import UserService from "./Users/userService";
import { IConfigApi } from "./apiBase/configApi";
import ConnectionService from "./connectionService/connectionService";
import DashboardService from "./dashboards/dashboardService";
import { RoutingRuleService } from "./routingRules/routingRuleService";
import SmartViewService from "./smart-views/smart-view-service";

export interface LoginConfig {
  loginRedirectUrl: string;
  logoutRedirectUrl: string;
}

export class ServiceContextData {
  entityTemplateService: EntityTemplateService;
  absencePlannerService: AbsencePlannerService;
  maintenanceManagementService: MaintenanceManagementService;
  connectionService: ConnectionService;
  menuItemService: MenuItemService;
  customerCustomizationService: CustomerCustomizationService;
  commentService: CommentService;
  smartViewService: SmartViewService;
  absenceRequestService: AbsenceRequestService;
  customFormLinkService: CustomFormLinkService;
  customFormService: CustomFormService;
  tenantQualificationDoneService: TenantQualificationDoneService;
  customFormAnswerService: CustomFormAnswerService;
  auditTrailService: AuditTrailService;
  eightDReportService: EightDReportService;
  projectService: ProjectService;
  printService: PrintService;
  companyFunctionService: CompanyFunctionService;
  dashboardService: DashboardService;
  claimService: ClaimService;
  trainingAttendeeService: TrainingAttendeeService;
  todoService: ToDoService;
  goalService: GoalService;
  tenantService: TenantService;
  departmentService: DepartmentService;
  organisationService: OrganisationService;
  auditMatrixService: AuditMatrixService;
  auditService: AuditService;
  opportunityEvaluationService: OpportunityEvaluationService;
  opportunityService: OpportunityService;
  trainingService: TrainingService;
  inventoryInspectionDoneService: InventoryInspectionDoneService;
  qualificationService: QualificationService;
  departmentPositionService: DepartmentPositionService;
  costService: CostService;
  defectsService: DefectsService;
  placeholderService: PlaceholderService;
  tenantServiceInMemory: TenantServiceInMemory;
  inventoryItemService: InventoryItemService;
  notificationService: NotificationService;
  customFieldService: CustomFieldService;
  entityPermissionService: EntityPermissionService;
  qualificationMatrixService: QualificationMatrixService;
  ideaManagementService: IdeaManagementService;
  inventoryInspectionsConnectedService: InventoryInspectionsConnectedService;
  productService: ProductService;
  groupService: GroupService;
  articleRevisionsReviewService: ArticleRevisionReviewService;
  articleViewService: ArticleViewService;
  emailService: EmailService;
  articleService: ArticleService;
  articleRevisionService: ArticleRevisionService;
  userService: UserService;
  settingsService: SettingsService;
  userSettingsService: UserSettingsService;
  moduleSettingsService: ModuleSettingsService;
  routingRuleService: RoutingRuleService;
  adminService: AdminService;
  appService: AppService;
  fileService: FileService;
  activeFilterService: ActiveFilterService;
  tagService: TagService;
  axiosInstance: Axios;
  opportunityCategoryService: OpportunityCategoryService;

  adminApis: AdminServiceContextData;
  storeApis: StoreServiceContextData;
  apis: ControllerContextData;

  constructor(config: IConfigApi, adminUrl: string, storeUrl: string) {
    this.apis = new ControllerContextData(config);
    this.entityTemplateService = new EntityTemplateService();
    this.absencePlannerService = new AbsencePlannerService(this.apis.absenceTypesApi, this.apis.absencePoliciesApi);
    this.connectionService = new ConnectionService(this.apis);
    this.maintenanceManagementService = new MaintenanceManagementService(
      this.apis.inventoryCategoriesApi,
      this.apis.inventoryStatusApi,
    );
    this.commentService = new CommentService(this.apis.commentsApi, this.apis.tenantsApi);
    this.smartViewService = new SmartViewService(this.apis.smartViewsApi);
    this.absenceRequestService = new AbsenceRequestService(
      this.apis.absenceRequestsApi,
      this.apis.absencePoliciesApi,
      this.apis.tenantsApi,
    );
    this.customFormLinkService = new CustomFormLinkService(this.apis.customFormLinksApi);
    this.customFormService = new CustomFormService(this.apis.customFormsApi);
    this.tenantQualificationDoneService = new TenantQualificationDoneService(
      this.apis.tenantQualificationsDoneApi,
      this.apis.tenantQualificationsApi,
    );
    this.customFormAnswerService = new CustomFormAnswerService(
      this.apis.customFormAnswersApi,
      this.apis.customFormsApi,
      this.apis.customFormLinksApi,
      this.apis.trainingsApi,
      this.apis.auditsApi,
    );
    this.auditTrailService = new AuditTrailService();
    this.eightDReportService = new EightDReportService(this.apis.blobsApi, this.apis.claimsApi);
    this.projectService = new ProjectService();
    this.printService = new PrintService(
      this.apis.claimsApi,
      this.apis.toDosApi,
      this.apis.productsApi,
      this.apis.defectCategoriesApi,
    );
    this.companyFunctionService = new CompanyFunctionService(this.apis.companyFunctionsApi);
    this.claimService = new ClaimService(this.apis.claimsApi);
    this.trainingAttendeeService = new TrainingAttendeeService(this.apis.trainingAttendeesApi);
    this.todoService = new ToDoService(this.apis.toDosApi);
    this.goalService = new GoalService(this.apis.goalsApi, this.apis.goalProgressesApi);
    this.tenantService = new TenantService(this.apis);
    this.departmentService = new DepartmentService();
    this.organisationService = new OrganisationService();
    this.auditMatrixService = new AuditMatrixService(this.apis);
    this.auditService = new AuditService(this.apis.auditTypesApi, this.apis.auditsApi);
    this.opportunityEvaluationService = new OpportunityEvaluationService();
    this.opportunityService = new OpportunityService(
      this.apis.opportunitiesApi,
      this.apis.opportunityCategoriesApi,
      this.apis.opportunityEvaluationsApi,
    );
    this.trainingService = new TrainingService(this.apis.trainingTypesApi);
    this.inventoryInspectionDoneService = new InventoryInspectionDoneService(
      this.apis.inventoryInspectionsDoneApi,
      this.apis.inventoryInspectionsApi,
      this.apis.inventoryItemsApi,
    );
    this.qualificationService = new QualificationService(this.apis.qualificationsApi);
    this.departmentPositionService = new DepartmentPositionService();
    this.placeholderService = new PlaceholderService(
      this.apis.auditsApi,
      this.apis.claimsApi,
      this.apis.costsApi,
      this.apis.employeeIdeasApi,
      this.apis.goalsApi,
      this.apis.goalProgressesApi,
      this.apis.inventoryItemsApi,
      this.apis.inventoryInspectionsDoneApi,
      this.apis.tenantsApi,
      this.apis.organisationsApi,
      this.apis.toDosApi,
      this.apis.trainingsApi,
      this.apis.opportunitiesApi,
      this.apis.opportunityEvaluationsApi,
      this.apis.organisationRatingsApi,
      this.apis.settingsApi,
    );
    this.costService = new CostService(this.apis.costsApi);
    this.defectsService = new DefectsService(this.apis.defectCategoriesApi);
    this.tenantServiceInMemory = new TenantServiceInMemory();
    this.inventoryItemService = new InventoryItemService(this.apis.inventoryItemsApi);
    this.customFieldService = new CustomFieldService(this.apis.customFieldsApi);
    this.entityPermissionService = new EntityPermissionService(this.apis.usersApi, this.apis.groupsApi);
    this.qualificationMatrixService = new QualificationMatrixService(
      this.apis.entityPermissionsApi,
      // this.entityPermissionService,
      this.apis.tenantsApi,
    );
    this.ideaManagementService = new IdeaManagementService(
      this.apis.employeeIdeasApi,
      this.apis.reportingsApi,
      this.apis.employeeIdeaCategoriesApi,
      this.apis.tenantsApi,
    );
    this.inventoryInspectionsConnectedService = new InventoryInspectionsConnectedService(
      this.apis.inventoryInspectionsConnectedApi,
      this.apis.inventoryInspectionsApi,
    );
    this.productService = new ProductService(this.apis.productsApi);
    this.groupService = new GroupService(this.apis.groupsApi);
    this.articleRevisionsReviewService = new ArticleRevisionReviewService(this.apis.articleRevisionReviewsApi);
    this.articleViewService = new ArticleViewService();
    this.emailService = new EmailService(this.apis.emailsApi);
    this.articleService = new ArticleService(
      this.apis.articlesApi,
      this.apis.articleRevisionsApi,
      this.apis.articleRevisionApprovalsApi,
      this.apis.articleRevisionReviewsApi,
      this.entityPermissionService,
    );
    this.articleRevisionService = new ArticleRevisionService(this.apis.tenantsApi, this.apis.articleRevisionsApi);
    this.userService = new UserService(this.apis.usersApi);
    this.settingsService = new SettingsService(this.apis.settingsApi, this.apis.appSettingsApi, this.apis.healthApi);
    this.userSettingsService = new UserSettingsService(this.apis.userApi);
    this.moduleSettingsService = new ModuleSettingsService(
      this.apis.moduleSettingsApi,
      this.apis.articlesApi,
      this.apis.toDosApi,
      this.apis.projectsApi,
      this.apis.customFormsApi,
      this.apis.goalsApi,
      this.apis.opportunitiesApi,
      this.apis.claimsApi,
    );

    this.notificationService = new NotificationService(
      this.apis.inventoryItemsApi,
      this.apis.inventoryInspectionsConnectedApi,
      this.settingsService,
    );
    this.dashboardService = new DashboardService(this.apis.dashboardsApi);
    this.routingRuleService = new RoutingRuleService(this.moduleSettingsService);
    this.adminService = new AdminService();
    this.appService = new AppService(this.apis.appsApi);
    this.tagService = new TagService();

    this.adminApis = new AdminServiceContextData(adminUrl);
    this.storeApis = new StoreServiceContextData(storeUrl);

    this.axiosInstance = new Axios({
      baseURL: config.baseUrl,
    });
    this.fileService = new FileService(config, this.axiosInstance);
    this.activeFilterService = new ActiveFilterService(
      this.apis.trainingTypesApi,
      this.apis.employeeIdeaCategoriesApi,
      this.apis.auditTypesApi,
      this.apis.opportunityCategoriesApi,
      this.apis.tenantsApi,
      this.apis.departmentsApi,
      this.apis.groupsApi,
    );
    this.customerCustomizationService = new CustomerCustomizationService(
      this.fileService,
      this.apis.emailsApi,
      this.apis.trainingsApi,
    );
    this.menuItemService = new MenuItemService(this.apis.menuItemsApi, this.apis.articlesApi);
    this.opportunityCategoryService = new OpportunityCategoryService(this.apis.opportunityCategoriesApi);
  }

  static fromBaseUrl(baseUrl: string, adminUrl: string, storeUrl: string): ServiceContextData {
    const config = new ConfigApi(baseUrl);
    return new ServiceContextData(config, adminUrl, storeUrl);
  }
}
