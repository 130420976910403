import React from "react";
import { useTranslation } from "react-i18next";

import { qmBaseIcons } from "../icons/qmBaseIcons";
import GenericAlert from "./genericAlert";

const AppUnderDevelopmentAlert: React.FC<{ children?: React.ReactNode }> = (props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <GenericAlert type="info">
        <qmBaseIcons.Wrench /> {t("This app is under active development. Some functions might not work as expected.")}
        {props.children}
      </GenericAlert>
    </React.Fragment>
  );
};

export default AppUnderDevelopmentAlert;
