import {
  ApplicationUserClaimDTO,
  ApplicationUserDTO,
  EntityKey,
  Gender,
  IApplicationUserDTO,
  IEntityPermissionDTO,
  IMeDTO,
  ITenantCreateDTO,
  ITenantDTO,
  IUsersControllerClient,
  MeDTO,
  PbdModule,
  PbdStatus,
  TenantDTO,
  UserType,
  UserTypePricing,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { UserCreateFormData } from "../../../ClientApp/users/components/userCreateForm";
import { ClaimTypeNames } from "../../../Constants/ClaimTypeNames";
import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import StringHelpers from "../../../Helpers/StringHelpers";
import { DTOMap, filterMap } from "../../../Helpers/filterMap";
import { BaseExportService } from "../Base/BaseExportService";
import ExportService, { ExportType } from "../Export/exportService";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { IApplicationUserPermission } from "./models/application-user-permission";
import { UserCreateFormBulkVM, UserCreateType } from "./models/userCreateFormBulk";
import { UserResetPasswordTokenExport } from "./models/userResetPasswordTokenExport";
import { IUserVM } from "./userVM";

export default class UserService extends BaseExportService<IApplicationUserDTO> {
  usersApi: IUsersControllerClient;

  constructor(usersApi: IUsersControllerClient) {
    super("Users");
    this.usersApi = usersApi;
  }

  async getAllForAccess(permission?: IEntityPermissionDTO): Promise<IApplicationUserPermission[] | undefined> {
    const permUsers = permission?.users;
    if (permUsers === undefined) return undefined;

    const id = permUsers.map((x) => x.id);
    const users = await this.usersApi.getAllQuery({ id });
    const usersMap = new DTOMap<IApplicationUserDTO, string>(users);

    return filterMap(permUsers, (permUser) => {
      const user = usersMap.getById(permUser.id);
      if (user) {
        return { ...user, ...permUser } as IApplicationUserPermission;
      } else {
        return undefined;
      }
    });
  }

  async getAllForGroup(groupId: string) {
    const users = await this.usersApi.getAll();
    const members = users.filter((x) => x.groups?.map((g) => g.id).includes(groupId));
    const nonMembers = users.filter((x) => !x.groups?.map((g) => g.id).includes(groupId));
    return { members, nonMembers };
  }

  mapToExport(x: IApplicationUserDTO): ExportType {
    return {
      id: x.id,
      title: x.userName,
      responsible: x.email,
      createdAt: x.createdAt,
    };
  }

  mapToExportResetTokens(x: UserResetPasswordTokenExport): ExportType {
    return {
      id: x.id,
      userName: x.userName,
      email: x.email,
      createdAt: x.createdAt,
      resetTokens: x.resetTokens.tokens?.join(" | "),
      password: x.password,
    };
  }

  exportResetTokenResp(items: UserResetPasswordTokenExport[]) {
    return ExportService.exportCSV("Users with reset tokens", items, (x) => this.mapToExportResetTokens(x));
  }

  static mapToUserVM(user: IApplicationUserDTO, tenant?: ITenantDTO) {
    const vm: IUserVM = {
      tenant,
      isReadOnly: this.isReadOnlyUser(user),
      ...user,
    };
    return vm;
  }

  static isReadOnlyUser(user: IApplicationUserDTO) {
    const claim = user.claims?.find((x) => x.claimType == ClaimTypeNames.ReadOnlyUser);
    if (claim && claim.claimValue == "True") {
      return true;
    } else {
      return false;
    }
  }

  static mapToMeAsUser(me: MeDTO): MeAsUser {
    const { tenant, user } = me;
    let tenantModified = tenant;
    if (!tenantModified) {
      tenantModified = new TenantDTO({
        fullName: user?.email,
        lastName: "",
        id: -1,
        gender: Gender.NotSet,
        externalId: "",
        publishBirthday: false,
        isEmployee: false,
        isInterface: false,
        createdAt: user?.createdAt,
        isPrivate: false,
        type: PbdModule.TenantManagement,
        entityKey: EntityKey.Tenant,
      });
    }
    const userModified = me.user ?? new ApplicationUserDTO();
    return {
      isReadOnly: this.isReadOnlyUser(userModified),
      ...me,
      user: userModified,
      tenant: tenantModified,
      roles: user?.roles?.map((x) => x.name) ?? [],
      groups: user?.groups ?? [],
      claims: user?.claims ?? [],
    };
  }

  static isProfileMissing(meAsUser: IMeDTO | MeAsUser): boolean {
    if (!meAsUser.tenant || meAsUser.tenant.id < 0) return true;
    if (!meAsUser.user?.claims?.find((x) => x.claimType == ClaimTypeNames.TenantId)) return true;
    return false;
  }

  /**Temporary add claim */
  static toggleReadOnlyClaim(user: IApplicationUserDTO) {
    if (this.isReadOnlyUser(user)) {
      user.claims = user.claims?.filter((x) => x.claimType == ClaimTypeNames.ReadOnlyUser); //.push({ claimType: "ReadOnlyUser", claimValue: "True" });
    } else {
      user.claims?.push(new ApplicationUserClaimDTO({ claimType: ClaimTypeNames.ReadOnlyUser, claimValue: "True" }));
    }
  }

  static get availableFilter() {
    return [SearchFilterTypes.CreatedAt, SearchFilterTypes.IsEmailConfirmed, SearchFilterTypes.ApplicationGroup];
  }

  static getInitialValues(user: IApplicationUserDTO) {
    const initialValues: ITenantCreateDTO = {
      gender: Gender.NotSet,
      firstName: "",
      lastName: "",
      email: user.email,
      isEmployee: true,
      publishBirthday: false,
    };
    if (user.email && user.email.toUpperCase() == GlobalQmBaseConstants.SupportMail) {
      initialValues.firstName = "Support";
      initialValues.lastName = "qmBase";
    } else if (user.email == "imsbase@netzlink.com") {
      initialValues.firstName = "NLI";
      initialValues.lastName = "Support";
    } else if (user.email == "guard@kroschke.com") {
      initialValues.firstName = "Guard";
      initialValues.lastName = "Kroschke";
    }
    return initialValues;
  }

  static getLanguage(i18nLanguage: string) {
    if (i18nLanguage.startsWith("de")) {
      return "de-DE";
    } else if (i18nLanguage.startsWith("en")) {
      return "en-US";
    } else {
      return "en-US";
    }
  }

  static getDomain(meAsUser: MeAsUser) {
    return `@${meAsUser.user.email?.split("@")[1]}`;
  }

  /**
   * This includes lower case Upper case number and non alpha numeric characters
   */
  static readonly strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:])/;

  static get initialCreateUserValues(): UserCreateFormData {
    return {
      userName: "",
      email: "",
      password: StringHelpers.generatePassword(12),
      confirmEmail: false,
      addDefaultPermissions: true,
      requirePasswordChange: false,
      userType: UserType.Default,
      userTypePricing: UserTypePricing.Default,
      generateRecoveryKeys: false,
      downloadCredentials: true,
    };
  }

  static initialCreateUserValuesBulk(tenants: ITenantDTO[]): UserCreateFormBulkVM {
    const mapped: UserCreateType[] = [];
    for (const item of tenants) {
      const userName = item.externalId ?? `${item.firstName}.${item.lastName}`;
      const password = StringHelpers.generatePassword(12);
      const status = item.applicationUserId == undefined ? PbdStatus.Open : PbdStatus.Blocked;
      mapped.push({
        ...this.initialCreateUserValues,
        userName,
        tenant: item,
        status,
        password,
        tenantId: item.id,
      });
    }
    return { users: mapped, generateRecoveryKeys: true, downloadCredentials: true, addDefaultPermissions: true };
  }
}
