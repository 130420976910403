import { useTranslation } from "react-i18next";

import { PbdModule, PbdStatus } from "../../../generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem, prepareSidebar } from "../../shared/components/sidebar/sidebarNew";
import { AbsencePlannerRoutePaths } from "../absencePlannerRoutePaths";

/**
 * Absence planner sidebar
 * */
function SidebarAbsencePlanner() {
  const { t } = useTranslation();
  const links: SidebarItem[] = prepareSidebar({
    t,
    createUrl: AbsencePlannerRoutePaths.AbsenceRequestCreate,
    overviewUrl: `${AbsencePlannerRoutePaths.AbsenceRequestIndex}?status=${PbdStatus.Open}`,
  });
  links.push({
    id: "calendar",
    title: t("Calendar"),
    icon: qmBaseIcons.CalendarPlus,
    href: AbsencePlannerRoutePaths.AbsenceScheduleIndex + `?status=${PbdStatus.Approved}`,
  });
  links.push({
    id: "statistics",
    title: t("Statistics"),
    icon: qmBaseIcons.ChartLine,
    href: AbsencePlannerRoutePaths.AbsencePlannerStatistics,
  });
  links.push(SidebarItem.settings(SettingsRoutePaths.AbsencePlannerHomePage, t));
  links.push(SidebarItem.deleted(AbsencePlannerRoutePaths.AbsenceRequestIndex + "?isDeleted=1", t));
  return <SidebarNew app={PbdModule.AbsencePlanner} items={links} />;
}

export default SidebarAbsencePlanner;
