import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { NumberHelpers } from "../../../../Helpers/NumberHelpers";
import { ITenantAsAttendeeVM } from "../../../../pbdServices/Models/Tenants/TenantAsAttendeeVM";
import { QualificationMatrixService } from "../../../../pbdServices/services/QualificationMatrix/qualificationMatrixService";

interface IProps {
  data: Pick<ITenantAsAttendeeVM, "qualificationRequirements">;
  showPercentage?: boolean;
}

function AttendeeTableCellRequirements(props: IProps) {
  const { t } = useTranslation();
  const { data, showPercentage } = props;

  if (!data.qualificationRequirements) return null;

  return (
    <>
      {data.qualificationRequirements.map((x) => (
        <Badge
          bg={QualificationMatrixService.getBadgeColor(x)}
          key={x.qualificationId}
          className="me-1"
          title={t(x.qualificationStatus)}
        >
          #{x.qualificationId} {x.qualification?.title ?? t("Qualification")}
          {showPercentage && x.currentQualification?.actualValue && (
            <span className="ms-1">({NumberHelpers.convertToPercentage(x.currentQualification.actualValue)})</span>
          )}
        </Badge>
      ))}
    </>
  );
}
export default AttendeeTableCellRequirements;
