import {
  CategoryDTO,
  IAuditTypesControllerClient,
  IDepartmentsControllerClient,
  IEmployeeIdeaCategoriesControllerClient,
  IGroupsControllerClient,
  IOpportunityCategoriesControllerClient,
  ITenantsControllerClient,
  ITrainingTypesControllerClient,
  PbdModule,
} from "../../generatedCode/pbd-core/pbd-core-api";

import { FilterKey, FilterValue } from "../../ClientApp/shared/components/tables/toolbar/filterKey";

export type ActiveFilterTitles =
  | "Status"
  | "Created by"
  | "Responsible"
  | "Approved by"
  | "Reviewer"
  | "Attendee"
  | "Owner"
  | "Contributor"
  | "Supporter"
  | "Tags"
  | "Created from"
  | "Created to"
  | "Deadline from"
  | "Deadline to"
  | "Planned from"
  | "Planned to"
  | "Updated from"
  | "Updated to"
  | "Project"
  | "Category"
  | "OpportunityType"
  | "Next inspection from"
  | "Next inspection to";

export default class ActiveFilterService {
  trainingTypesApi: ITrainingTypesControllerClient;
  employeeIdeaCategoriesApi: IEmployeeIdeaCategoriesControllerClient;
  auditTypesApi: IAuditTypesControllerClient;
  opportunityCategoriesApi: IOpportunityCategoriesControllerClient;
  tenantsApi: ITenantsControllerClient;
  departmentApi: IDepartmentsControllerClient;
  groupsApi: IGroupsControllerClient;
  constructor(
    trainingTypesApi: ITrainingTypesControllerClient,
    employeeIdeaCategoriesApi: IEmployeeIdeaCategoriesControllerClient,
    auditTypesApi: IAuditTypesControllerClient,
    opportunityCategoriesApi: IOpportunityCategoriesControllerClient,
    tenantsApi: ITenantsControllerClient,
    departmentApi: IDepartmentsControllerClient,
    groupsApi: IGroupsControllerClient,
  ) {
    this.trainingTypesApi = trainingTypesApi;
    this.employeeIdeaCategoriesApi = employeeIdeaCategoriesApi;
    this.auditTypesApi = auditTypesApi;
    this.opportunityCategoriesApi = opportunityCategoriesApi;
    this.tenantsApi = tenantsApi;
    this.departmentApi = departmentApi;
    this.groupsApi = groupsApi;
  }

  async getDataByTitle(filterKey: FilterKey, activeValues: FilterValue, currentApp: PbdModule) {
    switch (filterKey) {
      case "approvedById":
      case "createdById":
      case "responsibleId":
      case "reviewerId":
      case "ownerId":
      case "contributorId":
      case "attendeeId":
      case "supporterId": {
        const numberArray = this.getNumberArray(activeValues);
        return this.tenantsApi.getAllQuery({ id: numberArray }).then((x) => x.data.map((x) => x.fullName));
      }
      case "projectId":
        // missing API: skipped for this time
        return activeValues;
      case "categoryId":
        return this.getCategories(activeValues, currentApp);
      case "departmentId": {
        const numberArray = this.getNumberArray(activeValues);
        return this.departmentApi.getAllQuery({ id: numberArray }).then((x) => x.map((m) => m.title));
      }
      case "applicationUserGroupId": {
        const array = this.getStringArray(activeValues);
        return this.groupsApi.getAllQuery({ id: array }).then((x) => x.map((m) => m.name));
      }
      default:
        return activeValues;
    }
  }

  async getCategories(ids: FilterValue, currentApp: PbdModule): Promise<string[] | undefined> {
    if (!ids) return undefined;
    let categories: CategoryDTO[] = [];

    const numberArray = this.getNumberArray(ids);
    if (currentApp == PbdModule.TrainingManagement) {
      categories = await this.trainingTypesApi.getAllQuery({ id: numberArray });
    }
    if (currentApp == PbdModule.IdeaManagement) {
      categories = await this.employeeIdeaCategoriesApi.getAllQuery({ id: numberArray });
    }
    if (currentApp == PbdModule.AuditManagement) {
      categories = await this.auditTypesApi.getAllQuery({ id: numberArray });
    }
    if (currentApp == PbdModule.OpportunityManagement) {
      categories = await this.opportunityCategoriesApi.getAllQuery({ id: numberArray });
    }

    return categories.map((x) => x.title);
  }

  getNumberArray(data: FilterValue) {
    const asArrayData = Array.isArray(data) ? data : [data];
    const numberArray = asArrayData.map((x) => Number(x));
    return numberArray;
  }

  getStringArray(data: FilterValue) {
    const asArrayData = Array.isArray(data) ? data : [data];
    const array = asArrayData.map((x) => x.toString());
    return array;
  }
}
