import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ClaimRoutePaths } from "../claims/claimRoutePaths";
import { CostRoutePaths } from "../costs/costRoutePaths";
import { PurchaseOrderRoutePaths } from "../purchaseOrders/purchaseOrderRoutePaths";
import { SalesOrderRoutePaths } from "../salesOrders/salesOrderRoutePaths";
import { TaskManagementRoutePaths } from "../taskManagement/taskManagementRoutePaths";
import { StartpageRoutePaths } from "./startpageRoutePaths";

export const startpageRoutes: RouteObject[] = [
  {
    path: StartpageRoutePaths.StartPage,
    lazy: async () => {
      const { StartHomePage } = await import("./pages/startHomePage");
      return { Component: StartHomePage };
    },
  },
  {
    path: StartpageRoutePaths.Contact,
    Component: lazy(() => import("./pages/contact")),
  },
  {
    path: StartpageRoutePaths.SearchPage,
    Component: lazy(() => import("./pages/searchPage")),
  },
  {
    path: StartpageRoutePaths.StatusPage,
    Component: lazy(() => import("./pages/statusPage")),
  },
  {
    path: StartpageRoutePaths.RoutingRulesDetailsPage,
    Component: lazy(() => import("../routingRules/pages/detailsPageRoutingRules")),
  },
  {
    path: StartpageRoutePaths.AccessDenied,
    Component: lazy(() => import("./pages/accessDeniedPage")),
  },
  {
    path: CostRoutePaths.HomePageCostsByModule,
    Component: lazy(() => import("../costs/pages/homePageCosts")),
  },
  {
    path: CostRoutePaths.IndexPageCostsByModule,
    Component: lazy(() => import("../costs/pages/indexPageCosts")),
  },
  {
    path: CostRoutePaths.DetailsPageCostsByModule,
    Component: lazy(() => import("../costs/pages/detailsPageCost")),
  },
  {
    path: CostRoutePaths.CreatePageCostsByModuleTemplate,
    Component: lazy(() => import("../costs/pages/createPageCostsTemplate")),
  },
  {
    path: ClaimRoutePaths.ChooseTemplate,
    Component: lazy(() => import("../templates/pages/chooseTemplatePage")),
  },
  {
    path: TaskManagementRoutePaths.ChooseToDoTemplate,
    Component: lazy(() => import("../templates/pages/chooseTemplatePage")),
  },
  {
    path: SalesOrderRoutePaths.IndexPage,
    Component: lazy(() => import("../salesOrders/pages/indexPageSalesOrders")),
  },
  {
    path: PurchaseOrderRoutePaths.IndexPage,
    Component: lazy(() => import("../purchaseOrders/pages/indexPagePurchaseOrders")),
  },
];
