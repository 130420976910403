import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { CellProps, Column } from "react-table";

import { IExternalIdSetting } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import StringHelpers from "../../../../../Helpers/StringHelpers";
import { ExternalIdSettings, getExternalIdName } from "../../../../../Models/Settings/ExternalIdSettings";

interface IProps {
  id: number | string;
  externalId?: string;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getExternalIdColumn<T extends IProps>(externalIdSetting?: ExternalIdSettings) {
  const column: Column<T> = {
    id: "externalId",
    Header: <>{getExternalIdName(externalIdSetting)}</>,
    accessor: "externalId",
    Cell: ({ row }: CellProps<T>) => <>{row.original.externalId}</>,
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const externalIdColumn = (setting?: IExternalIdSetting) => {
  const header = StringHelpers.isNullOrWhitespace(setting?.displayName)
    ? i18next.t("External ID")
    : setting?.displayName;
  return columnHelper.accessor("externalId", {
    id: "externalId",
    header,
  });
};
