import { Card, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { IExternalIdSetting, PbdModule, SettingType } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";

import FeatureFlagService from "../../../../pbdServices/services/FeatureFlags/featureFlagService";
import EntityFieldTemplateComponent from "../../components/entityFieldTemplateComponent";
import ExternalIdForm from "../../components/externalIdForm";

const IdeaManagementConfigurationPage = () => {
  const { moduleSettingsService } = useAPIServices();
  const { moduleSettingsApi } = useAPIs();
  const { t } = useTranslation();
  const { data: externalIdSetting } = useSWR("/api/moduleSettings/employeeIdeas/externalId", () =>
    moduleSettingsApi.getExternalIdSetting(PbdModule.IdeaManagement),
  );

  const updateExternalIdSetting = async (dto: IExternalIdSetting) => {
    return moduleSettingsService.save(PbdModule.IdeaManagement, SettingType.ExternalId, dto);
  };

  return (
    <>
      <h2>{t("Idea management")}</h2>
      <hr />
      <Card>
        <CardBody>
          {externalIdSetting && <ExternalIdForm onSubmit={updateExternalIdSetting} itemToUpdate={externalIdSetting} />}
          {FeatureFlagService.isFieldKeysAvailable() && <EntityFieldTemplateComponent app={PbdModule.IdeaManagement} />}
        </CardBody>
      </Card>
    </>
  );
};

export default IdeaManagementConfigurationPage;
