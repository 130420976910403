import React from "react";
import { useTranslation } from "react-i18next";
import { GroupBase, MultiValue, SingleValue } from "react-select";
import { AsyncPaginate, LoadOptions } from "react-select-async-paginate";
import { FormGroup, FormText, Label } from "reactstrap";

import { IAddressDTO, IAddressRoomDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { GlobalQmBaseConstants } from "../../../../../Constants/GlobalQmBaseConstants";
import GenericSelect, { OptionData, mapToOptionData } from "./genericSelect";
import { Additional } from "./models/additional";
import { getValue } from "./reactSelectHelpers";

const getLabel = (option: IAddressDTO) => {
  if (option.fullAddress) {
    return option.fullAddress;
  } else {
    return `#${option.id.toString()}`;
  }
};

interface IProps {
  value?: IAddressDTO | IAddressDTO[];
  room?: IAddressRoomDTO;
  onChange?: (address?: IAddressDTO, room?: IAddressRoomDTO) => void;
  onChangeArray?: (items?: IAddressDTO[]) => void;
  includeRoomSelect?: boolean;
  isMulti?: boolean;
  inputId?: string;
}

function AddressSelectAsync(props: IProps) {
  const { value, onChange, includeRoomSelect = true, room, isMulti, onChangeArray, inputId } = props;
  const { t } = useTranslation();
  const [selectedAddress, setSelectedAddress] = React.useState<IAddressDTO | IAddressDTO[] | undefined>(value);
  const [selectedRoom, setSelectedRoom] = React.useState<IAddressRoomDTO | undefined>(room);
  const { addressesApi } = useAPIs();

  const handleRoomChange = (address: IAddressDTO, room?: OptionData[]) => {
    if (!onChange) throw Error("onChange not defined");
    const selected = room ? address.addressRooms?.find((x) => x.id == Number(room[0].value)) : undefined;
    onChange(address, selected);
    selected ? setSelectedRoom({ id: selected.id, title: selected.title }) : setSelectedRoom(undefined);
  };

  const handleChange = (dto?: MultiValue<IAddressDTO> | SingleValue<IAddressDTO>) => {
    if (!dto) {
      if (onChange) {
        onChange(undefined);
      } else if (onChangeArray) {
        onChangeArray(undefined);
      }
    } else if (Array.isArray(dto) && onChangeArray) {
      onChangeArray(dto);
    } else if (onChange && !Array.isArray(dto)) {
      const dtoAsAddressDTO = dto as IAddressDTO;
      setSelectedAddress(dtoAsAddressDTO);
      //@ts-expect-error Goes away in strict mode
      onChange(dto);
    }
  };

  const loadOptions: LoadOptions<IAddressDTO, GroupBase<IAddressDTO>, Additional> = async (
    search,
    loadedOptions,
    additional,
  ) => {
    const response = await addressesApi.getAllQuery({
      q: search,
      page: additional?.page,
      pageSize: GlobalQmBaseConstants.DefaultPageSize,
      sortBy: "Line1",
    });

    return {
      options: response.data,
      hasMore: response.pagination.hasNext,
      additional: {
        page: (additional?.page ?? 0) + 1,
      },
    };
  };

  return (
    <>
      <AsyncPaginate
        inputId={inputId}
        menuPosition="fixed"
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        debounceTimeout={GlobalQmBaseConstants.DebounceMs}
        value={value}
        loadOptions={loadOptions}
        onChange={handleChange}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        additional={{
          page: 1,
        }}
        isClearable
        isMulti={isMulti}
      />
      {!isMulti && includeRoomSelect && (
        <FormGroup>
          {selectedAddress &&
          !Array.isArray(selectedAddress) &&
          selectedAddress.addressRooms &&
          selectedAddress.addressRooms.length > 0 ? (
            <>
              <Label>{t("Room")}</Label>
              <GenericSelect
                data={mapToOptionData(selectedAddress.addressRooms)}
                name="AddressRoom"
                onChange={(x) => handleRoomChange(selectedAddress, x)}
                selected={selectedRoom?.id}
              />
            </>
          ) : (
            <FormText>{t("No rooms have been defined for this address")}</FormText>
          )}
        </FormGroup>
      )}
    </>
  );
}

export default AddressSelectAsync;
