import { ErrorMessage, FieldProps } from "formik";
import { TwitterPicker } from "react-color";
import { Button, FormFeedback, FormText } from "reactstrap";

import { useToggle } from "../../../hooks/useToggle";
import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface FormTextProps {
  formText: string;
  autoFocus?: boolean;
}

/**Use type="textarea" to render a textarea */
export const FormikColorInput = ({
  field,
  form: { touched, errors, setFieldValue },
  formText,
  ...props
}: FieldProps<string> & FormTextProps) => {
  const [showColorPicker, toggleColorPicker] = useToggle();
  return (
    <>
      <div className="d-flex align-items-center">
        <div>
          <qmBaseIcons.SquareSolid className="fa-3x" style={{ color: field.value }} />
        </div>
        <div className="ms-3">
          {showColorPicker ? (
            <TwitterPicker
              width="550px"
              onChangeComplete={(x) => setFieldValue(field.name, x.hex)}
              //   {...field}
              //   {...props}
              triangle="hide"
            />
          ) : (
            <Button color="link" onClick={toggleColorPicker}>
              <qmBaseIcons.Pencil />
            </Button>
          )}
        </div>
      </div>
      <ErrorMessage component={FormFeedback} className="order-last" {...field} />
      {formText && <FormText color="muted">{formText}</FormText>}
    </>
  );
};
