import { useTranslation } from "react-i18next";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { StatusString } from "../../../Models/Enums/Status";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { IdeaManagementRoutePaths } from "../ideaManagementRoutePaths";

/**
 * Idea management sidebar
 * */
function SidebarIdeaManagement() {
  const { t } = useTranslation();

  const links: SidebarItem[] = [
    SidebarItem.created(IdeaManagementRoutePaths.CreatePage, t),
    SidebarItem.overview(
      `${IdeaManagementRoutePaths.IndexPage}/?status=${StatusString.Open}&status=${StatusString.Submitted}&status=${StatusString.UnderReview}&status=${StatusString.InProgress}&status=${StatusString.Approved}`,
      t,
      t("Ideas"),
    ),
  ];

  return <SidebarNew app={PbdModule.IdeaManagement} showSmartViews items={links} />;
}

export default SidebarIdeaManagement;
