import { isEqual } from "lodash";

import {
  ICostDTO,
  IQualificationDTO,
  ITenantAsAttendee,
  IToDoDTO,
  ITrainingDTO,
  ITrainingEditDTO,
  ITrainingTypesControllerClient,
  PbdStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import CostService, { WithCosts } from "../Costs/costService";
import ExportService from "../Export/exportService";
import ToDoService from "../ToDos/todoService";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { AcceptanceStatus } from "./models/acceptance-status";
import { TrainingKpis } from "./models/training-kpis";

export default class TrainingService implements IPrerequisitesService {
  private _trainingsTypesApi: ITrainingTypesControllerClient;
  constructor(trainingsTypesApi: ITrainingTypesControllerClient) {
    this._trainingsTypesApi = trainingsTypesApi;
  }
  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const trainingsTypes = await this._trainingsTypesApi.getAll();
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "trainingsTypes",
        title: "Trainings Types",
        status: trainingsTypes.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.TrainingsManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
    return resp;
  }

  exportToCsv(items: WithCosts<ITrainingDTO>[]) {
    return ExportService.exportCSV("Training", items, (x) => ({
      id: x.id,
      title: x.title,
      status: x.status,
      category: x.category?.title,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt,
      plannedAt: x.plannedAt,
      costs: CostService.convertCostSumToString(x.costSums, true),
      costDetails: CostService.convertCostDetailsToString(x.costs, true),
      tags: x.tags?.map((x) => x.title).join(),
    }));
  }

  isDefaultQualifications(connectedQualifications: IQualificationDTO[], defaultQualifications: IQualificationDTO[]) {
    return isEqual(
      connectedQualifications.map((x) => x.id),
      defaultQualifications.map((x) => x.id),
    );
  }

  static getInitialValues(item: ITrainingDTO): ITrainingEditDTO {
    return {
      id: item.id,
      title: item.title,
      description: item.description ?? undefined,
      maximumNumberOfAttendees: item.maximumNumberOfAttendees,
      rateableByAttendee: item.rateableByAttendee,
      attendeesCanSelfRegister: item.attendeesCanSelfRegister,
      categoryId: item.category?.id ?? 0,
    };
  }

  static mapCosts(array: ITrainingDTO[], costs: ICostDTO[]) {
    return CostService.mapWithCosts(array, costs);
  }

  static mapAttendees<T extends { id: number }>(items: T[], attendees: ITenantAsAttendee[]) {
    return items.map((x) => {
      return {
        ...x,
        attendees: attendees.filter((a) => a.attendanceInfo.trainingId == x.id),
      };
    });
  }

  static getKpis(all: ITrainingDTO[], connectedTodos: IToDoDTO[], costs: ICostDTO[], totalUrl?: string) {
    const withCosts = CostService.mapWithCosts(all, costs);
    const kpis = new TrainingKpis(withCosts, totalUrl);
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    return kpis;
  }

  static canEditAttendees(meAsUser: MeAsUser, tenantAttendee: ITenantAsAttendee) {
    const meIsAuthorized =
      hasRole(meAsUser, [PbdRoles.Admin]) ||
      hasRole(meAsUser, [PbdRoles.Trainings_ModuleAdmin]) ||
      meAsUser.tenant.id == tenantAttendee.id ||
      meAsUser.tenant.id == tenantAttendee.attendanceInfo.training?.responsible?.id;

    return meIsAuthorized;
  }

  static disableChangeAttendeeStatus(
    meAsUser: MeAsUser,
    tenantAttendee: ITenantAsAttendee,
    isConfirmedOrAttended?: boolean,
  ) {
    const meIsAuthorized =
      hasRole(meAsUser, [PbdRoles.Admin]) ||
      hasRole(meAsUser, [PbdRoles.Trainings_ModuleAdmin]) ||
      meAsUser.tenant.id == tenantAttendee.id ||
      meAsUser.tenant.id == tenantAttendee.attendanceInfo.training?.responsible?.id;

    // If the I am not authorized, the button will be disabled.
    if (!meIsAuthorized) {
      return true;
    }

    // If the training attendee is confirmed or attended, the button will be disabled.
    return isConfirmedOrAttended ? true : false;
  }

  static getAttendanceStatus(
    training: ITrainingDTO,
    attendance: ITenantAsAttendee,
  ): {
    options: AcceptanceStatus[];
    label: string;
    responseRequired: boolean;
    otherOptions: AcceptanceStatus[];
    ratingRequired?: boolean;
    virtualStatus: PbdStatus;
  } {
    if (training.status == PbdStatus.Completed) {
      return {
        label: "Did you participate?",
        responseRequired: !attendance.attendanceInfo.attendanceConfirmedAt,
        options: [this.possibleAttendanceStatus().Attended, this.possibleAttendanceStatus().NotAttended],
        otherOptions: [this.possibleAttendanceStatus().Open],
        ratingRequired:
          attendance.attendanceInfo.status == PbdStatus.Attended &&
          training.rateableByAttendee &&
          attendance.attendanceInfo.ratedAt == undefined,
        virtualStatus: attendance.attendanceInfo.attendanceConfirmedAt
          ? attendance.attendanceInfo.status
          : PbdStatus.Open,
      };
    } else {
      return {
        label: "Do you accept this invitation?",
        responseRequired: !attendance.attendanceInfo.acceptedAt,
        options: [this.possibleAttendanceStatus().Accepted, this.possibleAttendanceStatus().NotAccepted],
        otherOptions: [this.possibleAttendanceStatus().Open],
        virtualStatus: attendance.attendanceInfo.status,
      };
    }
  }

  static canTransferQualifications(
    meAsUser: MeAsUser,
    training: ITrainingDTO,
    qualifications: IQualificationDTO[],
    attendees: ITenantAsAttendee[],
  ): boolean {
    if (training.status != PbdStatus.Completed) return false;

    if (
      (training.responsible?.id == meAsUser.tenant.id ||
        hasRole(meAsUser, [PbdRoles.Admin, PbdRoles.Trainings_ModuleAdmin])) &&
      qualifications.length > 0 &&
      attendees.filter((x) => !x.attendanceInfo.isQualificationsTransferred).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  static possibleAttendanceStatus(): AcceptanceStatusType {
    return {
      Open: {
        status: PbdStatus.Open,
        label: "Reset",
        color: "danger",
        outline: true,
        description: "Delete all infos about your attendance status",
      },
      Accepted: {
        status: PbdStatus.Accepted,
        label: "Yes",
        color: "success",
        description: "You plan to attend this event",
      },
      NotAccepted: {
        status: PbdStatus.NotAccepted,
        label: "No",
        outline: true,
        color: "danger",
        description: "You will not attend this event",
      },
      Attended: {
        status: PbdStatus.Attended,
        label: "Yes",
        color: "success",
        description: "You have attended this event",
      },
      NotAttended: {
        status: PbdStatus.NotAttended,
        label: "No",
        outline: true,
        color: "danger",
        description: "You have not attended this event",
      },
    };
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.Qualification,
      SearchFilterTypes.Attendees,
      SearchFilterTypes.Category,
      SearchFilterTypes.PlannedAt,
      SearchFilterTypes.Department,
      SearchFilterTypes.DepartmentPosition,
      SearchFilterTypes.CustomField,
    ];
  }
}

type AcceptanceStatusType = Record<
  PbdStatus.Open | PbdStatus.Accepted | PbdStatus.NotAccepted | PbdStatus.Attended | PbdStatus.NotAttended,
  AcceptanceStatus
>;
