import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

import { ICostDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { NumberHelpers } from "../../../Helpers/NumberHelpers";

interface IProps {
  items: ICostDTO[];
}

const CostsPrintTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { items } = props;
  return (
    <React.Fragment>
      <h5>
        {t("Costs")} ({items.length})
      </h5>
      <Table responsive>
        <thead>
          <tr>
            <th>{t("Title")}</th>
            <th>{t("Category")}</th>
            <th>{t("Cost center")}</th>
            <th>{t("Created")}</th>
            <th className="text-end">{t("Value")}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((d) => (
            <tr key={d.id}>
              <td>{d.title}</td>
              <td>{d.category?.title}</td>
              <td>{d.costCenter?.title}</td>
              <td>
                {d.createdAt > DateTime.fromISO("0002-01-01") && DateTimeLuxonHelpers.convertUtcToDateTime(d.createdAt)}
              </td>
              <td align="right">{NumberHelpers.convertToMoney(d.value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};
export default CostsPrintTable;
