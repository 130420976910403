import * as yup from "yup";

import { getCurrentCulture } from "../i18n/cultureSettingService";
import yup18nDeLocale from "./yupLocaleDE";

export function buildYupLocale() {
  const culture = getCurrentCulture();
  if (culture.startsWith("de")) {
    yup.setLocale(yup18nDeLocale);
  }

  //   }
}
