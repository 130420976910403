import React from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ICategoryCreateDTO,
  IInventoryInspectionDTO,
  TimeIntervalDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import EntityTemplatesButton from "../../../shared/components/entityTemplates/entityTemplatesButton";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function EditPageInventoryInspections() {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { inventoryInspectionsApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IInventoryInspectionDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingInventoryInspections } = useSWR(`inventoryInspections`, () => inventoryInspectionsApi.getAll());

  React.useEffect(() => {
    async function getData() {
      const type = await inventoryInspectionsApi.getById(Number(id));
      setItemToUpdate(type);
    }
    if (id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  const handleSubmit = (itemToCreate: ICategoryCreateDTO) => {
    if (id) {
      return inventoryInspectionsApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return inventoryInspectionsApi.create(new CategoryCreateDTO(itemToCreate));
    }
  };

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Item missing");
    if (itemToUpdate.isDeleted) {
      await inventoryInspectionsApi.restore(itemToUpdate.id);
    } else {
      await inventoryInspectionsApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  const handleTemplateClick = (dto: ICategoryEntityTemplate) => {
    const itemToCreate: ICategoryCreateDTO = {
      title: dto.title,
      description: dto.description,
      color: dto.color ?? undefined,
      monitoringInterval: dto.monitoringInterval ? new TimeIntervalDTO(dto.monitoringInterval) : undefined,
      isRecurring: dto.monitoringInterval != null,
    };
    return inventoryInspectionsApi.create(new CategoryCreateDTO(itemToCreate));
  };

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageInventoryInspections.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  const entityTemplateButton = (
    <EntityTemplatesButton
      onSelect={handleTemplateClick}
      templateName={"inventoryInspection"}
      onSuccess={handleSuccess}
    />
  );

  if (loading) return null;

  return (
    <BaseSettingsForm
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      onDelete={toggleDelete}
      onRestore={(tId) => inventoryInspectionsApi.restore(tId)}
      itemToUpdate={itemToUpdate}
      additionalFields={["Recurring", "WarningTime", "OldCustomFields", "DescriptionAsHtml"]}
      handleRefresh={handleRefresh}
      entityTemplateComponent={entityTemplateButton}
      existingItems={existingInventoryInspections}
      formType={"InventoryInspection"}
    />
  );
}

export default EditPageInventoryInspections;
