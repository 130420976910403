import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RouterProvider } from "react-router-dom";

import { myRouter } from "./appRoutes";
import { reactPlugin } from "./applicationInsightsService";
import AppWrapperFallbackLoading from "./shared/components/loading/appWrapperFallbackLoading";
import { ServiceContextWrapper } from "./shared/contexts/serviceContextWrapper";

export default function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <ServiceContextWrapper>
        <RouterProvider
          router={myRouter}
          fallbackElement={<AppWrapperFallbackLoading title="Starting App..." />}
          future={{ v7_startTransition: true }}
        />
      </ServiceContextWrapper>
    </AppInsightsContext.Provider>
  );
}
