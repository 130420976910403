import { EntityKey } from "../generatedCode/pbd-core/pbd-core-api";

import { CrmRoutePaths } from "../ClientApp/crm/crmRoutePaths";
import { IconComponent } from "../ClientApp/shared/components/icons/icon-base";
import { qmBaseIcons } from "../ClientApp/shared/components/icons/qmBaseIcons";

export const EntityDefinitionData: EntityDefinitionRecord = {
  Organisation: { create: CrmRoutePaths.CreatePageOrganisation, iconComponent: qmBaseIcons.Building },
  Tenant: { create: CrmRoutePaths.CreatePageTenant, iconComponent: qmBaseIcons.User },
  OrganisationRating: { create: CrmRoutePaths.CreatePageOrganisationRatings, iconComponent: qmBaseIcons.Rate },
};

export type EntityDefinitionRecord = Partial<Record<keyof typeof EntityKey, UiData>>;

interface UiData {
  iconComponent: IconComponent;
  /**Startpage of the module */
  homePage?: string;
  /**Create page */
  create: string;
  /**Choose template page */
  chooseTemplate?: string;
  /**Edit page */
  details?: string;
  /**Edit page */
  edit?: string;
  /**Index or list page */
  list?: string;
  /**Recently accessed items */
  recent?: string;
  /**Link to settings */
  settings?: string;
  /**Link to dashboard */
  dashboard?: string;
  displayName?: string;
  displayNameSidebar?: string;
}
