import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

import { ITenantMinDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { getExternalIdName } from "../../../Models/Settings/ExternalIdSettings";
import { preferExternalId } from "../../../pbdServices/Models/Settings/ExternalIdSettings";
import { TenantApp } from "../../../pbdServices/services/ModuleSettings/models/app-module-settings";

interface IProps {
  attendees: ITenantMinDTO[];
  settings: TenantApp;
}

const AttendeeTablePrintView: React.FC<IProps> = (props) => {
  const { attendees, settings } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <h5>
        {t("Attendees")} ({attendees.length})
      </h5>
      <Table className="table table-bordered">
        <thead>
          <tr style={{ borderStyle: "solid", borderColor: "black", fontSize: "large" }}>
            <th>
              {preferExternalId(settings.externalIdSetting) ? getExternalIdName(settings.externalIdSetting) : t("ID")}
            </th>
            <th>{t("Name")}</th>
            <th>{t("Signature")}</th>
          </tr>
        </thead>
        <tbody>
          {attendees.map((x) => (
            <tr key={x.id} style={{ borderStyle: "solid", borderColor: "black" }}>
              <td>{preferExternalId(settings.externalIdSetting) ? x.externalId : x.id}</td>
              <td>{x.fullName}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default AttendeeTablePrintView;
