import React from "react";

import { DefaultLayoutSubsidebar } from "../shared/layouts/defaultLayoutSubsidebar";
import SubsidebarComponent from "./components/subsidebars/subsidebarComponent";

interface IProps {
  Component: React.ComponentType;
}

/**Special layout for admin page with sub sidebar */
export function SettingLayoutWithSubsidebar(props: IProps) {
  return <DefaultLayoutSubsidebar SubSidebar={SubsidebarComponent} {...props} />;
}
