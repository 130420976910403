import {
  ICategoryDTO,
  IInventoryInspectionDoneDTO,
  IInventoryItemDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

export class InventoryItemKpis {
  constructor(totalUrl: string) {
    this.totalUrl = totalUrl;
  }
  all?: IInventoryItemDTO[];
  allExcluded?: IInventoryItemDTO[];
  /**Created during query */
  total?: IInventoryItemDTO[];
  validInspection?: IInventoryItemDTO[];
  categories?: ICategoryDTO[];
  partOfItemsWithValidInspection?: number;
  partOfItemsWithValidInspectionFormatted?: string;
  inventoryInspectionsDone?: IInventoryInspectionDoneDTO[];
  totalUrl?: string;
}
