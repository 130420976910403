import _ from "lodash";
import React, { useCallback } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IdType,
  Row,
  SortingRule,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { useQueryParams } from "use-query-params";

import { ReactTableStorageDTO } from "../../../../Models/ReactTableStorageDTO";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { QueryParamsConfigPbd } from "../genericSearchFilter/queryParamsConfigPbd";
import { getSelectionColumn } from "./columns/selectionColumns";
import TableLoadingSkeleton from "./components/tableLoadingSkeleton";
import TableNoDataRow from "./components/tableNoDataRow";
import { TablePagination } from "./components/tablePagination";
import TableSortIcon from "./components/tableSortIcon";
import { ReactstrapTableProps } from "./reactstrapTableProps";
import { useTableKeyName } from "./tableHelperFunctions";
import AdvancedTableOptionsRow from "./toolbar/advancedTableOptionsRow";
import TableToolbar from "./toolbar/tableToolbar";

export const idOfSelectionColumn = "selectionColumn";

export const idOfActionColumn = "actionColumn";

// eslint-disable-next-line @typescript-eslint/naming-convention, max-lines-per-function
export function ReactstrapTable<T extends object>({
  columns,
  tableRows,
  setSelected,
  activeFilters,
  onDeleteFilterKey,
  columnsVisibleDefault,
  showTableToolbar = true,
  onClick,
  availableTableActions = [],
  localStorageStateKey,
  hideAdvancedTableOptionsButton,
  showSearchInput = true,
  autoResetPage = true,
  small = false,
  responsive = true,
  isAlwaysSelectable = false,
  ...props
}: ReactstrapTableProps<T>) {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams(QueryParamsConfigPbd);
  const data = React.useMemo(() => tableRows ?? [], [tableRows]);
  // const data = tableRows ?? [];
  // Use the state and functions returned from useTable to build your UI
  const [showAdvancedTableOptions, setShowAdvancedTableOptions] = React.useState(false);
  const tableKey = useTableKeyName(localStorageStateKey);

  const defaultHidden = React.useMemo(() => {
    if (columns.find((x) => x.id == "id") == undefined) {
      console.warn("qWarning: Missing Id column selection not possible.");
    }
    if (!columnsVisibleDefault || columnsVisibleDefault.length == 0) {
      return [];
    } else {
      return _.compact(columns.filter((x) => !columnsVisibleDefault.includes(x.id ?? "")).map((x) => x.id));
    }
  }, [columns, columnsVisibleDefault]);

  const [tableState, setTableState] = useLocalStorage<ReactTableStorageDTO<T>>(
    tableKey,
    new ReactTableStorageDTO(defaultHidden),
  );

  const toggleShowAdvancedTableOptions = () => setShowAdvancedTableOptions(!showAdvancedTableOptions);

  const ourGlobalFilterFunction = useCallback((rows: Row<T>[], ids: IdType<T>[], query: string) => {
    const columnsToIgnore = ["selectionColumn", "createdAt", "lastUpdatedAt", "plannedAt", "plannedAtEnd"];
    return rows.filter((row) => {
      return ids
        .filter((x) => !columnsToIgnore.includes(x))
        .some((_id) => {
          let rowValue = `${row.values[_id]}`.toUpperCase();
          if (_id == "id") {
            rowValue = "#" + rowValue;
          }
          return rowValue.includes(query.toUpperCase());
        });
    });
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleHideColumn,
    allColumns,
    getToggleHideAllColumnsProps,
    setSortBy,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, hiddenColumns, sortBy },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: tableState.hiddenColumns ?? [],
        sortBy: tableState.sortBy,
        pageSize: tableState.pageSize,
      }, // Pass our hoisted table state
      useSortBy,
      autoResetPage,
      autoResetSelectedRows: false,
      // autoResetSelectedRows: false,
      // filterTypes,
      globalFilter: ourGlobalFilterFunction,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((col) => [
        // Let's make a column for selection
        getSelectionColumn(tableKey),
        ...col,
      ]);
    },
  );

  React.useEffect(() => {
    if (!autoResetPage && tableRows && tableRows.length < pageCount * pageSize) {
      console.log("Reset page current page was too far behind.");
      gotoPage(0);
    }
  }, [autoResetPage, gotoPage, pageCount, pageSize, tableRows]);

  React.useMemo(() => {
    const isColumnVisible = isAlwaysSelectable || showAdvancedTableOptions;
    toggleHideColumn(idOfSelectionColumn, !isColumnVisible);
  }, [showAdvancedTableOptions]);

  React.useEffect(() => {
    if (setSelected && tableRows) {
      // Current workaround until this is resolved
      // https://github.com/tannerlinsley/react-table/issues/2210
      const selectedNew: T[] = [];
      Object.keys(selectedRowIds).forEach((x) => {
        const element = data[Number(x)];
        selectedNew.push(element);
      });
      setSelected(selectedNew);
    }
    // do not change the dependency array or it will lead to infinite loops
  }, [selectedRowIds]);

  React.useEffect(() => {
    if (sortBy.length > 0) {
      saveSortingColumnState(sortBy);
    }
  }, [sortBy]);

  React.useEffect(() => {
    saveHiddenColumnsState(hiddenColumns ?? []);
  }, [hiddenColumns]);

  const saveHiddenColumnsState = (dto: IdType<T>[]) => {
    setTableState((prev) => ({
      ...prev,
      hiddenColumns: dto,
    }));
  };

  const saveSortingColumnState = (dto: SortingRule<T>[]) => {
    setTableState((prev) => ({
      ...prev,
      sortBy: dto,
    }));
    setSortBy(dto);
  };

  const handleChangePageSize = (pageSizeNew: number) => {
    setPageSize(pageSizeNew);
    setTableState((prev) => ({
      ...prev,
      pageSize: pageSizeNew,
    }));
  };

  const numSelected = Object.keys(selectedRowIds).length;

  // Render the UI for your table
  return (
    <>
      {/* <pre>{JSON.stringify(tableState, null, 2)}</pre>
      <pre>{JSON.stringify(hiddenColumns, null, 2)}</pre> */}
      {showTableToolbar && (
        <TableToolbar
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          showAdvancedTableOptions={showAdvancedTableOptions}
          toggleShowAdvancedTableOptions={toggleShowAdvancedTableOptions}
          hideAdvancedTableOptionsButton={hideAdvancedTableOptionsButton}
          showSearchBox={showSearchInput}
          activeFilters={activeFilters ?? query}
          onDelete={(k) => {
            console.log(k);
            onDeleteFilterKey?.(k);
            setQuery({ [k]: null });
          }}
          {...props}
        />
      )}
      <AdvancedTableOptionsRow
        availableTableActions={availableTableActions}
        showAdvancedTableOptions={showAdvancedTableOptions}
        numSelected={numSelected}
        defaultHidden={defaultHidden}
        onClick={onClick}
        saveHiddenColumnsState={saveHiddenColumnsState}
        allColumns={allColumns}
        getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        tableKey={tableKey}
      />

      <Table responsive={responsive} size={small ? "sm" : undefined} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps([
                    //@ts-expect-error: Not included in default typing but required for align right for date and number columns
                    { className: column.className },
                  ])}
                  key={i}
                >
                  <span {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <TableSortIcon column={column} />
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!tableRows && <TableLoadingSkeleton columns={columns} />}
          {tableRows?.length == 0 && (
            <TableNoDataRow
              onClick={onClick}
              availableTableActions={availableTableActions}
              activeFilters={activeFilters}
              columns={columns}
            />
          )}
          {tableRows && tableRows.length > 0 && page.length == 0 && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                <em>{t("No data available")}</em>
              </td>
            </tr>
          )}
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        //@ts-expect-error: Not included in default typing but required for align right for date and number columns
                        { className: cell.column.className },
                      ])}
                      key={i}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <TablePagination
        data={data}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        gotoPage={gotoPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        setPageSize={handleChangePageSize}
      />
    </>
  );
}
