import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Alert, Badge, Button, Card, CardBody, CardHeader, CardTitle, Input, Table } from "reactstrap";
import useSWR from "swr";

import {
  AuthOperation,
  ICustomField,
  IObjectPermission,
  ObjectPermission,
  PbdModule,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { CustomRolePrefix } from "../../../../pbdServices/services/Account/roleService";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { AdminRoutePaths } from "../../../admin/adminRoutePaths";
import RequiredRolesComponent from "../../../admin/roles/components/requiredRolesComponent";
import GenericAlert from "../../../shared/components/alerts/genericAlert";

interface IProps {
  item: ICustomField;
  module: PbdModule;
}

const ops = Object.keys(AuthOperation).filter((x) => x == "FullAccess");

const CustomFieldAuthCard: React.FC<IProps> = (props) => {
  const { item, module } = props;
  const { rolesApi, customFieldsApi } = useAPIs();
  const { data: roles } = useSWR(["/api/identity/roles", CustomRolePrefix], () =>
    rolesApi.getAllQuery({ q: CustomRolePrefix }),
  );
  const { t } = useTranslation();
  const [update, setUpdate] = React.useState<IObjectPermission[]>(item.permissions ?? []);

  const deletedRoles = React.useMemo(() => {
    if (roles && item.permissions) {
      const allRoles = item.permissions.filterMap((x) => x.roles);
      //todo fix empty value
      const reduced = _.compact(allRoles.reduce((pv, cv) => pv.concat(cv), []));
      return reduced.filter((x) => !roles.map((r) => r.name).includes(x));
    } else {
      return undefined;
    }
  }, [roles, item.permissions]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!update) throw Error("Missing update value");
    const roleName = event.target.id.split("_")[1];
    const opId = event.target.id.split("_")[0];
    const oldSelection = update.find((x) => x.operation == opId);
    const selected = handleClick(roleName, oldSelection?.roles ?? []);
    setUpdate(update.filter((x) => x.operation != opId));
    setUpdate((prev) => [...prev, { operation: opId as AuthOperation, roles: selected, category: "General" }]);
    // setUpdate([{ operationName: opId as Operation, roleIds: selected }]);
  };

  function handleClick(id: string, selected: string[]) {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    return newSelected;
  }

  const handleSave = async () => {
    if (!roles) throw Error("Missing data");
    const updateArray = update.map((x) => {
      x.roles = x.roles?.filter((r) => roles.map((y) => y.name).includes(r));
      return new ObjectPermission(x);
    });
    const onlyWithRoles = updateArray.filter((x) => x.roles && x.roles.length > 0);
    await wrapApiCallWithToast(() => customFieldsApi.editCapabilities(module, item.id, onlyWithRoles));
  };

  return (
    <React.Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-between">
            <CardTitle tag="h5">{t("Restricted access")}</CardTitle>
            <RequiredRolesComponent roles={[PbdRoles.Admin]} />
          </div>
        </CardHeader>
        <CardBody>
          {deletedRoles && deletedRoles.length > 0 && (
            <Alert color="info">
              {t("Roles connected to this custom field have been deleted and will be removed on save.")}
              <p>
                {deletedRoles.map((x) => (
                  <Badge key={x} className="me-1">
                    {x}
                  </Badge>
                ))}
              </p>
            </Alert>
          )}
          {roles && roles.length == 0 && (
            <GenericAlert dismissible={false}>
              {t("Please define custom roles to use this function.")}{" "}
              <Link to={AdminRoutePaths.IndexPageRoles}>{t("Link")}</Link>
            </GenericAlert>
          )}
          {roles && roles.length > 0 && (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>{t("Role")}</th>
                    {ops.map((x) => (
                      <th key={x}>{t(x)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {roles
                    .filter((x) => x.name.startsWith(CustomRolePrefix))
                    .map((x) => (
                      <tr key={x.id}>
                        <td>{x.name}</td>
                        {ops.map((o) => {
                          const id = `${o}_${x.name}`;
                          const permissions = update.find((u) => u.operation == o);
                          return (
                            <th key={id}>
                              <Input
                                type="checkbox"
                                id={id}
                                onChange={handleChange}
                                defaultChecked={permissions?.roles?.includes(x.name)}
                              />
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Button color="primary" onClick={handleSave}>
                {t("Save")}
              </Button>
            </>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CustomFieldAuthCard;
