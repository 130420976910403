import { NumberHelpers } from "../../../../Helpers/NumberHelpers";
import { ProgressBar } from "./progressBar";

interface IProps {
  metric: string | number;
  progress: number;
  target: string;
  color?: "success" | "danger";
  visibleDecimals?: number;
}

function ProgressBarWithText(props: IProps) {
  const { progress, target, metric, color, visibleDecimals = 2 } = props;
  const progressCut = NumberHelpers.convertToFractionNumber(progress, 0, visibleDecimals);
  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          {progressCut}% ({metric})
        </div>
        <div>{target}</div>
      </div>
      <ProgressBar currentValue={progress} maxValue={100} showValue={false} color={color} />
    </>
  );
}
export default ProgressBarWithText;
