/* eslint-disable @typescript-eslint/naming-convention */
import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { IconComponent } from "../icons/icon-base";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import { TableAction } from "./TableClickCommand";

interface TableActionUiDefinition {
  Icon: IconComponent;
  title: string;
  requiresSelection: boolean;
  allowMultiSelect?: boolean;
  maxSelectedEntries?: number;
}

type TableActionRecord = Partial<Record<TableAction, TableActionUiDefinition>>;

export const tableActionUiData: TableActionRecord = {
  Delete: { title: "Delete", Icon: qmBaseIcons.Delete, requiresSelection: true },
  Export: { title: "Export", Icon: qmBaseIcons.Export, requiresSelection: false },
  Print: { title: "Print", Icon: qmBaseIcons.Print, requiresSelection: true },
  ChangeResponsible: {
    title: "Change responsible",
    Icon: qmBaseIcons.ChangeResponsible,
    requiresSelection: true,
  },
  LogInspection: { title: "Log inspection", Icon: qmBaseIcons.LogInspection, requiresSelection: true },
  SetAccess: { title: "Set access", Icon: qmBaseIcons.SetAccess, requiresSelection: true },
  Move: { title: "Move", Icon: qmBaseIcons.Move, requiresSelection: true },
  ShowCosts: { title: "Show costs", Icon: qmBaseIcons.Cost, requiresSelection: true },
  SetConnectionType: { title: "Set connection type", Icon: qmBaseIcons.Move, requiresSelection: true },
  ConnectInspection: { title: "Connect inspection", Icon: qmBaseIcons.Microscope, requiresSelection: true },
  Add: { title: "Add", Icon: qmBaseIcons.Plus, requiresSelection: true },
  Remove: { title: "Remove", Icon: qmBaseIcons.Minus, requiresSelection: true },
  Notify: { title: "Notify", Icon: qmBaseIcons.Envelope, requiresSelection: true },
  RemoveFromEmployees: { title: "Remove from employees", Icon: qmBaseIcons.Minus, requiresSelection: true },
  ChangeLocation: { title: "Change location", Icon: qmBaseIcons.MapMarker, requiresSelection: true },
  ChangeTags: { title: "Change tags", Icon: qmBaseIcons.Tags, requiresSelection: true },
  SetQuantity: { title: "Set quantity", Icon: qmBaseIcons.PenRuler, requiresSelection: true },
  Rate: { title: "Set rating", Icon: qmBaseIcons.Rate, requiresSelection: true },
  ChangeStatus: { title: "Set status", Icon: qmBaseIcons.Check, requiresSelection: true },
  SetReadonly: { title: "Set readonly", Icon: qmBaseIcons.ReadOnly, requiresSelection: true },
  PasswordResetKeys: { title: "Reset password keys", Icon: qmBaseIcons.PasswordResetKeys, requiresSelection: true },
  CreateUser: {
    title: "Create users",
    Icon: qmBaseIcons.Plus,
    requiresSelection: true,
    maxSelectedEntries: GlobalQmBaseConstants.MaxUserBulkCreate,
  },
};
