import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IAbsencePolicyOverwriteableParameters, ITenantDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../../Constants/GlobalQmBaseConstants";
import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import { useCustomTableContext } from "../../../shared/components/tables/v8/customTableContext";
import AvatarSpanWithName from "../../../shared/components/tenants/avatarSpanWithName";
import { useTenantsHook } from "../../../shared/hooks/useTenantsHook";

type TableRowType = ITenantDTO & {
  absencePolicyOverwriteableParameters?: IAbsencePolicyOverwriteableParameters;
};

const columnHelper = createColumnHelper<TableRowType>();

export const absencePolicyColumn = columnHelper.accessor("absencePolicyOverwriteableParameters", {
  header: i18next.t("Customization"),
  cell: (props) => <CustomizationCell row={props.row.original} />,
  enableSorting: false,
});

function CustomizationCell({ row }: { row: TableRowType }) {
  const { onRowClickNew } = useCustomTableContext();
  const { t } = useTranslation();
  const { data } = useTenantsHook({
    pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    id: row.absencePolicyOverwriteableParameters?.approverIds,
  });
  if (!onRowClickNew) throw Error("Not implemented");
  return (
    <div className="d-flex">
      <div className="flex-grow-1">
        <div>
          <ul>
            {row.absencePolicyOverwriteableParameters?.availableDays && (
              <li>
                {t("Available days")}: {row.absencePolicyOverwriteableParameters.availableDays}
              </li>
            )}
            {row.absencePolicyOverwriteableParameters?.residualDays && (
              <li>
                {t("Residual days")}: {row.absencePolicyOverwriteableParameters.residualDays}
              </li>
            )}
            {row.absencePolicyOverwriteableParameters?.residualExpirationDate && (
              <li>
                {t("Residual expiration date")}:{" "}
                {DateTimeLuxonHelpers.convertUtcToDate(row.absencePolicyOverwriteableParameters.residualExpirationDate)}
              </li>
            )}
          </ul>
        </div>
        <div>
          {row.absencePolicyOverwriteableParameters?.approverIds &&
            row.absencePolicyOverwriteableParameters.approverIds.length > 0 && (
              <ul>
                {t("Approvers")}:
                {data ? (
                  <React.Fragment>
                    {data.data.map((x) => (
                      <li key={x.id}>
                        <AvatarSpanWithName tenant={x} />
                      </li>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {row.absencePolicyOverwriteableParameters.approverIds.map((x) => (
                      <li key={x}>#{x}</li>
                    ))}
                  </React.Fragment>
                )}
              </ul>
            )}
        </div>
      </div>
      <div>
        <Button variant="outline-secondary" size="sm" onClick={() => onRowClickNew(row)}>
          <qmBaseIcons.Pencil />
        </Button>
      </div>
    </div>
  );
}
