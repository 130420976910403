import { Outlet } from "react-router-dom";
import useSWR from "swr";

import { useAPIServices } from "../../../pbdServices/services/service-context";

import "./css/watermark.css";
import "./layoutPrint.css";

function LayoutPrint() {
  const { settingsService } = useAPIServices();
  const { data: logoUrl } = useSWR("/api/appSettings/printLogo", () =>
    settingsService.getValueByKey("Global_PrintLogo"),
  );

  return (
    <div className="h-100 w-100 print-background">
      {logoUrl && <img style={{ height: "75px" }} src={logoUrl} alt="logo" className="float-end pe-33" />}
      <Outlet />
    </div>
  );
}

export default LayoutPrint;
