import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { CellProps, Column } from "react-table";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { TenantConnectedToQualification } from "../../../Models/Tenants/TenantConnectedToQualification";
import { WithWarnings } from "../../../pbdServices/Models/Shared/with-warnings";
import { EmployeeRoutePaths } from "../../employees/employeeRoutePaths";
import DeadlineComponent from "../../shared/components/dateTime/deadlineComponent";
import { ProgressBar } from "../../shared/components/progress/progressBar";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getTagColumn } from "../../shared/components/tables/columns/tagsColumn";
import { getWarningsColumn } from "../../shared/components/tables/columns/warningsColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";
import { useFilteredColumnsMemo } from "../../shared/components/tables/tableHelperFunctions";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";

type TableType = WithWarnings<TenantConnectedToQualification>;

type IProps = BaseTableProps<TableType>;

export const TenantConnectedToQualificationTable: React.FC<IProps> = (props) => {
  const {
    columnsVisibleDefault = ["fullName", "department"],
    titleColumnBaseLink = EmployeeRoutePaths.EditPage,
    localStorageStateKey = "TenantDTO",
    columnsToExclude,
  } = props;

  const columns = React.useMemo<Column<TableType>[]>(
    () => [
      {
        id: "id",
        Header: "ID",
        accessor: "id", // accessor is the "key" in the data
        Cell: ({ row }) => (
          <>
            <Link to={titleColumnBaseLink.replace(":id", row.original.id.toString())}>#{row.original.id}</Link>
          </>
        ),
      },
      {
        id: "fullName",
        Header: <>{i18next.t("Name")}</>,
        accessor: (x) => x.fullName,
        Cell: ({ row }: CellProps<TableType>) => (
          <AvatarSpanWithName
            linkTo={titleColumnBaseLink.replace(":id", row.original.id.toString())}
            tenant={row.original}
          />
        ),
      },
      getTagColumn(),
      {
        id: "department",
        Header: <>{i18next.t("Department")}</>,
        accessor: (x) => x.primaryDepartmentPosition?.department.title,
      },
      {
        id: "position",
        Header: <>{i18next.t("Position")}</>,
        accessor: (x) => x.primaryDepartmentPosition?.title,
      },
      {
        id: "actualValue",
        Header: <>{i18next.t("Fitness")}</>,
        accessor: (x) => x.availableQualification?.actualValue,
        sortType: "basic",
        Cell: ({ row }: CellProps<TableType>) => (
          <div className="d-flex">
            <div className="flex-grow-1">
              <ProgressBar currentValue={row.original.availableQualification?.actualValue ?? 0} maxValue={1} />
            </div>
          </div>
        ),
      },
      {
        id: "lastInspection",
        Header: <>{i18next.t("Valid from")}</>,
        // sortType: "datetime",
        // sortType: datetimeOptional,
        accessor: (x) => x.availableQualification?.lastInspection?.toMillis(),
        Cell: ({ row }: CellProps<TableType>) => (
          <>
            {row.original.availableQualification?.lastInspection &&
              DateTimeLuxonHelpers.convertUtcToDate(row.original.availableQualification.lastInspection)}
          </>
        ),
      },
      {
        id: "nextInspection",
        Header: <>{i18next.t("Invalid from")}</>,
        // sortType: datetimeOptional,
        // sortType: datetimeOptional,
        accessor: (x) => x.availableQualification?.nextInspection?.toMillis(),
        Cell: ({ row }: CellProps<TableType>) => (
          <>
            {row.original.availableQualification?.nextInspection && (
              <DeadlineComponent
                deadline={row.original.availableQualification.nextInspection}
                warningTimeTimeSpan={row.original.availableQualification.warningTimeInterval?.timeSpanISO}
              />
            )}
          </>
        ),
      },
      getWarningsColumn(),
    ],
    [],
  );
  const filteredColumns = useFilteredColumnsMemo(columns, columnsToExclude);

  return (
    <React.Fragment>
      <ReactstrapTable<TableType>
        columns={filteredColumns}
        columnsVisibleDefault={columnsVisibleDefault}
        localStorageStateKey={localStorageStateKey}
        {...props}
      />
    </React.Fragment>
  );
};
