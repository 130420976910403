import { IAuditTrailDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";

export default class AuditTrailService extends BaseExportService<IAuditTrailDTO> {
  constructor() {
    super("Audit trail");
  }

  mapToExport(x: IAuditTrailDTO): ExportType {
    return {
      id: x.id,
      //TODO add more export fields, stringify technical details
      /*id: number;
    actorId: number;
    ipAddress?: string | undefined;
    userAgent?: string | undefined;
    timestamp: DateTime;
    area?: string | undefined;
    auditActionType: AuditActionType;
    details?: string | undefined;
    technicalDetails?: TechnicalDetails;
    correlationId?: string | undefined;
    tableName?: string | undefined;
    keyValues?: string | undefined;
    oldValues?: string | undefined;
    newValues?: string | undefined;
    service?: string | undefined;*/
    };
  }
}
