import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Badge,
  Button,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  UncontrolledButtonDropdown,
} from "reactstrap";

import { IAbsencePolicyDTO, PbdStatus } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import UpdatedDescriptionList from "../../../shared/components/dateTime/updatedDescriptionList";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import IdComponent from "../../../shared/components/id/idComponent";
import DescriptionList from "../../../shared/components/lists/descriptionList";
import { IDescriptionListItemProps } from "../../../shared/components/lists/descriptionListItem";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

interface IProps {
  policy: IAbsencePolicyDTO;
  handleDelete: () => void;
  handlePolicyStatus: () => void;
  toggleEditMode: () => void;
}

function DetailsCardAbsencePolicy(props: IProps) {
  const { t } = useTranslation();
  const { policy, handleDelete, handlePolicyStatus, toggleEditMode } = props;

  const descriptionList: IDescriptionListItemProps[] = [
    {
      label: t("Description"),
      value: policy.description,
      type: "html",
    },
  ];
  return (
    <React.Fragment>
      <ListGroup flush>
        <ListGroupItem>
          <UpdatedDescriptionList createdAt={policy.createdAt} lastUpdatedAt={policy.lastUpdatedAt} />
        </ListGroupItem>
      </ListGroup>
      <div className="float-end">
        <Button color="primary" onClick={toggleEditMode} disabled={!policy.capabilities?.canEdit} className="m-1">
          <qmBaseIcons.Pencil />
        </Button>
        <UncontrolledButtonDropdown>
          <DropdownToggle color="outline-secondary" disabled={!policy.capabilities?.canEdit}>
            <qmBaseIcons.EllipsisVertical />
          </DropdownToggle>

          <DropdownMenu end>
            <DropdownItem onClick={handlePolicyStatus}>
              <qmBaseIcons.Pin />
              {policy.status == PbdStatus.Active ? t("Set as Inactive") : t("Set as active")}
            </DropdownItem>
            <DropdownItem onClick={handleDelete}>
              <qmBaseIcons.Delete /> {t("Delete")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </div>
      <CardTitle tag="h4">{policy.title}</CardTitle>
      <dl>
        <dt>{t("Status")}</dt>
        <dd>
          {policy.status == PbdStatus.Active ? (
            <Badge color="success">{t("Active")}</Badge>
          ) : (
            <Badge color="danger">{t("Not active")}</Badge>
          )}
        </dd>

        <dt>{t("Valid from")}</dt>
        <dd>{DateTimeLuxonHelpers.convertUtcToDate(policy.validFrom)}</dd>

        <dt>{t("Valid to")}</dt>
        <dd>{DateTimeLuxonHelpers.convertUtcToDate(policy.validTo)}</dd>

        <dt>{t("Absence type")}</dt>
        <dd>{policy.absenceType?.title}</dd>

        <dt>{t("Available days")}</dt>
        <dd>{policy.availableDays}</dd>
        {policy.absencePolicyParentId && (
          <>
            <dt>{t("Previous policy")}</dt>
            <dd>
              <Link
                to={SettingsRoutePaths.EditPageAbsencePolicy.replace(":id", policy.absencePolicyParentId.toString())}
              >
                {t("Policy")} <IdComponent id={policy.absencePolicyParentId} />
              </Link>
            </dd>
          </>
        )}
        <dt>{t("Residual days")}</dt>
        <dd>{policy.residualDays}</dd>
        <dt>{t("Residual expiration period")}</dt>
        <dd>
          {policy.residualExpirationDate != null
            ? DateTimeLuxonHelpers.convertUtcToDate(policy.residualExpirationDate)
            : ""}
        </dd>
      </dl>
      <DescriptionList list={descriptionList} />
      <hr />
      <Link
        className="btn btn-primary"
        to={`${SettingsRoutePaths.CreatePageAbsencePolicy}?policyToCopyId=${policy.id.toString()}`}
      >
        <qmBaseIcons.Copy />
        {t("Copy policy")}
      </Link>
    </React.Fragment>
  );
}

export default DetailsCardAbsencePolicy;
