import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  ConnectionsToEightDReportDTO,
  EightDReportStep,
  ICustomField,
  IDefectDTO,
  IEightDReportEditDTO,
  IToDoDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../pbdServices/services/service-context";

import { EightDReportStepDataValue } from "../../../Models/EightDReports/EightDReportStep";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import { DefectTable } from "../../defects/components/defectTable";
import { PrintOptions } from "../../prints/components/printoutIncludeOptions";
import { PrintRoutePaths } from "../../prints/printRoutePaths";
import CustomFieldsDescriptionList from "../../shared/components/customFields/customFieldsDescriptionList";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import IdComponent from "../../shared/components/id/idComponent";
import StatusBadge from "../../shared/components/status/statusBadge";
import FormattedUserInput from "../../shared/components/text/formattedUserInput";
import { useToggle } from "../../shared/hooks/useToggle";
import CheckListItem from "../../todos/components/checkListItem";
import { TodoTable } from "../../todos/components/todoTable";
import { isDimensionReady } from "../helpers/helperFunctions";
import EightDReportDescriptionForm, { DescriptionFormValues } from "./eightDReportDescriptionForm";
import EightDReportDescriptionRenderer from "./eightDReportDescriptionRenderer";
import FilloutSectionPlaceholder from "./filloutSectionPlaceholder";

function getFilteredDefects(connectedDefects: IDefectDTO[], actions: number[]) {
  return connectedDefects.filter((x) => !actions.includes(x.id));
}

function getFilteredToDos(connectedToDos: IToDoDTO[], actions: number[]) {
  return connectedToDos.filter((x) => !actions.includes(x.id));
}

interface IProps {
  itemToUpdate: IEightDReportEditDTO;
  refreshParent: () => void;
  step: EightDReportStepDataValue;
  connectedTodos?: IToDoDTO[];
  connectedDefects?: IDefectDTO[];
  printOptions?: PrintOptions;
  customFieldsTodo: ICustomField[];
}
function ActionCard(props: IProps) {
  const { itemToUpdate, refreshParent, step, connectedTodos, connectedDefects, printOptions, customFieldsTodo } = props;
  const { eightDReportsApi } = useAPIs();
  const { eightDReportService } = useAPIServices();
  const { t } = useTranslation();
  const [editMode, toggleEditMode] = useToggle();
  const [selected, setSelected] = React.useState<number[]>([]);

  const location = useLocation();
  const isPrintView = location.pathname == PrintRoutePaths.EightDReports.replace(":id", itemToUpdate.id.toString());

  const actions = eightDReportService.returnReportActionByActionType(itemToUpdate, step.step);
  const description = eightDReportService.returnDescriptionByActionType(itemToUpdate, step.step);
  const ready = isDimensionReady(itemToUpdate, step.step);

  const handleSetAction = (values: DescriptionFormValues) => {
    const actions = eightDReportService.returnReportActionByActionType(itemToUpdate, step.step);
    return eightDReportsApi.setReportStep(
      itemToUpdate.id,
      new ConnectionsToEightDReportDTO({
        step: step.step,
        description: values.description,
        connectionIds: selected.concat(...actions),
        showClaim: false,
        teamLeaderId: 0,
      }),
    );
  };

  const disconnectAction = async (idToRemove: number) => {
    const connectedItems = eightDReportService.returnReportActionByActionType(itemToUpdate, step.step);
    const newConnections = connectedItems.filter((x) => x != idToRemove);
    const resp = await wrapApiCallWithToast(() =>
      eightDReportsApi.setReportStep(
        itemToUpdate.id,
        new ConnectionsToEightDReportDTO({
          step: step.step,
          description: description,
          connectionIds: newConnections,
          showClaim: false,
          teamLeaderId: 0,
        }),
      ),
    );
    if (resp.isOk) {
      refreshParent();
    }
  };

  const changeSelected = (ids: number[]) => {
    setSelected(ids);
  };

  const changeSelectedDefects = (defects: IDefectDTO[]) => {
    const ids = defects.map((x) => x.id);
    setSelected(ids);
  };

  const todosConnected = React.useMemo(() => {
    if (itemToUpdate.actions) {
      const items: IToDoDTO[] = [];
      if (step.step == EightDReportStep.ImmediateActions) {
        items.push(...itemToUpdate.actions.filter((x) => x.isImmediateAction).filterMap((x) => x.toDo));
      } else if (step.step == EightDReportStep.CorrectiveActions) {
        items.push(...itemToUpdate.actions.filter((x) => x.isCorrectiveAction).filterMap((x) => x.toDo));
      } else if (step.step == EightDReportStep.VerificationAndResult) {
        items.push(...itemToUpdate.actions.filter((x) => x.isVerificationAndResult).filterMap((x) => x.toDo));
      } else if (step.step == EightDReportStep.PreventiveActions) {
        items.push(...itemToUpdate.actions.filter((x) => x.isPreventiveAction).filterMap((x) => x.toDo));
      }
      return items;
    } else {
      return undefined;
    }
  }, [itemToUpdate.actions, step.step]);

  const filteredDefects = React.useMemo(() => {
    return getFilteredDefects(connectedDefects ?? [], actions);
  }, [actions, connectedDefects]);

  const filteredToDos = React.useMemo(() => {
    return getFilteredToDos(connectedTodos ?? [], actions);
  }, [actions, connectedTodos]);

  if (printOptions?.showOnlyCompletedSections && !isDimensionReady(itemToUpdate, step.step)) return null;

  return (
    <Card className="mb-3">
      <Card.Header>
        <div className="d-flex justify-content-between">
          <Card.Title as="h5">
            D{step.dimension} - {t(step.title)}
          </Card.Title>
          <div>
            {!isPrintView && (
              <Button variant="primary" onClick={toggleEditMode}>
                <qmBaseIcons.Pencil />
              </Button>
            )}
          </div>
        </div>
      </Card.Header>
      {editMode ? (
        <Card.Body>
          {step.step != EightDReportStep.RootCauses && connectedTodos && (
            <>
              <h5>{t("Connect tasks to this report")}</h5>
              <TodoTable
                tableRows={filteredToDos}
                setSelected={(ids) => changeSelected(ids.map((x) => x.id))}
                isAlwaysSelectable
              />
            </>
          )}
          {step.step == EightDReportStep.RootCauses && connectedDefects && (
            <>
              <h5>{t("Connect defects to this report")}</h5>
              <DefectTable
                tableRows={filteredDefects}
                setSelected={(x) => changeSelectedDefects(x)}
                isAlwaysSelectable
              />
            </>
          )}

          <EightDReportDescriptionForm
            onSubmit={handleSetAction}
            valueToUpdate={description}
            heading={t("Description")}
            toggleEditMode={toggleEditMode}
            onSuccess={() => {
              refreshParent();
              toggleEditMode();
            }}
            step={step}
          />
        </Card.Body>
      ) : (
        <>
          {!ready && (
            <Card.Body>
              {!isPrintView && <FilloutSectionPlaceholder show={!ready} toggleEditMode={toggleEditMode} />}
            </Card.Body>
          )}
          {step.step == EightDReportStep.RootCauses && (
            <ListGroup variant="flush">
              {itemToUpdate.rootCauses?.map((x) => (
                <ListGroup.Item key={x.id}>
                  <div className="d-flex w-100 justify-content-between">
                    <h5>{x.title}</h5>
                    <div>
                      <div className="d-flex w-100 justify-content-between">
                        <div>
                          <StatusBadge status={x.status} />
                        </div>
                        {!isPrintView && (
                          <Button
                            variant="outline-danger"
                            className="ms-1"
                            size="sm"
                            title={t("Disconnect from report")}
                            onClick={() => disconnectAction(x.id)}
                          >
                            <qmBaseIcons.Delete />
                          </Button>
                        )}
                      </div>
                      <div>
                        {t("Responsible")}: {x.responsible?.fullName}
                      </div>
                    </div>
                  </div>
                  <FormattedUserInput content={x.description} />
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          <ListGroup variant="flush">
            {todosConnected?.map((x) => (
              <ListGroup.Item key={x.id}>
                <div className="d-flex w-100 justify-content-between">
                  <h5>
                    {x.title} <IdComponent id={x.id} />
                  </h5>
                  <div>
                    <div>
                      <div className="d-flex w-100 justify-content-between">
                        <div>
                          <StatusBadge status={x.status} />
                        </div>
                        {!isPrintView && (
                          <Button
                            color="outline-danger"
                            className="ms-1"
                            size="sm"
                            title={t("Disconnect from report")}
                            onClick={() => disconnectAction(x.id)}
                          >
                            <qmBaseIcons.Delete />
                          </Button>
                        )}
                      </div>
                    </div>
                    <div>
                      {t("Responsible")}: {x.responsible?.fullName}
                    </div>
                  </div>
                </div>
                <FormattedUserInput content={x.description} />

                {x.checkListItems && x.checkListItems.length > 0 && (
                  <ListGroup>
                    {x.checkListItems.map((checkList) => (
                      <CheckListItem
                        key={checkList.id}
                        item={x}
                        checkListItem={checkList}
                        refresh={() => void 0}
                        isReadOnly
                      />
                    ))}
                  </ListGroup>
                )}
                <CustomFieldsDescriptionList customFieldValues={x.customFields} customFields={customFieldsTodo} />
              </ListGroup.Item>
            ))}
          </ListGroup>

          {description && (
            <Card.Body>
              <EightDReportDescriptionRenderer
                includeImagesInPrintView={printOptions?.includeImages}
                description={description}
              />
            </Card.Body>
          )}
        </>
      )}
    </Card>
  );
}

export default ActionCard;
