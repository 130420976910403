import React, { createContext, useContext } from "react";
import useSWRImmutable from "swr/immutable";

import { MeDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { AuthService } from "../../../pbdServices/services/Authz/authService";
import { MeAsUser } from "../../../pbdServices/services/UserSettings/models/me-as-user";
import UserService from "../../../pbdServices/services/Users/userService";
import AppWrapperFallbackLoading from "../components/loading/appWrapperFallbackLoading";

interface ContextInterface {
  meDTO: MeDTO;
  mutate: () => void;
  user: AuthService;
  meAsUser: MeAsUser;
}

const AuthenticationContext = createContext<ContextInterface | null>(null);

const useAuthenticationContext = () => {
  const object = useContext(AuthenticationContext);

  if (!object) {
    throw new Error("useAuthorizationContext must be used within a Provider");
  }
  return object;
};

interface Props {
  children: React.ReactNode;
}

/**
 * This context makes sure the authorization state is available. That's it no redirect or any other logic
 */
const AuthenticationContextProvider = ({ children }: Props) => {
  const { userApi } = useAPIs();
  const { data, mutate, isLoading } = useSWRImmutable("/api/me/user", () => userApi.getMe());

  if (!data) return <AppWrapperFallbackLoading title={"Getting authentication state..."} />;

  const meAsUser = UserService.mapToMeAsUser(data);
  const authService = new AuthService(meAsUser);

  return (
    <AuthenticationContext.Provider value={{ meDTO: data, mutate: () => mutate(), meAsUser, user: authService }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationContextProvider, useAuthenticationContext };
