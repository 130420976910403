import { useTranslation } from "react-i18next";
import { MultiValue, SingleValue } from "react-select";
import AsyncSelect from "react-select/async";
import useSWR from "swr";

import { IQualificationDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { getLabel, getValue } from "./reactSelectHelpers";

interface IProps {
  onChange: (id?: number | number[]) => void;
  selected?: IQualificationDTO[];
  isMulti?: boolean;
  isClearable?: boolean;
}

function QualificationSelect(props: IProps) {
  const { onChange, selected } = props;
  const { t } = useTranslation();
  const { qualificationsApi } = useAPIs();

  const { data } = useSWR(["/api/qualifications"], () => qualificationsApi.getAll());
  const loadOptionsPromise = async (inputValue: string) => {
    const resp = await qualificationsApi.getAllQuery({ q: inputValue });
    return resp;
  };

  function handleChange(value: SingleValue<IQualificationDTO> | MultiValue<IQualificationDTO>) {
    if (!value) {
      onChange(undefined);
    } else {
      if (Array.isArray(value)) {
        onChange(value.map((x) => x.id));
      } else {
        onChange((value as IQualificationDTO).id);
      }
    }
  }

  return (
    <AsyncSelect
      key={JSON.stringify(selected?.map((x) => x.id))}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      defaultValue={selected}
      cacheOptions
      loadOptions={loadOptionsPromise}
      defaultOptions={data}
      onChange={handleChange}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      placeholder={t("Please select") + "..."}
      isClearable={props.isClearable}
      isMulti={props.isMulti}
    />
  );
}

export default QualificationSelect;
