import { DateTime } from "luxon";
import React from "react";
import { Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  createdAt: DateTime;
  lastUpdatedAt?: DateTime;
  doneAt?: DateTime;
  deadline?: DateTime;
  children?: React.ReactNode;
}

function UpdatedDescriptionList(props: IProps) {
  const { createdAt, lastUpdatedAt, doneAt, deadline } = props;
  const { t } = useTranslation();
  return (
    <dl className="mb-0">
      <dd>
        {deadline && !doneAt && (
          <Badge title={t("Deadline")} color={DateTime.now() < deadline ? "success" : "danger"} className="me-1">
            <qmBaseIcons.FlagCheckered /> {DateTimeLuxonHelpers.convertUtcToTimeFromUnknown(deadline)}
          </Badge>
        )}
        {doneAt && (
          <Badge
            title={`${t("Done")} ${DateTimeLuxonHelpers.convertUtcToDate(doneAt)}`}
            color="success"
            className="me-1"
          >
            <qmBaseIcons.CheckCircle /> {DateTimeLuxonHelpers.convertUtcToDate(doneAt)}
          </Badge>
        )}
        {lastUpdatedAt && (
          <Badge
            title={`${t("Updated")} ${DateTimeLuxonHelpers.convertUtcToDate(lastUpdatedAt)}`}
            bg="primary"
            className="me-1"
          >
            <qmBaseIcons.PencilAlt /> {DateTimeLuxonHelpers.convertUtcToDate(lastUpdatedAt)}
          </Badge>
        )}
        <Badge title={`${t("Created")} ${DateTimeLuxonHelpers.convertUtcToDateTime(createdAt)}`} className="me-1">
          <qmBaseIcons.Plus /> {DateTimeLuxonHelpers.convertUtcToDate(createdAt)}
        </Badge>
        {props.children}
      </dd>
    </dl>
  );
}

export default UpdatedDescriptionList;
