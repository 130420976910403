import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { CustomField, HttpVerbs, PbdModule } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../../pbdServices/services/service-context";

import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import { useTypedParams } from "../../../shared/hooks/useTypedParams";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function mapDataToSettingsDTO(module: string, data?: CustomField[]) {
  if (!data) return undefined;
  const tableData: SettingsIndexDTO[] = [];
  data.forEach((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.name,
      type: d.type,
      createdAt: d.createdAt,
      links: [
        {
          rel: "self",
          href: SettingsRoutePaths.EditPageCustomFields.replace(":module", module).replace(":id", d.id),
          method: HttpVerbs.GET,
        },
      ],
    });
    tableData.push(item);
  });
  return tableData;
}

const IndexPageCustomFields = () => {
  const navigate = useNavigate();
  const { module } = useTypedParams<string, PbdModule>(["module"]);
  const { customFieldService } = useAPIServices();
  const { data, isValidating } = useSWR(
    `/api/customFields/${module}`,
    () => customFieldService.getAllCustomFieldsByModule(module),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleCreateClick = () => navigate(SettingsRoutePaths.CreatePageCustomFields.replace(":module", module));

  const handleClick = (command: TableClickCommand) => {
    if (!data) throw new Error("Missing data");
    const { action } = command;
    switch (action) {
      case "Export":
        customFieldService.exportToCsv(data);
        break;
      default:
        throw new Error("Not implemented");
    }
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Custom Fields"
      pathToCreate={SettingsRoutePaths.CreatePageCustomFields.replace(":module", module)}
      loading={isValidating}
    >
      <BaseSettingsTable
        tableRows={mapDataToSettingsDTO(module, data)}
        onCreateClick={handleCreateClick}
        availableTableActions={["Export"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
};

export default IndexPageCustomFields;
