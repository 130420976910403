import React from "react";
import { Breadcrumb, Button, Card, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { BooleanParam, useQueryParam } from "use-query-params";

import { IRoutingRuleItem, RoutingRuleItem } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import LinkHelpers from "../../../Helpers/linkHelpers";
import { useTryDelete } from "../../../pbdServices/services/Api/api-hooks";
import { RoutingRuleService } from "../../../pbdServices/services/routingRules/routingRuleService";
import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import { StartpageRoutePaths } from "../../startpage/startpageRoutePaths";
import RoutingRuleForm from "../components/routingRuleForm";

function DetailsPageRoutingRules() {
  const { id } = useTypedParams(["id"]);
  const { moduleSettingsApi } = useAPIs();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [routingRule, setRoutingRule] = React.useState<IRoutingRuleItem>();
  const [noRedirect, setNoRedirect] = useQueryParam("noRedirect", BooleanParam);
  const [success, setSuccess] = React.useState(false);
  const { data, mutate } = useSWR(`/api/routingRules/${id}`, () => moduleSettingsApi.getRoutingRuleItemById(id));
  const { data: routingRules } = useSWR(`/api/routingRules`, () => moduleSettingsApi.getRoutingRuleItems());

  React.useEffect(() => {
    if (data) {
      data.href = RoutingRuleService.createRedirect(data);
      setRoutingRule(data);
      setTimeout(() => {
        if (data.href && !noRedirect) {
          if (LinkHelpers.isExternal(data.href)) {
            window.location.href = data.href;
          } else {
            navigate(data.href);
          }
        }
      }, 500);
    }
  }, [data, noRedirect]);

  const handleSubmit = (dto: IRoutingRuleItem) => moduleSettingsApi.createRoutingRule(new RoutingRuleItem(dto));

  const handleSuccess = () => {
    setSuccess(true);
    mutate();
  };

  const tryDelete = useTryDelete<IRoutingRuleItem, string>(
    async (dto) => {
      await moduleSettingsApi.deleteRoutingRuleItemById(dto.id);
    },
    [moduleSettingsApi],
    () => navigate(SettingsRoutePaths.RoutingRulesIndexPage),
  );

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: SettingsRoutePaths.RoutingRulesIndexPage }}>
          {t("Routing rules")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active>#{id}</Breadcrumb.Item>
      </Breadcrumb>
      <hr />
      {routingRule && (
        <Card className="mb-3">
          <Card.Body className="text-center">
            {!noRedirect && (
              <>
                <h1>🕐 {t("Waiting for redirect")}</h1>
                <p>{t("If you are waiting for longer than 5 seconds, click on the link below.")}</p>
              </>
            )}
            {t("App")}: {t(routingRule.app.toString())}
            <FormGroup className="mb-3">
              {routingRule.href && (
                <Button
                  variant="link"
                  as={LinkHelpers.isExternal(routingRule.href) ? undefined : (Link as any)}
                  to={routingRule.href}
                >
                  {routingRule.href}
                </Button>
              )}
            </FormGroup>
            <FormGroup>
              <Button variant="danger" onClick={() => tryDelete(routingRule)}>
                {t("Delete")}
              </Button>
            </FormGroup>
          </Card.Body>
        </Card>
      )}
      {!routingRule && (
        <Card className="mb-3">
          <Card.Header>
            <Card.Title as="h5">{t("Configure routing rule")}</Card.Title>
          </Card.Header>
          <Card.Body>
            <p>{t("Please configure a routing rule for the given ID")}</p>
            {success && <GenericAlert type="success"></GenericAlert>}
            {routingRules && (
              <RoutingRuleForm
                onSubmit={handleSubmit}
                itemToUpdate={{ id: id }}
                onCancel={() => navigate(StartpageRoutePaths.RoutingRulesIndexPage)}
                onSuccess={handleSuccess}
                routingRules={routingRules}
              />
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
}
export default DetailsPageRoutingRules;
