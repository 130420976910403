import i18next from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";

import { IRoutingRuleItem } from "../../../generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";

type IProps = BaseTableProps<IRoutingRuleItem>;

export function RoutingRuleTable(props: IProps) {
  const columns = React.useMemo<Column<IRoutingRuleItem>[]>(
    () => [
      {
        id: "id",
        Header: <>{i18next.t("ID")}</>,
        accessor: "id",
        Cell: ({ row }) => (
          <>
            <Link to={SettingsRoutePaths.RoutingRulesEditPage.replace(":id", row.original.id) + "?noRedirect=1"}>
              {row.original.id}
            </Link>
          </>
        ),
      },
      {
        id: "app",
        Header: <>{i18next.t("App")}</>,
        accessor: "app",
        Cell: ({ row }) => <>{row.original.app}</>,
      },
      {
        id: "appId",
        Header: <>{i18next.t("App ID")}</>,
        accessor: "appId",
        Cell: ({ row }) => <>{row.original.appId}</>,
      },
      {
        id: "href",
        Header: <>{i18next.t("Href")}</>,
        accessor: "href",
        Cell: ({ row }) => (
          <>
            <a href={row.original.href}>{row.original.href}</a>
          </>
        ),
      },
    ],
    [],
  );

  return <ReactstrapTable<IRoutingRuleItem> columns={columns} localStorageStateKey={"RoutingRule"} {...props} />;
}
