import React from "react";
import { Popover, PopoverProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  IAbsenceTypeDTO,
  ICategoryDTO,
  ICategoryMinDTO,
  ITimeIntervalDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../../settings/settingsRoutePaths";
import RecurringComponent from "../recurring/recurringComponent";
import HoveDynamicContent from "./hoverDynamicContent";

type CategoryType = (ICategoryDTO | ICategoryMinDTO | IAbsenceTypeDTO) & {
  isRecurring?: boolean;
  /**If the event is recurring the timeInterval must be set */
  monitoringInterval?: ITimeIntervalDTO;
  /**This requires a recurring event */
  useWarningTime?: boolean;
  warningTimeInterval?: ITimeIntervalDTO;
};

interface IProps extends PopoverProps {
  category: CategoryType;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const CategoryHoverCard = React.forwardRef<HTMLDivElement, IProps>((props: IProps, ref) => {
  const { category } = props;
  const { t } = useTranslation();

  return (
    <Popover ref={ref} {...props}>
      <Popover.Header as="h3">
        <div className="d-flex">
          <div className="me-3">
            <span>
              {category.color && <i className="fa fa-circle me-1" style={{ color: category.color }} />}
              {category.title}
            </span>
          </div>
        </div>
      </Popover.Header>
      <Popover.Body>
        {category.description && (
          <dl>
            <dt>{t("Description")}</dt>
            <dd>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    category.description.length > 100
                      ? `${category.description.substring(0, 100)}...`
                      : category.description.toString(),
                }}
              />
            </dd>
          </dl>
        )}
        {category.isRecurring != undefined && (
          <RecurringComponent item={{ ...category, isRecurring: category.isRecurring }} />
        )}
        <HoveDynamicContent id={category.id} />
        <Link to={SettingsRoutePaths.getCategoryPath(category.entityKey, category.id.toString())}>Details</Link>
      </Popover.Body>
    </Popover>
  );
});
export default CategoryHoverCard;
