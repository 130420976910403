import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../../../pbdServices/services/service-context";

/**
 * This hook can be used to determine whether features should be available on the front end or not.
 */
export function useFeatureFlagHook() {
  const { featureFlagApi } = useAPIs();
  const data = useSWRImmutable("/api/featureFlag", () => featureFlagApi.getAll());

  return data;
}
