import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ConnectAuditRequirementDTO,
  ConnectAuditTypeDTO,
  IAuditRequirementDTO,
  ICategoryCreateDTO,
  ICategoryDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import AuditRequirementsConnectedCard from "../../../shared/components/connectionElements/auditRequirements/auditRequirementsConnectedCard";
import AuditTypesConnectedCard from "../../../shared/components/connectionElements/auditTypes/auditTypesConnectedCard";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function EditPageAuditRequirements() {
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auditRequirementsApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IAuditRequirementDTO>();
  const [childRequirements, setChildRequirements] = React.useState<IAuditRequirementDTO[]>([]);
  const [connectedAuditTypes, setConnectedAuditTypes] = React.useState<ICategoryDTO[]>([]);
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingAuditRequirements } = useSWR(`auditRequirements`, () => auditRequirementsApi.getAll());

  React.useEffect(() => {
    async function getData() {
      const category = await auditRequirementsApi.getById(Number(id));
      setItemToUpdate(category);

      const children = await auditRequirementsApi.getChildAuditRequirements(Number(id));
      setChildRequirements(children);

      const auditTypes = await auditRequirementsApi.getConnectedAuditTypes(Number(id));
      setConnectedAuditTypes(auditTypes);
      setLoading(false);
    }
    if (id != null) {
      getData();
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id) {
      return auditRequirementsApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return auditRequirementsApi.create(new CategoryCreateDTO(itemToCreate));
    }
  }

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Item missing");
    if (itemToUpdate.isDeleted) {
      await auditRequirementsApi.restore(itemToUpdate.id);
    } else {
      await auditRequirementsApi.delete(itemToUpdate.id);
    }
    handleRefresh();
  }

  function handleConnectChildren(_id: number, auditRequirementIds: number[]) {
    return auditRequirementsApi.connectChildAuditRequirements(
      _id,
      new ConnectAuditRequirementDTO({ auditRequirementIds }),
    );
  }

  function handleDisconnectChild(_id: number, auditRequirementId: number) {
    return auditRequirementsApi.disconnectChildAuditRequirement(auditRequirementId, _id);
  }

  function handleConnectAuditType(_id: number, auditTypeIds: number[]) {
    return auditRequirementsApi.connectAuditTypes(_id, new ConnectAuditTypeDTO({ auditTypeIds }));
  }

  function handleDisconnectAuditType(_id: number, auditTypeId: number) {
    return auditRequirementsApi.disconnectAuditType(_id, auditTypeId);
  }

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageAuditRequirements.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  return (
    <>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          onDelete={toggleDelete}
          onRestore={(tId) => auditRequirementsApi.restore(tId)}
          existingItems={existingAuditRequirements}
          formType={"AuditRequirement"}
        >
          {itemToUpdate?.parentAuditRequirement && (
            <Alert color="info">
              <dl>
                <dt>{t("Part of the requirement")}</dt>
                <dd>
                  <Link
                    to={SettingsRoutePaths.EditPageAuditRequirements.replace(
                      ":id",
                      itemToUpdate.parentAuditRequirement.id.toString(),
                    )}
                  >
                    {itemToUpdate.parentAuditRequirement.title}
                  </Link>
                </dd>
              </dl>
            </Alert>
          )}
        </BaseSettingsForm>
      )}
      {itemToUpdate && (
        <>
          <AuditTypesConnectedCard
            baseDTO={itemToUpdate}
            data={connectedAuditTypes}
            refreshParent={handleRefresh}
            onConnectSubmit={(ids) => handleConnectAuditType(itemToUpdate.id, ids)}
            onDisconnectSubmit={(idToDisconnect) => handleDisconnectAuditType(itemToUpdate.id, idToDisconnect)}
            cardTitle={t("Audit categories")}
          >
            {t("These are the audit categories that fullfil the requirement")}
            <br />
          </AuditTypesConnectedCard>
          <AuditRequirementsConnectedCard
            cardTitle={t("Sub audit requirements")}
            baseDTO={itemToUpdate}
            data={childRequirements}
            refreshParent={handleRefresh}
            onConnectSubmit={(ids) => handleConnectChildren(itemToUpdate.id, ids)}
            onDisconnectSubmit={(idToDisconnect) => handleDisconnectChild(itemToUpdate.id, idToDisconnect)}
          >
            {t("Every requirement can only be connected to one parent audit requirement")}
          </AuditRequirementsConnectedCard>
        </>
      )}
    </>
  );
}

export default EditPageAuditRequirements;
