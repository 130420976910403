import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { CellProps, Column } from "react-table";

import { ICustomField, ICustomFieldDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import CustomFieldValueRenderer from "../../customFields/customFieldValueRenderer";

interface IProps {
  customFields?: ICustomFieldDTO[];
}

export function getCustomFieldsColumns<T extends IProps>(customFields: ICustomField[] | undefined) {
  const cfColumns: Column<T>[] = [];
  if (!customFields) return cfColumns;

  customFields.forEach((x, i) => {
    const column: Column<T> = {
      id: x.id,
      Header: <>{x.name}</>,
      accessor: (a) => a.customFields?.find((q) => q.id == x.id)?.value ?? "",
      // disableSortBy: true,
      Cell: ({ row }: CellProps<T>) => (
        <CustomFieldValueRenderer
          customField={x}
          customFieldContent={row.original.customFields?.find((y) => y.id == x.id) ?? { id: x.id }}
          renderMissingValueAsNull
        />
      ),
    };
    cfColumns.push(column);
  });

  return cfColumns;
}

export function getCustomFieldsColumns2<T extends IProps>(customFields: ICustomField[] | undefined) {
  const columnHelper = createColumnHelper<T>();
  const cfColumns: ColumnDef<T, any>[] = [];

  if (!customFields) return cfColumns;

  customFields.forEach((x, i) => {
    const col = columnHelper.accessor(
      //@ts-expect-error TODO: 20240702 PP Fix with better typings
      x.id,
      {
        id: x.id,
        header: () => x.name,
        // accessor: (a) => a.customFields?.find((q) => q.id == x.id)?.value ?? "",
        // disableSortBy: true,
        cell: (row) => (
          <CustomFieldValueRenderer
            customField={x}
            customFieldContent={row.row.original.customFields?.find((y) => y.id == x.id) ?? { id: x.id }}
            renderMissingValueAsNull
          />
        ),
        enableSorting: false,
      },
    );
    cfColumns.push(col);
  });

  return cfColumns;
}
