import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { useAPIs } from "../../../pbdServices/services/service-context";

import { useTypedParams } from "../../shared/hooks/useTypedParams";
import { ArticleRoutePaths } from "../articleRoutePaths";

interface IProps {}

function DetailsPageArticleRedirect(props: IProps) {
  const { t } = useTranslation();
  const { id } = useTypedParams(["id"]);
  const { articlesApi } = useAPIs();
  const navigate = useNavigate();
  const { data } = useSWR(["api/articles", id], () => articlesApi.getById(id));

  if (data) {
    navigate(ArticleRoutePaths.DetailsPageFn(data));
  }

  return (
    <Card className="mb-3">
      <Card.Body>
        <h1>{t("Under construction")}</h1>
      </Card.Body>
    </Card>
  );
}
export default DetailsPageArticleRedirect;
