import { IRoutingRuleItem, PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { MaintenanceManagementRoutePaths } from "../../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import LinkHelpers from "../../../Helpers/linkHelpers";
import ModuleSettingsService from "../ModuleSettings/moduleSettingsService";

export class RoutingRuleService {
  constructor(moduleSettingsService: ModuleSettingsService) {
    this._moduleSettingsService = moduleSettingsService;
  }
  private _moduleSettingsService: ModuleSettingsService;

  async getByPath(path: string) {
    const items = await this._moduleSettingsService.moduleSettingsApi.getRoutingRuleItems();
    for (const element of items) {
      element.href = RoutingRuleService.createRedirect(element);
    }
    return items.find((x) => x.href == path);
  }

  static createRedirect(dto: IRoutingRuleItem) {
    if (dto.app == PbdModule.MaintenanceManagement && dto.appId) {
      return MaintenanceManagementRoutePaths.EditPageInventoryItemFn(dto.appId);
    } else if (dto.href) {
      return LinkHelpers.makeInternalUrlRelative(dto.href);
    } else {
      return undefined;
    }
  }
}

// export class RoutingRuleItem {
//   /**This is either the id from azure function redirect or can be a standalone redirect */
//   id: string;
//   app?: PbdModule;
//   appId?: string;
//   /**Will be calculated on the client */
//   href?: string;
// }
