import { useTranslation } from "react-i18next";

import { ICompanyFunctionDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import FormattedUserInput from "../../shared/components/text/formattedUserInput";

interface IProps {
  companyFunction: ICompanyFunctionDTO;
}

function CompanyFunctionQualificationsList(props: IProps) {
  const { companyFunction } = props;
  const { t } = useTranslation();
  return (
    <>
      {companyFunction.requiredQualifications && companyFunction.requiredQualifications.length > 0 && (
        <>
          <h5>{t("Required qualifications")}</h5>
          {companyFunction.requiredQualifications.map((q) => (
            <div key={q.id}>
              <h6>{q.title}</h6>
              {q.description && <FormattedUserInput content={q.description} />}
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default CompanyFunctionQualificationsList;
