import React from "react";

import { ICustomField, ICustomFieldDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import CustomFieldValueRenderer from "./customFieldValueRenderer";

interface IProps {
  customFieldValues: ICustomFieldDTO[] | undefined;
  customFields: ICustomField[];
}

/**
 * This returns a description list for custom fields
 * <dl>
 *  <dd>{cf.title}</dd>
 *  <dt>{cf.value}</dt>
 * </dl>
 */
function CustomFieldsDescriptionList(props: IProps) {
  const { customFieldValues, customFields } = props;
  if (!customFieldValues) return null;
  if (customFieldValues.length == 0) return null;

  return (
    <dl>
      {customFieldValues.map((c) => (
        <React.Fragment key={c.id}>
          <dt>{customFields.find((t) => t.id == c.id)?.name ?? c.id}</dt>
          <dd>
            <CustomFieldValueRenderer customFieldContent={c} customField={customFields.find((t) => t.id == c.id)} />
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
}
export default CustomFieldsDescriptionList;
