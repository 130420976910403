import { Dictionary, countBy, map } from "lodash";

import { HttpVerbs, IAddressDTO, IAddressRoomDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { AddressGroupBy } from "../../../ClientApp/qualityMonitor/components/addressChartCard";
import { AddressFilterTypes } from "../../../ClientApp/qualityMonitor/components/addressesChartRow";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { SettingsIndexDTO } from "../../../Models/Settings/SettingsIndexDTO";
import { IAddressVm } from "./address-vm";

export interface IGroupedResult {
  key: string;
  count: number;
  percentage: number;
}

export default class AddressService {
  static mapToVm(items: { address?: IAddressDTO; addressRoom?: IAddressRoomDTO }[]): IAddressVm[] {
    const data: IAddressVm[] = [];
    for (const element of items) {
      if (element.address) {
        data.push({
          ...element.address,
          roomId: element.addressRoom?.id,
          roomTitle: element.addressRoom?.title,
        });
      }
    }
    return data;
  }

  static mapDataToSettingsDTO(data: IAddressDTO[]) {
    const tableData: SettingsIndexDTO[] = [];
    data.forEach((d) => {
      const item = new SettingsIndexDTO({
        id: d.id,
        title: d.fullAddress == "" ? `#${d.id}` : d.fullAddress,
        links: [
          {
            rel: "self",
            href: SettingsRoutePaths.EditPagePlaces.replace(":id", d.id.toString()),
            method: HttpVerbs.GET,
          },
        ],
      });
      tableData.push(item);
    });
    return tableData;
  }

  static getAdministrativeActions(addresses?: IAddressDTO[]): {
    available: boolean;
    actions: { cleanUpPossible: boolean };
  } {
    const cleanUpPossible = (addresses?.filter((x) => x.count == 0).length ?? 0) > 0;
    return { available: cleanUpPossible, actions: { cleanUpPossible } };
  }

  static groupByChartType(addresses: IAddressVm[], groupBy: AddressGroupBy, filter?: AddressFilterTypes) {
    let grouped: Dictionary<number> = {};
    let filtered: IAddressVm[] = addresses;
    if (filter) {
      filtered = filter.country ? addresses.filter((x) => x.country == filter.country) : filtered;
      filtered = filter.city ? addresses.filter((x) => x.city == filter.city) : filtered;
      filtered = filter.address ? addresses.filter((x) => x.fullAddress == filter.address) : filtered;
    }
    if (groupBy == "country") {
      grouped = countBy(filtered, (x) => x.country);
    } else if (groupBy == "city") {
      grouped = countBy(filtered, (x) => x.city);
    } else if (groupBy == "address") {
      grouped = countBy(filtered, (x) => x.fullAddress);
    } else {
      grouped = countBy(
        filtered.filter((x) => x.roomTitle),
        (x) => x.roomTitle,
      );
    }

    const data = map(grouped, function (value, key): IGroupedResult {
      return {
        key: key,
        count: value,
        percentage: value / filtered.length,
      };
    }).sort((a, b) => b.count - a.count);

    return data;
  }
}
