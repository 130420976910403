import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

import { IFileDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import FileRoutePaths from "../../files/fileRoutePaths";

interface IProps {
  attachments: IFileDTO[];
}

const AttachmentTablePrintView: React.FC<IProps> = (props) => {
  const { attachments } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <h5>
        {t("Attachments")} ({attachments.length})
      </h5>
      <hr />
      <Table responsive>
        <thead>
          <tr>
            <th>{t("Name")}</th>
            <th>{t("Uploaded")}</th>
            <th>{t("Download")}</th>
          </tr>
        </thead>
        <tbody>
          {attachments.map((d) => (
            <tr key={d.id}>
              <td>{d.title}</td>
              <td>{DateTimeLuxonHelpers.convertUtcToDateTime(d.createdAt)}</td>
              <td>{`${window.location.host}${FileRoutePaths.Download(d.id)}`}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default AttachmentTablePrintView;
