import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ITenantDTO, ITenantMinDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../../../pbdServices/services/service-context";

import { TenantConnectedToCompanyFunction } from "../../../../../Models/Tenants/TenantConnectedToCompanyFunction";
import { TenantConnectedToDepartmentPosition } from "../../../../../Models/Tenants/TenantConnectedToDepartmentPosition";
import { TenantConnectedToQualification } from "../../../../../Models/Tenants/TenantConnectedToQualification";
import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import { TenantConnectedToCompanyFunctionTable } from "../../../../tenants/components/tenantConnectedToCompanyFunctionTable";
import { TenantConnectedToDepartmentPositionTable } from "../../../../tenants/components/tenantConnectedToDepartmentPositionTable";
import { TenantConnectedToQualificationTable } from "../../../../tenants/components/tenantConnectedToQualificationTable";
import { TenantTable } from "../../../../tenants/components/tenantTable";
import { useConfirmation } from "../../../contexts/modalConfirmationContext";
import { TableAction, TableClickCommand } from "../../tables/TableClickCommand";
import { ConnectedCardProps } from "../connectedCardProps";
import ConnectedCardHeader from "../shared/connectedCardHeader";
import ConnectTenantsModal from "./connectTenantsModal";

type TableType =
  | ITenantMinDTO
  | ITenantDTO
  | TenantConnectedToQualification
  | TenantConnectedToCompanyFunction
  | TenantConnectedToDepartmentPosition;

interface IProps extends ConnectedCardProps<TableType> {
  availableTableActions?: TableAction[];
  preloadedData?: ITenantDTO[];
  submitButtonLabel?: string;
  type?:
    | "TenantDTO"
    | "TenantConnectedToQualification"
    | "TenantConnectedToCompanyFunction"
    | "TenantConnectedToDepartmentPosition";
  onTableClick?: (command: TableClickCommand, selected: ITenantMinDTO[]) => void;
  onRowEditClick?: (dto: TableType) => void;
  additionalColumns?: ColumnDef<ITenantMinDTO>[];
  noDataPlaceholder?: React.ReactNode;
}

function TenantsConnectedCard(props: IProps) {
  const {
    refreshParent,
    data,
    baseDTO,
    cardTitle,
    onConnectSubmit,
    onDisconnectSubmit,
    columnsVisibleDefault = ["name"],
    availableTableActions = baseDTO.capabilities?.canEdit ? ["Delete"] : undefined,
    type = "TenantDTO",
    additionalColumns,
  } = props;
  const { t } = useTranslation();
  const [connectionModal, setConnectionModal] = React.useState(false);
  const [selected, setSelected] = React.useState<TableType[]>([]);
  const confirm = useConfirmation();
  const { tenantService } = useAPIServices();

  if (onConnectSubmit && !refreshParent) throw Error("Combination of connect and refresh not allowed");

  const toggleConnectModal = () => setConnectionModal(!connectionModal);

  const handleTableClick = async (command: TableClickCommand) => {
    const { action } = command;
    if (data) {
      switch (action) {
        case "Delete":
          confirm({
            itemsToDelete: selected.map((x) => {
              return { id: x.id, title: x.fullName };
            }),
          }).then(() => submitDeleteRequest(selected.map((x) => x.id)));
          break;
        case "Export":
          tenantService.exportConnectedDTOToCsv(data, type);
          break;
        case "LogInspection":
          if (!props.onTableClick) throw Error("Missing click function");
          props.onTableClick(command, selected);
          break;
        case "Add":
          if (!props.onTableClick) throw Error("Missing click function");
          props.onTableClick(command, selected);
          refreshParent?.();
          break;
        default:
          throw Error("Not implemented");
      }
    }
  };

  const submitDeleteRequest = async (dto: number[]) => {
    if (!onDisconnectSubmit) throw Error("Missing disconnect");
    for (const i of dto) {
      await wrapApiCallWithToast(() => onDisconnectSubmit(i));
    }
    refreshParent?.();
    // console.log("set selected");
    // setSelected([]);
  };

  const canAdd = baseDTO.capabilities?.canEdit ?? false;

  return (
    <Card className="mb-3">
      <ConnectedCardHeader
        title={
          <span>
            <i className="fas icon-qmBase-Employees littleLeafGradientColor" /> {cardTitle}
          </span>
        }
        toggleConnectModal={onConnectSubmit ? toggleConnectModal : undefined}
        data={data}
        canAdd={canAdd}
      />

      {data && data.length == 0 && props.noDataPlaceholder && <Card.Body>{props.noDataPlaceholder}</Card.Body>}
      {data && data.length > 0 && (
        <Card.Body>
          {type == "TenantDTO" && (
            <TenantTable
              tableRows={data as ITenantDTO[]}
              columnsVisibleDefault={columnsVisibleDefault}
              setSelected={setSelected}
              availableTableActions={availableTableActions}
              onClick={handleTableClick}
              onCreateClick={onConnectSubmit ? toggleConnectModal : undefined}
              onCreateButtonTitle={t("Add person")}
              onRowClickNew={props.onRowEditClick}
              //@ts-expect-error TODO 2024-08-06 PP: Fix with better typings
              additionalColumns={additionalColumns}
              {...props}
            />
          )}
          {type == "TenantConnectedToQualification" && (
            <TenantConnectedToQualificationTable
              tableRows={data as TenantConnectedToQualification[]}
              columnsVisibleDefault={columnsVisibleDefault}
              setSelected={setSelected}
              availableTableActions={availableTableActions}
              onClick={handleTableClick}
              onCreateClick={onConnectSubmit ? toggleConnectModal : undefined}
              onCreateButtonTitle={t("Add person")}
              columnsToExclude={["createdAt"]}
              {...props}
            />
          )}
          {type == "TenantConnectedToCompanyFunction" && (
            <TenantConnectedToCompanyFunctionTable
              tableRows={data as TenantConnectedToCompanyFunction[]}
              columnsVisibleDefault={columnsVisibleDefault}
              setSelected={setSelected}
              availableTableActions={availableTableActions}
              onClick={handleTableClick}
              onCreateClick={onConnectSubmit ? toggleConnectModal : undefined}
              onCreateButtonTitle={t("Add person")}
              {...props}
            />
          )}
          {type == "TenantConnectedToDepartmentPosition" && (
            <TenantConnectedToDepartmentPositionTable
              tableRows={data as TenantConnectedToDepartmentPosition[]}
              columnsVisibleDefault={columnsVisibleDefault}
              setSelected={setSelected}
              availableTableActions={availableTableActions}
              onClick={handleTableClick}
              onCreateClick={onConnectSubmit ? toggleConnectModal : undefined}
              onCreateButtonTitle={t("Add person")}
              {...props}
            />
          )}
        </Card.Body>
      )}
      {onConnectSubmit && refreshParent && (
        <ConnectTenantsModal
          modal={connectionModal}
          toggle={toggleConnectModal}
          connectedElements={data}
          onSubmit={onConnectSubmit}
          // Needs to be handled via logic
          canSubmit={canAdd}
          refreshParent={refreshParent}
          {...props}
        />
      )}
    </Card>
  );
}

export default TenantsConnectedCard;
