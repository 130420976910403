import { Header, RowData, Table, Updater, VisibilityState, flexRender } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { Button, Collapse, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useToggle } from "../../../hooks/useToggle";
import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { TableAction, TableClickCommand } from "../TableClickCommand";
import { idOfActionColumn, idOfSelectionColumn } from "../reactstrapTable";
import { ActiveFilters } from "../toolbar/activeFilters";
import AdvancedTableButtons from "../toolbar/advancedTableButtons";
import { IFilterProps } from "../toolbar/filterKey";
import GlobalFilter from "./globalFilter";

interface IProps<T extends RowData> extends IFilterProps {
  table: Table<T>;
  onCreateClick?: () => void;
  onCreateButtonTitle?: React.ReactNode;
  tableKey: string;
  setGlobalFilter: (filterValue: any) => void;
  availableTableActions?: readonly TableAction[];
  onClick?: (action: TableClickCommand) => void;
  filterBox?: React.ReactNode;
  smartViewBox?: React.ReactNode;
  setColumnVisibility: (dto: Updater<VisibilityState>) => void;
}

function TableToolbar<T extends RowData>({
  table,
  onCreateButtonTitle,
  onCreateClick,
  tableKey,
  setGlobalFilter,
  availableTableActions = [],
  onClick,
  filterBox,
  smartViewBox,
  onDelete,
  activeFilters,
  setColumnVisibility,
}: IProps<T>) {
  const { t } = useTranslation();
  const [showAdvancedTableOptions, toggleShowAdvancedTableOptions] = useToggle();
  const [columnCustomization, toggleColumnCustomization] = useToggle();
  const [showFilter, toggleFilter] = useToggle();
  const [showSmartViews, toggleSmartViews] = useToggle();
  const numSelected = Object.keys(table.getState().rowSelection).length;

  useEffect(() => {
    const existingVisibility = table.getState().columnVisibility;
    existingVisibility[idOfSelectionColumn] = showAdvancedTableOptions;
    setColumnVisibility(existingVisibility);
  }, [showAdvancedTableOptions]);

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <div>
          {onCreateClick && (
            <Button className="rounded-pill shadow-sm" variant="outline-primary" onClick={onCreateClick}>
              <qmBaseIcons.Plus /> {onCreateButtonTitle ?? t("New")}
            </Button>
          )}
        </div>
        <div className="d-flex">
          <InputGroup>
            <GlobalFilter
              value={table.getState().globalFilter ?? ""}
              onChange={(value) => setGlobalFilter(String(value))}
            />
            {filterBox && (
              <Button variant="outline-secondary" onClick={toggleFilter} active={showFilter}>
                <qmBaseIcons.Filter /> {t("Filter")}
              </Button>
            )}
            <Button
              variant="outline-secondary"
              active={showAdvancedTableOptions}
              onClick={toggleShowAdvancedTableOptions}
              title={t("Advanced table options")}
            >
              <qmBaseIcons.EllipsisVertical />
            </Button>
          </InputGroup>
        </div>
      </div>
      {showAdvancedTableOptions && (
        <div className="bg-light shadow-sm mb-3 p-2">
          <div className="d-flex justify-content-between align-items-end">
            <div>
              <AdvancedTableButtons
                onClick={onClick}
                availableTableActions={availableTableActions}
                numSelected={numSelected}
              />
              {numSelected > 0 && (
                <small>
                  <strong>{table.getIsAllRowsSelected() ? t("All") : numSelected}</strong> {t("Selected")}
                </small>
              )}
            </div>
            <div>
              <div className="d-flex justify-content-end">
                <h6>{t("Advance table options")}</h6>
              </div>

              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-secondary"
                  title={t("Toggle column visibility")}
                  onClick={toggleColumnCustomization}
                  className="me-2"
                  size="sm"
                  active={columnCustomization}
                >
                  <qmBaseIcons.Column />
                </Button>
              </div>
            </div>
          </div>
          {columnCustomization && (
            <Form.Group className="mb-3">
              <Form.Label>{t("Visible columns")}</Form.Label>
              <div>
                <Form.Check
                  type="checkbox"
                  id={`${tableKey}_allColumns`}
                  label={t("All")}
                  inline
                  checked={table.getIsAllColumnsVisible()}
                  onChange={table.getToggleAllColumnsVisibilityHandler()}
                />
                {table
                  .getAllLeafColumns()
                  .filter((x) => ![idOfSelectionColumn, idOfActionColumn].includes(x.id))
                  .map((column) => {
                    return (
                      <Form.Check
                        id={`${tableKey}_${column.id}`}
                        key={column.id}
                        inline
                        label={flexRender(column.columnDef.header, {
                          table,
                          column,
                          // TODO: PP 20240627
                          // This hack builds on https://github.com/TanStack/table/discussions/4857#discussioncomment-9289126
                          // Maybe there will be better typings in the future;
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                          header: { column } as Header<unknown, unknown>,
                        })}
                        type="checkbox"
                        checked={column.getIsVisible()}
                        disabled={!column.getCanHide()}
                        onChange={column.getToggleVisibilityHandler()}
                      />
                    );
                  })}
              </div>
            </Form.Group>
          )}
        </div>
      )}
      <div className="d-flex justify-content-between mb-3">
        <div>
          {showFilter ? (
            <h6>
              <qmBaseIcons.Filter /> {t("Filter")}
            </h6>
          ) : (
            <ActiveFilters toggleFilter={toggleFilter} activeFilters={activeFilters} onDelete={onDelete} />
          )}
        </div>
        <div>
          {smartViewBox && (
            <Button variant="link" onClick={toggleSmartViews}>
              <qmBaseIcons.SmartView /> {t("Smart Views")}
              {" - "}
              {t("Get insights in your data")}
            </Button>
          )}
        </div>
      </div>
      <Collapse in={showFilter} data-testid="table-filter-box" mountOnEnter>
        <div>{filterBox}</div>
      </Collapse>
      <Collapse in={showSmartViews} mountOnEnter>
        <div>{smartViewBox}</div>
      </Collapse>
    </>
  );
}
export default TableToolbar;
