import {
  IBaseMinDTO,
  IDepartmentPositionDTO,
  IDepartmentPositionWithChildrenDTO,
  ITenantDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

interface ID3OrgChart {
  id: string;
  title: string;
  parentId?: string;
  tenants: ITenantDTO[];
  department: IBaseMinDTO;
}

export class D3OrgChart implements ID3OrgChart {
  constructor(
    item: IDepartmentPositionWithChildrenDTO,
    tenants: ITenantDTO[],
    positions: IDepartmentPositionWithChildrenDTO[],
  ) {
    this.id = item.id.toString();
    this.title = item.title;
    this.parentId = undefined;
    this.tenants = tenants.filter((f) => f.departmentPositions?.map((m) => m.id).includes(item.id));
    this.department = item.department;
    this.departmentPositions = positions;
    this.children = item.childDepartmentPositions ?? [];
    this.allTenants = tenants;
  }
  department: IBaseMinDTO;
  tenants: ITenantDTO[];
  id: string;
  title: string;
  parentId: string | undefined;
  private children: IDepartmentPositionDTO[];
  private departmentPositions: IDepartmentPositionWithChildrenDTO[];
  private allTenants: ITenantDTO[];

  // using concept of tree data structure
  // On this case :
  // adv: stabile and uncomplicated to implement
  // disadvantage : going through more loops
  getChildren(): D3OrgChart[] {
    let result: D3OrgChart[] = [this];
    for (const child of this.children) {
      // find item
      const item = this.departmentPositions.find((x) => x.id == child.id);
      if (!item) {
        continue;
      }

      // create instance
      const chart = new D3OrgChart(item, this.allTenants, this.departmentPositions);
      chart.parentId = this.id;
      result.push(chart);
      result = result.concat(chart.getChildren());
    }

    const outputResult: D3OrgChart[] = [];

    // remove duplicate
    for (const r of result) {
      if (!outputResult.map((x) => x.id).includes(r.id)) {
        outputResult.push(r);
      }
    }

    return outputResult;
  }
}
