import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { IArticleWatermarkSettings } from "../../../generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";

interface IProps {
  articleWatermarkSetting: IArticleWatermarkSettings;
}

function ArticleWaterMark(props: IProps) {
  const { articleWatermarkSetting } = props;
  const { t } = useTranslation();
  return (
    <>
      {articleWatermarkSetting.showWatermark && (
        <>
          <div className="watermark-body">
            <p aria-label="watermark" className="watermark-inner">
              {articleWatermarkSetting.watermarkText}
              {articleWatermarkSetting.showTimestamp && (
                <span style={{ fontSize: "1rem", marginTop: "1rem" }}>
                  {t("Access date")}: {DateTimeLuxonHelpers.convertUtcToDate(DateTime.now())}
                </span>
              )}
            </p>
          </div>
        </>
      )}
    </>
  );
}

export default ArticleWaterMark;
