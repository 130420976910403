import { ColumnDef } from "@tanstack/react-table";

import { idColumn } from "../shared/components/tables/columns/idColumn";
import { titleColumn } from "../shared/components/tables/columns/titleColumn";
import { BaseTableProps } from "../shared/components/tables/v8/base-table-props";
import ReactstrapTable from "../shared/components/tables/v8/reactstrap-table";

interface BaseTableEntityProps {
  id: string;
  title: string;
  /**React link */
  linkTo: string;
  // Just for testing
  // createdAt: DateTime;
}

type IProps = BaseTableProps<BaseTableEntityProps>;

export function BaseTable(props: IProps) {
  const { columnsVisibleDefault = ["title"], localStorageStateKey = "BaseTable", hideTitleLink } = props;

  const columns = [idColumn, titleColumn(hideTitleLink)];

  return (
    <ReactstrapTable<BaseTableEntityProps>
      columns={columns as ColumnDef<BaseTableEntityProps>[]}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={localStorageStateKey}
      {...props}
    />
  );
}
