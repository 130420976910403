import { useTranslation } from "react-i18next";

import { DateTimeLuxonHelpers } from "../../../../Helpers/DateTimeLuxonHelpers";
import { ICustomFormAnswerVm } from "../../../../pbdServices/services/CustomFormAnswers/models/custom-form-answer-vm";

interface IProps {
  answer: ICustomFormAnswerVm;
}

export function CustomFormAnswerHeader(props: IProps) {
  const { t } = useTranslation();
  const { answer } = props;
  return (
    <>
      <dl>
        <dt>{t("Answer ID")}</dt>
        <dd>{answer.id}</dd>
        <dt>{t("Submitted")}</dt>
        <dd>
          {answer.createdBy?.fullName} {DateTimeLuxonHelpers.convertUtcToDate(answer.submittedAt)}
        </dd>
      </dl>
    </>
  );
}
