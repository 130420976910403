import { useTranslation } from "react-i18next";
import Select, { MultiValue, SingleValue } from "react-select";

import { ICategoryDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import { getLabel, getValue } from "./reactSelectHelpers";

type OptionTypeForSelect = ICategoryDTO;

interface IProps {
  availableOptions: ICategoryDTO[];
  defaultValue: number[] | undefined;
  onChange: (value?: number[]) => void;
  isMulti?: boolean;
}

const getDefaultValue = (availableOptions: OptionTypeForSelect[], defaultValue: number[] | undefined) => {
  if (defaultValue) {
    availableOptions = availableOptions.filter((x) => defaultValue.includes(x.id));
    return availableOptions;
  } else {
    return [];
  }
};

export function CategorySelect(props: IProps) {
  const { availableOptions, defaultValue, onChange, isMulti } = props;
  const { t } = useTranslation();

  const handleChange = (newValue: MultiValue<ICategoryDTO> | SingleValue<ICategoryDTO>) => {
    if (newValue) {
      if (Array.isArray(newValue)) {
        onChange(newValue.map((x) => x.id));
      }
    } else {
      onChange();
    }
  };

  return (
    <Select
      menuPosition="fixed"
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      options={availableOptions}
      defaultValue={getDefaultValue(availableOptions, defaultValue)}
      onChange={handleChange}
      isMulti={isMulti}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      placeholder={`${t("Please select")}...`}
    />
  );
}
