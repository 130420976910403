import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ILoginProviderDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import XsfrService from "../../../pbdServices/services/Xsfr/xsfrService";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { AccountRoutePaths } from "../accountRoutePaths";

interface IProps {
  qsForExternalLogin: string | null;
  loginProviders: ILoginProviderDTO[];
}

function ExternalLoginForm(props: IProps) {
  const { t } = useTranslation();
  const { qsForExternalLogin, loginProviders } = props;
  const token = XsfrService.getXSFRToken();
  return (
    <div className="text-center">
      <h5>{t("Login with")}</h5>
      {loginProviders.map((x) => (
        <form
          key={x.name}
          method="post"
          action={`${AccountRoutePaths.ExternalLogin}/${qsForExternalLogin}`}
          className="mb-3"
        >
          <div className="d-grid gap-2">
            <Button variant="outline-secondary" name="provider" value={x.name} type="submit">
              {x.name == "AzureAD" && <qmBaseIcons.Windows className=" me-2" style={{ color: "#008AD7" }} />}
              {x.name == "Google" && <qmBaseIcons.Google className=" me-2" />} {x.displayName}
            </Button>
          </div>

          <input type="hidden" name="__RequestVerificationToken" value={token.tokenName} />
        </form>
      ))}
    </div>
  );
}
export default ExternalLoginForm;
