import { useTranslation } from "react-i18next";

function Welcome() {
  const { t } = useTranslation();
  return (
    <div className="w-full rounded-lg p-4">
      <p>{t("You can search your data here. Currently this search is limited to the displayed apps:")}</p>
    </div>
  );
}
export default Welcome;
