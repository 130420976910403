import { DateTime } from "luxon";

import {
  IArticleDTO,
  IArticleViewDTO,
  IMenuItemDTO,
  ITenantMinDTO,
  PbdModule,
  TagDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

import { ArticleRoutePaths } from "../../../../ClientApp/articles/articleRoutePaths";

// export type FolderContentVm = TableArticle | TableMenuItem;
export type FolderContentVm = FolderContentVm2;

export function toFolderTableType(dto: IArticleDTO | IMenuItemDTO): FolderContentVm {
  return new FolderContentVm2(dto);
  // if (isArticle(dto)) {
  //   return new TableArticle(dto);
  // }
  // if (isMenuItem(dto)) {
  //   return new TableMenuItem(dto);
  // }
  // throw Error("Unknown type");
}

class FolderContentVm2 {
  id: string;
  title: string;
  href: string;
  data: IArticleDTO | IMenuItemDTO;
  responsible?: ITenantMinDTO;
  lastViewed?: IArticleViewDTO;
  constructor(data: IArticleDTO | IMenuItemDTO) {
    if (isArticle(data)) {
      this.id = "article_" + data.id;
      this.title = data.title;
      this.href = ArticleRoutePaths.DetailsPageFn(data);
      this.data = data;
      this.createdAt = data.createdAt;
      this.module = data.module;
      this.isPrivate = data.isPrivate;
      this.responsible = data.responsible;
      this.tags = data.tags;
      this.lastUpdatedAt = data.lastUpdatedAt;
    } else if (isMenuItem(data)) {
      this.id = "menuItem_" + data.id;
      this.title = data.title;
      this.href = ArticleRoutePaths.IndexPageMenuItemsFn(data.module, data.id);
      this.data = data;
      this.createdAt = data.createdAt;
      this.module = data.module;
      this.isPrivate = data.isPrivate;
      this.tags = data.tags;
      this.lastUpdatedAt = data.lastUpdatedAt;
    } else {
      throw Error("Not implemented");
    }
  }
  parentMenuItemId?: number | undefined;
  createdAt: DateTime;
  lastUpdatedAt?: DateTime | undefined;
  module: PbdModule;
  tags?: TagDTO[] | undefined;
  isPrivate: boolean;
  isDeleted?: boolean;
}

class TableArticle implements IBaseItemDocs {
  id: string;
  title: string;
  href: string;
  kind: "Article";
  data: IArticleDTO;
  responsible?: ITenantMinDTO;
  lastViewed?: IArticleViewDTO;
  constructor(data: IArticleDTO) {
    this.id = "article_" + data.id;
    this.title = data.title;
    this.href = ArticleRoutePaths.DetailsPageFn(data);
    this.kind = "Article";
    this.data = data;
    this.createdAt = data.createdAt;
    this.module = data.module;
    this.isPrivate = data.isPrivate;
    this.responsible = data.responsible;
    this.tags = data.tags;
  }
  parentMenuItemId?: number | undefined;
  createdAt: DateTime;
  lastUpdatedAt?: DateTime | undefined;
  module: PbdModule;
  tags?: TagDTO[] | undefined;
  isPrivate: boolean;
  isDeleted?: boolean;
}

class TableMenuItem implements IBaseItemDocs {
  id: string;
  title: string;
  href: string;
  kind: "MenuItem";
  data: IMenuItemDTO;
  responsible?: ITenantMinDTO;
  constructor(data: IMenuItemDTO) {
    this.id = "menuItem_" + data.id;
    this.title = data.title;
    this.href = ArticleRoutePaths.IndexPageMenuItemsFn(data.module, data.id);
    this.kind = "MenuItem";
    this.data = data;
    this.createdAt = data.createdAt;
    this.module = data.module;
    this.isPrivate = data.isPrivate;
    this.tags = data.tags;
  }
  parentMenuItemId?: number | undefined;
  createdAt: DateTime;
  lastUpdatedAt?: DateTime | undefined;
  module: PbdModule;
  tags?: TagDTO[] | undefined;
  isPrivate: boolean;
  isDeleted?: boolean;
}

type ExtendType = Omit<IArticleDTO | IMenuItemDTO, "id">;

interface IBaseItemDocs {
  /**menuItem_id or article_id */
  id: string;
  href: string;
  title: string;
  responsible?: ITenantMinDTO;
  lastViewed?: IArticleViewDTO;
  /**This can be used to identify the error */
  kind: "Article" | "MenuItem";
  data: IMenuItemDTO | IArticleDTO;
}

// export type IFolderTableTypeExt = FolderTableTypeExtIntern & Extension;

export function isArticle(obj: IArticleDTO | IMenuItemDTO): obj is IArticleDTO {
  return (obj as IArticleDTO).isPublished != undefined;
}

export function isMenuItem(obj: IArticleDTO | IMenuItemDTO): obj is IMenuItemDTO {
  return (obj as IArticleDTO).isPublished == undefined;
}
