import { Field, Form, Formik } from "formik";
import { nanoid } from "nanoid";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { IScaleSection } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import CancelSubmitFormGroup from "../../../shared/components/buttons/cancelSubmitFormGroup";
import { BaseFormProps } from "../../../shared/components/forms/formik/BaseFormProps";
import { FormikNumberInput } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";

const ValidationSchema: yup.ObjectSchema<IScaleSection> = yup.object({
  scaleSectionId: yup.string().required(),
  from: yup.number().required(),
  to: yup.number().required(),
  description: yup.string().required().min(2).max(32),
  opportunityCategoryVariableId: yup.string().notRequired(),
  opportunityCategoryId: yup.number().notRequired(),
});

const EditFormScaleSection: React.FC<BaseFormProps<IScaleSection>> = (props) => {
  const { itemToUpdate, onSubmit, onCancel, onSuccess } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IScaleSection>((val) => onSubmit(val), [onSubmit], onSuccess);

  const initialValues: IScaleSection = {
    scaleSectionId: itemToUpdate?.scaleSectionId || nanoid(),
    from: itemToUpdate?.from || 0,
    to: itemToUpdate?.to || 0,
    description: itemToUpdate?.description || "",
  };

  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
        {(formikBag) => (
          <Form>
            <FormGroup>
              <Label for="description">{t("Description")}</Label>
              <Field name="description" component={FormikTextInput} />
            </FormGroup>
            <FormGroup>
              <Label for="from">{t("From")}</Label>
              <Field name="from" component={FormikNumberInput} />
            </FormGroup>
            <FormGroup>
              <Label for="to">{t("To")}</Label>
              <Field name="to" component={FormikNumberInput} />
            </FormGroup>
            <CancelSubmitFormGroup onCancel={onCancel} formikBag={formikBag} />

            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default EditFormScaleSection;
