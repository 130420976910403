import * as PbdApi from "./pbd-core-api";
export class ControllerContextData {
  absencePoliciesApi: PbdApi.IAbsencePoliciesControllerClient;
  absenceRequestsApi: PbdApi.IAbsenceRequestsControllerClient;
  absenceTypesApi: PbdApi.IAbsenceTypesControllerClient;
  accountApi: PbdApi.IAccountControllerClient;
  accountRequestsApi: PbdApi.IAccountRequestsControllerClient;
  addressesApi: PbdApi.IAddressesControllerClient;
  appsApi: PbdApi.IAppsControllerClient;
  appSettingsApi: PbdApi.IAppSettingsControllerClient;
  articleRevisionApprovalsApi: PbdApi.IArticleRevisionApprovalsControllerClient;
  articleRevisionReviewsApi: PbdApi.IArticleRevisionReviewsControllerClient;
  articleRevisionsApi: PbdApi.IArticleRevisionsControllerClient;
  articlesApi: PbdApi.IArticlesControllerClient;
  articleViewsApi: PbdApi.IArticleViewsControllerClient;
  auditRequirementsApi: PbdApi.IAuditRequirementsControllerClient;
  auditsApi: PbdApi.IAuditsControllerClient;
  auditTrailApi: PbdApi.IAuditTrailControllerClient;
  auditTypesApi: PbdApi.IAuditTypesControllerClient;
  backupApi: PbdApi.IBackupControllerClient;
  blobsApi: PbdApi.IBlobsControllerClient;
  calendarApi: PbdApi.ICalendarControllerClient;
  claimsApi: PbdApi.IClaimsControllerClient;
  commentsApi: PbdApi.ICommentsControllerClient;
  companyFunctionsApi: PbdApi.ICompanyFunctionsControllerClient;
  connectionsApi: PbdApi.IConnectionsControllerClient;
  costCategoriesApi: PbdApi.ICostCategoriesControllerClient;
  costCentersApi: PbdApi.ICostCentersControllerClient;
  costsApi: PbdApi.ICostsControllerClient;
  costTemplatesApi: PbdApi.ICostTemplatesControllerClient;
  cronJobsApi: PbdApi.ICronJobsControllerClient;
  customFieldsApi: PbdApi.ICustomFieldsControllerClient;
  customFormAnswersApi: PbdApi.ICustomFormAnswersControllerClient;
  customFormLinksApi: PbdApi.ICustomFormLinksControllerClient;
  customFormsApi: PbdApi.ICustomFormsControllerClient;
  dashboardsApi: PbdApi.IDashboardsControllerClient;
  defectCategoriesApi: PbdApi.IDefectCategoriesControllerClient;
  defectsApi: PbdApi.IDefectsControllerClient;
  departmentPositionsApi: PbdApi.IDepartmentPositionsControllerClient;
  departmentsApi: PbdApi.IDepartmentsControllerClient;
  eightDReportsApi: PbdApi.IEightDReportsControllerClient;
  emailsApi: PbdApi.IEmailsControllerClient;
  employeeIdeaCategoriesApi: PbdApi.IEmployeeIdeaCategoriesControllerClient;
  employeeIdeaReviewsApi: PbdApi.IEmployeeIdeaReviewsControllerClient;
  employeeIdeasApi: PbdApi.IEmployeeIdeasControllerClient;
  entityPermissionsApi: PbdApi.IEntityPermissionsControllerClient;
  externalLoginsApi: PbdApi.IExternalLoginsControllerClient;
  featureFlagApi: PbdApi.IFeatureFlagControllerClient;
  goalProgressesApi: PbdApi.IGoalProgressesControllerClient;
  goalsApi: PbdApi.IGoalsControllerClient;
  graphsApi: PbdApi.IGraphsControllerClient;
  groupsApi: PbdApi.IGroupsControllerClient;
  healthApi: PbdApi.IHealthControllerClient;
  houseKeepingApi: PbdApi.IHouseKeepingControllerClient;
  importApi: PbdApi.IImportControllerClient;
  integrationsApi: PbdApi.IIntegrationsControllerClient;
  internalSettingsApi: PbdApi.IInternalSettingsControllerClient;
  inventoryCategoriesApi: PbdApi.IInventoryCategoriesControllerClient;
  inventoryInspectionsApi: PbdApi.IInventoryInspectionsControllerClient;
  inventoryInspectionsConnectedApi:
    PbdApi.IInventoryInspectionsConnectedControllerClient;
  inventoryInspectionsDoneApi: PbdApi.IInventoryInspectionsDoneControllerClient;
  inventoryItemRepairsApi: PbdApi.IInventoryItemRepairsControllerClient;
  inventoryItemsApi: PbdApi.IInventoryItemsControllerClient;
  inventoryStatusApi: PbdApi.IInventoryStatusControllerClient;
  keyValueObjectsApi: PbdApi.IKeyValueObjectsControllerClient;
  manageApi: PbdApi.IManageControllerClient;
  menuItemsApi: PbdApi.IMenuItemsControllerClient;
  migrationsApi: PbdApi.IMigrationsControllerClient;
  modulesApi: PbdApi.IModulesControllerClient;
  moduleSettingsApi: PbdApi.IModuleSettingsControllerClient;
  notificationsApi: PbdApi.INotificationsControllerClient;
  notificationTemplatesApi: PbdApi.INotificationTemplatesControllerClient;
  opportunitiesApi: PbdApi.IOpportunitiesControllerClient;
  opportunityCategoriesApi: PbdApi.IOpportunityCategoriesControllerClient;
  opportunityEvaluationsApi: PbdApi.IOpportunityEvaluationsControllerClient;
  organisationRatingsApi: PbdApi.IOrganisationRatingsControllerClient;
  organisationsApi: PbdApi.IOrganisationsControllerClient;
  productsApi: PbdApi.IProductsControllerClient;
  projectsApi: PbdApi.IProjectsControllerClient;
  purchaseOrdersApi: PbdApi.IPurchaseOrdersControllerClient;
  qualificationsApi: PbdApi.IQualificationsControllerClient;
  reportingsApi: PbdApi.IReportingsControllerClient;
  rolesApi: PbdApi.IRolesControllerClient;
  salesOrdersApi: PbdApi.ISalesOrdersControllerClient;
  sampleDataApi: PbdApi.ISampleDataControllerClient;
  searchApi: PbdApi.ISearchControllerClient;
  settingsApi: PbdApi.ISettingsControllerClient;
  smartViewsApi: PbdApi.ISmartViewsControllerClient;
  storeItemsApi: PbdApi.IStoreItemsControllerClient;
  swaggerTestApi: PbdApi.ISwaggerTestControllerClient;
  syncApi: PbdApi.ISyncControllerClient;
  syncHistoryApi: PbdApi.ISyncHistoryControllerClient;
  tagsApi: PbdApi.ITagsControllerClient;
  talentTenantsApi: PbdApi.ITalentTenantsControllerClient;
  tenantCompanyFunctionsApi: PbdApi.ITenantCompanyFunctionsControllerClient;
  tenantQualificationRequirementsApi:
    PbdApi.ITenantQualificationRequirementsControllerClient;
  tenantQualificationsApi: PbdApi.ITenantQualificationsControllerClient;
  tenantQualificationsDoneApi: PbdApi.ITenantQualificationsDoneControllerClient;
  tenantsApi: PbdApi.ITenantsControllerClient;
  toDosApi: PbdApi.IToDosControllerClient;
  trainingAttendeesApi: PbdApi.ITrainingAttendeesControllerClient;
  trainingDemandsApi: PbdApi.ITrainingDemandsControllerClient;
  trainingsApi: PbdApi.ITrainingsControllerClient;
  trainingTypesApi: PbdApi.ITrainingTypesControllerClient;
  unitsApi: PbdApi.IUnitsControllerClient;
  userApi: PbdApi.IUserControllerClient;
  usersApi: PbdApi.IUsersControllerClient;
  userSettingsApi: PbdApi.IUserSettingsControllerClient;
  userSettingsExternalApi: PbdApi.IUserSettingsExternalControllerClient;
  watchApi: PbdApi.IWatchControllerClient;
  constructor(config: PbdApi.ConfigApi) {
    this.absencePoliciesApi = new PbdApi.AbsencePoliciesControllerClient(
      config,
    );
    this.absenceRequestsApi = new PbdApi.AbsenceRequestsControllerClient(
      config,
    );
    this.absenceTypesApi = new PbdApi.AbsenceTypesControllerClient(config);
    this.accountApi = new PbdApi.AccountControllerClient(config);
    this.accountRequestsApi = new PbdApi.AccountRequestsControllerClient(
      config,
    );
    this.addressesApi = new PbdApi.AddressesControllerClient(config);
    this.appsApi = new PbdApi.AppsControllerClient(config);
    this.appSettingsApi = new PbdApi.AppSettingsControllerClient(config);
    this.articleRevisionApprovalsApi = new PbdApi
      .ArticleRevisionApprovalsControllerClient(config);
    this.articleRevisionReviewsApi = new PbdApi
      .ArticleRevisionReviewsControllerClient(config);
    this.articleRevisionsApi = new PbdApi.ArticleRevisionsControllerClient(
      config,
    );
    this.articlesApi = new PbdApi.ArticlesControllerClient(config);
    this.articleViewsApi = new PbdApi.ArticleViewsControllerClient(config);
    this.auditRequirementsApi = new PbdApi.AuditRequirementsControllerClient(
      config,
    );
    this.auditsApi = new PbdApi.AuditsControllerClient(config);
    this.auditTrailApi = new PbdApi.AuditTrailControllerClient(config);
    this.auditTypesApi = new PbdApi.AuditTypesControllerClient(config);
    this.backupApi = new PbdApi.BackupControllerClient(config);
    this.blobsApi = new PbdApi.BlobsControllerClient(config);
    this.calendarApi = new PbdApi.CalendarControllerClient(config);
    this.claimsApi = new PbdApi.ClaimsControllerClient(config);
    this.commentsApi = new PbdApi.CommentsControllerClient(config);
    this.companyFunctionsApi = new PbdApi.CompanyFunctionsControllerClient(
      config,
    );
    this.connectionsApi = new PbdApi.ConnectionsControllerClient(config);
    this.costCategoriesApi = new PbdApi.CostCategoriesControllerClient(config);
    this.costCentersApi = new PbdApi.CostCentersControllerClient(config);
    this.costsApi = new PbdApi.CostsControllerClient(config);
    this.costTemplatesApi = new PbdApi.CostTemplatesControllerClient(config);
    this.cronJobsApi = new PbdApi.CronJobsControllerClient(config);
    this.customFieldsApi = new PbdApi.CustomFieldsControllerClient(config);
    this.customFormAnswersApi = new PbdApi.CustomFormAnswersControllerClient(
      config,
    );
    this.customFormLinksApi = new PbdApi.CustomFormLinksControllerClient(
      config,
    );
    this.customFormsApi = new PbdApi.CustomFormsControllerClient(config);
    this.dashboardsApi = new PbdApi.DashboardsControllerClient(config);
    this.defectCategoriesApi = new PbdApi.DefectCategoriesControllerClient(
      config,
    );
    this.defectsApi = new PbdApi.DefectsControllerClient(config);
    this.departmentPositionsApi = new PbdApi
      .DepartmentPositionsControllerClient(config);
    this.departmentsApi = new PbdApi.DepartmentsControllerClient(config);
    this.eightDReportsApi = new PbdApi.EightDReportsControllerClient(config);
    this.emailsApi = new PbdApi.EmailsControllerClient(config);
    this.employeeIdeaCategoriesApi = new PbdApi
      .EmployeeIdeaCategoriesControllerClient(config);
    this.employeeIdeaReviewsApi = new PbdApi
      .EmployeeIdeaReviewsControllerClient(config);
    this.employeeIdeasApi = new PbdApi.EmployeeIdeasControllerClient(config);
    this.entityPermissionsApi = new PbdApi.EntityPermissionsControllerClient(
      config,
    );
    this.externalLoginsApi = new PbdApi.ExternalLoginsControllerClient(config);
    this.featureFlagApi = new PbdApi.FeatureFlagControllerClient(config);
    this.goalProgressesApi = new PbdApi.GoalProgressesControllerClient(config);
    this.goalsApi = new PbdApi.GoalsControllerClient(config);
    this.graphsApi = new PbdApi.GraphsControllerClient(config);
    this.groupsApi = new PbdApi.GroupsControllerClient(config);
    this.healthApi = new PbdApi.HealthControllerClient(config);
    this.houseKeepingApi = new PbdApi.HouseKeepingControllerClient(config);
    this.importApi = new PbdApi.ImportControllerClient(config);
    this.integrationsApi = new PbdApi.IntegrationsControllerClient(config);
    this.internalSettingsApi = new PbdApi.InternalSettingsControllerClient(
      config,
    );
    this.inventoryCategoriesApi = new PbdApi
      .InventoryCategoriesControllerClient(config);
    this.inventoryInspectionsApi = new PbdApi
      .InventoryInspectionsControllerClient(config);
    this.inventoryInspectionsConnectedApi = new PbdApi
      .InventoryInspectionsConnectedControllerClient(config);
    this.inventoryInspectionsDoneApi = new PbdApi
      .InventoryInspectionsDoneControllerClient(config);
    this.inventoryItemRepairsApi = new PbdApi
      .InventoryItemRepairsControllerClient(config);
    this.inventoryItemsApi = new PbdApi.InventoryItemsControllerClient(config);
    this.inventoryStatusApi = new PbdApi.InventoryStatusControllerClient(
      config,
    );
    this.keyValueObjectsApi = new PbdApi.KeyValueObjectsControllerClient(
      config,
    );
    this.manageApi = new PbdApi.ManageControllerClient(config);
    this.menuItemsApi = new PbdApi.MenuItemsControllerClient(config);
    this.migrationsApi = new PbdApi.MigrationsControllerClient(config);
    this.modulesApi = new PbdApi.ModulesControllerClient(config);
    this.moduleSettingsApi = new PbdApi.ModuleSettingsControllerClient(config);
    this.notificationsApi = new PbdApi.NotificationsControllerClient(config);
    this.notificationTemplatesApi = new PbdApi
      .NotificationTemplatesControllerClient(config);
    this.opportunitiesApi = new PbdApi.OpportunitiesControllerClient(config);
    this.opportunityCategoriesApi = new PbdApi
      .OpportunityCategoriesControllerClient(config);
    this.opportunityEvaluationsApi = new PbdApi
      .OpportunityEvaluationsControllerClient(config);
    this.organisationRatingsApi = new PbdApi
      .OrganisationRatingsControllerClient(config);
    this.organisationsApi = new PbdApi.OrganisationsControllerClient(config);
    this.productsApi = new PbdApi.ProductsControllerClient(config);
    this.projectsApi = new PbdApi.ProjectsControllerClient(config);
    this.purchaseOrdersApi = new PbdApi.PurchaseOrdersControllerClient(config);
    this.qualificationsApi = new PbdApi.QualificationsControllerClient(config);
    this.reportingsApi = new PbdApi.ReportingsControllerClient(config);
    this.rolesApi = new PbdApi.RolesControllerClient(config);
    this.salesOrdersApi = new PbdApi.SalesOrdersControllerClient(config);
    this.sampleDataApi = new PbdApi.SampleDataControllerClient(config);
    this.searchApi = new PbdApi.SearchControllerClient(config);
    this.settingsApi = new PbdApi.SettingsControllerClient(config);
    this.smartViewsApi = new PbdApi.SmartViewsControllerClient(config);
    this.storeItemsApi = new PbdApi.StoreItemsControllerClient(config);
    this.swaggerTestApi = new PbdApi.SwaggerTestControllerClient(config);
    this.syncApi = new PbdApi.SyncControllerClient(config);
    this.syncHistoryApi = new PbdApi.SyncHistoryControllerClient(config);
    this.tagsApi = new PbdApi.TagsControllerClient(config);
    this.talentTenantsApi = new PbdApi.TalentTenantsControllerClient(config);
    this.tenantCompanyFunctionsApi = new PbdApi
      .TenantCompanyFunctionsControllerClient(config);
    this.tenantQualificationRequirementsApi = new PbdApi
      .TenantQualificationRequirementsControllerClient(config);
    this.tenantQualificationsApi = new PbdApi
      .TenantQualificationsControllerClient(config);
    this.tenantQualificationsDoneApi = new PbdApi
      .TenantQualificationsDoneControllerClient(config);
    this.tenantsApi = new PbdApi.TenantsControllerClient(config);
    this.toDosApi = new PbdApi.ToDosControllerClient(config);
    this.trainingAttendeesApi = new PbdApi.TrainingAttendeesControllerClient(
      config,
    );
    this.trainingDemandsApi = new PbdApi.TrainingDemandsControllerClient(
      config,
    );
    this.trainingsApi = new PbdApi.TrainingsControllerClient(config);
    this.trainingTypesApi = new PbdApi.TrainingTypesControllerClient(config);
    this.unitsApi = new PbdApi.UnitsControllerClient(config);
    this.userApi = new PbdApi.UserControllerClient(config);
    this.usersApi = new PbdApi.UsersControllerClient(config);
    this.userSettingsApi = new PbdApi.UserSettingsControllerClient(config);
    this.userSettingsExternalApi = new PbdApi
      .UserSettingsExternalControllerClient(config);
    this.watchApi = new PbdApi.WatchControllerClient(config);
  }
}
