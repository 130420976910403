import { isBoolean, isNumber } from "lodash";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { FilterValue } from "react-table";

import { DateTimeLuxonHelpers } from "../../../../../Helpers/DateTimeLuxonHelpers";
import { qmBaseIcons } from "../../icons/qmBaseIcons";

interface IProps {
  values: FilterValue;
  separator?: string;
}

function ActiveFilterBadgeValue(props: IProps) {
  const { values, separator } = props;
  const { t } = useTranslation();
  if (Array.isArray(values)) {
    return <>{values.map((x) => (isNumber(x) ? x : t(x))).join(separator ?? ", ")}</>;
  } else if (DateTime.isDateTime(values)) {
    return <>{DateTimeLuxonHelpers.convertUtcToDate(values)}</>;
  } else if (isBoolean(values)) {
    return <>{values ? <qmBaseIcons.Check /> : <qmBaseIcons.Cancel />}</>;
  } else {
    return <>{values}</>;
  }
}
export default ActiveFilterBadgeValue;
