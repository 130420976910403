import { ISetting } from "../../../generatedCode/pbd-core/pbd-core-api";

import JsonHelpers from "../Json/jsonHelpers";
import { SettingKeyNames } from "./models/setting-key-names";

export class SettingsMappingService {
  static getAllowedDomains(item: ISetting) {
    if (item.key != SettingKeyNames.AllowedDomains) throw Error("Wrong key name");
    if (item.value && JsonHelpers.isJson(item.value)) {
      return JsonHelpers.parse<string[]>(item.value);
    } else {
      return item.value?.split(",") ?? [];
    }
  }
}
