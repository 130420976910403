import { createColumnHelper } from "@tanstack/react-table";
import i18next from "i18next";
import { CellProps, Column } from "react-table";

import { ITenantMinDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import AvatarSpanWithName from "../../tenants/avatarSpanWithName";

interface IProps {
  responsible?: ITenantMinDTO;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getResponsibleColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "responsible",
    Header: <>{i18next.t("Responsible")}</>,
    accessor: (originalRow, rowIndex) => originalRow.responsible?.fullName,
    Cell: ({ row }: CellProps<T>) => (
      <>{row.original.responsible && <AvatarSpanWithName tenant={row.original.responsible} />}</>
    ),
  };
  return column;
}

const columnHelper = createColumnHelper<IProps>();

export const responsibleColumn = columnHelper.accessor("responsible.fullName", {
  id: "responsible",
  header: () => <>{i18next.t("Responsible")}</>,
  cell: ({ row }) => <>{row.original.responsible && <AvatarSpanWithName tenant={row.original.responsible} />}</>,
});
