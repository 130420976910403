import {
  EightDReportStep,
  IBlobsControllerClient,
  IClaimsControllerClient,
  IEightDReportEditDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

export default class EightDReportService {
  blobsApi: IBlobsControllerClient;
  claimsApi: IClaimsControllerClient;
  constructor(blobsApi: IBlobsControllerClient, claimsApi: IClaimsControllerClient) {
    this.blobsApi = blobsApi;
    this.claimsApi = claimsApi;
  }
  returnReportActionByActionType(report: IEightDReportEditDTO, step: EightDReportStep) {
    const { actions, rootCauses } = report;
    if (!actions || !rootCauses) throw Error("Missing actions");
    switch (step) {
      case EightDReportStep.ImmediateActions: {
        return actions.filter((t) => t.isImmediateAction).filterMap((t) => t.toDo?.id);
      }
      case EightDReportStep.RootCauses: {
        return rootCauses.map((x) => x.id);
      }
      case EightDReportStep.CorrectiveActions: {
        return actions.filter((t) => t.isCorrectiveAction).filterMap((t) => t.toDo?.id);
      }
      case EightDReportStep.PreventiveActions: {
        return actions.filter((t) => t.isPreventiveAction).filterMap((t) => t.toDo?.id);
      }
      case EightDReportStep.VerificationAndResult: {
        return actions.filter((t) => t.isVerificationAndResult).filterMap((t) => t.toDo?.id);
      }
      default: {
        throw new Error("type not found");
      }
    }
  }

  returnDescriptionByActionType(report: IEightDReportEditDTO, actionType: EightDReportStep) {
    let description;
    switch (actionType) {
      case EightDReportStep.ImmediateActions: {
        description = report.immediateActionDescription;
        break;
      }
      case EightDReportStep.RootCauses: {
        description = report.rootCauseDescription;
        break;
      }
      case EightDReportStep.CorrectiveActions: {
        description = report.correctiveActionsDescription;
        break;
      }
      case EightDReportStep.PreventiveActions: {
        description = report.preventiveActionsDescription;
        break;
      }
      case EightDReportStep.VerificationAndResult: {
        description = report.verificationAndResultDescription;
        break;
      }
      default: {
        throw new Error("type not found");
      }
    }
    return description;
  }

  async returnClaimConnectedImages(report: IEightDReportEditDTO) {
    let connectedImages = await this.claimsApi.getConnectedFiles(report.claimId);
    connectedImages = connectedImages.filter((x) => x.mimeType?.startsWith("image/"));
    return connectedImages;
  }
}
