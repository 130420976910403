import { EntityKey, IProjectDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";

//TODO
export type PickStrKeys<T, K extends keyof T & string> = {
  [P in K]: T[P];
};

export default class ProjectService extends BaseExportService<IProjectDTO> {
  constructor() {
    super("Projects");
  }

  mapToExport(x: IProjectDTO): ExportType {
    //TODO still needs testing
    return x as PickStrKeys<IProjectDTO, "id" | "createdAt">;
  }

  static get availableConnections(): AvailableConnection[] {
    const connections = [new AvailableConnection(EntityKey.ToDo)];
    return connections;
  }
}
