import React from "react";
import CommandPalette, { JsonStructureItem } from "react-cmdk";
import { useTranslation } from "react-i18next";

interface IProps {
  pages: JsonStructureItem[];
  children: React.ReactNode;
  onEscape: () => void;
  prefix?: string;
}

function SearchBoxDynamicSpecificPages(props: IProps) {
  const { t } = useTranslation();
  const { pages, onEscape, children, prefix } = props;
  return (
    <>
      {pages.map((page, i) => (
        <CommandPalette.Page
          key={i}
          id={page.id}
          searchPrefix={
            prefix ? [prefix, page.children?.toString() ?? t("Not set")] : [page.children?.toString() ?? t("Not set")]
          }
          onEscape={onEscape}
        >
          {children}
        </CommandPalette.Page>
      ))}
    </>
  );
}

export default SearchBoxDynamicSpecificPages;
