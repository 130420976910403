import { Form, Formik } from "formik";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from "reactstrap";
import * as yup from "yup";

import {
  CustomFieldGradingBasis,
  CustomFieldType,
  ICustomField,
  ICustomFieldCreateDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import CustomFieldService from "../../../../pbdServices/services/CustomFields/customFieldService";
import { requiredTitleStringSchema } from "../../../../services/validation/stringSchemas";
import CancelButton from "../../../shared/components/buttons/cancelButton";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";

const getValidationSchema = (t: TFunction) => {
  //@ts-expect-error TODO Fix with better typings
  const GradingSchema: yup.ObjectSchema<CustomFieldGradingBasis> = yup.object({
    answerPlain: yup.string(),
    point: yup.number().notRequired(),
  });

  //@ts-expect-error Errors occurs in non strict
  const ValidationSchema: yup.ObjectSchema<ICustomFieldCreateDTO> = yup.object({
    title: requiredTitleStringSchema(t),
    type: yup.mixed<CustomFieldType>().oneOf(Object.values(CustomFieldType)).required(),
    isRequired: yup.boolean().required(),
    optionalHelpText: yup.string(),
    choices: yup.array(yup.string().required()),
    gradingBases: GradingSchema,
  });
  return ValidationSchema;
};

interface IProps {
  onSubmit: (dto: ICustomFieldCreateDTO) => Promise<ICustomField>;
  onCancel: () => void;
  onSuccess: (dto: ICustomField) => void;
  excludedCustomFieldTypes?: CustomFieldType[];
}

function CustomFieldCreateForm(props: IProps) {
  const { t } = useTranslation();
  const { onSubmit, onCancel, onSuccess, excludedCustomFieldTypes } = props;

  const submitter = useFormikAPISubmitter<ICustomFieldCreateDTO, ICustomField>(
    (val) => {
      CustomFieldService.addDefaultValuesBeforeCreate(val);
      return onSubmit(val);
    },
    [onSubmit],
    (resp) => onSuccess(resp),
  );

  const initialValues: ICustomFieldCreateDTO = {
    title: t("Question"),
    type: CustomFieldType.Text,
    isRequired: false,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getValidationSchema(t)}>
      {(formikBag) => (
        <Form>
          <FormikDebugInfo formikBag={formikBag} />
          <ListGroup className="mb-3">
            <ListGroupItem>
              <ListGroupItemHeading>{t("Please select the type of field you want to add")}</ListGroupItemHeading>
              <ListGroupItemText>{t("This type can not be changed afterwards")}</ListGroupItemText>
            </ListGroupItem>
            {CustomFieldService.customFieldTypes
              .filter((x) => (excludedCustomFieldTypes ? !excludedCustomFieldTypes.includes(x) : true))
              .map((x) => (
                <ListGroupItem
                  active={formikBag.values.type == x}
                  action
                  onClick={() => formikBag.setFieldValue("type", x)}
                  key={x}
                  style={{ cursor: "pointer" }}
                >
                  {formikBag.values.type == x && <qmBaseIcons.Check />} {t(x)}
                </ListGroupItem>
              ))}
          </ListGroup>
          <FormGroup>
            <CancelButton onClick={onCancel} />
            <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
              {t("Continue")}
            </Button>
          </FormGroup>
          <FormikValidationSummary formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}
export default CustomFieldCreateForm;
