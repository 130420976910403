import { uniqBy } from "lodash";
import { DateTime } from "luxon";

import {
  AbsencePolicyDTO,
  AbsenceRequestCreateDTO,
  AbsenceRequestDTO,
  ConnectApproverDTO,
  Holidays,
  IAbsencePoliciesControllerClient,
  IAbsencePolicyDTO,
  IAbsenceRequestCreateDTO,
  IAbsenceRequestDTO,
  IAbsenceRequestsControllerClient,
  IBankHolidaySettings,
  IHolidays,
  ITenantsControllerClient,
  PbdStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { filterMap } from "../../../Helpers/filterMap";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { IAbsenceRequestVm } from "./models/absence-request-vm";
import { AbsentDayForTenant } from "./models/absent-data-for-tenant";
import { AnonymousAbsencePolicy } from "./models/anonymous-absence-policy";
import { AbsenceRequestQueryParameters } from "./models/query-parameters";
import {
  AbsenceDaysPerPolicyDTO,
  TenantApprovedAbsenceRequestsDTO,
} from "./models/tenant-approved-absence-requests-vm";

export default class AbsenceRequestService extends BaseExportService<IAbsenceRequestVm> {
  absenceRequestApi: IAbsenceRequestsControllerClient;
  absencePolicyApi: IAbsencePoliciesControllerClient;
  tenantsApi: ITenantsControllerClient;
  constructor(
    absenceRequestApi: IAbsenceRequestsControllerClient,
    absencePolicyApi: IAbsencePoliciesControllerClient,
    tenantsApi: ITenantsControllerClient,
  ) {
    super("Absence requests");
    this.absenceRequestApi = absenceRequestApi;
    this.absencePolicyApi = absencePolicyApi;
    this.tenantsApi = tenantsApi;
  }

  async getByIdAsVm(
    id: number,
    bankHolidays: IBankHolidaySettings | undefined,
  ): Promise<IAbsenceRequestVm | undefined> {
    if (!bankHolidays) return undefined;

    const resp = await this.absenceRequestApi.getById(id);
    const policy = await this.absencePolicyApi.getById(resp.absencePolicyId);
    const approvers =
      resp.absenceRequestApprovals && resp.absenceRequestApprovals.length > 0
        ? await this.tenantsApi.getAllQuery({
            id: resp.absenceRequestApprovals.map((x) => x.approverId),
            pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
          })
        : undefined;

    const totalDays = AbsenceRequestService.getAbsenceDays([resp]);
    const netDays = AbsenceRequestService.removeNonWorkingDaysFromAbsenceDays(totalDays, bankHolidays.holidays);
    return {
      ...resp,
      absencePolicy: policy,
      netDays: netDays.length,
      totalDays: totalDays.length,
      absenceRequestApprovals: resp.absenceRequestApprovals?.map((x) => {
        return { ...x, approver: approvers?.data.find((a) => a.id == x.approverId) };
      }),
    };
  }

  async getAllQueryAsVM(
    query: AbsenceRequestQueryParameters,
    bankHolidays: IBankHolidaySettings | undefined,
    meAsUser: MeAsUser,
    absencePolicies: AbsencePolicyDTO[],
  ): Promise<IAbsenceRequestVm[] | undefined> {
    if (!bankHolidays) return undefined;

    const resp = await this.absenceRequestApi.getAllQuery(query);
    return resp.map((x) => {
      const totalDays = AbsenceRequestService.getAbsenceDays([x]);
      const netDays = AbsenceRequestService.removeNonWorkingDaysFromAbsenceDays(totalDays, bankHolidays.holidays);
      const policy = absencePolicies.find((p) => p.id == x.absencePolicyId);
      return {
        ...x,
        totalDays: totalDays.length,
        netDays: netDays.length,
        absencePolicy: AbsenceRequestService.anonymiseAbsenceRequests(policy, meAsUser),
      };
    });
  }

  static anonymiseAbsenceRequests(absencePolicy: AbsencePolicyDTO | undefined, meAsUser: MeAsUser): AbsencePolicyDTO {
    if (!hasRole(meAsUser, [PbdRoles.AbsencePlanner_ModuleAdmin]) || !absencePolicy) return AnonymousAbsencePolicy;

    return absencePolicy;
  }

  getAbsenceDaysIncludingTenants(absenceRequests: IAbsenceRequestDTO[]): AbsentDayForTenant[] {
    return absenceRequests.flatMap((x) => {
      const days = DateTimeLuxonHelpers.getDaysBetween(x.dateFrom, x.dateTo);
      return days.map((day) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        tenant: x.absentFor!,
        day,
      }));
    });
  }

  groupByDate(absenceDays: AbsentDayForTenant[]) {
    return DateTimeLuxonHelpers.groupArrayByDate(absenceDays, (x) => x.day);
  }

  static isRequestValidForPolicy(
    absenceRequest: { dateFrom: DateTime; dateTo: DateTime },
    absencePolicy: IAbsencePolicyDTO,
  ) {
    if (absenceRequest.dateFrom < absencePolicy.validFrom) return false;
    if (!absencePolicy.residualExpirationDate) return true;

    //TODO2 residual can be null
    if (absenceRequest.dateTo > absencePolicy.residualExpirationDate) return false;

    if (absenceRequest.dateTo > absencePolicy.validTo) return false;

    return true;
  }

  static isBankHoliday(day: DateTime, holidays: IHolidays[]) {
    const recurringDays = holidays.filter((x) => x.isRecurring).map((x) => x.date);
    const uniqueHolidays = holidays.filter((x) => !x.isRecurring).map((x) => x.date);
    return (
      DateTimeLuxonHelpers.containsDay(day, recurringDays) || DateTimeLuxonHelpers.containsDate(day, uniqueHolidays)
    );
  }

  static removeNonWorkingDaysFromAbsenceDays(days: DateTime[], holidays: IHolidays[]) {
    return days.filter((d) => !DateTimeLuxonHelpers.isWeekend(d) && !this.isBankHoliday(d, holidays));
  }

  static getAbsenceDays(absenceRequests: { dateFrom: DateTime; dateTo: DateTime }[]) {
    return absenceRequests.flatMap((x) => DateTimeLuxonHelpers.getDaysBetween(x.dateFrom, x.dateTo));
  }

  static isOverlapping(
    currentRequest: { id?: number; dateFrom: DateTime; dateTo: DateTime },
    allAbsence: { id: number; dateFrom: DateTime; dateTo: DateTime }[],
  ): boolean {
    if (allAbsence.length == 0) return false;
    const absenceDays = this.getAbsenceDays(allAbsence.filter((x) => x.id != currentRequest.id));
    const currentAbsenceDays = this.getAbsenceDays([currentRequest]);
    for (const d of currentAbsenceDays) {
      if (DateTimeLuxonHelpers.containsDay(d, absenceDays)) return true;
    }
    return false;
  }

  getAbsenceIntervals(absenceRequests: { dateFrom: DateTime; dateTo: DateTime }[]) {
    return absenceRequests.map((x) => DateTimeLuxonHelpers.getDateInterval(x.dateFrom, x.dateTo));
  }

  static getTenantsAbsent(absenceRequests: IAbsenceRequestVm[], day: DateTime) {
    return filterMap(absenceRequests, (a) => {
      const iv = DateTimeLuxonHelpers.getDateInterval(a.dateFrom, a.dateTo);
      return iv.contains(day) ? a.absentForId : undefined;
    });
  }

  static checkIfDayIsBankHoliday(day: DateTime, holidays: IHolidays[]) {
    return this.isBankHoliday(day, holidays);
  }

  mapToExport(x: IAbsenceRequestVm): ExportType {
    return {
      id: x.id,
      absentForId: x.absentFor?.id ?? "",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      absentFor_fullName: x.absentFor?.fullName,
      description: x.description,
      createdAt: x.createdAt,
      dateFrom: x.dateFrom,
      dateTo: x.dateTo,
      absencePolicyId: x.absencePolicyId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      absencePolicy_title: x.absencePolicy?.title,
    };
  }

  static canSetTotalApproval(item: IAbsenceRequestVm, meAsUser: MeAsUser) {
    if (!item.absenceRequestApprovals) return undefined;
    if (item.absenceRequestApprovals.find((x) => x.approverId == meAsUser.tenant.id)) return true;
    return false;
  }

  static getEmployeeAbsenceDataPerPolicy(requests: IAbsenceRequestVm[], holidayList: IHolidays[]) {
    const data: TenantApprovedAbsenceRequestsDTO[] = [];
    const uniquePolicies = uniqBy(requests, (x) => x.absencePolicyId).filterMap((x) => x.absencePolicy);
    const uniqueTenants = uniqBy(requests, (x) => x.absentForId).filterMap((x) => x.absentFor);
    uniqueTenants.forEach((t) => {
      uniquePolicies.forEach((p) => {
        const approvedRequests = requests.filter(
          (x) => x.absencePolicyId == p.id && x.absentForId == t.id && x.status == PbdStatus.Approved,
        );
        const totalDays = AbsenceRequestService.getAbsenceDays(approvedRequests).length;
        const netDays = AbsenceRequestService.removeNonWorkingDaysFromAbsenceDays(
          AbsenceRequestService.getAbsenceDays(approvedRequests),
          holidayList,
        ).length;
        const absenceDaysPerPolicy: AbsenceDaysPerPolicyDTO = {
          policy: p,
          netDays: netDays,
          totalDays: totalDays,
        };
        const existing = data.find((x) => x.tenant.id == t.id);
        if (existing) {
          existing.absenceDaysPerPolicies.push(absenceDaysPerPolicy);
        } else {
          const item: TenantApprovedAbsenceRequestsDTO = {
            tenant: t,
            absenceDaysPerPolicies: [absenceDaysPerPolicy],
          };
          data.push(item);
        }
      });
    });
    return data;
  }

  static showValidation(item?: { dateFrom?: DateTime; dateTo?: DateTime }, absencePolicy?: IAbsencePolicyDTO): boolean {
    if (!item?.dateFrom || !item.dateTo) return false;
    if (!absencePolicy) return false;
    const maxRange = item.dateTo.diff(item.dateFrom, "years");
    if (maxRange.years > 1) return false;
    return true;
  }

  static validateAbsenceRequest(
    absenceRequestsForSelectedTenant: IAbsenceRequestVm[],
    currentAbsenceRequest: IAbsenceRequestCreateDTO & {
      id?: number | undefined;
    },
    absencePolicy: IAbsencePolicyDTO,
    holidayList: Holidays[],
  ) {
    const absenceRequestsForTenant = absenceRequestsForSelectedTenant.filter(
      (x) => x.absencePolicyId == absencePolicy.id,
    );
    const currentAbsenceRequestDays = AbsenceRequestService.getAbsenceDays([currentAbsenceRequest]);
    const currentAbsenceRequestNetDays = AbsenceRequestService.removeNonWorkingDaysFromAbsenceDays(
      currentAbsenceRequestDays,
      holidayList,
    );

    const absenceDaysTotalNet = absenceRequestsForTenant.reduce((pv, cv) => pv + cv.netDays, 0);
    const isOverlapping = AbsenceRequestService.isOverlapping(currentAbsenceRequest, absenceRequestsForTenant);

    const absenceDaysIncludingCurrent = absenceDaysTotalNet + currentAbsenceRequestNetDays.length;
    const sufficientDaysLeftCount: number | undefined =
      absencePolicy.availableDays == undefined ? undefined : absencePolicy.availableDays - absenceDaysIncludingCurrent;
    const sufficientDaysLeft: boolean = sufficientDaysLeftCount == undefined ? true : sufficientDaysLeftCount >= 0;
    const absencePolicyValid = AbsenceRequestService.isRequestValidForPolicy(currentAbsenceRequest, absencePolicy);
    return {
      /**Is current request overlapping any other requests for this tenant */
      isOverlapping,
      /**Days left for current policy */
      sufficientDaysLeftCount,
      sufficientDaysLeft,
      /** Current request valid for given policy */
      absencePolicyValid,
      absenceDaysTotalNet,
      currentAbsenceRequestNetDays,
    };
  }

  async copy(item: IAbsenceRequestVm): Promise<AbsenceRequestDTO> {
    const itemToCreate = new AbsenceRequestCreateDTO({
      isAllDay: item.isAllDay,
      absencePolicyId: item.absencePolicyId,
      absentForId: item.absentForId,
      dateFrom: item.dateFrom,
      dateTo: item.dateTo,
      description: item.description,
    });

    const resp = await this.absenceRequestApi.create(itemToCreate);

    if (item.absenceRequestApprovals && item.absenceRequestApprovals.length > 0) {
      const dto = new ConnectApproverDTO({ tenantIds: item.absenceRequestApprovals.map((x) => x.approverId) });
      await this.absenceRequestApi.addApprover(resp.id, dto);
    }

    return resp;
  }
}
