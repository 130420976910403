import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import { useAPIServices } from "../../../pbdServices/services/service-context";

import CustomFieldValueRenderer from "../../shared/components/customFields/customFieldValueRenderer";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import FormHeaderPrintout from "../components/formHeaderPrintout";

function PrintFormAnswerPage() {
  const { id } = useTypedParams(["id"]);
  const { customFormAnswerService } = useAPIServices();
  const { t } = useTranslation();

  const { data } = useSWR([`/api/customFormAnswers/`, id], () => customFormAnswerService.getById(Number(id)));

  React.useEffect(() => {
    function getData() {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
    if (data) {
      getData();
    }
  }, [data]);

  if (!data) return null;

  return (
    <React.Fragment>
      <FormHeaderPrintout answer={data} form={data.customForm} />
      {data.formFields.map((x) => {
        const field = data.customForm.customFields.find((t) => t.id == x.id);
        if (!field) {
          return <>{t("Missing field")}</>;
        }
        return (
          <React.Fragment key={x.id}>
            <dt>{field.name}</dt>
            <dd>
              <CustomFieldValueRenderer customField={field} customFieldContent={x} />
            </dd>
            <hr />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

export default PrintFormAnswerPage;
