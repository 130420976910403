import { DateTime } from "luxon";

import {
  AbsencePolicyDTO,
  AbsenceTypeDTO,
  CapabilitiesDTO,
  EntityKey,
  PbdStatus,
} from "../../../../generatedCode/pbd-core/pbd-core-api";

export const AnonymousAbsencePolicy = new AbsencePolicyDTO({
  id: -1,
  validFrom: DateTime.now(),
  validTo: DateTime.now(),
  title: "Private",
  departmentPositions: [],
  absencePolicyApprovers: [],
  createdAt: DateTime.now(),
  createdById: 1,
  isDeleted: false,
  capabilities: new CapabilitiesDTO(),
  absenceType: new AbsenceTypeDTO({
    title: "Private",
    color: "grey",
    id: -1,
    isDeleted: false,
    createdById: 1,
    createdAt: DateTime.now(),
    entityKey: EntityKey.AbsenceType,
  }),
  status: PbdStatus.NotAttended,
});
