import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { useAPISubmitter2 } from "../../../../../pbdServices/services/Api/api-submitter";
import { QualificationMatrixRoutePaths } from "../../../../qualificationMatrix/qualificationMatrixRoutePaths";
import { QualificationTable } from "../../../../qualifications/components/qualificationTable";
import EnhancedSubmitButton from "../../buttons/enhancedSubmitButton";
import { ConnectModalProps } from "../generic/connect-modal-props";

type IProps = ConnectModalProps;

function ConnectQualificationsModal(props: IProps) {
  const { modal, toggle, connectedElements, canSubmit = false, onSubmit, onSuccess } = props;
  const { t } = useTranslation();
  const { qualificationsApi } = useAPIs();
  const [selected, setSelected] = React.useState<number[]>([]);
  const navigate = useNavigate();
  const { data } = useSWRImmutable(modal ? "api/qualifications" : null, () => qualificationsApi.getAll());

  const filtered = React.useMemo(() => {
    if (data && connectedElements) {
      const rawDataExcludeConnected = data.filter((x) => !connectedElements.map((y) => y.id).includes(x.id));
      return rawDataExcludeConnected;
    } else {
      return undefined;
    }
  }, [connectedElements, data]);

  const { submit: handleSubmit, isSubmitting } = useAPISubmitter2<number[]>(
    (val) => onSubmit(val),
    [],
    () => {
      setSelected([]);
      toggle();
      onSuccess?.();
    },
  );

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggle} className="modal-lg">
        <ModalHeader toggle={toggle}>{t("Qualifications")}</ModalHeader>
        <ModalBody>
          <QualificationTable
            tableRows={filtered}
            setSelected={(ids) => setSelected(ids.map((x) => Number(x.id)))}
            isAlwaysSelectable
            onCreateClick={() => navigate(QualificationMatrixRoutePaths.CreatePageQualification)}
            includeTitleLink={false}
          />
        </ModalBody>
        <ModalFooter>
          <EnhancedSubmitButton
            onClick={() => handleSubmit(selected)}
            submitting={isSubmitting}
            disabled={selected.length == 0}
            canSubmit={canSubmit}
          />
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default ConnectQualificationsModal;
