import { useTranslation } from "react-i18next";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { DevRoutePaths } from "../devRoutePaths";

const SidebarDev = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    new SidebarItem({
      title: t("Definitions"),
      href: DevRoutePaths.EntityDefinitions,
    }),
    new SidebarItem({
      title: t("AppSettings"),
      href: DevRoutePaths.AppSettings,
    }),
  ];

  return <SidebarNew app={PbdModule.Dev} items={links} />;
};

export default SidebarDev;
