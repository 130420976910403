import React from "react";
import { useTranslation } from "react-i18next";

import { ITenantMinDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import AvatarSpanWithName from "../tenants/avatarSpanWithName";
import FormattedUserInput from "../text/formattedUserInput";

interface IItemProps {
  title: string;
  description?: string;
  responsible?: ITenantMinDTO;
}

interface IProps {
  item: IItemProps;
  entriesToHide?: (keyof IItemProps)[];
  /**This can used to extend the end of the list */
  children?: React.ReactNode;
  includeTitle?: boolean;
}

function DefaultDescriptionList(props: IProps) {
  const { t } = useTranslation();
  const { item, entriesToHide = [], includeTitle } = props;
  return (
    <dl>
      {includeTitle && (
        <>
          <dt>{t("Title")}</dt>
          <dd>{item.title}</dd>
        </>
      )}
      {!entriesToHide.includes("responsible") && (
        <>
          <dt>{t("Responsible")}</dt>
          <dd>{item.responsible && <AvatarSpanWithName tenant={item.responsible} />}</dd>
        </>
      )}
      {!entriesToHide.includes("description") && (
        <>
          <dt>{t("Description")}</dt>
          <dd>{item.description && <FormattedUserInput content={item.description} />}</dd>
        </>
      )}
      {props.children}
    </dl>
  );
}
export default DefaultDescriptionList;
