import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { EmailCreateDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { SendNotificationForm } from "../components/modals/notificationForm";
import SendNotificationModal from "../components/modals/sendNotificationModal";
import { toastSuccess } from "../components/toasts/toastSuccess";
import { useToggle } from "../hooks/useToggle";

type SubmitHandler = (values: SendNotificationForm) => Promise<void>;

type SuccessHandler = () => void;

interface NotificationContextInterface {
  notificationTemplate?: SendNotificationForm;
  /**
   * @param handler if handler is undefined the normal emailsApi will be used.
   */
  addNotification: (
    template: SendNotificationForm,
    handler?: SubmitHandler,
    onSuccess?: SuccessHandler,
  ) => Promise<void>;
  removeNotification: () => void;
}

export function createCtx<NotificationContextInterface>() {
  const ctx = React.createContext<NotificationContextInterface | undefined>(undefined);
  function useCtx() {
    const c = React.useContext(ctx);
    if (!c) throw new Error("useCtx from NotificationContext must be inside a Provider with a value");
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}

const [useNotificationContext, CtxProvider] = createCtx<NotificationContextInterface>();

function NotificationProvider({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const [notificationTemplate, setNotificationTemplate] = useState<SendNotificationForm>();
  const [notificationModal, toggleNotificationModal] = useToggle();
  const { emailsApi } = useAPIs();
  const resolver = useRef<SubmitHandler>();
  const successResolver = useRef<SuccessHandler>();

  const removeNotification = () => {
    setNotificationTemplate(undefined);
  };

  const addNotification = (template: SendNotificationForm, handler?: SubmitHandler, onSuccess?: SuccessHandler) => {
    setNotificationTemplate(template);
    // setSubmitHandler(handler);

    toggleNotificationModal();
    return new Promise<void>(function (resolve) {
      resolver.current = handler;
      successResolver.current = onSuccess;
    });
  };

  const handleSubmit = async (values: SendNotificationForm) => {
    if (resolver.current) {
      await resolver.current(values);
    } else {
      if (!values.to) throw Error("The recipient is not defined");
      await emailsApi.create(
        new EmailCreateDTO({
          to: values.to,
          subject: values.subject,
          body: values.message,
        }),
      );
    }
    removeNotification();
  };

  const handleSuccess = () => {
    toggleNotificationModal();
    if (successResolver.current) {
      successResolver.current();
    } else {
      toastSuccess(t("Message submitted."));
    }
  };

  const contextValue: NotificationContextInterface = {
    notificationTemplate,
    addNotification: useCallback(
      (template: SendNotificationForm, handler?: SubmitHandler, onSuccess?: SuccessHandler) =>
        addNotification(template, handler, onSuccess),
      [],
    ),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return (
    <CtxProvider value={contextValue}>
      {children}
      <SendNotificationModal
        showModal={notificationModal}
        handleToggle={toggleNotificationModal}
        onSubmit={handleSubmit}
        defaultValues={notificationTemplate}
        onSuccess={handleSuccess}
      />
    </CtxProvider>
  );
}

export { NotificationProvider, useNotificationContext };
