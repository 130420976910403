import { ITenantDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

export class TenantKpis {
  constructor(items: ITenantDTO[], totalUrl: string) {
    this.total = items;
    this.totalUrl = totalUrl;
  }
  total: ITenantDTO[];
  totalUrl: string;
}
