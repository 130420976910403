import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { QualityMonitorRoutePaths } from "./qualityMonitorRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.QualityMonitor_Standard, PbdRoles.QualityMonitor_ModuleAdmin],
};

export const dashboardLazyRoutes: RouteObject[] = [
  {
    path: QualityMonitorRoutePaths.All,
    Component: lazy(() => import("./pages/overviewQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Employees,
    Component: lazy(() => import("./pages/employeesQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.ProjectsAndTasks,
    Component: lazy(() => import("./pages/projectsAndTaskQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Trainings,
    Component: lazy(() => import("./pages/trainingsQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Claims,
    Component: lazy(() => import("./pages/claimsQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.EmployeeIdeas,
    Component: lazy(() => import("./pages/employeeIdeasQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.ArticlesDashboard,
    Component: lazy(() => import("./pages/articlesQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Crm,
    Component: lazy(() => import("./pages/crmQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Goals,
    Component: lazy(() => import("./pages/goalsQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Audits,
    Component: lazy(() => import("./pages/auditsQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Costs,
    Component: lazy(() => import("./pages/costsQmonitor")),
  },
  {
    path: QualityMonitorRoutePaths.Maintenance,
    Component: lazy(() => import("./pages/maintenanceQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.Opportunities,
    Component: lazy(() => import("./pages/opportunitiesQmonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.CustomForms,
    Component: lazy(() => import("./pages/customFormsQMonitor")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.IndexPageDashboard,
    Component: lazy(() => import("../dashboards/pages/indexPageDashboard")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.DetailsPageDashboard,
    Component: lazy(() => import("../dashboards/pages/detailsPageDashboard")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.CreatePageDashboard,
    Component: lazy(() => import("../dashboards/pages/createPageDashboard")),
    ...defaultRouteParams,
  },
  {
    path: QualityMonitorRoutePaths.CreateChartPage,
    Component: lazy(() => import("./pages/createPageDashboardItem")),
    ...defaultRouteParams,
  },
];
