import React from "react";
import { Overlay } from "react-bootstrap";
import { Link } from "react-router-dom";

import { IAbsenceTypeDTO, ICategoryMinDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import CategoryHoverCard from "../hoverCards/categoryHoverCard";
import { useHoverActionsHook } from "../hoverCards/useHoverActionsHook";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  item: ICategoryMinDTO | IAbsenceTypeDTO;
  linkTo?: string;
  onClick?: () => void;
  title?: string;
}

function CategorySpan(props: IProps) {
  const { item, onClick, linkTo, title } = props;

  let clickableStyle: React.CSSProperties = {};
  if (onClick != null) {
    clickableStyle = {
      cursor: "pointer",
    };
  }
  const { show, setShow, handleMouseEnter, handleOnMouseLeave, debouncedHandleMouseLeave, target } =
    useHoverActionsHook();

  return (
    <>
      <Overlay target={target.current} show={show}>
        {(cardProps) => (
          <CategoryHoverCard
            category={item}
            id="popover-contained"
            onMouseLeave={() => setShow(false)}
            onMouseEnter={() => debouncedHandleMouseLeave.cancel()}
            {...cardProps}
          />
        )}
      </Overlay>
      <div
        className={`avatarWithNameContainer${onClick != null ? " hoverInput" : ""}`}
        onClick={onClick}
        style={clickableStyle}
        title={title}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        ref={target}
      >
        {linkTo == null ? (
          <span>
            {item.color && <qmBaseIcons.Circle className="me-1" style={{ color: item.color }} />} {item.title}
          </span>
        ) : (
          <span>
            <Link to={linkTo}>
              <qmBaseIcons.Circle style={{ color: item.color ? item.color : "blue" }} /> {item.title}
            </Link>
          </span>
        )}
        {onClick && <qmBaseIcons.PencilAlt className="ms-1 me-1" style={{ color: "white" }} />}
      </div>
    </>
  );
}

export default CategorySpan;
