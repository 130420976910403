import { Field, Form, Formik } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { ConversionFactorBase, IUnitCreateDTO, IUnitDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import { requiredTitleStringSchema } from "../../../../services/validation/stringSchemas";
import CancelButton from "../../../shared/components/buttons/cancelButton";
import FormikDebugInfo from "../../../shared/components/forms/formik/formikDebugInfo";
import { FormikNumberInput } from "../../../shared/components/forms/formik/formikNumberInput";
import { FormikSelectInput } from "../../../shared/components/forms/formik/formikSelectInput";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import FormikValidationSummary from "../../../shared/components/forms/formik/formikValidationSummary";

const getSchema = (t: TFunction) => {
  const ValidationSchema: yup.ObjectSchema<IUnitCreateDTO> = yup.object({
    title: requiredTitleStringSchema(t),
    conversionFactor: yup.number().required(),
    conversionFactorBase: yup.mixed<ConversionFactorBase>().oneOf(Object.values(ConversionFactorBase)).required(),
  });
  return ValidationSchema;
};

interface IProps {
  itemToUpdate?: IUnitDTO;
  onSubmit: (values: IUnitCreateDTO) => Promise<void>;
  onCancel: () => void;
}

const UnitsForm: React.FC<IProps> = (props) => {
  const { itemToUpdate, onSubmit, onCancel } = props;
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IUnitCreateDTO>((values) => onSubmit(values), [onSubmit]);

  const initialValues: IUnitCreateDTO = {
    title: itemToUpdate?.title ?? "",
    conversionFactor: itemToUpdate?.conversionFactor ?? 0,
    conversionFactorBase: itemToUpdate?.conversionFactorBase ?? ConversionFactorBase.None,
  };

  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getSchema(t)}>
        {(formikBag) => (
          <Form>
            <FormikDebugInfo formikBag={formikBag} />
            <FormGroup>
              <Label for="title">{t("Title")}</Label>
              <Field name="title" component={FormikTextInput} />
            </FormGroup>
            <FormGroup>
              <Label for="conversionFactor">{t("Conversion factor")}</Label>
              <Field name="conversionFactor" component={FormikNumberInput} />
            </FormGroup>
            <FormGroup>
              <Label for="conversionFactorBase">{t("Base")}</Label>
              <Field component={FormikSelectInput} id="conversionFactorBase" name="conversionFactorBase">
                {Object.keys(ConversionFactorBase).map((x) => (
                  <option key={x} value={x} defaultValue={formikBag.initialValues.conversionFactorBase}>
                    {t(x)}
                  </option>
                ))}
              </Field>
            </FormGroup>
            <FormGroup>
              <CancelButton onClick={onCancel} />
              <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                {t("Save")}
              </Button>
            </FormGroup>
            <FormikValidationSummary formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default UnitsForm;
