import { sortBy } from "lodash";
import { Duration } from "luxon";

import {
  BoundType,
  EntityKey,
  EvaluationDTO,
  ICustomField,
  IEvaluationDTO,
  IOpportunitiesControllerClient,
  IOpportunityCategoriesControllerClient,
  IOpportunityCategoryBoundary,
  IOpportunityCategoryDTO,
  IOpportunityDTO,
  IOpportunityEvaluationsControllerClient,
  IToDoDTO,
  PbdStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { ValidationResultDescriber } from "../../Models/Shared/validation-result-describer";
import { WithWarnings } from "../../Models/Shared/with-warnings";
import { BaseExportService } from "../Base/BaseExportService";
import CustomFieldService from "../CustomFields/customFieldService";
import { ExportType } from "../Export/exportService";
import ToDoService from "../ToDos/todoService";
import { OpportunityKpis } from "./models/opportunity-kpis";
import { IOpportunityVm } from "./models/opportunity-vm";
import { OpportunityQueryParameters } from "./models/query-parameter";

export type LimitWarningType = "Over" | "Below";

export interface LimitWarningProps {
  type: LimitWarningType;
  message: string;
}

export default class OpportunityService extends BaseExportService<IOpportunityDTO> implements IPrerequisitesService {
  private _opportunitiesApi: IOpportunitiesControllerClient;
  private _opportunitiesCategoriesApi: IOpportunityCategoriesControllerClient;
  private _opportunityEvaluationsApi: IOpportunityEvaluationsControllerClient;
  constructor(
    opportunitiesAPi: IOpportunitiesControllerClient,
    opportunitiesCategoriesApi: IOpportunityCategoriesControllerClient,
    opportunityEvaluationsApi: IOpportunityEvaluationsControllerClient,
  ) {
    super("Chances");
    this._opportunitiesApi = opportunitiesAPi;
    this._opportunitiesCategoriesApi = opportunitiesCategoriesApi;
    this._opportunityEvaluationsApi = opportunityEvaluationsApi;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const oportunitiyCategories = await this._opportunitiesCategoriesApi.getAllQuery({ take: 1 });
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "oportunitiyCategories",
        title: "Opportunity categories",
        status: oportunitiyCategories.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.OpportunityManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
    return resp;
  }
  async getAllQueryAsVm(
    query: OpportunityQueryParameters & { customFields?: string[] },
    customFields?: ICustomField[],
  ) {
    const opportunities = await this._opportunitiesApi
      .getAllQuery(query)
      .then((resp) => CustomFieldService.filterByCustomFields(resp, query.customFields));

    const evaluations = await this._opportunityEvaluationsApi.getAllQuery({
      opportunityId: opportunities.map((x) => x.id),
    });
    const vmList = OpportunityService.mapToVm(opportunities, evaluations);

    if (customFields) {
      OpportunityService.getWarnings(vmList, customFields);
    }
    return vmList;
  }

  mapToExport(x: IOpportunityDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      responsible: x.responsible?.fullName,
      category: x.category.title,
      createdAt: x.createdAt,
      rating: x.currentRating,
      //TODO: lastEvaluation: x.latestEvaluation.evaluatedAt,
      nextEvaluation: x.nextEvaluationAt,
    };
  }

  static mapRatingToOpportunities(items: IOpportunityDTO[], categories: IOpportunityCategoryDTO[]) {
    for (const element of items) {
      if (element.latestEvaluation) {
        const category = categories.find((x) => x.id == element.category.id);
        if (category?.formulaInfo?.maxResult) {
          const resultAsPercentOfMax = element.latestEvaluation.result / category.formulaInfo.maxResult;
          element.latestEvaluation.resultAsPercentOfMax = resultAsPercentOfMax;
          if (category.surveillanceIntervalTimeSpan) {
            element.nextEvaluationAt = element.latestEvaluation.evaluatedAt.plus(
              Duration.fromISO(category.surveillanceIntervalTimeSpan),
            );
          }

          element.deadline = element.nextEvaluationAt;
        }
      }
    }
  }

  static mapToVm(opportunities: IOpportunityDTO[], evaluations: EvaluationDTO[]): IOpportunityVm[] {
    const sortedEvaluations = sortBy(evaluations, (x) => x.evaluatedAt);

    return opportunities.map((opportunity) => {
      const evaluationsForOpportunity = sortedEvaluations.filter((x) => x.opportunityId == opportunity.id);
      const nextEvaluationsAt = opportunity.deadline;
      return {
        ...opportunity,
        evaluations: evaluationsForOpportunity,
        nextEvaluationsAt,
        latestEvaluation:
          evaluationsForOpportunity.length > 0
            ? evaluationsForOpportunity[evaluationsForOpportunity.length - 1]
            : undefined,
      };
    });
  }

  static getKpis(all: IOpportunityDTO[], connectedTodos: IToDoDTO[], evaluations: IEvaluationDTO[], totalUrl?: string) {
    const kpis = new OpportunityKpis(all, totalUrl);
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    kpis.evaluations = evaluations;
    return kpis;
  }

  static getWarning(item: WithWarnings<IOpportunityVm>, customFields: ICustomField[]) {
    item.warnings = [];
    if (item.deadline && DateTimeLuxonHelpers.inPast(item.deadline)) {
      item.warnings.push(ValidationResultDescriber.deadlineExpired());
    }
    const requiredCustomFields = customFields.filter((x) => x.isRequired);
    for (const element of requiredCustomFields) {
      if (!item.customFields?.find((x) => x.id == element.id)) {
        item.warnings.push(ValidationResultDescriber.requiredCustomFieldMissing());
        break;
      }
    }
  }

  static getWarnings(array: WithWarnings<IOpportunityVm>[], customFields: ICustomField[]) {
    for (const element of array) {
      this.getWarning(element, customFields);
    }
  }

  static findBestBound(boundaries: IOpportunityCategoryBoundary[], type: BoundType) {
    // check if there is any element
    if (boundaries.length == 0) {
      return undefined;
    }

    // initialize result
    let result: IOpportunityCategoryBoundary | undefined;

    // loop through
    for (const boundary of boundaries) {
      // assign boundary to result if result is undefined and continue to next iteration
      if (!result) {
        result = boundary;
        continue;
      }

      // assign boundary to result if the current result is lower than boundary (upper)
      if (type == BoundType.UpperBound && boundary.boundaryValue > result.boundaryValue) {
        result = boundary;
        continue;
      }

      // assign boundary to result if the current result is higher than boundary (lower)
      if (type == BoundType.LowerBound && boundary.boundaryValue < result.boundaryValue) {
        result = boundary;
      }
    }
    return result;
  }

  static getLimitWarning(category: IOpportunityCategoryDTO, latestEvaluation?: IEvaluationDTO) {
    // check if boundaries has at least an element and latest evaluation not null
    if (!category.boundaries || category.boundaries.length == 0 || !latestEvaluation) {
      return undefined;
    }

    // split the boundary into two types : lower and upper bound
    const lowerBoundaries = category.boundaries.filter((x) => x.type == BoundType.LowerBound);
    const upperBoundaries = category.boundaries.filter((x) => x.type == BoundType.UpperBound);

    // find any passed boundary to the latest evaluation
    const foundPassedLowerBoundary = lowerBoundaries.find((x) => latestEvaluation.result <= x.boundaryValue);
    const foundPassedUpperBoundary = upperBoundaries.find((x) => latestEvaluation.result >= x.boundaryValue);

    // find global maximum and minimum
    const bestLowerBoundary = this.findBestBound(lowerBoundaries, BoundType.LowerBound);
    const bestUpperBoundary = this.findBestBound(upperBoundaries, BoundType.UpperBound);

    // check if latest evaluation passed any boundary (lower or upper bound)
    const isPassedLowerBoundary = bestLowerBoundary && latestEvaluation.result <= bestLowerBoundary.boundaryValue;
    const isPassedUpperBoundary = bestUpperBoundary && latestEvaluation.result >= bestUpperBoundary.boundaryValue;

    // return based on priority
    // priority : global min or max
    // if no global min or max passed, return the found boundary
    // otherwise nothing should be passed
    if (isPassedLowerBoundary) {
      return bestLowerBoundary;
    }
    if (isPassedUpperBoundary) {
      return bestUpperBoundary;
    }
    if (foundPassedLowerBoundary) {
      return foundPassedLowerBoundary;
    }

    if (foundPassedUpperBoundary) {
      return foundPassedUpperBoundary;
    }

    return undefined;
  }

  static get availableFilters() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.Category,
      SearchFilterTypes.CustomField,
      SearchFilterTypes.OpportunityType,
    ];
  }

  static get getConnections() {
    return [
      new AvailableConnection(EntityKey.ToDo),
      new AvailableConnection(EntityKey.Goal),
      new AvailableConnection(EntityKey.Opportunity),
    ];
  }
}
