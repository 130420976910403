import { Table, flexRender } from "@tanstack/react-table";

import { qmBaseIcons } from "../../icons/qmBaseIcons";
import { CustomRowData } from "./reactstrap-table-props";

interface IProps<T extends CustomRowData> {
  table: Table<T>;
}

export function TableHeadComponent<T extends CustomRowData>(props: IProps<T>) {
  const { table } = props;
  return (
    <thead>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <th key={header.id} colSpan={header.colSpan} className={header.column.columnDef.meta?.className}>
                {header.isPlaceholder ? null : (
                  <div
                    className={header.column.getCanSort() ? "cursor-pointer select-none text-nowrap" : ""}
                    onClick={header.column.getToggleSortingHandler()}
                    style={{ cursor: header.column.getCanSort() ? "pointer" : undefined }}
                    aria-hidden="true"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: <qmBaseIcons.SortUp className="ms-1 text-info" />,
                      desc: <qmBaseIcons.SortDown className="ms-1 text-info" />,
                    }[header.column.getIsSorted() as string] ?? null}
                    {!header.column.getIsSorted() && header.column.getCanSort() && (
                      <qmBaseIcons.Sort className="ms-1 text-body-tertiary" />
                    )}
                  </div>
                )}
              </th>
            );
          })}
        </tr>
      ))}
    </thead>
  );
}
