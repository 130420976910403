import _ from "lodash";

import {
  IInventoryInspectionDTO,
  IInventoryInspectionsConnectedDTO,
  IInventoryItemDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { MaintenanceManagementRoutePaths } from "../../../ClientApp/maintenanceManagement/maintenanceManagementRoutePaths";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import RestUtilities from "../../../services/restClients/restUtilities";
import { BaseTableEntityProps } from "../../Models/BaseClasses/BaseTableEntityProps";
import { KeyValue } from "../../Models/Shared/KeyValue";
import { ValidationResult } from "../../Models/Shared/validation-result";
import { ValidationResultDescriber } from "../../Models/Shared/validation-result-describer";

export default class InventoryInspectionService {
  static validateData(items: IInventoryInspectionDTO[]) {
    return items.map((item) => {
      const errors = [];
      if (item.isRecurring && !item.monitoringInterval?.timeSpanISO) {
        errors.push(new KeyValue("MonitoringInterval", "Monitoring interval timespan must not be null"));
      }
      if (item.useWarningTime && !item.warningTimeInterval?.timeSpanISO) {
        errors.push(new KeyValue("WarningInterval", "Warning interval timespan must not be null"));
      }

      const hasErrors = errors.length > 0 ? () => true : () => false;
      return {
        ...item,
        errors,
        hasErrors,
      };
    });
  }

  static mapToTableProps(data: IInventoryInspectionDTO[]): BaseTableEntityProps[] {
    return data.map(
      (x) => new BaseTableEntityProps(x.id.toString(), x.title ?? "", SettingsRoutePaths.EditPageInventoryInspections),
    );
  }

  static generateInspectionUrl(selected: IInventoryInspectionsConnectedDTO[]) {
    const data = {
      inventoryItemId: Array.from(_.uniq(selected.map((x) => x.inventoryItemId))),
      inventoryInspectionId: Array.from(_.uniq(selected.map((x) => x.inventoryInspectionId))),
    };
    return MaintenanceManagementRoutePaths.CreatePageInspectionsDone + RestUtilities.getQueryString(data);
  }

  static isInspectionPossible(
    inspection?: IInventoryInspectionDTO[],
    inventoryItem?: IInventoryItemDTO[],
  ): { isInspectionPossible: boolean; errors: ValidationResult[] } {
    const errors: ValidationResult[] = [];
    if (inspection && inspection.length > 1) {
      errors.push(ValidationResultDescriber.mixedInspections());
    }
    if (!inventoryItem) errors.push(ValidationResultDescriber.missingInventoryItem());
    if (!inspection) errors.push(ValidationResultDescriber.missingInspection());

    return { errors, isInspectionPossible: errors.length == 0 };
  }
}
