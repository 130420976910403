import { ErrorMessage, FieldHookConfig, useField } from "formik";
import { upperFirst } from "lodash";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { IBaseFormElementProps } from "./baseFormElementProps";

export function FormikInputGroupWrapper(
  props: FieldHookConfig<string> & IBaseFormElementProps & { children: React.ReactNode },
) {
  const { formText, label, id, type, children } = props;
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      <Form.Label>{label ?? t(upperFirst(props.name))}</Form.Label>
      {children}
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
