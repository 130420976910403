import React from "react";
import useSWRImmutable from "swr/immutable";

import { MimeType, UploadLimitationDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

export const useUploadLimitationsHook = (types?: MimeType[]) => {
  const { appSettingsApi } = useAPIs();
  const { data, error } = useSWRImmutable("/api/appSettings/uploadLimitations", () =>
    appSettingsApi.getUploadLimitations(),
  );

  // TODO: How can we implement this so the file picker for the user gets easier and exclude exe files. Or have a virus scan on the backend.
  const limitationsMapped = React.useMemo(() => {
    if (data && types) {
      const filteredExtensions = Object.entries(data.allowedExtensions)
        .filter((kv) => types.some((x) => kv[0].toLowerCase().startsWith(x.toLowerCase())))
        .map((x) => {
          return { key: x[0], value: x[1] };
        });
      const objNew: Record<string, string[]> = {};
      for (const element of filteredExtensions) {
        objNew[element.key] = element.value;
      }
      return new UploadLimitationDTO({ ...data, allowedExtensions: objNew });
    } else {
      return data;
    }
  }, [data]);

  return {
    limitations: limitationsMapped,
    isLoading: !error && !limitationsMapped,
    isError: error,
  };
};
