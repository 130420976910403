import React from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { MimeType } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";

import DropzoneComponent from "../../../shared/components/fileUpload/dropzoneComponent";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useApps } from "../../../shared/hooks/useApps";
import { usePrintLogo } from "../../../shared/hooks/usePrintLogo";
import { useTypedParams } from "../../../shared/hooks/useTypedParams";
import { useUploadLimitationsHook } from "../../../shared/hooks/useUploadLimitationsHook";

type PrintIds = "Navbar" | "Print";

function LogoPage() {
  const { id } = useTypedParams<string, PrintIds>(["id"]);
  const { t } = useTranslation();
  const { brandIcon } = useApps();
  const { productConfig } = useAppContext();
  const { printLogoUrl } = usePrintLogo();
  const { settingsApi, modulesApi } = useAPIs();
  const { settingsService } = useAPIServices();
  const { limitations } = useUploadLimitationsHook([MimeType.Image]);
  const [logoUrl, setLogoUrl] = React.useState("");

  React.useEffect(() => {
    function getData() {
      if (id == "Navbar" && brandIcon) {
        setLogoUrl(brandIcon.href);
      } else if (id == "Print" && printLogoUrl) {
        setLogoUrl(printLogoUrl);
      }
    }
    getData();
  }, [brandIcon, id, printLogoUrl]);

  const reloadPage = () => {
    window.location.reload();
  };

  const resetLogo = async (_id: PrintIds) => {
    if (_id == "Navbar") {
      await modulesApi.resetNavbarPicture();
    } else if (_id == "Print") {
      await settingsService.updateAppSettings({ key: "Global_PrintLogo", value: undefined });
    }
    reloadPage();
  };

  const onDrop = React.useCallback(async function (acceptedFiles: File[]) {
    if (id == "Navbar") {
      return modulesApi.uploadLogo(
        acceptedFiles.map((x) => {
          return { fileName: x.name, data: x };
        })[0],
      );
    } else {
      return settingsApi.uploadPrintLogo(
        acceptedFiles.map((x) => {
          return { fileName: x.name, data: x };
        })[0],
      );
    }
  }, []);

  return (
    <>
      <h2>{id == "Navbar" ? t("Change logo in navigation bar") : t("Change logo in printed documents")}</h2>
      <hr />
      <Card>
        <Card.Body>
          <h3>{t("Current image")}</h3>
          <img style={{ height: "70px" }} src={logoUrl} alt="logo" />
          <hr />
          <h3>{t("Change image")}</h3>
          <dl>
            <dt>{t("Width")}</dt>
            <dd></dd>
            <dt>{t("Height")}</dt>
            <dd>max. 70 Pixel</dd>
          </dl>
          {limitations && <DropzoneComponent onDrop={onDrop} limitations={limitations} onSuccess={reloadPage} />}
          {productConfig.iconUrl != logoUrl && (
            <div>
              <hr />
              <h3>{t("Reset to default")}</h3>
              <img style={{ height: "70px" }} src={productConfig.iconUrl} alt="logo" />
              <Form.Group className="mb-3">
                <Button type="submit" variant="danger" onClick={() => resetLogo(id)}>
                  {t("Reset")}
                </Button>
              </Form.Group>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default LogoPage;
