import { Form, FormikProps, FormikValues } from "formik";

import CancelSubmitFormGroup from "../../buttons/cancelSubmitFormGroup";
import FormikDebugInfo from "./formikDebugInfo";
import FormikValidationSummary from "./formikValidationSummary";

interface IProps<T> {
  formikBag: FormikProps<T>;
  /**
   * Here we have place for our form.
   */
  children: React.ReactNode;
  /**
   *
   */
  canSubmitDirty?: boolean;
  hideSubmitButton?: boolean;
  submitLabel?: string;
  /**
   * If onCancel == undefined Cancel button will not be visible.
   */
  onCancel?: () => void;
  submitVariant?: "danger";
}

function FormikCustomForm<T extends FormikValues>(props: IProps<T>) {
  const { children, formikBag, canSubmitDirty, submitLabel, onCancel, hideSubmitButton, submitVariant } = props;

  return (
    <Form>
      <FormikDebugInfo formikBag={formikBag} />
      {children}
      <CancelSubmitFormGroup
        formikBag={formikBag}
        canSubmitDirty={canSubmitDirty}
        submitLabel={submitLabel}
        onCancel={onCancel}
        hideSubmitButton={hideSubmitButton}
        submitVariant={submitVariant}
      />
      <FormikValidationSummary formikBag={formikBag} />
    </Form>
  );
}
export default FormikCustomForm;
