import {
  EntityKey,
  IAuditDTO,
  IAuditRequirementDTO,
  IAuditTypesControllerClient,
  IAuditsControllerClient,
  ICustomFormDTO,
  IGoalDTO,
  ITenantAttendeeDTO,
  IToDoDTO,
  PbdStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { AuditRoutePaths } from "../../../ClientApp/audits/auditRoutePaths";
import { CrmRoutePaths } from "../../../ClientApp/crm/crmRoutePaths";
import { GoalRoutePaths } from "../../../ClientApp/goals/goalRoutePaths";
import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { AvailableConnection } from "../../../ClientApp/shared/components/connectionElements/generic/available-connection";
import { SearchFilterTypes } from "../../../ClientApp/shared/components/genericSearchFilter/availableSearchFilters";
import { TaskManagementRoutePaths } from "../../../ClientApp/taskManagement/taskManagementRoutePaths";
import { BaseExportService } from "../Base/BaseExportService";
import { ExportType } from "../Export/exportService";
import ToDoService from "../ToDos/todoService";
import { AuditKpis } from "./models/audit-kpis";

export interface IAuditWthConnections extends Omit<IAuditDTO, "connectedElements"> {
  link: string;
  connectedElements: IConnectedElements;
}

interface IConnection<T> {
  link: string;
  item: T;
}

interface IConnectedElements {
  requirements?: IAuditRequirementDTO[];
  todos: IConnection<IToDoDTO>[];
  goals: IConnection<IGoalDTO>[];
  forms: ICustomFormDTO[];
  attendees: IConnection<ITenantAttendeeDTO>[];
}

export class AuditService extends BaseExportService<IAuditDTO> implements IPrerequisitesService {
  auditTypesApi: IAuditTypesControllerClient;
  auditApi: IAuditsControllerClient;

  constructor(_auditTypesApi: IAuditTypesControllerClient, _auditApi: IAuditsControllerClient) {
    super("Audits");
    this.auditTypesApi = _auditTypesApi;
    this.auditApi = _auditApi;
  }

  private static buildLink(route: string, id: number) {
    return `${window.location.protocol}//${window.location.host}${route.replace(":id", id.toString())}`;
  }

  private static generateConnection<T extends { id: number }>(items: T[], route: string) {
    return items.map((x) => {
      const toReturn: IConnection<T> = {
        link: AuditService.buildLink(route, x.id),
        item: x,
      };
      return toReturn;
    });
  }

  async getByIdWithConnections(id: number, auditRequirements: IAuditRequirementDTO[]): Promise<IAuditWthConnections> {
    const [audit, auditGoals, auditTodos, auditForms, auditAttendees] = await Promise.all([
      this.auditApi.getById(id),
      this.auditApi.getConnectedGoals(id),
      this.auditApi.getConnectedToDos(id),
      this.auditApi.getConnectedForms(id),
      this.auditApi.getAttendees(id),
    ]);
    const auditType = await this.auditTypesApi.getById(audit.category.id);
    const requirements: IAuditRequirementDTO[] = [];
    auditType.auditRequirements?.forEach((r) => {
      requirements.push(r);
      const childRequirements = auditRequirements.filter((x) => x.parentAuditRequirementId == r.id);
      requirements.push(...childRequirements);
    });
    const item: IAuditWthConnections = {
      ...audit,
      link: AuditRoutePaths.EditPage.replace(":id", audit.id.toString()),
      connectedElements: {
        todos: AuditService.generateConnection(auditTodos, TaskManagementRoutePaths.EditTodoPage),
        forms: auditForms,
        goals: AuditService.generateConnection(auditGoals, GoalRoutePaths.EditPage),
        attendees: AuditService.generateConnection(auditAttendees, CrmRoutePaths.EditPageTenant),
        requirements,
      },
    };
    return item;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const types = await this.auditTypesApi.getAllQuery({ take: 1 });
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "types",
        title: "Audit categories",
        status: types.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.AuditManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
    return resp;
  }
  mapToExport(x: IAuditDTO): ExportType {
    return {
      id: x.id,
      title: x.title,
      responsible: x.responsible?.fullName,
      createdAt: x.createdAt,
      plannedAt: x.plannedAt,
      lastUpdatedAt: x.lastUpdatedAt,
    };
  }

  static getKpis(audits: IAuditDTO[], connectedTodos: IToDoDTO[], totalUrl?: string) {
    const kpis = new AuditKpis(audits, totalUrl);
    kpis.connectedTodos = ToDoService.getKpis(connectedTodos);
    return kpis;
  }

  static get availableFilter() {
    return [
      SearchFilterTypes.Responsible,
      SearchFilterTypes.Status,
      SearchFilterTypes.CreatedAt,
      SearchFilterTypes.Tags,
      SearchFilterTypes.Attendees,
      SearchFilterTypes.IsDeleted,
      SearchFilterTypes.PlannedAt,
      SearchFilterTypes.Category,
      SearchFilterTypes.CustomField,
    ];
  }

  static get getConnections() {
    return [
      new AvailableConnection(EntityKey.ToDo),
      new AvailableConnection(EntityKey.Goal),
      new AvailableConnection(EntityKey.Tenant, "default", undefined, { hideConnectionCard: true }),
      new AvailableConnection(EntityKey.CustomForm, "default", undefined, { hideConnectionCard: true }),
    ];
  }
}
