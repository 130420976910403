import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, FormGroup, Label, Table } from "reactstrap";
import * as yup from "yup";

import SettingsService from "../../../../services/Settings/settingsService";
import NoEntriesAlert from "../../../shared/components/alerts/noEntriesAlert";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import SettingsCardHeader from "../../components/settingsCardHeader";

const settingsService = new SettingsService();

const ValidationSchema = yup.object({
  title: yup.string().min(2).max(50).required(),
});

interface ConnectionCategory {
  title: string;
}

function IndexPageConnectionCategories() {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<string[]>([]);
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const confirm = useConfirmation();
  const { t } = useTranslation();

  const tryToDelete = (itemToDelete: string) => {
    confirm({ itemsToDelete: [{ id: itemToDelete }] }).then(() => submitDeleteRequest(itemToDelete));
  };

  React.useEffect(() => {
    async function getData() {
      const categories = await settingsService.getValueByKey<string[]>("ConnectionCategories");
      if (categories) {
        setData(categories);
      }
      setLoading(false);
    }
    getData();
  }, [refresh]);

  async function handleSubmit(values: ConnectionCategory) {
    const array = Array.from(data);
    array.push(values.title);
    const unique = [..._.uniq(array)];
    // setData(listNew);
    await settingsService.updateAppSettings({
      key: "ConnectionCategories",
      value: JSON.stringify(unique),
    });
    handleRefresh();
  }

  const submitDeleteRequest = async (itemToDelete: string) => {
    const copy = [...data];
    copy.splice(copy.indexOf(itemToDelete), 1);
    await settingsService.updateAppSettings({
      key: "ConnectionCategories",
      value: JSON.stringify(copy),
    });
    setData(copy);
  };

  const initialValues: ConnectionCategory = { title: "" };
  return (
    <Card>
      <SettingsCardHeader cardTitle={t("Connection categories")} />
      <CardBody>
        <Formik
          initialValues={initialValues}
          onSubmit={async function (values, actions) {
            console.log(values);
            const resp = await handleSubmit(values);
            // if (resp.success) {
            actions.setSubmitting(false);
            actions.resetForm();
            // } else {
            //     actions.setErrors(resp.errors);
            // }
          }}
          validationSchema={ValidationSchema}
        >
          {(formikBag) => (
            <Form>
              <FormGroup>
                <Label for="title">{t("Category")}</Label>
                <Field name="title" component={FormikTextInput} />
              </FormGroup>

              <FormGroup>
                <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
                  {t("Save")}
                </Button>
              </FormGroup>
            </Form>
          )}
        </Formik>
        {!loading && data.length == 0 && <NoEntriesAlert />}
        {data.length > 0 && (
          <Table>
            <tbody>
              {data.map((x) => (
                <tr key={x}>
                  <td>{x}</td>
                  <td>
                    <Button color="danger" onClick={() => tryToDelete(x)}>
                      <qmBaseIcons.Delete />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CardBody>
    </Card>
  );
}

export default IndexPageConnectionCategories;
