/* eslint-disable @typescript-eslint/naming-convention */
import { createQmIcon } from "./icon-base";

export const qmBaseIcons = {
  Delete: createQmIcon("fa-regular fa-trash-alt"),
  Export: createQmIcon("fas fa-file-csv"),
  Print: createQmIcon("fas fa-print"),
  ChangeResponsible: createQmIcon("fas fa-people-arrows"),
  LogInspection: createQmIcon("far fa-plus-square"),
  SetAccess: createQmIcon("fa-solid fa-key"),
  Key: createQmIcon("fa-solid fa-key"),
  Move: createQmIcon("fa-solid fa-random"),
  Cost: createQmIcon("fas fa-money-bill-wave"),
  PrescriptionBottle: createQmIcon("fa-solid fa-prescription-bottle"),
  Column: createQmIcon("fas fa-columns"),
  Plus: createQmIcon("fas fa-plus"),
  Minus: createQmIcon("fas fa-minus"),
  Undo: createQmIcon("fas fa-undo-alt"),
  Envelope: createQmIcon("fa-regular fa-envelope"),
  Microscope: createQmIcon("fas fa-microscope"),
  Alert: createQmIcon("fa-solid fa-triangle-exclamation"),
  AlertCircle: createQmIcon("fa-solid fa-exclamation-circle"),
  NormalDanger: createQmIcon("fa fa-fire"),
  Danger: createQmIcon("fa-solid fa-fire-flame-curved"),
  Info: createQmIcon("fa-solid fa-info"),
  InfoCircle: createQmIcon("fa-solid fa-circle-info"),
  Pencil: createQmIcon("fa-solid fa-pencil"),
  PencilAlt: createQmIcon("fa-solid fa-pencil-alt"),
  PenSquare: createQmIcon("fa-solid fa-pen-square"),
  PenToSquare: createQmIcon("fa-regular fa-pen-to-square"),
  ArrowRight: createQmIcon("fa-solid fa-arrow-right"),
  ArrowLeft: createQmIcon("fa-solid fa-arrow-left"),
  ArrowUp: createQmIcon("fa-solid fa-arrow-up"),
  TrendUp: createQmIcon("fa-solid fa-arrow-trend-up"),
  ArrowDown: createQmIcon("fa-solid fa-arrow-down"),
  ArrowUpDown: createQmIcon("fa-solid fa-arrows-up-down"),
  TrendDown: createQmIcon("fa-solid fa-arrow-trend-down"),
  FileUpload: createQmIcon("fa-solid fa-cloud-upload-alt"),
  Sort: createQmIcon("fa-solid fa-sort"),
  SortUp: createQmIcon("fa-solid fa-arrow-up-wide-short"),
  SortDown: createQmIcon("fa-solid fa-arrow-down-wide-short"),
  SortAlpha: createQmIcon("fa-solid fa-sort"),
  SortAlphaUp: createQmIcon("fa-solid fa-sort-up"),
  SortAlphaDown: createQmIcon("fa-solid fa-sort-down"),
  SortNumeric: createQmIcon("fa-solid fa-sort"),
  SortNumericUp: createQmIcon("fa-solid fa-arrow-up-9-1"),
  SortNumericDown: createQmIcon("fa-solid fa-arrow-down-9-1"),
  ArrowCircleRight: createQmIcon("fa-solid fa-arrow-circle-right"),
  ArrowCircleLeft: createQmIcon("fa-solid fa-arrow-circle-left"),
  ArrowConnection: createQmIcon("fa-solid fa-arrows-turn-to-dots"),
  CheckCircle: createQmIcon("fa-regular fa-circle-check"),
  CheckCircleSolid: createQmIcon("fa-solid fa-circle-check"),
  InProgress: createQmIcon("fas fa-wrench"),
  Wrench: createQmIcon("fas fa-wrench"),
  UnderReview: createQmIcon("fa-solid fa-cubes"),
  Submitted: createQmIcon("fas fa-paper-plane"),
  ReturnedToSender: createQmIcon("fa fa-retweet"),
  Approved: createQmIcon("fa-regular fa-circle-play"),
  NotApproved: createQmIcon("far fa-times-circle"),
  Times: createQmIcon("fa-solid fa-times"),
  Windows: createQmIcon("fa-brands fa-windows"),
  Google: createQmIcon("fa-brands fa-google "),
  BuromobelExperte: createQmIcon("fa-brands fa-buromobelexperte"),
  Withdrawn: createQmIcon("fas fa-undo-alt"),
  MapMarker: createQmIcon("fas fa-map-marker-alt"),
  Tags: createQmIcon("fa-solid fa-tags"),
  Random: createQmIcon("fa-solid fa-shuffle"),
  HandLizard: createQmIcon("fa-solid fa-hand-lizard"),
  User: createQmIcon("fa-solid fa-user"),
  Training: createQmIcon("fa-solid fa-chalkboard-user"),
  Article: createQmIcon("fa-regular fa-file"),
  Users: createQmIcon("fa-solid fa-users"),
  UserAstronaut: createQmIcon("fa-solid fa-user-astronaut"),
  UserGroup: createQmIcon("fa-solid fa-user-group"),
  UserShield: createQmIcon("fa-solid fa-user-shield"),
  UserGear: createQmIcon("fa-solid fa-user-gear"),
  UserPlus: createQmIcon("fa-solid fa-user-plus"),
  CameraRetro: createQmIcon("fa-solid fa-camera-retro"),
  UserMinus: createQmIcon("fa-solid fa-user-minus"),
  BellSlash: createQmIcon("fa-solid fa-bell-slash"),
  Bell: createQmIcon("fa-solid fa-bell"),
  ExternalLink: createQmIcon("fa-solid fa-arrow-up-right-from-square"),
  Public: createQmIcon("fa-solid fa-globe"),
  Private: createQmIcon("fa-solid fa-user-lock"),
  Unlock: createQmIcon("fa-solid fa-unlock-alt"),
  Lock: createQmIcon("fa-solid fa-lock"),
  Phone: createQmIcon("fa-solid fa-phone"),
  Like: createQmIcon("fa-solid fa-thumbs-up"),
  UnLink: createQmIcon("fa-solid fa-unlink"),
  Dislike: createQmIcon("fa-solid fa-thumbs-down"),
  EllipsisVertical: createQmIcon("fa-solid fa-ellipsis-vertical"),
  PenRuler: createQmIcon("fa-solid fa-pen-ruler"),
  Star: createQmIcon("fa-solid fa-star"),
  Filter: createQmIcon("fa-solid fa-filter"),
  FilterXmark: createQmIcon("fa-solid fa-filter-circle-xmark"),
  CalendarDays: createQmIcon("fa-regular fa-calendar-days"),
  Building: createQmIcon("fa-regular fa-building"),
  CalendarPlus: createQmIcon("fa-regular fa-calendar-plus"),
  CalendarWeek: createQmIcon("fas fa-calendar-week"),
  Clock: createQmIcon("fa-regular fa-clock"),
  Glasses: createQmIcon("fa-solid fa-glasses"),
  Globe: createQmIcon("fa-solid fa-globe"),
  Unknown: createQmIcon("far fa-question-circle"),
  QuestionCircle: createQmIcon("far fa-question-circle"),
  Pin: createQmIcon("fa-solid fa-thumbtack"),
  Save: createQmIcon("fa-solid fa-floppy-disk"),
  FlowGraph: createQmIcon("fa-solid fa-sitemap"),
  BpmnGraph: createQmIcon("fa-solid fa-diagram-predecessor"),
  Markdown: createQmIcon("fa-brands fa-markdown"),
  RotateRight: createQmIcon("fa-solid fa-rotate-right"),
  Gear: createQmIcon("fa-solid fa-gear"),
  Maximize: createQmIcon("fa-solid fa-maximize"),
  Copy: createQmIcon("fa-regular fa-copy"),
  Comment: createQmIcon("fa-regular fa-comment"),
  Comments: createQmIcon("fa-regular fa-comments"),
  Compass: createQmIcon("fa-solid fa-compass"),
  Check: createQmIcon("fa-solid fa-check"),
  CheckSquare: createQmIcon("fa-regular fa-check-square"),
  Square: createQmIcon("fa-regular fa-square"),
  SquareSolid: createQmIcon("fa-solid fa-square"),
  HistoryRegular: createQmIcon("fa fa-history"),
  History: createQmIcon("fa-solid fa-clock-rotate-left"),
  HistoryClock: createQmIcon("fa-regular fa-clock"),
  FileText: createQmIcon("fa-regular fa-file-lines"),
  FileSignature: createQmIcon("fa-solid fa-file-signature"),
  Circle: createQmIcon("fa-solid fa-circle"),
  CircleCheck: createQmIcon("fa-regular fa-circle-check"),
  Search: createQmIcon("fa-solid fa-magnifying-glass"),
  SmartView: createQmIcon("fa-solid fa-magnifying-glass-arrow-right"),
  Clipboard: createQmIcon("fa-solid fa-clipboard"),
  Cancel: createQmIcon("fa-solid fa-xmark"),
  StarHalf: createQmIcon("fa-solid fa-star-half-stroke"),
  Link: createQmIcon("fa-solid fa-link"),
  LifeRing: createQmIcon("fa-regular fa-life-ring"),
  Admin: createQmIcon("fa-solid fa-user-doctor"),
  Home: createQmIcon("fa-solid fa-house"),
  MainHome: createQmIcon("fa fa-home"),
  Reply: createQmIcon("fa-solid fa-reply"),
  Transfer: createQmIcon("fa-solid fa-right-left"),
  SimCard: createQmIcon("fa-solid fa-sim-card"),
  SignUp: createQmIcon("fa-regular fa-hand-point-up"),
  SignOut: createQmIcon("fa-solid fa-arrow-right-from-bracket"),
  Rate: createQmIcon("fa-solid fa-scale-balanced"),
  RoutingRule: createQmIcon("fa-solid fa-route"),
  Repeat: createQmIcon("fa-solid fa-repeat"),
  ChartPie: createQmIcon("fa-solid fa-chart-pie"),
  ChartBar: createQmIcon("fa-regular fa-chart-bar"),
  ChartColumn: createQmIcon("fa-solid fa-chart-column"),
  ChartLine: createQmIcon("fa-solid fa-chart-line"),
  TableList: createQmIcon("fa-solid fa-table-list"),
  Table: createQmIcon("fa-solid fa-table"),
  LayerGroup: createQmIcon("fa-solid fa-layer-group"),
  Folder: createQmIcon("fa-solid fa-folder"),
  FolderRegular: createQmIcon("fa-regular fa-folder"),
  FolderTree: createQmIcon("fa-solid fa-folder-tree"),
  FolderPlus: createQmIcon("fa-solid fa-folder-plus"),
  Newspaper: createQmIcon("fa-solid fa-newspaper"),
  ListSolid: createQmIcon("fa-solid fa-list"),
  ListSolidUl: createQmIcon("fa-solid fa-list-ul"),
  Bullhorn: createQmIcon("fa-solid fa-bullhorn"),
  Atlas: createQmIcon("fa-solid fa-atlas"),
  Code: createQmIcon("fa-solid fa-code"),
  PullRequest: createQmIcon("fa-solid fa-code-pull-request"),
  Revision: createQmIcon("fa-solid fa-code-branch"),
  NotWatching: createQmIcon("fa-solid fa-eye-slash"),
  ListCheck: createQmIcon("fa-solid fa-list-check"),
  Watching: createQmIcon("fa-solid fa-eye"),
  Eye: createQmIcon("fa-regular fa-eye"),
  EyeSolid: createQmIcon("fa-solid fa-eye"),
  ThumbsUp: createQmIcon("fa-solid fa-thumbs-up"),
  ThumbsDown: createQmIcon("fa-solid fa-thumbs-down"),
  Spinner: createQmIcon("fa-solid fa-spinner fa-spin"),
  SpinnerPulsing: createQmIcon("fa-solid fa-spinner fa-pulse"),
  HeartBeat: createQmIcon("fa-solid fa-heartbeat"),
  Robot: createQmIcon("fa-solid fa-robot fa-beat"),
  Download: createQmIcon("fa-solid fa-download"),
  Bug: createQmIcon("fa-solid fa-bug"),
  UserSettings: createQmIcon("fa-solid fa-user-gear"),
  Language: createQmIcon("fa-solid fa-language"),
  Bolt: createQmIcon("fa-solid fa-bolt"),
  BirthdayCake: createQmIcon("fa-solid fa-cake-candles"),
  ChevronRight: createQmIcon("fa-solid fa-chevron-right"),
  ChevronLeft: createQmIcon("fa-solid fa-chevron-left"),
  DotsMenu: createQmIcon("fa-solid fa-braille"),
  Customer: createQmIcon("fa-solid fa-chess-king"),
  Supplier: createQmIcon("fa-solid fa-shipping-fast"),
  JobDescription: createQmIcon("fa-solid fa-chess-board"),
  School: createQmIcon("fa-solid fa-school"),
  Department: createQmIcon("fa-solid fa-users-between-lines"),
  Position: createQmIcon("fa-solid fa-users-rectangle"),
  AuditMatrix: createQmIcon("fa-solid fa-dharmachakra"),
  Graph: createQmIcon("fa-solid fa-project-diagram"),
  Integration: createQmIcon("fa-solid fa-plug"),
  Billing: createQmIcon("fa-solid fa-credit-card"),
  AuditTrail: createQmIcon("fa-solid fa-paw"),
  Files: createQmIcon("fa-solid fa-photo-film"),
  Backup: createQmIcon("fa-solid fa-database"),
  Notification: createQmIcon("fa-solid fa-bell"),
  Telephone: createQmIcon("fa-solid fa-phone"),
  Group: createQmIcon("fa-solid fa-object-group"),
  SadTear: createQmIcon("fa-solid fa-face-sad-tear"),
  StopCircle: createQmIcon("fa-regular fa-circle-stop"),
  Play: createQmIcon("fa-solid fa-play"),
  PlayCircle: createQmIcon("fa-regular fa-play-circle"),
  Refresh: createQmIcon("fa-solid fa-rotate-right"),
  FileAlt: createQmIcon("fa-regular fa-file-alt"),
  Industry: createQmIcon("fa-solid fa-industry"),
  Database: createQmIcon("fa-solid fa-database"),
  ReadOnly: createQmIcon("fa-solid fa-book-skull"),
  PasswordResetKeys: createQmIcon("fa-solid fa-passport"),
  ZeroDataChart: createQmIcon("fa-solid fa-signal"),
  UmbrellaBeach: createQmIcon("fa-solid fa-umbrella-beach"),
  Certificate: createQmIcon("fa-solid fa-certificate"),
  CodeBranch: createQmIcon("fa-solid fa-code-branch"),
  Exchange: createQmIcon("fa-solid fa-right-left"),
  SearchPlus: createQmIcon("fa-solid fa-magnifying-glass-plus"),
  GripVertical: createQmIcon("fa-solid fa-grip-vertical"),
  PencilRuler: createQmIcon("fa-solid fa-pen-ruler"),
  ClipboardList: createQmIcon("fa-solid fa-clipboard-list"),
  Trophy: createQmIcon("fa-solid fa-trophy"),
  Infinity: createQmIcon("fa-solid fa-infinity"),
  WeightHanging: createQmIcon("fa-solid fa-weight-hanging"),
  Directions: createQmIcon("fa-solid fa-diamond-turn-right"),
  Ban: createQmIcon("fa-solid fa-ban"),
  FlagCheckered: createQmIcon("fa-solid fa-flag-checkered"),
  CircleNotch: createQmIcon("fa-solid fa-circle-notch"),
  Eraser: createQmIcon("fa-solid fa-eraser"),
  Ellipsis: createQmIcon("fa-solid fa-ellipsis"),
  Calculator: createQmIcon("fa-solid fa-calculator"),
  BellRegular: createQmIcon("fa-regular fa-bell"),
  HandLizardRegular: createQmIcon("fa-regular fa-hand-lizard"),
  PlanDeparture: createQmIcon("fa-solid fa-plane-departure"),
  PlanArrival: createQmIcon("fa-solid fa-plane-arrival"),
  Location: createQmIcon("fa-solid fa-location-dot"),
  PeopleRoof: createQmIcon("fa-solid fa-people-roof"),
  Exclamation: createQmIcon("fa-solid fa-exclamation"),
  MinusSquare: createQmIcon("fa-regular fa-square-minus"),
  File: createQmIcon("fa-regular fa-file"),
  PaperClip: createQmIcon("fa-solid fa-paperclip"),
  FileMedical: createQmIcon("fa-solid fa-file-medical"),
  FaceSmile: createQmIcon("fa-regular fa-face-smile"),
  DoorClosed: createQmIcon("fa-solid fa-door-closed"),
  CheckDouble: createQmIcon("fa-solid fa-check-double"),
  SquareUpRight: createQmIcon("fa-solid fa-square-up-right"),
  Book: createQmIcon("fa-solid fa-book"),
  Rotate: createQmIcon("fa-solid fa-rotate"),
  UserPen: createQmIcon("fa-solid fa-user-pen"),
  FingerPrint: createQmIcon("fa-solid fa-fingerprint"),
  GraduationCap: createQmIcon("fa-solid fa-graduation-cap"),
  FireExtinguisher: createQmIcon("fa-solid fa-fire-extinguisher"),
  Lightbulb: createQmIcon("fa-regular fa-lightbulb"),
  Warehouse: createQmIcon("fa-solid fa-warehouse"),
  AddressBook: createQmIcon("fa-solid fa-address-book"),
  AddressCard: createQmIcon("fa-solid fa-address-card"),
  Box: createQmIcon("fa-solid fa-box"),
};
