import { useTranslation } from "react-i18next";

function Loading() {
  const { t } = useTranslation();
  return (
    <div className="w-full rounded-lg p-4">
      <p>{t("Loading...")}</p>
    </div>
  );
}

export default Loading;
