import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useSWRImmutable from "swr/immutable";

import { useAPIs } from "../../../pbdServices/services/service-context";

import { getProductConfig } from "../../../Constants/productConfig";
import { SupportedLanguages } from "../../../services/i18n/supportedLanguages";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";

function CardFooterAccount() {
  const { t, i18n } = useTranslation();
  const { appSettingsApi } = useAPIs();
  const { data } = useSWRImmutable("/api/version", () => appSettingsApi.getVersion());

  const configData = React.useMemo(() => {
    if (data) {
      return getProductConfig(data.productId);
    } else {
      return undefined;
    }
  }, [data]);

  const changeLanguage = (lng: string) => {
    const selected = lng.includes("-") ? lng.split("-")[0] : lng;
    void i18n.changeLanguage(selected);
  };

  return (
    <>
      <hr />
      <div className="d-flex justify-content-between">
        <div className="text-body-secondary">
          &copy; {new Date().getFullYear()} |{" "}
          {configData?.landingPageUrl != undefined && (
            <a href={configData.landingPageUrl}>{configData.landingPageUrl.replace("https://", "")}</a>
          )}
        </div>
        <div>
          <Form.Group as={Row} controlId="languageSelect">
            <Form.Label column sm={3} title={t("Language")}>
              <qmBaseIcons.Language />
            </Form.Label>
            <Col sm={9}>
              <Form.Select
                name="languageSelect"
                aria-label="languageSelect"
                onChange={(x) => changeLanguage(x.target.value)}
                defaultValue={i18n.language}
              >
                {SupportedLanguages.map((x) => (
                  <option key={x.key} value={x.key}>
                    {x.value}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        </div>
      </div>
    </>
  );
}

export default CardFooterAccount;
