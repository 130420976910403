import {
  ICustomFormLinksControllerClient,
  IFillOutInstructionCustomFormConnectedDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { WithValidations } from "../../Models/Shared/with-validations";
import { ICustomFormAnswerVm } from "../CustomFormAnswers/models/custom-form-answer-vm";

export default class CustomFormLinkService {
  customFormLinksControllerClient: ICustomFormLinksControllerClient;
  constructor(customFormLinksControllerClient: ICustomFormLinksControllerClient) {
    this.customFormLinksControllerClient = customFormLinksControllerClient;
  }

  async getAnswers(connectedForms: WithValidations<IFillOutInstructionCustomFormConnectedDTO>[]) {
    const answers: ICustomFormAnswerVm[] = [];
    for (const element of connectedForms) {
      const answersForConnection = await this.customFormLinksControllerClient.getAnswersById(element.customFormLinkId);
      for (const answer of answersForConnection) {
        answers.push({ ...answer, customForm: element });
      }
    }
    return answers;
  }
}
