/* eslint-disable @typescript-eslint/naming-convention */
import { PbdModule } from "../../generatedCode/pbd-core/pbd-core-api";

export const routePrefix = "/QualityMonitor";
export class QualityMonitorRoutePaths {
  public static All = `${routePrefix}/All`;
  public static HomePage = routePrefix;
  public static Blog = `${routePrefix}/${PbdModule.Blog}`;
  public static Employees = `${routePrefix}/Employees`;
  public static Trainings = `${routePrefix}/Trainings`;
  public static Claims = `${routePrefix}/Claims`;
  public static CustomForms = `${routePrefix}/Forms`;
  public static ProjectsAndTasks = `${routePrefix}/ProjectsAndTasks`;
  public static EmployeeIdeas = `${routePrefix}/Ideas`;
  public static Documents = `${routePrefix}/${PbdModule.DocumentManagement}`;
  public static KnowledgeBase = `${routePrefix}/${PbdModule.KnowledgeBase}`;
  public static Crm = `${routePrefix}/Crm`;
  public static Costs = `${routePrefix}/Costs`;
  public static Goals = `${routePrefix}/Goals`;
  public static Audits = `${routePrefix}/Audits`;
  public static Maintenance = `${routePrefix}/Maintenance`;
  public static Opportunities = `${routePrefix}/Opportunities`;
  public static IndexPageDashboard = `${routePrefix}/Dashboards/Index`;
  public static CreatePageDashboard = `${routePrefix}/Dashboards/Create`;
  public static DetailsPageDashboard = `${routePrefix}/Dashboards/Details/:id`;
  public static CreateChartPage = `${routePrefix}/Dashboards/Details/:id/Charts/Create`;
  public static ArticlesDashboard = `${routePrefix}/:pbdModule`;
}
