import React from "react";
import { Card, CardBody, CardHeader } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import {
  AbsencePolicyCreateDTO,
  ConnectApproversDTO,
  ConnectDepartmentPositionsDTO,
  ConnectTenantDTO,
  IAbsencePolicyCreateDTO,
  IAbsencePolicyDTO,
  ITenantAbsencePolicyDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import IdComponent from "../../../shared/components/id/idComponent";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import CreateFormAbsencePolicy from "../components/createFormAbsencePolicy";

const CreatePageAbsencePolicy = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { absencePoliciesApi } = useAPIs();
  const [loading, setLoading] = React.useState(false);
  const [policy, setPolicy] = React.useState<IAbsencePolicyDTO>();
  const [connectedTenants, setConnectedTenants] = React.useState<ITenantAbsencePolicyDTO[]>();
  const [query, setQuery] = useQueryParams({ policyToCopyId: withDefault(NumberParam, undefined) });
  const [copiedItems, setCopiedItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    async function getData() {
      setLoading(true);
      if (query.policyToCopyId) {
        const policyToCopy = await absencePoliciesApi.getById(query.policyToCopyId);
        policyToCopy.absencePolicyParentId = query.policyToCopyId;
        setPolicy(policyToCopy);
        const tenants = await absencePoliciesApi.getConnectedTenants(query.policyToCopyId);
        setConnectedTenants(tenants);
      }
      setLoading(false);
    }
    getData();
  }, [query.policyToCopyId]);

  const handleSubmit = (dto: IAbsencePolicyCreateDTO) => absencePoliciesApi.create(new AbsencePolicyCreateDTO(dto));

  const handleSuccess = async (dto: IAbsencePolicyDTO) => {
    if (query.policyToCopyId) {
      setCopiedItems((x) => [...x, "Policy"]);
      if (query.policyToCopyId && policy && connectedTenants) {
        const { departmentPositions, absencePolicyApprovers } = policy;
        if (departmentPositions && departmentPositions.length > 0) {
          await wrapApiCallWithToast(() =>
            absencePoliciesApi.connectDepartmentPositions(
              dto.id,
              new ConnectDepartmentPositionsDTO({ departmentPositionIds: departmentPositions.map((x) => x.id) }),
            ),
          );
          setCopiedItems((x) => [...x, "Positions"]);
          if (absencePolicyApprovers && absencePolicyApprovers.length > 0) {
            await wrapApiCallWithToast(() =>
              absencePoliciesApi.connectApprovers(
                dto.id,
                new ConnectApproversDTO({ approverIds: absencePolicyApprovers.map((x) => x.id) }),
              ),
            );
            setCopiedItems((x) => [...x, "Approvers"]);
          }
          if (connectedTenants && connectedTenants.length > 0) {
            await wrapApiCallWithToast(() =>
              absencePoliciesApi.connectTenants(
                dto.id,
                new ConnectTenantDTO({ tenantIds: connectedTenants.map((x) => x.id) }),
              ),
            );
            setCopiedItems((x) => [...x, "Connected persons"]);
          }
        }
      }
    }
    navigate(SettingsRoutePaths.EditPageAbsencePolicy.replace(":id", dto.id.toString()));
  };

  return (
    <Card className="mb-3">
      <CardHeader>{t("New")}</CardHeader>
      <CardBody>
        {!loading && (
          <CreateFormAbsencePolicy
            onCancel={() => navigate(SettingsRoutePaths.IndexPageAbsencePolicy)}
            itemToUpdate={policy}
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
          />
        )}

        {query.policyToCopyId && policy && connectedTenants && (
          <React.Fragment>
            <h4>
              {t("Copy of")}: {policy.title} <IdComponent id={policy.id} />{" "}
            </h4>
            {t("We will also copy the following information")}
            <ul>
              <li>
                {t("Positions")}: {policy.departmentPositions?.map((x) => x.title).join(", ")}
              </li>
              <li>
                {t("Default approvers")}: {policy.absencePolicyApprovers?.map((x) => x.fullName).join(", ")}
              </li>
              <li>
                {t("Persons")}: {connectedTenants.map((x) => x.fullName).join(", ")}
              </li>
            </ul>
            {t("Limitations")}: {t("Individual customizations per person will not be copied")}
            {copiedItems.length > 0 && (
              <>
                <h5>{t("Copied items")}</h5>
                <ul>
                  {copiedItems.map((x) => (
                    <li key={x}>{x}</li>
                  ))}
                </ul>
              </>
            )}
          </React.Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default CreatePageAbsencePolicy;
