import React from "react";
import ReactAvatar from "react-avatar";

import { ITenantDTO, ITenantMinDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

export const getSizeAsPx = (size: AvatarSize) => {
  switch (size) {
    case "s":
      return "24px";
    case "l":
      return "150px";
    default:
      return "32px";
  }
};

export type AvatarSize = "xs" | "s" | "m" | "l";

interface AvatarSpanProps {
  tenant: ITenantDTO | ITenantMinDTO;
  size?: AvatarSize;
  className?: string;
}

function AvatarSpan(props: AvatarSpanProps) {
  const { tenant, size = "m", className } = props;
  const sizeAsPx = React.useMemo(() => getSizeAsPx(size), [size]);
  const src = React.useMemo(
    () => (tenant.profilePictureId ? `/api/blobs/${tenant.profilePictureId}/sasUrl` : ""),
    [tenant],
  );
  const resultInitial = tenant.fullName.includes(",")
    ? tenant.fullName.split(",")[1] + " " + tenant.fullName.split(",")[0]
    : tenant.fullName;
  return <ReactAvatar name={resultInitial} src={src} size={sizeAsPx} round maxInitials={2} className={className} />;
}

export default AvatarSpan;
