import { useTranslation } from "react-i18next";

function NoResults() {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full rounded-lg p-4">
        <p>{t("Sorry, we can't find any matching results.")}</p>
      </div>
    </>
  );
}

export default NoResults;
