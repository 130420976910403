import React from "react";
import { Column } from "react-table";

import { ICategoryDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { getTitleColumn } from "../../shared/components/tables/columns/titleColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";

type LocalType = Pick<ICategoryDTO, "id" | "title">;

type IProps = BaseTableProps<LocalType>;

function CategoryTable(props: IProps) {
  const { includeTitleLink, titleColumnBaseLink } = props;

  const columns = React.useMemo<Column<LocalType>[]>(
    () => [getIdColumn(includeTitleLink, titleColumnBaseLink), getTitleColumn(includeTitleLink, titleColumnBaseLink)],
    [],
  );

  return <ReactstrapTable<LocalType> columns={columns} localStorageStateKey={"CategoryDTO"} {...props} />;
}

export default CategoryTable;
