import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { CategoryCreateDTO, ICategoryCreateDTO, ITagDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import TagServiceNew from "../../../../pbdServices/services/Tags/tagService";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { LoadingComponent } from "../../../shared/components/loading/loadingComponent";
import ManyToManyConnectionCard from "../../../shared/components/manyToManyConnections/manyToManyConnectionCard";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import { useTagsHook } from "../../../shared/hooks/useTagsHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function EditPageTags() {
  const { meAsUser, handleApiError } = useAppContext();
  const { t } = useTranslation();
  const { tagsApi } = useAPIs();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const confirm = useConfirmation();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<ITagDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingTags } = useTagsHook();
  const { data: connectedElements } = useSWR(id ? `/api/tags/${id}/connections` : undefined, () =>
    tagsApi.getConnectionsById(Number(id)).then((resp) => TagServiceNew.getDataGroupedConnectionsByModule(resp)),
  );

  React.useEffect(() => {
    async function getData() {
      const resp = await tagsApi.getById(Number(id));
      setItemToUpdate(resp);
    }
    if (id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [id, refresh]);

  async function handleSubmit(itemToCreate: ICategoryCreateDTO) {
    if (id == null) {
      return tagsApi.create(new CategoryCreateDTO(itemToCreate));
    } else {
      return tagsApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    }
  }

  const handleClick = () => {
    if (itemToUpdate) {
      confirm({ itemsToDelete: [itemToUpdate] }).then(() => handleDelete());
    }
  };

  const handleDelete = async () => {
    const resp = await wrapApiCallWithToast(() => tagsApi.delete(Number(id)), { handleApiError });
    if (resp.isOk) {
      navigate(SettingsRoutePaths.IndexPageTags);
    }
  };

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageTags.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  if (loading) return <LoadingComponent />;

  return (
    <>
      <BaseSettingsForm
        itemToUpdate={itemToUpdate}
        onSubmit={handleSubmit}
        onSuccess={handleSuccess}
        additionalFields={["Color", "BackgroundColor"]}
        onDelete={handleClick}
        disableDeleteButton={!hasRole(meAsUser, [PbdRoles.Admin])}
        existingItems={existingTags}
        formType={"Tag"}
      />
      {itemToUpdate && (
        <ManyToManyConnectionCard
          data={connectedElements}
          cardTitle={t("See how often the tags are used")}
          routePath={SettingsRoutePaths.Home}
          localStorageKey={"TagConnectionGroupByModuleTable"}
          viewAs={"Group"}
          queryStringKey={"tags"}
          queryStringValue={itemToUpdate.title}
        />
      )}
    </>
  );
}

export default EditPageTags;
