import { EntityKey } from "../../../../../generatedCode/pbd-core/pbd-core-api";

export class ConnectionRestrictions {
  constructor() {
    this.disableDisconnect = false;
  }
  disableConnect?: boolean;
  disableDisconnect: boolean;
}

export class ConnectionOptions {
  title?: string;
  includeParent?: boolean;
  hideConnectionCard?: boolean;
}

export class AvailableConnection<T extends object = object> {
  constructor(
    entityKey: EntityKey,
    connectionType: ConnectionType = "default",
    restrictions?: ConnectionRestrictions,
    options?: ConnectionOptions,
    query?: T,
  ) {
    this.id = `${entityKey}_${connectionType}`;
    this.entityKey = entityKey;
    this.connectionType = connectionType;
    this.restrictions = restrictions ?? new ConnectionRestrictions();
    this.options = options ?? new ConnectionOptions();
    this.query = query;
  }
  id: string;
  entityKey: EntityKey;
  connectionType: ConnectionType;
  restrictions: ConnectionRestrictions;
  options: ConnectionOptions;
  query?: T;
}

type ConnectionType = "default";
