import React from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { HttpVerbs, IInventoryStatusDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import SwrApiHelpers from "../../../../pbdServices/services/Api/swr-api-helpers";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function mapDataToSettingsDTO(data?: IInventoryStatusDTO[]) {
  if (!data) return undefined;
  const tableData: SettingsIndexDTO[] = [];
  data.forEach((d) => {
    const item = new SettingsIndexDTO({
      id: d.id,
      title: d.title,
      links: [
        {
          rel: "self",
          href: SettingsRoutePaths.EditPageInventoryStatus.replace(":id", d.id.toString()),
          method: HttpVerbs.GET,
        },
      ],
    });
    tableData.push(item);
  });
  return tableData;
}

function IndexPageInventoryStatus() {
  const navigate = useNavigate();
  const { inventoryStatusApi } = useAPIs();

  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const confirm = useConfirmation();

  const { data, isValidating, mutate } = useSWR(
    `/api/inventoryStatus`,
    () => inventoryStatusApi.getAll(),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      if (selected) {
        confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
      }
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.MaintenanceManagement_Settings])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => inventoryStatusApi.delete(Number(s)), { handleApiError });
      }
      mutate();
    }
  };

  const tableRows = React.useMemo(() => mapDataToSettingsDTO(data), [data]);

  return (
    <>
      <BaseSettingsIndexCard
        data={data}
        cardTitle="Inventory status"
        pathToCreate={SettingsRoutePaths.CreatePageInventoryStatus}
        loading={isValidating}
      >
        <BaseSettingsTable
          tableRows={tableRows}
          onCreateClick={() => navigate(SettingsRoutePaths.CreatePageInventoryStatus)}
          setSelected={(ids) => setSelected(ids.map((x) => x))}
          availableTableActions={["Delete"]}
          onClick={handleClick}
        />
      </BaseSettingsIndexCard>
    </>
  );
}
export default IndexPageInventoryStatus;
