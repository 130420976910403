import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";

import {
  IInventoryStatusCreateDTO,
  IInventoryStatusDTO,
  InventoryStatusCreateDTO,
  InventoryStatusEditDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import GenericAlert from "../../../shared/components/alerts/genericAlert";
import EntityTemplatesButton from "../../../shared/components/entityTemplates/entityTemplatesButton";
import BaseSettingsFormHeader from "../../components/editPage/baseSettingsFormHeader";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import InventoryStatusForm from "../components/inventoryStatusForm";

function EditPageInventoryStatus() {
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { inventoryStatusApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IInventoryStatusDTO>();

  React.useEffect(() => {
    async function getData() {
      const category = await inventoryStatusApi.getById(Number(params.id));
      setItemToUpdate(category);
    }
    if (params.id != null) {
      getData().then(() => {
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [params.id]);

  async function handleSubmit(dto: IInventoryStatusCreateDTO) {
    if (params.id == null) {
      return await inventoryStatusApi.create(new InventoryStatusCreateDTO(dto));
    } else {
      return await inventoryStatusApi.edit(
        itemToUpdate.id,
        new InventoryStatusEditDTO({ ...dto, id: Number(params.id) }),
      );
    }
  }

  const handleSuccess = () => navigate(SettingsRoutePaths.IndexPageInventoryStatus);

  async function submitDelete(dto: IInventoryStatusDTO) {
    await inventoryStatusApi.delete(dto.id);
    navigate(SettingsRoutePaths.IndexPageInventoryStatus);
  }

  const handleTemplateClick = (dto: ICategoryEntityTemplate) => {
    return inventoryStatusApi.create(new InventoryStatusCreateDTO({ title: dto.title }));
  };

  const entityTemplateButton = (
    <EntityTemplatesButton onSelect={handleTemplateClick} templateName={"inventoryStatus"} onSuccess={handleSuccess} />
  );

  const disabledDeleteButton = itemToUpdate && itemToUpdate.connectedInventoryItemsCount > 0 ? true : false;

  return (
    <>
      {!loading && (
        <>
          {disabledDeleteButton && (
            <GenericAlert type="danger">
              {t("This item can't be deleted because it has been connected with other items.")}
            </GenericAlert>
          )}
          <Card>
            <BaseSettingsFormHeader
              itemToUpdate={itemToUpdate}
              onDelete={itemToUpdate ? () => submitDelete(itemToUpdate) : undefined}
              disableDeleteButton={disabledDeleteButton}
            />

            <Card.Body>
              <InventoryStatusForm
                itemToUpdate={itemToUpdate}
                onSubmit={handleSubmit}
                entityTemplateComponent={entityTemplateButton}
                onSuccess={handleSuccess}
              />

              <Link to={SettingsRoutePaths.IndexPageInventoryStatus}>{t("Back to index")}</Link>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}
export default EditPageInventoryStatus;
