import { useState } from "react";

import { FilterElement } from "../components/genericSearchFilter/availableSearchFilters";

type QueryRecord = Record<string, unknown>;

export function useLocalQueryHook<T extends QueryRecord>(initialValue?: T) {
  const [q, setQ] = useState<T>(initialValue ?? ({} as any));
  const [filter, setFilter] = useState<FilterElement[]>([]);

  const handleChange = (dto: FilterElement[]) => {
    setFilter(dto);
    if (dto.length == 0) {
      clearQ();
    }
    for (const element of dto) {
      if (element.queryValue == undefined || element.queryValue == null) {
        const copy = { ...q };
        delete copy[element.queryKey];

        setQ(copy);
      } else {
        setQ((q) => {
          return {
            ...q,
            [element.queryKey]: element.queryValue,
          };
        });
      }
    }
  };

  const deleteFilterProp = (key: string) => {
    if (q[key]) {
      setQ({ ...q, [key]: undefined });
    }
  };

  const clearQ = () => {
    setQ({} as any);
    setFilter([]);
  };

  return { localQuery: q, setLocalQuery: handleChange, clearLocalQuery: clearQ, setFilter, filter, deleteFilterProp };
}
