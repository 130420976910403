/**
 * This config file can used to define settings that are different based in the environment. DO NOT put any secrets inside.
 */

const dev = {
  adminApi: {
    url: "https://localhost:44390",
  },
  appQmBaseCom: {
    url: "https://app.qmbase.com",
  },
  store: {
    baseUrl: { url: "https://localhost:44373" },
  },
  // store: {
  //   baseUrl: { url: "https://qmbasestore.azurewebsites.net" },
  // },
  azureArmFunctionApp: {
    baseUrl: "http://localhost:7071",
    keys: {
      orderCreate: "developKey",
      orderGetAll: "developKey==",
    },
  },
};

const prod = {
  adminApi: {
    url: "https://qmadmin.qmbase.com",
  },
  appQmBaseCom: {
    url: "https://app.qmbase.com",
  },
  store: {
    baseUrl: { url: "https://qmbasestore.azurewebsites.net" },
  },
  azureArmFunctionApp: {
    baseUrl: "https://qmbase-armfunctions.azurewebsites.net",
    keys: {
      orderCreate: "9gTynwTsnZHmtcHSZjkraf4010DwR1cmH3sJkdhFneao34aTjID02g==",
      orderGetAll: "Fe5R2lakvboPrlLoHPZYKR3B4ql2wSfv6j7lRZ2yGnLccxp3XqUH5w==",
    },
  },
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

/**
 * This is the base qmBase config file
 */
export default {
  // Add common config values here
  /**Atom feed for the changelog */
  changeLogUrl: "https://help.qmbase.com/blog/atom.xml",
  env: process.env.NODE_ENV,
  ...config,
};
