import { Badge, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ExternalLink from "../../shared/components/links/externalLink";
import { useChangelogHook } from "../../shared/hooks/useChangelogHook";

function ReleaseNoteEntry({ helpUrl }: { helpUrl: string }) {
  const { t } = useTranslation();
  const { latestRelease } = useChangelogHook();

  if (!latestRelease) return null;

  return (
    <ListGroup.Item className="text-center bg-body-tertiary">
      <Badge bg="primary">{t("What's new")}</Badge>
      <p>
        <ExternalLink href={helpUrl} label={latestRelease.title} />
      </p>
    </ListGroup.Item>
  );
}

export default ReleaseNoteEntry;
