import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";

import {
  CategoryCreateDTO,
  ConnectDepartmentPositionsDTO,
  DepartmentPositionsQueryField,
  ICategoryCreateDTO,
  IDepartmentDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import DepartmentPositionsConnectedCard from "../../../shared/components/connectionElements/departmentPositions/departmentPositionsConnectedCard";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsForm, { hasId } from "../../components/editPage/baseSettingsForm";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function EditPageDepartments() {
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();
  const { departmentsApi, departmentPositionsApi } = useAPIs();
  const navigate = useNavigate();
  const { departmentPositionService } = useAPIServices();
  const [loading, setLoading] = React.useState(true);
  const [itemToUpdate, setItemToUpdate] = React.useState<IDepartmentDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();

  const { data: existingDepartments } = useSWR(`api/departments`, () => departmentsApi.getAll());

  const { data: departmentPositions, mutate } = useSWR(id ? ["/api/departmentPositions", id] : null, async () => {
    const dpFromApi = await departmentPositionsApi.getAllQuery({
      fields: [
        DepartmentPositionsQueryField.Tenants,
        DepartmentPositionsQueryField.ChildDepartmentPositions,
        DepartmentPositionsQueryField.ParentDepartmentPositions,
      ],
      departmentId: [Number(id)],
    });
    return departmentPositionService.mapToVM(dpFromApi);
  });

  React.useEffect(() => {
    async function getData() {
      if (id) {
        const item = await departmentsApi.getById(Number(id));
        setItemToUpdate(item);
      }
      setLoading(false);
    }
    getData();
  }, [id, refresh]);

  const handleSubmit = (itemToCreate: ICategoryCreateDTO) => {
    if (id) {
      return departmentsApi.edit(Number(id), new CategoryCreateDTO(itemToCreate));
    } else {
      return departmentsApi.create(new CategoryCreateDTO(itemToCreate));
    }
  };

  async function toggleDelete() {
    if (!itemToUpdate) throw Error("Missing itemToUpdate");
    if (itemToUpdate.isDeleted) {
      await wrapApiCallWithToast(() => departmentsApi.restore(itemToUpdate.id));
    } else {
      await wrapApiCallWithToast(() => departmentsApi.delete(itemToUpdate.id));
    }
    handleRefresh();
  }

  const handleDisconnectDepartmentPosition = async (id: number) => {
    if (!itemToUpdate) throw Error("Missing data");
    const resp = await wrapApiCallWithToast(() => departmentsApi.disconnectPosition(itemToUpdate.id, id));
    if (resp.isOk) {
      handleRefresh();
    }
  };

  const handleSuccess = (dto?: unknown) => {
    if (hasId(dto)) {
      navigate(SettingsRoutePaths.EditPageDepartment.replace(":id", dto.id.toString()));
    } else {
      handleRefresh();
    }
  };

  const handleAddDepartmentPositions = async (id: number, idsToAdd: number[]) => {
    await departmentPositionsApi.connectChildDepartmentPositions(
      id,
      new ConnectDepartmentPositionsDTO({ departmentPositionIds: idsToAdd }),
    );
    mutate();
  };

  return (
    <>
      {!loading && (
        <BaseSettingsForm
          itemToUpdate={itemToUpdate}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          additionalFields={["DescriptionAsHtml"]}
          onDelete={toggleDelete}
          onRestore={(x) => departmentsApi.restore(x)}
          handleRefresh={handleRefresh}
          existingItems={existingDepartments}
          formType={"Department"}
        />
      )}

      {itemToUpdate && (
        <DepartmentPositionsConnectedCard
          baseDTO={itemToUpdate}
          refreshParent={mutate}
          data={departmentPositions}
          onConnectSubmit={(ids) => handleAddDepartmentPositions(itemToUpdate.id, ids)}
          onDisconnectSubmit={handleDisconnectDepartmentPosition}
          cardTitle={t("Department positions")}
          columnsVisibleDefault={["title", "department"]}
          showCreateModal
        />
      )}
    </>
  );
}

export default EditPageDepartments;
