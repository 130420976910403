/* eslint-disable @typescript-eslint/naming-convention */
import { PbdModule } from "../../generatedCode/pbd-core/pbd-core-api";

const routePrefix = "/Prints";

export class PrintRoutePaths {
  public static HomePage = routePrefix;

  public static Articles = `${routePrefix}/Articles/:id/`;

  public static ArticlesBulk = `${routePrefix}/:module/Bulk`;

  public static Ideas = `${routePrefix}/${PbdModule.IdeaManagement}/:id/`;

  public static Attendees = `${routePrefix}/:module/:id/Attendees`;

  public static Forms = `${routePrefix}/Forms/:id`;

  public static FormAnswers = `${routePrefix}/CustomFormAnswers/:id`;

  public static EightDReports = `${routePrefix}/EightDReports/:id`;

  public static JobDescriptions = `${routePrefix}/JobDescriptions/:id`;

  public static Claims = `${routePrefix}/${PbdModule.ClaimManagement}/:id/`;

  public static InventoryInspectionsDone = `${routePrefix}/${PbdModule.MaintenanceManagement}/InventoryInspectionsDone/:id/`;
}
