import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { SettingsRoutePaths } from "../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { OpportunityRoutePaths } from "../opportunityRoutePaths";

const SidebarOpportunities: React.FC = (props) => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(OpportunityRoutePaths.CreatePage, t),
    SidebarItem.overview(OpportunityRoutePaths.IndexPage + "/?status=Open&status=InProgress", t),
  ];

  links.push(
    new SidebarItem({
      icon: qmBaseIcons.ListCheck,
      title: t("Evaluations"),
      href: OpportunityRoutePaths.IndexPageEvaluations,
    }),
  );
  links.push(SidebarItem.settings(SettingsRoutePaths.OpportunityManagementHome, t));
  links.push(SidebarItem.deleted(OpportunityRoutePaths.IndexDeleted, t));
  return <SidebarNew app={PbdModule.OpportunityManagement} items={links} />;
};

export default SidebarOpportunities;
