import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ICustomField } from "../../generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { GoalProgressRoutePaths } from "../goalProgresses/goalProgressRoutePaths";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { GoalRoutePaths } from "./goalRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.GoalManagement_Standard, PbdRoles.GoalManagement_ModuleAdmin],
};

export const goalLazyRoutes: RouteObject[] = [
  {
    path: GoalRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageGoals")),
    ...defaultRouteParams,
  },
  {
    path: GoalRoutePaths.IndexPage,
    Component: lazy(() => import("./pages/indexPageGoals")),
    ...defaultRouteParams,
  },
  {
    path: GoalRoutePaths.CreatePage,
    Component: lazy(() => import("./pages/createPageGoals")),
    ...defaultRouteParams,
  },
  {
    path: GoalRoutePaths.EditPage,
    Component: lazy(() => import("./pages/detailsPageGoal")),
    ...defaultRouteParams,
  },
  {
    path: GoalProgressRoutePaths.IndexPage,
    Component: lazy(() => import("../goalProgresses/pages/indexPageGoalProgresses")),
    ...defaultRouteParams,
  },
  {
    path: GoalProgressRoutePaths.EditPage,
    Component: lazy(() => import("../goalProgresses/pages/editPageGoalProgresses")),
    ...defaultRouteParams,
  },
  {
    path: GoalRoutePaths.Graph,
    Component: lazy(() => import("../graphs/pages/graphPage")),
    ...defaultRouteParams,
  },
  {
    path: GoalRoutePaths.CommentsPage,
    Component: lazy(() => import("../shared/components/comments/pages/indexPageComments")),
    ...defaultRouteParams,
  },
  {
    path: GoalRoutePaths.NotificationsPage,
    Component: lazy(() => import("../notifications/pages/indexPageNotifications")),
    ...defaultRouteParams,
  },
];

export class GoalSettings {
  constructor() {
    this.customFields = [];
  }
  customFields: ICustomField[];
}
