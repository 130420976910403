import React from "react";
import { useTranslation } from "react-i18next";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import FeatureFlagService from "../../../pbdServices/services/FeatureFlags/featureFlagService";
import { CostRoutePaths } from "../../costs/costRoutePaths";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import SidebarNew, { SidebarItem } from "../../shared/components/sidebar/sidebarNew";
import { TrainingRoutePaths } from "../trainingRoutePaths";

const SidebarTrainings: React.FC = () => {
  const { t } = useTranslation();
  const links: SidebarItem[] = [
    SidebarItem.created(TrainingRoutePaths.CreatePage, t),
    SidebarItem.overview(TrainingRoutePaths.IndexPage + "/?status=Open&status=InProgress", t),
  ];
  links.push(
    new SidebarItem({
      title: t("Training attendees"),
      icon: qmBaseIcons.Users,
      href: TrainingRoutePaths.TrainingAttendees + "/?isEmployee=1",
    }),
  );
  links.push(
    new SidebarItem({
      title: t("Training catalog"),
      icon: qmBaseIcons.Newspaper,
      href: `${TrainingRoutePaths.IndexPage}/?status=Open&status=InProgress&onlyAttendeesCanSelfRegister=1`,
    }),
  );
  links.push(
    SidebarItem.costs(
      `${CostRoutePaths.IndexPageCostsByModule.replace(":pbdModule", "Trainings")}/?keyName=Training&app=${
        PbdModule.TrainingManagement
      }`,
      t,
    ),
  );
  if (FeatureFlagService.isTrainingDemandAvailable()) {
    links.push(
      new SidebarItem({
        title: t("Training demand"),
        href: `${TrainingRoutePaths.TrainingDemands}/?status=Open&status=InProgress`,
      }),
    );
  }
  return <SidebarNew app={PbdModule.TrainingManagement} items={links} />;
};

export default SidebarTrainings;
