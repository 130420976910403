import useSWR from "swr";

import SettingsService from "../../../services/Settings/settingsService";
import { useAppContext } from "../contexts/appContext";

const settingsService = new SettingsService();

export function usePrintLogo() {
  const { productConfig } = useAppContext();
  const { data, isValidating } = useSWR(`/api/settings/printLogo`, () =>
    settingsService.getValueByKey("Global_PrintLogo"),
  );

  let printLogoUrl = data;
  if (!isValidating && !printLogoUrl) {
    printLogoUrl = productConfig.iconUrl;
  }

  return {
    printLogoUrl,
  };
}
