import clip from "text-clipper";

import { GlobalQmBaseConstants } from "../Constants/GlobalQmBaseConstants";
import { Lazy } from "./lazy";

const ellipsis = "...";

export default class StringHelpers {
  static maxVisibleStringLengthForDescription = 200;

  static getUniquePlaceholdersFromText(textContent: string): string[] {
    const placeholders: string[] = [];
    const str = textContent,
      rg = /\{\{(.+?)\}\}/g;
    let match;
    while ((match = rg.exec(str))) {
      placeholders.push(match[1]);
    } // Iterate matches
    const uniquePlaceholder = placeholders.filter(function (item, i, ar) {
      return ar.indexOf(item) === i;
    });
    return uniquePlaceholder;
  }

  /**
   * Truncate any string to a given length. Use clipper for truncating html strings.
   * @param s The string to truncate
   * @param l the length default 150
   */
  static truncate(s: string, l = 150) {
    if (s.length <= l) {
      return s;
    } else {
      let ts = "";
      const subs = s.split("&");
      if (subs[0].length > l) {
        return subs[0].substring(0, l) + ellipsis;
      } else {
        ts = ts + subs[0];
      }
      for (let i = 1; i < subs.length; i++) {
        const end = subs[i].indexOf(";");
        l += end + 1;
        ts = ts + "&" + subs[i];
        if (ts.length >= l) {
          return ts.substring(0, l) + ellipsis;
        }
      }
      return ts;
    }
  }

  /**
   *
   * @param s
   * @param l
   * @returns
   */
  static truncateHtml(
    s: string,
    l = 150,
  ): { isTruncated: boolean; contentTruncated: string; content: string; isError: boolean; errorContent: string } {
    let isError = false;
    let errorContent = "";
    let truncated = s;
    try {
      truncated = clip(truncated, l, { html: true });
    } catch (ex) {
      isError = true;
      if (typeof ex == "string") {
        errorContent = ex.toString();
      }
    }

    return { isTruncated: truncated != s, contentTruncated: truncated, content: s, isError: isError, errorContent };
  }

  static stripHtmlFromString(stringWithHtml: string | undefined) {
    const html = stringWithHtml;
    const div = document.createElement("div");
    div.innerHTML = html ?? "";
    const text = div.textContent ?? div.innerText;
    return text;
  }

  static isNullOrWhitespace(input?: string | undefined | null) {
    return !input?.trim();
  }

  static capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static lowercaseFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static _umlautMap: Record<string, string> = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "\u00dc": "UE",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "\u00c4": "AE",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "\u00d6": "OE",
    "\u00fc": "ue",
    "\u00e4": "ae",
    "\u00f6": "oe",
    "\u00df": "ss",
  };

  private static _umlautRegex = new Lazy(() => new RegExp("[" + Object.keys(this._umlautMap).join("|") + "]", "g"));

  static replaceUmlaute(str: string) {
    return str
      .replace(/[\u00dc|\u00c4\u00d6][a-z]/g, (a) => {
        const big = this._umlautMap[a.slice(0, 1)];
        return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
      })
      .replace(StringHelpers._umlautRegex.get(), (a) => this._umlautMap[a]);
  }

  static removeTrailingSlash(str: string) {
    return str.replace(/\/+$/, "");
  }

  /**Replace link/to/:id with link/to/99 */
  static populateLink(linkTemplate: string, idValue: number | string) {
    return linkTemplate.replace(":id", idValue.toString());
  }

  static loremIpsum(take = 600) {
    const text =
      "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.";
    return text.slice(0, take);
  }

  static endsWithAny(suffixes: string[], string: string, ignoreCasing = true) {
    if (ignoreCasing) {
      return suffixes.some(function (suffix) {
        return string.toUpperCase().endsWith(suffix.toUpperCase());
      });
    } else {
      return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
      });
    }
  }

  static generatePassword(length: number) {
    if (length < GlobalQmBaseConstants.PasswordRequiredLength) {
      throw Error(`Password must be at least ${GlobalQmBaseConstants.PasswordRequiredLength}`);
    }
    let password = this.generatePasswordInternal(length);
    do {
      password = this.generatePasswordInternal(length);
    } while (!this.strongRegex.test(password));
    return password;
  }

  private static generatePasswordInternal(length: number) {
    //Input Variables Global
    const inputs = [""];
    const uppercases = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercases = "abcdefghiklmnopqrsttuvwxtz";
    const numbers = "0123456789";
    const specials = "*&^%$#@!?><{}";
    let randPassword = "";

    for (const lower of lowercases) {
      inputs.push(lower);
    }

    for (const upper of uppercases) {
      inputs.push(upper);
    }

    for (const number of numbers) {
      inputs.push(number);
    }

    for (const special of specials) {
      inputs.push(special);
    }

    //compiles input choices into an array, returning random values through Math input by the length of the input array, returning password to the box
    for (let i = 0; i < length; i++) {
      inputs[Math.floor(Math.random() * inputs.length)];

      randPassword += inputs[Math.floor(Math.random() * inputs.length)];
    }

    return randPassword;
  }

  /**
   * This includes lower case Upper case number and non alpha numeric characters
   */
  static readonly strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s:])/;
}
