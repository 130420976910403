import {
  IArticleActionSetting,
  IClaimDeadlineEntry,
  ICustomField,
  IEntityFieldTemplateDTO,
  IEntityTemplateDTO,
  IExternalIdSetting,
  IModuleSettingDTO,
  INotificationEntry,
  IPinnedToAppHomePageDTO,
  ISmartViewDTO,
  IWorkflowRule,
  SettingType,
} from "../../generatedCode/pbd-core/pbd-core-api";

import { DefaultArticleActionSettings } from "../../Models/Articles/ArticleActionSetting";
import { EmployeeIdeaManagementSettings } from "../../Models/EmployeeIdeas/EmployeeIdeaManagementSettings";
import { ExternalIdSettings } from "../../Models/Settings/ExternalIdSettings";
import JsonHelpers from "../Json/jsonHelpers";

interface IDefaultAppSettingsDTO_Local {
  externalIdSetting?: IExternalIdSetting;
  pinnedToAppHomePage?: IPinnedToAppHomePageDTO;
  customFields?: ICustomField[] | undefined;
  smartViews?: ISmartViewDTO[] | undefined;
  deadlines?: IClaimDeadlineEntry[] | undefined;
  templates?: IEntityTemplateDTO[] | undefined;
  notifications?: INotificationEntry[] | undefined;
  workflowRules?: IWorkflowRule[] | undefined;
  fieldTemplates?: IEntityFieldTemplateDTO[] | undefined;
}

export default class ModuleSettingsMapper {
  static mapToObject<T extends IDefaultAppSettingsDTO_Local>(array: IModuleSettingDTO[], defaultSettings: T) {
    defaultSettings.customFields = [];
    for (const element of array) {
      if (element.value) {
        switch (element.type) {
          case SettingType.CustomFields:
            defaultSettings.customFields = JsonHelpers.parseToCamelCase(element.value);
            defaultSettings.customFields?.sort((a, b) => a.name.localeCompare(b.name));
            break;
          case SettingType.WorkflowRule:
            defaultSettings.workflowRules = JsonHelpers.parseUnknown(element.value);
            break;
          case SettingType.Notifications:
            defaultSettings.notifications = JsonHelpers.parseUnknown(element.value);
            break;
          case SettingType.Templates:
            defaultSettings.templates = JsonHelpers.parseUnknown(element.value);
            break;
          case SettingType.Deadlines:
            defaultSettings.deadlines = JsonHelpers.parseUnknown(element.value);
            break;
          case SettingType.FieldTemplates:
            defaultSettings.deadlines = JsonHelpers.parseUnknown(element.value);
            break;
        }
      }
    }
    return defaultSettings;
  }

  static mapToArticleSettings(moduleSettings: IModuleSettingDTO[]) {
    const settings = {
      articleActionSetting: DefaultArticleActionSettings,
    };
    const articleAction = moduleSettings.find((x) => x.type == SettingType.ArticleActionSetting);
    if (articleAction && typeof articleAction.value == "string") {
      settings.articleActionSetting = JsonHelpers.parseToCamelCase<IArticleActionSetting>(articleAction.value);
    }
    return settings;
  }

  static mapToIdeaSettings(moduleSettings: IModuleSettingDTO[]) {
    const settings = new EmployeeIdeaManagementSettings();
    const extSet = moduleSettings.find((x) => x.type == SettingType.ExternalId);
    if (extSet?.value && typeof extSet.value == "string") {
      settings.externalIdSetting = JsonHelpers.parse<ExternalIdSettings>(extSet.value);
    } else {
      settings.externalIdSetting = new ExternalIdSettings();
    }

    const cfSetting = moduleSettings.find((x) => x.type == SettingType.CustomFields);
    if (cfSetting?.value && Array.isArray(cfSetting.value)) {
      settings.customFields = cfSetting.value;
    }
    return settings;
  }
}
