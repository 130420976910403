import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../Constants/app-ui-data";

const isKeyPressed = (e: KeyboardEvent, key: string) => e.altKey && e.key === key;

export function useKeyboardHook(currentApp: PbdModule) {
  const navigate = useNavigate();
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (isKeyPressed(e, "n")) {
        e.preventDefault();
        e.stopPropagation();
        const createPage = AppUiData[currentApp].create;
        if (createPage) {
          navigate(createPage);
        }
      } else if (isKeyPressed(e, "l")) {
        e.preventDefault();
        e.stopPropagation();
        const listPage = AppUiData[currentApp].list;
        if (listPage) {
          navigate(listPage);
        }
      } else if (isKeyPressed(e, "h")) {
        e.preventDefault();
        e.stopPropagation();
        const page = AppUiData[currentApp].homePage;
        if (page) {
          navigate(page);
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentApp]);
}
