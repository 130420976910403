import { DateTime } from "luxon";

import {
  IArticleDTO,
  IArticlesControllerClient,
  IMenuItemDTO,
  IMenuItemsControllerClient,
  MenuItemCreateDTO,
  MenuItemDTO,
  PbdModule,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { ListHelpers } from "../../../Helpers/ListHelpers";
import { ArticlesQueryParameters } from "../Articles/models/query-parameters";
import { FolderContentVm, toFolderTableType } from "./models/folderContentVm";
import { MenuItemQueryParameters } from "./models/queryParameters";

interface IMenuItemVm extends IMenuItemDTO {
  articles: IArticleDTO[];
  menuItems: IMenuItemDTO[];
  content: FolderContentVm[];
}

export default class MenuItemService {
  private _menuItemsApi: IMenuItemsControllerClient;
  private _articlesApi: IArticlesControllerClient;

  static rootItemVirtual(app: PbdModule): MenuItemDTO {
    return new MenuItemDTO({
      id: -1,
      title: "Home",
      isDeleted: false,
      createdAt: DateTime.now(),
      isPrivate: false,
      module: app,
      createdById: -1,
    });
  }

  constructor(menuItemsApi: IMenuItemsControllerClient, articleApi: IArticlesControllerClient) {
    this._menuItemsApi = menuItemsApi;
    this._articlesApi = articleApi;
  }

  createFolderPath(folders: string[], app: PbdModule) {
    const data = folders.map(
      (f) =>
        new MenuItemCreateDTO({
          title: f,
          module: app,
        }),
    );

    return this._menuItemsApi.createFolderPath(data);
  }

  async getById(
    id: number | undefined,
    query: MenuItemQueryParameters & ArticlesQueryParameters,
  ): Promise<IMenuItemVm> {
    const menuItem = id ? await this._menuItemsApi.getById(id) : MenuItemService.rootItemVirtual(query.module);
    const menuItems = await this._menuItemsApi
      .getAllQuery({
        module: query.module,
        parentMenuItemId: menuItem.id,
        tags: query.tags,
        createdFrom: query.createdFrom,
        createdTo: query.createdTo,
      })
      .then((resp) => ListHelpers.sortByString(resp, (x) => x.title));

    const articles = await this._articlesApi
      .getAllQuery({
        parentMenuItemId: menuItem.id,
        module: query.module,
        tags: query.tags,
        createdFrom: query.createdFrom,
        createdTo: query.createdTo,
        isDeleted: query.isDeleted,
      })
      .then((resp) => ListHelpers.sortByString(resp, (x) => x.title));

    return {
      ...menuItem,
      articles,
      menuItems,
      content: [...menuItems.map((x) => toFolderTableType(x)), ...articles.map((x) => toFolderTableType(x))],
    };
  }

  static getFolderTree(menuItems: IMenuItemDTO[], parentMenuItemId?: number) {
    let folders: IMenuItemDTO[] = [];
    if (parentMenuItemId && menuItems) {
      let currentMenuItem = menuItems.find((x) => x.id == parentMenuItemId);
      if (currentMenuItem != null) {
        folders.push(currentMenuItem);
        let currentMenuItemParentId = currentMenuItem.parentMenuItemId;
        do {
          if (currentMenuItemParentId != null) {
            currentMenuItem = menuItems.find((x) => x.id == currentMenuItemParentId);
            if (currentMenuItem) {
              folders.push(currentMenuItem);
              currentMenuItemParentId = currentMenuItem.parentMenuItemId;
            } else {
              currentMenuItemParentId = undefined;
            }
          }
        } while (currentMenuItemParentId);
      }
    }
    folders = folders.reverse();
    return folders;
  }
}
