import i18next from "i18next";

import {
  ICategoryWithResponsibleDTO,
  IInventoryCategoriesControllerClient,
  IInventoryInspectionDTO,
  IInventoryStatusControllerClient,
  IInventoryStatusDTO,
  PbdStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import {
  IPrerequisitesReturnType,
  IPrerequisitesService,
  IPrerequisitesWrapper,
} from "../../../ClientApp/prerequisitesModals/prerequisitesModal";
import { SettingsRoutePaths } from "../../../ClientApp/settings/settingsRoutePaths";
import { Severity } from "../../../Models/Enums/Severity";
import InventoryInspectionService from "../InventoryInspections/inventoryInspectionService";
import { IBotRecommendation } from "../recommendation/models/bot-recommendation";

export default class MaintenanceManagementService implements IPrerequisitesService {
  inventoryCategories: IInventoryCategoriesControllerClient;
  inventoryStatus: IInventoryStatusControllerClient;

  constructor(
    _inventoryCategories: IInventoryCategoriesControllerClient,
    _inventoryStatus: IInventoryStatusControllerClient,
  ) {
    this.inventoryCategories = _inventoryCategories;
    this.inventoryStatus = _inventoryStatus;
  }

  async getAllPrerequisites(): Promise<IPrerequisitesWrapper> {
    const inventoryCategories = await this.inventoryCategories.getAllQuery({ take: 1 });
    const inventoryStatus = await this.inventoryStatus.getAllQuery();
    const checks: IPrerequisitesReturnType[] = [
      {
        id: "inventoryCategories",
        title: "Inventory categories",
        status: inventoryCategories.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.MaintenanceManagementHome,
      },
      {
        id: "inventoryStatus",
        title: "Inventory status",
        status: inventoryStatus.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
        route: SettingsRoutePaths.MaintenanceManagementHome,
      },
    ];
    const resp: IPrerequisitesWrapper = {
      checks,
      actionRequired: checks.find((x) => x.status != PbdStatus.Completed) != undefined,
    };
    return resp;
  }
  static getRecommendations(
    status: IInventoryStatusDTO[],
    categories: ICategoryWithResponsibleDTO[],
    inspectionTypes: IInventoryInspectionDTO[],
  ) {
    const data = InventoryInspectionService.validateData(inspectionTypes);
    const items: IBotRecommendation[] = [];
    items.push({
      id: "status",
      title: i18next.t("Inventory status"),
      description: i18next.t("You have not defined status so far"),
      severity: Severity.High,
      link: SettingsRoutePaths.IndexPageInventoryStatus,
      isHref: true,
      status: status.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
    });
    items.push({
      id: "categories",
      title: i18next.t("Inventory categories"),
      description: i18next.t("You have not defined categories so far"),
      severity: Severity.High,
      link: SettingsRoutePaths.IndexPageInventoryCategories,
      isHref: true,
      status: categories.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
    });
    items.push({
      id: "inspections",
      title: i18next.t("Inventory inspections"),
      description: i18next.t("You have not defined inspections so far"),
      severity: Severity.High,
      link: SettingsRoutePaths.IndexPageInventoryInspections,
      isHref: true,
      status: inspectionTypes.length == 0 ? PbdStatus.Open : PbdStatus.Completed,
    });
    items.push({
      id: "inspectionsInterval",
      title: i18next.t("Inventory inspections"),
      description: i18next.t("Some inspection types do not have valid intervals"),
      severity: Severity.High,
      link: SettingsRoutePaths.IndexPageInventoryInspections,
      isHref: true,
      taskCount: data.filter((x) => x.errors.length !== 0).length,
      status: data.filter((x) => x.errors.length !== 0).length > 0 ? PbdStatus.Open : PbdStatus.Completed,
    });
    return items;
  }
}
