import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../Helpers/FlowChartHelpers";
import FormattedUserInput from "../../text/formattedUserInput";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function CustomInputNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  return (
    <>
      <NodeResizer isVisible={selected} />
      <Handle type="source" position={Position.Bottom} id="a" />
      <Handle type="source" position={Position.Left} id="b" />
      <Handle type="source" position={Position.Right} id="c" />
      <div style={FlowChartHelpers.getStylesForCustomNodes(data)}>
        <FormattedUserInput content={data.label} />
      </div>
    </>
  );
}

export default CustomInputNode;
