import { Button, ButtonGroup, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { ICheckListItemDTO, IToDoDTO, PbdStatus } from "../../../generatedCode/pbd-core/pbd-core-api";

import { StatusString } from "../../../Models/Enums/Status";
import { wrapApiCallWithToast } from "../../../pbdServices/services/Api/api-wrapper";
import CardTitleForm from "../../shared/components/cardComponents/cardTitleForm";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import StatusIconComponent from "../../shared/components/status/statusIcon";
import { useToggle } from "../../shared/hooks/useToggle";

interface IProps {
  item: IToDoDTO;
  checkListItem: ICheckListItemDTO;
  refresh: () => void;
  isReadOnly?: boolean;
  onDelete?: (id: ICheckListItemDTO) => void;
  onUpgrade?: (id: ICheckListItemDTO) => void;
  onEdit?: (dto: ICheckListItemDTO) => Promise<void>;
}

function CheckListItem(props: IProps) {
  const { item, checkListItem, isReadOnly, refresh, onDelete, onUpgrade, onEdit } = props;
  const { t } = useTranslation();
  const [editMode, toggleEditMode] = useToggle();

  const handleEdit = (dto: ICheckListItemDTO) => {
    if (!onEdit) throw Error("Not implemented");
    void wrapApiCallWithToast(() => onEdit(dto), { onSuccess: () => refresh() });
  };

  if (isReadOnly) {
    return (
      <ListGroup.Item>
        <StatusIconComponent status={checkListItem.isChecked ? PbdStatus.Completed : PbdStatus.Open} />{" "}
        {checkListItem.content}
      </ListGroup.Item>
    );
  } else {
    return (
      <>
        {editMode && onEdit && (
          <ListGroup.Item>
            <CardTitleForm
              title={checkListItem.content}
              onRefresh={refresh}
              toggleEditMode={toggleEditMode}
              onSubmit={(val) => onEdit({ ...checkListItem, content: val })}
            />
          </ListGroup.Item>
        )}
        {!editMode && (
          <ListGroup.Item className="d-flex">
            <>
              <div>
                <Button
                  variant="outline-light"
                  disabled={!item.capabilities?.canEdit}
                  onClick={() => handleEdit({ ...checkListItem, isChecked: !checkListItem.isChecked })}
                >
                  <StatusIconComponent status={checkListItem.isChecked ? StatusString.Completed : StatusString.Open} />
                </Button>
              </div>
              <span className="hoverInput flex-grow-1" onClick={toggleEditMode}>
                {checkListItem.content} <qmBaseIcons.PencilAlt className="ms-1 me-1 fa-xs" style={{ color: "white" }} />{" "}
              </span>
              <ButtonGroup>
                {onUpgrade && (
                  <Button
                    variant="outline-light"
                    title={t("Upgrade check list item to task")}
                    className="hoverEditButton"
                    disabled={!item.capabilities?.canEdit}
                    onClick={() => onUpgrade(checkListItem)}
                  >
                    <qmBaseIcons.ArrowUp className="text-secondary" />
                  </Button>
                )}

                {onDelete && (
                  <Button
                    variant="outline-light"
                    title={t("Delete")}
                    className="hoverDeleteButton"
                    disabled={!item.capabilities?.canEdit}
                    onClick={() => onDelete(checkListItem)}
                  >
                    <qmBaseIcons.Delete className="text-secondary" />
                  </Button>
                )}
              </ButtonGroup>
            </>
          </ListGroup.Item>
        )}
      </>
    );
  }
}

export default CheckListItem;
