import { IInventoryInspectionDoneDTO, IInventoryItemDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { NumberHelpers } from "../../../Helpers/NumberHelpers";
import { InventoryItemKpis } from "./models/inventory-item-kpis";

export default class InventoryItemReportingService {
  static getKpis(
    all: IInventoryItemDTO[],
    total: IInventoryItemDTO[],
    allInspectionsDone: IInventoryInspectionDoneDTO[],
    totalUrl: string,
  ) {
    const kpis = new InventoryItemKpis(totalUrl);
    kpis.all = all;
    kpis.allExcluded = all.filter((x) => x.excludeFromMonitoring);
    kpis.total = total;
    kpis.validInspection = all.filter(
      (x) => !x.excludeFromMonitoring && x.nextInspection != null && DateTimeLuxonHelpers.inFuture(x.nextInspection),
    );
    kpis.partOfItemsWithValidInspection = kpis.validInspection.length / (kpis.all.length - kpis.allExcluded.length);
    kpis.partOfItemsWithValidInspectionFormatted = NumberHelpers.convertToPercentage(
      kpis.partOfItemsWithValidInspection,
    );
    kpis.inventoryInspectionsDone = allInspectionsDone;
    return kpis;
  }
}
