export function filterMap<T, U>(data: T[], filterMap: (val: T) => U | undefined): U[] {
  const result = [];
  for (const elem of data) {
    const val = filterMap(elem);
    if (val !== undefined && val != null) result.push(val);
  }
  return result;
}

declare global {
  interface Array<T> {
    filterMap<U>(applyFilter: (val: T) => U | undefined): U[];
  }
}

if (!Array.prototype.filterMap) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.filterMap = function <T, U>(this: T[], applyFilter: (val: T) => U | undefined) {
    return filterMap(this, applyFilter);
  };
}

type MapCallback<T, M> = (val: T) => M;

export function mapNullable<T, M>(value: T | undefined, map: MapCallback<T, M>): M | undefined {
  if (value !== undefined && value !== null) return map(value);

  return undefined;
}

export function mapInsertOrUpdate<K, V>(map: Map<K, V>, key: K, newValue: V, update: (v: V) => void) {
  const val = map.get(key);
  if (val !== undefined) {
    update(val);
  } else {
    map.set(key, newValue);
  }
}

export function findMap<T, U>(data: T[], checkMap: (val: T) => U | undefined): U | undefined {
  for (const elem of data) {
    const val = checkMap(elem);
    if (val !== undefined) return val;
  }

  return undefined;
}

export const delay = (ms: number) => new Promise((_) => setTimeout(_, ms));

export function findDuplicates<T>(arr: T[]): T[] {
  //If the array has less than 2 elements there are no duplicates
  const n = arr.length;
  if (n < 2) return [];

  const sorted = arr.sort();
  const result = [];

  //Head
  if (sorted[0] === sorted[1]) result.push(sorted[0]);

  //Index (Head :: Inner :: Tail)
  for (let i = 1; i < n - 1; i++) {
    if (sorted[i] === sorted[i - 1]) result.push(sorted[i]);
  }

  //Tail
  if (sorted[n - 1] == sorted[n - 2]) result.push(sorted[n - 1]);

  return result;
}

export function median(arr: number[]): number | undefined {
  const n = arr.length;
  if (n === 0) return undefined;
  // Sorting for floating numbers only works with function
  arr.sort((a, b) => a - b);

  const half = Math.floor(arr.length / 2);

  if (arr.length % 2) return arr[half];

  return (arr[half - 1] + arr[half]) / 2.0;
}

export function groupBy<T, TKey>(items: T[], getKey: (val: T) => TKey): Map<TKey, T[]> {
  return items.reduce((acc, item) => {
    mapInsertOrUpdate(acc, getKey(item), [item], (arr) => arr.push(item));
    return acc;
  }, new Map());
}

export function countBy<T, TKey>(items: T[], getKey: (val: T) => TKey): Map<TKey, number> {
  return items.reduce((acc, item) => {
    const key = getKey(item);
    const val = acc.get(key) ?? 0;
    acc.set(key, val + 1);
    return acc;
  }, new Map());
}

export interface BaseIdDTO<TKey> {
  id: TKey;
}

export class DTOMap<T extends BaseIdDTO<TKey>, TKey = number> {
  items: T[];

  constructor(data: T[]) {
    this.items = data;
    this.sort();
  }

  sort() {
    //TODO2 allow sort adaption + maybe create DTOString + DTONumberMap
    this.items.sort();
  }

  getById(id: TKey) {
    return this.items.find((x) => x.id == id);
  }

  mustGetById(id: TKey) {
    const val = this.getById(id);
    if (val === undefined) throw new Error("Unable to find dto by id: " + id);
    return val;
  }

  contains(id: TKey) {
    return this.getById(id) !== undefined;
  }

  getByIds(ids: TKey[]) {
    return ids.filterMap((id) => this.getById(id));
  }
}
