import { useTranslation } from "react-i18next";

import { PbdStatus } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import CustomSelect from "./customSelect";

const types = Object.values(PbdStatus);

interface IProps {
  /**
   * This can be used to overwrite the default available Options: [Open,InProgress,Completed]
   */
  availableOptions?: PbdStatus[];
  onChange: (id?: PbdStatus | PbdStatus[]) => void;
  selectedIds?: PbdStatus[];
  isMulti?: boolean;
  isClearable?: boolean;
}

function StatusSelect(props: IProps) {
  const { t } = useTranslation();
  const { availableOptions = [PbdStatus.Open, PbdStatus.InProgress, PbdStatus.Completed] } = props;

  return (
    <CustomSelect<PbdStatus>
      data={types
        .filter((x) => availableOptions.includes(x))
        .map((x) => {
          return {
            title: t(x),
            id: x,
          };
        })}
      name="status"
      {...props}
    />
  );
}

export default StatusSelect;
