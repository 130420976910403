import i18next from "i18next";
import React from "react";
import { Button } from "react-bootstrap";
import { Column } from "react-table";

import { IQuickAccessLink } from "../../../generatedCode/pbd-core/pbd-core-api";

import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import BaseTableProps from "../../shared/components/tables/BaseTableProps";
import { getIdColumn } from "../../shared/components/tables/columns/idColumn";
import { ReactstrapTable } from "../../shared/components/tables/reactstrapTable";

type IProps = BaseTableProps<IQuickAccessLink>;

export function QuickAccessTable(props: IProps) {
  const { columnsVisibleDefault = ["title", "link"], onRowClick } = props;

  const columns = React.useMemo<Column<IQuickAccessLink>[]>(
    () => [
      getIdColumn(false),
      {
        id: "title",
        Header: <>{i18next.t("Title")}</>,
        accessor: "title",
        Cell: ({ row }) => (
          <>
            {onRowClick ? (
              <Button variant="link" onClick={() => onRowClick({ row: row.original, action: "Details" })}>
                {row.original.title}
              </Button>
            ) : (
              <> {row.original.title}</>
            )}
            {row.original.isFavorite && <qmBaseIcons.Star className="ms-1" />}
          </>
        ),
      },
      {
        id: "link",
        Header: <>{i18next.t("Link")}</>,
        accessor: "link",
        disableSortBy: true,
        Cell: ({ row }) => (
          <a target="_blank" rel="noopener noreferrer" href={row.original.link.href}>
            {row.original.link.href}
          </a>
        ),
      },
    ],
    [onRowClick],
  );

  return (
    <ReactstrapTable<IQuickAccessLink>
      columns={columns}
      columnsVisibleDefault={columnsVisibleDefault}
      localStorageStateKey={window.location.pathname}
      {...props}
    />
  );
}

export default QuickAccessTable;
