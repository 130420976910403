import { TenantsControllerClient, TenantsQueryField } from "../../../../generatedCode/pbd-core/pbd-core-api";

export type TenantQueryParameters = Parameters<TenantsControllerClient["getAllQuery"]>[0];

export class TenantQueries {
  static employeeIndex: TenantQueryParameters = {
    isEmployee: true,
    fields: [TenantsQueryField.Telephones, TenantsQueryField.DepartmentPositions, TenantsQueryField.ApplicationUser],
  };

  static tenantIndex: TenantQueryParameters = {
    fields: [TenantsQueryField.Telephones, TenantsQueryField.DepartmentPositions, TenantsQueryField.ApplicationUser],
  };

  static defaultConnectionModal: TenantQueryParameters = {
    isEmployee: true,
    isApplicationUser: true,
    fields: [TenantsQueryField.DepartmentPositions, TenantsQueryField.ApplicationUser],
  };
}
