import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import useSWR from "swr";

import { IModuleSettingDTO, PbdModule, SettingType } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { useTryDelete } from "../../../pbdServices/services/Api/api-hooks";
import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { AdminRoutePaths } from "../../admin/adminRoutePaths";

const DetailsPageModuleSettings: React.FC = () => {
  const { t } = useTranslation();
  const { moduleSettingsApi } = useAPIs();
  const { pbdModule, type } = useParams<{ pbdModule: PbdModule; type: SettingType }>();
  const navigate = useNavigate();

  const { data } = useSWR(
    `/api/moduleSettings/${pbdModule}/${type}`,
    () => moduleSettingsApi.getByModuleAndType(pbdModule, type),
    SwrApiHelpers.getDefaultGetOptions(navigate),
  );

  const tryDelete = useTryDelete<IModuleSettingDTO & { id: string }, string>(
    (dto) => moduleSettingsApi.deleteModuleSettingComplete(dto.module, dto.type),
    [moduleSettingsApi],
    () => navigate(AdminRoutePaths.IndexPageModuleSettings),
  );

  return (
    <React.Fragment>
      <Card className="mb-3">
        <CardBody>
          {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
          {data && (
            <Button color="danger" onClick={() => tryDelete({ ...data, id: data.module })}>
              {t("Delete")}
            </Button>
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
export default DetailsPageModuleSettings;
