import axios from "axios";
import { DateTime } from "luxon";

import {
  CapabilitiesDTO,
  EntityKey,
  IArticleActionSetting,
  IArticlesControllerClient,
  IClaimsControllerClient,
  ICustomFormsControllerClient,
  IGoalsControllerClient,
  IModuleSettingDTO,
  IModuleSettingsControllerClient,
  IOpportunitiesControllerClient,
  IPinnedItemDTO,
  IProjectsControllerClient,
  ITagDTO,
  ITenantDTO,
  IToDosControllerClient,
  ModuleSettingDTO,
  PbdModule,
  PbdStatus,
  PinnedItemDTO,
  PinnedToAppHomePageDTO,
  SettingType,
  TagDTO,
  TenantMinDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { AppUiData } from "../../../Constants/app-ui-data";
import { DefaultArticleActionSettings } from "../../../Models/Articles/ArticleActionSetting";
import JsonHelpers from "../Json/jsonHelpers";
import { AppSettingsVm } from "./models/app-module-settings";

export interface ItemWithTags {
  id: number;
  title: string;
  description?: string;
  tags?: TagDTO[] | ITagDTO[];
  link?: string;
  capabilities?: CapabilitiesDTO;
  entityKey?: EntityKey;
  createdAt: DateTime;
  createdBy?: TenantMinDTO | ITenantDTO;
  status: PbdStatus;
  module?: PbdModule;
}

export default class ModuleSettingsService {
  moduleSettingsApi: IModuleSettingsControllerClient;
  articlesApi: IArticlesControllerClient;
  toDosApi: IToDosControllerClient;
  projectApi: IProjectsControllerClient;
  customFormsApi: ICustomFormsControllerClient;
  goalsApi: IGoalsControllerClient;
  opportunitiesApi: IOpportunitiesControllerClient;
  claimsApi: IClaimsControllerClient;

  constructor(
    moduleSettingsApi: IModuleSettingsControllerClient,
    articlesApi: IArticlesControllerClient,
    toDosApi: IToDosControllerClient,
    projectApi: IProjectsControllerClient,
    customFormsApi: ICustomFormsControllerClient,
    goalsApi: IGoalsControllerClient,
    opportunitiesApi: IOpportunitiesControllerClient,
    claimsApi: IClaimsControllerClient,
  ) {
    this.moduleSettingsApi = moduleSettingsApi;
    this.articlesApi = articlesApi;
    this.toDosApi = toDosApi;
    this.projectApi = projectApi;
    this.customFormsApi = customFormsApi;
    this.goalsApi = goalsApi;
    this.opportunitiesApi = opportunitiesApi;
    this.claimsApi = claimsApi;
  }

  getByModuleAndType(module: PbdModule, settingType: SettingType) {
    return this.moduleSettingsApi.getByModuleAndType(module, settingType);
  }

  async getAllAsVm() {
    const [settings, startpageSettings, blogSetting, docSetting, wikiSetting] = await Promise.all([
      this.moduleSettingsApi.getAll(),
      this.moduleSettingsApi.getStartpageSettings(),
      this.moduleSettingsApi.getArticleSetting(PbdModule.Blog),
      this.moduleSettingsApi.getArticleSetting(PbdModule.DocumentManagement),
      this.moduleSettingsApi.getArticleSetting(PbdModule.KnowledgeBase),
    ]);
    return new AppSettingsVm(settings, { blogSetting, docSetting, wikiSetting, startpageSettings });
  }

  async getJoyRideStepsFromGitHub(url: string) {
    //TODO2 verify
    const resp = await axios.get<string>(url);
    if (resp.status !== 200) throw new Error("Invalid");
    return resp.data;
  }

  save(module: PbdModule, settingType: SettingType, settingValue: unknown) {
    const data: IModuleSettingDTO = {
      module: module,
      type: settingType,
      value: JSON.stringify(settingValue),
    };

    return this.moduleSettingsApi.addOrUpdateModuleSettings(module, settingType, new ModuleSettingDTO(data));
  }

  hardDelete(module: PbdModule, settingType: SettingType, id: string) {
    return this.moduleSettingsApi.deleteModuleSettingPartial(module, settingType, id);
  }

  mapPinnedEntities(item: ItemWithTags, app: PbdModule) {
    if (app == PbdModule.Article && item.module) {
      app = item.module;
    }
    const updatedItem: ItemWithTags = {
      id: item.id,
      title: item.title,
      description: item.description,
      capabilities: item.capabilities,
      createdAt: item.createdAt,
      status: item.status,
      entityKey: item.entityKey,
      createdBy: item.createdBy,
      tags: item.tags,
      link: (AppUiData[app].details ?? AppUiData[app].edit)?.replace(":id", item.id.toString()),
    };
    return updatedItem;
  }

  async getPinnedItemsByApp(item: Pick<IPinnedItemDTO, "entityKey" | "id">): Promise<ItemWithTags> {
    //1 Call the settings api and get module settings;
    //2 Thinks about a clever undefinded / no exsiting data value => empty array;
    //3 Return the list of items with tags
    const id = Number(item.id.split("_")[0]);
    switch (item.entityKey) {
      case EntityKey.ToDo:
        return this.mapPinnedEntities(await this.toDosApi.getById(id), PbdModule.ToDoManagement);
      case EntityKey.Project:
        return this.mapPinnedEntities(await this.projectApi.getById(id), PbdModule.ProjectManagement);
      case EntityKey.Article:
        return this.mapPinnedEntities(await this.articlesApi.getById(id.toString()), PbdModule.Article);
      case EntityKey.CustomForm:
        return this.mapPinnedEntities(await this.customFormsApi.getById(id), PbdModule.CustomForms);
      case EntityKey.Goal:
        return this.mapPinnedEntities(await this.goalsApi.getById(id), PbdModule.GoalManagement);
      case EntityKey.Opportunity:
        return this.mapPinnedEntities(await this.opportunitiesApi.getById(id), PbdModule.OpportunityManagement);
      case EntityKey.Claim:
        return this.mapPinnedEntities(await this.claimsApi.getById(id), PbdModule.ClaimManagement);
      default:
        throw Error("Not implemented");
    }
  }

  changePinnedItemsOrder(pinnedItems: IPinnedItemDTO[], module: PbdModule) {
    for (let i = 0; i < pinnedItems.length; i++) {
      const entity = pinnedItems[i];
      entity.orderNumber = i;
    }
    const dto = new PinnedToAppHomePageDTO({ items: pinnedItems.map((x) => new PinnedItemDTO(x)) });
    return this.save(module, SettingType.PinnedToAppHomePage, dto);
  }

  async getArticleActionSettings(app: PbdModule) {
    const data = await this.moduleSettingsApi.getByModuleAndType(app, SettingType.ArticleActionSetting);
    return data.value ? JsonHelpers.parse<IArticleActionSetting>(data.value) : DefaultArticleActionSettings;
  }
}
