import React, { FocusEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import useSWRImmutable from "swr/immutable";

import { IDepartmentDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { ListHelpers } from "../../../../../Helpers/ListHelpers";
import { LoadingComponent } from "../../loading/loadingComponent";
import { BaseSelectProps } from "./BaseSelectProps";
import { getLabel, getValue } from "./reactSelectHelpers";

type DepartmentDTOForMigration = Pick<IDepartmentDTO, "id" | "title">;

interface IProps extends BaseSelectProps<DepartmentDTOForMigration> {
  onChange: (id?: number | number[]) => void;
  onBlur?: FocusEventHandler;
  invalid?: boolean;
}

function DepartmentSelect(props: IProps) {
  const { selectedIds, onChange, isMulti, isClearable, availableOptions, onBlur, invalid } = props;
  const { t } = useTranslation();
  const { departmentsApi } = useAPIs();
  const { data } = useSWRImmutable("/api/departments", () => departmentsApi.getAll());

  function handleChange(newValue: readonly DepartmentDTOForMigration[] | DepartmentDTOForMigration | null) {
    if (newValue == null) {
      onChange(undefined);
    } else {
      if (newValue instanceof Array) {
        onChange(newValue.map((x) => x.id));
      } else {
        onChange(newValue.id);
      }
    }
  }

  const defaultValue = React.useMemo(() => {
    if (data && selectedIds != null) {
      const dv = data.filter((x) => selectedIds.includes(x.id));
      return dv as DepartmentDTOForMigration[];
    }
    return null;
  }, [data, selectedIds]);

  const options = React.useMemo(() => {
    if (availableOptions) {
      return ListHelpers.sortByString(availableOptions, (x) => x.title);
    } else {
      return ListHelpers.sortByString(data, (x) => x.title);
    }
  }, [availableOptions, data]);

  if (!options) return <LoadingComponent />;

  return (
    <Select
      key={defaultValue?.length == 0 ? "departmentSelect" : JSON.stringify(defaultValue)}
      menuPosition="fixed"
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        container: (base) => ({
          ...base,
          border: invalid ? "1px solid red" : base.border,
        }),
      }}
      options={options}
      isSearchable
      isClearable={isClearable}
      isMulti={isMulti}
      defaultValue={defaultValue}
      onChange={handleChange}
      placeholder={`${t("Search by name")}...`}
      name="departmentId"
      inputId="departmentId"
      onBlur={onBlur}
      className={invalid ? "is-invalid" : undefined}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
    />
  );
}

export default DepartmentSelect;
