import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { useQueryParams, withDefault } from "use-query-params";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { NumberArrayParam } from "../../../Helpers/QueryHelpers";
import { ValidArticleApps } from "../../../pbdServices/services/Articles/articleService";
import { ArticleRoutePaths } from "../../articles/articleRoutePaths";
import { useAppContext } from "../../shared/contexts/appContext";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import ArticlePrintout from "../components/articlePrintout";
import ArticleWaterMark from "../components/articleWaterMark";
import PrintoutIncludeOptions, { PrintOptions } from "../components/printoutIncludeOptions";
import QrCodeContainer from "../components/qrCodeContainer";

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });

function PrintArticlesBulkPage() {
  const { articlesApi, moduleSettingsApi } = useAPIs();
  const { appSettings } = useAppContext();
  const { module } = useParams<{ module: PbdModule }>() as { module: PbdModule };
  const articleSettings = appSettings[module as ValidArticleApps];
  const [query, setQuery] = useQueryParams({
    id: withDefault(NumberArrayParam, undefined),
  });
  const [options, setOptions] = useLocalStorage<PrintOptions>("printOptions", new PrintOptions());

  const { data: articlesToPrint } = useSWR(["api/articles", query.id], () =>
    articlesApi
      .getAllQuery({ module: module, id: query.id })
      .then((resp) => resp.sort((a, b) => collator.compare(a.title, b.title))),
  );

  const handleOptionsChange = (value: PrintOptions) => setOptions(value);

  return (
    <>
      <PrintoutIncludeOptions
        availableOptions={["showQrCode"]}
        onChange={handleOptionsChange}
        initialOptions={options}
      />
      {articleSettings.articleWatermarkSettings && (
        <ArticleWaterMark articleWatermarkSetting={articleSettings.articleWatermarkSettings} />
      )}
      {articlesToPrint?.map((article) => (
        <React.Fragment key={article.id}>
          <ArticlePrintout article={article} />
          <div className="pagebreakBulk" />
        </React.Fragment>
      ))}
      {options.showQrCode && <QrCodeContainer url={ArticleRoutePaths.HomePage(module)} />}
    </>
  );
}

export default PrintArticlesBulkPage;
