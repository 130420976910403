import { IInventoryInspectionsConnectedDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import InspectionsFilteredResult, { FilterBy } from "./inspectionsFilteredResult";

export default class UpcomingInspections {
  constructor() {
    this.segments = {} as Record<FilterBy, InspectionsFilteredResult>;
    this.totalEntries = [];
  }
  segments: Record<FilterBy, InspectionsFilteredResult>;
  totalEntries: IInventoryInspectionsConnectedDTO[];
}
