import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import { ISmartViewDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import { AbsencePlannerRoutePaths } from "../../../absencePlanner/absencePlannerRoutePaths";
import { qmBaseIcons } from "../icons/qmBaseIcons";

interface IProps {
  item: ISmartViewDTO;
}

const AbsencePlannerSmartViewButtons: React.FC<IProps> = (props) => {
  const { item } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div>
        <Button
          tag={Link}
          size="sm"
          outline
          to={AbsencePlannerRoutePaths.AbsenceRequestIndex + item.query}
          className="me-2"
        >
          <qmBaseIcons.ListSolid /> {t("Overview")}
        </Button>
        <Button
          tag={Link}
          size="sm"
          outline
          to={AbsencePlannerRoutePaths.AbsenceScheduleIndex + item.query}
          className="me-2"
        >
          <qmBaseIcons.CalendarDays /> {t("Calendar")}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AbsencePlannerSmartViewButtons;
