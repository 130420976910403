export class ColorHelpers {
  static getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}

export const ColorMap: Record<string, string> = {
  "1": "blue",
  "2": "green",
  "3": "yellow",
  "4": "brown",
  "5": "tomato",
  "6": "purple",
  "7": "cyan",
  "8": "teal",
};

export const getColor = (key: string, index: number): string => {
  const defaultVal = ColorMap[key];
  const valByNumber = ColorMap[index];
  return defaultVal ?? valByNumber ?? ColorHelpers.getRandomColor();
};
