import { ColumnDef, SortingState, VisibilityState } from "@tanstack/react-table";

import { idOfSelectionColumn } from "../reactstrapTable";

export class ReactTableStateDTO<T> {
  constructor(columns: ColumnDef<T>[], columnsVisibleDefault?: (keyof T | string)[], isAlwaysSelectable?: boolean) {
    this.pageSize = 10;
    this.sorting = [];
    const visibility: VisibilityState = {};
    if (columnsVisibleDefault) {
      for (const col of columns) {
        if (col.enableHiding === false) {
          continue;
        }
        if (col.id && columnsVisibleDefault.includes(col.id)) {
          visibility[col.id] = true;
        } else if (col.id) {
          visibility[col.id] = false;
        }

        if (isAlwaysSelectable) {
          visibility[idOfSelectionColumn] = true;
        }
      }
    }
    this.columnVisibility = visibility;
  }
  sorting: SortingState;
  pageSize: number;
  columnVisibility: VisibilityState;
}
