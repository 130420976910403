import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  IBasicAdminDataSettings,
  IOrganisationDTO,
  ITenantDetailsDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices, useAPIs } from "../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { SettingKeyNames } from "../../../../services/Settings/SettingKeyNames";
import AvatarSpanOrganisation from "../../../organisations/components/avatarSpanOrganisation";
import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";
import AvatarSpanWithName from "../../../shared/components/tenants/avatarSpanWithName";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import { AdminRoutePaths } from "../../adminRoutePaths";

type AdminDataTypes = "ManagingDirector" | "DataProtectionOfficer" | "Organisation";

/**
 * Admin -> Getting Started Index Page
 * */
function GettingStartedIndex() {
  const { t } = useTranslation();
  const { tenantsApi, organisationsApi, appSettingsApi, settingsApi } = useAPIs();
  const { settingsService } = useAPIServices();
  const [loading, setLoading] = React.useState(true);
  const [showRefreshBilling, setShowRefreshBilling] = React.useState(false);
  const [basicAdminData, setBasicAdminData] = React.useState<IBasicAdminDataSettings>();
  const [gettingStartedDone, setGettingStartedDone] = React.useState(true);
  const [managingDirector, setManagingDirector] = React.useState<ITenantDetailsDTO>();
  const [dataProtectionOfficer, setDataProtectionOfficer] = React.useState<ITenantDetailsDTO>();
  const [productOwner, setProductOwner] = React.useState<IOrganisationDTO>();
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const confirm = useConfirmation();

  const tryToDelete = async (item?: AdminDataTypes) => {
    await confirm({ itemsToDelete: [{ id: item, title: item }] }).then(() => resetBasicAdminData(item));
  };

  async function resetBasicAdminData(itemToDelete?: AdminDataTypes) {
    if (!basicAdminData) {
      throw Error("Basic admin data is missing");
    }
    if (itemToDelete == "ManagingDirector") {
      basicAdminData.managingDirectorId = 0;
    } else if (itemToDelete == "DataProtectionOfficer") {
      basicAdminData.dataProtectionOfficerId = 0;
    } else if (itemToDelete == "Organisation") {
      basicAdminData.organisationId = 0;
    }
    setLoading(true);
    const resp = await wrapApiCallWithToast(() =>
      settingsService.updateAppSettings({
        key: SettingKeyNames.BasicAdminData,
        value: JSON.stringify(basicAdminData),
      }),
    );
    if (resp.isOk) {
      setLoading(false);
      handleRefresh();
    }
  }

  React.useEffect(() => {
    async function getData() {
      setLoading(true);
      const gettingStartedDone = await settingsService.getGettingStartedDone();
      setGettingStartedDone(gettingStartedDone);
      const billingPropertiesResp = await appSettingsApi.getAllSubscriptions();
      if (!billingPropertiesResp) {
        // Todo here lint is saying property is alwys falsy
        setShowRefreshBilling(true);
      } else {
        settingsService.setCustomerId(billingPropertiesResp.customerId);
      }
      const serverSettings = await appSettingsApi.getServerSettings();
      settingsService.setCustomerKey(serverSettings.tokens.customerKey);
      const basicAdminData = await settingsApi.getBasicAdminData();
      setBasicAdminData(basicAdminData);
      // Todo here lint is saying property is alwys truthy
      if (basicAdminData.managingDirectorId) {
        const md = await tenantsApi.getById(basicAdminData.managingDirectorId);
        setManagingDirector(md);
      }
      if (basicAdminData.dataProtectionOfficerId) {
        const dpo = await tenantsApi.getById(basicAdminData.dataProtectionOfficerId);
        setDataProtectionOfficer(dpo);
      }
      if (basicAdminData.organisationId) {
        const org = await organisationsApi.getById(basicAdminData.organisationId);
        setProductOwner(org);
      }

      setLoading(false);
    }
    getData();
  }, [refresh]);

  async function markAsDone() {
    await settingsService.updateAppSettings({
      key: SettingKeyNames.GettingStartedAdminDone,
      value: JSON.stringify(!gettingStartedDone),
    });
    handleRefresh();
  }

  return (
    <Card className="mb-3" id="gettingStartedDataCard">
      <Card.Header>
        <Card.Title as="h5">
          <qmBaseIcons.PenSquare /> {t("Base data")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        {t("Please fill out the base data listed below")}
        <ListGroup variant="flush">
          {showRefreshBilling && (
            <ListGroup.Item>
              <h3>{t("Refresh subscription")}</h3>
              <Link to={AdminRoutePaths.HomePageBilling} className="btn btn-primary">
                <qmBaseIcons.Refresh /> {t("Refresh")}
              </Link>
            </ListGroup.Item>
          )}

          {!loading && basicAdminData && (
            <React.Fragment>
              <ListGroup.Item>
                {!basicAdminData.organisationId ? (
                  <Link to={AdminRoutePaths.SetProductOwner}>{t("Your company")}</Link>
                ) : (
                  <React.Fragment>
                    {t("Your company")}
                    <div className="d-flex">
                      {productOwner && <AvatarSpanOrganisation organisation={productOwner} />}
                      {!gettingStartedDone && productOwner && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="ms-3"
                          onClick={() => tryToDelete("Organisation")}
                        >
                          <qmBaseIcons.Delete />
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                {!basicAdminData.managingDirectorId || basicAdminData.managingDirectorId == 0 ? (
                  <Link to={AdminRoutePaths.SetTenantInSpecialRole.replace(":specialRole", "ManagingDirector")}>
                    {t("Managing Director")}
                  </Link>
                ) : (
                  <React.Fragment>
                    {t("Managing Director")}
                    <div className="d-flex">
                      {managingDirector && <AvatarSpanWithName tenant={managingDirector} />}

                      {!gettingStartedDone && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="ms-3"
                          onClick={() => tryToDelete("ManagingDirector")}
                        >
                          <qmBaseIcons.Delete />
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </ListGroup.Item>
              <ListGroup.Item>
                {!basicAdminData.dataProtectionOfficerId || basicAdminData.dataProtectionOfficerId == 0 ? (
                  <Link to={AdminRoutePaths.SetTenantInSpecialRole.replace(":specialRole", "DataProtectionOfficer")}>
                    {t("DataProtectionOfficer")}
                  </Link>
                ) : (
                  <React.Fragment>
                    {t("Data Protection Officer")}
                    <div className="d-flex">
                      {dataProtectionOfficer && <AvatarSpanWithName tenant={dataProtectionOfficer} />}

                      {!gettingStartedDone && (
                        <Button
                          variant="outline-danger"
                          size="sm"
                          className="ms-3"
                          onClick={() => tryToDelete("DataProtectionOfficer")}
                        >
                          <qmBaseIcons.Delete />
                        </Button>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </ListGroup.Item>
            </React.Fragment>
          )}
          <ListGroup.Item>
            {gettingStartedDone ? (
              <Button color="success" size="sm" onClick={markAsDone}>
                <qmBaseIcons.CheckCircle /> {t("Completed")}
              </Button>
            ) : (
              <Button color="outline-primary" onClick={markAsDone}>
                {t("Completed?")}
              </Button>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default GettingStartedIndex;
