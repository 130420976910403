import i18next from "i18next";
import { CellProps, Column } from "react-table";

import { ITenantMinDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";

import AvatarSpanWithName from "../../tenants/avatarSpanWithName";

interface IProps {
  createdBy?: ITenantMinDTO;
}

/**
 * This column requires a property of type tags:TagDTO
 */
export function getCreatedByColumn<T extends IProps>() {
  const column: Column<T> = {
    id: "createdBy",
    Header: <>{i18next.t("Created by")}</>,
    accessor: (originalRow, rowIndex) => originalRow.createdBy?.fullName,
    Cell: ({ row }: CellProps<T>) => (
      <>{row.original.createdBy && <AvatarSpanWithName tenant={row.original.createdBy} />}</>
    ),
  };
  return column;
}
