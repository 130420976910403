import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { ICustomField } from "../../generatedCode/pbd-core/pbd-core-api";

import { PbdRoles } from "../../services/Authz/PbdRoles";
import { PbdRouteModel } from "../router/pbdRouteModel";
import { AbsencePlannerRoutePaths } from "./absencePlannerRoutePaths";

const defaultRouteParams: Partial<PbdRouteModel> = {
  roles: [PbdRoles.AbsencePlanner_Standard, PbdRoles.AbsencePlanner_ModuleAdmin],
};

export const absencePlannerLazyRoutes: RouteObject[] = [
  {
    path: AbsencePlannerRoutePaths.HomePage,
    Component: lazy(() => import("./pages/homePageAbsence")),
    ...defaultRouteParams,
  },
  {
    path: AbsencePlannerRoutePaths.AbsenceRequestCreate,
    Component: lazy(() => import("./absenceRequest/pages/createPageAbsenceRequest")),
    ...defaultRouteParams,
  },
  {
    path: AbsencePlannerRoutePaths.AbsenceRequestIndex,
    Component: lazy(() => import("./absenceRequest/pages/indexPageAbsenceRequest")),
    ...defaultRouteParams,
  },
  {
    path: AbsencePlannerRoutePaths.AbsenceRequestEdit,
    Component: lazy(() => import("./absenceRequest/pages/detailsPageAbsenceRequest")),
    ...defaultRouteParams,
  },
  {
    path: AbsencePlannerRoutePaths.AbsenceScheduleIndex,
    Component: lazy(() => import("./absenceScheduler/pages/indexPageAbsenceSchedule")),
    ...defaultRouteParams,
  },
  {
    path: AbsencePlannerRoutePaths.AbsencePlannerStatistics,
    exact: false,
    Component: lazy(() => import("./pages/statisticsPageAbsencePlanner")),
    ...defaultRouteParams,
  },
];

export class AbsencePlannerSettings {
  constructor() {
    this.customFields = [];
  }
  customFields: ICustomField[];
}
