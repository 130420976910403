import { FormikProps } from "formik";

import { PbdRoles } from "../../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../../services/Authz/authService";
import { useAppContext } from "../../../contexts/appContext";
import CardCollapsible from "../../cardComponents/cardCollapsible";

interface IProps<T> {
  formikBag: FormikProps<T>;
}

function FormikDebugInfo<T>({ formikBag }: IProps<T>) {
  const { meAsUser } = useAppContext();
  if (hasRole(meAsUser, [PbdRoles.Dev])) {
    return (
      <CardCollapsible cardTitle={"Formik DEBUG"} cardId="formikDebugInfo">
        <pre
          style={{
            background: "#f6f8fa",
            fontSize: ".8rem",
            padding: ".5rem",
          }}
        >
          {JSON.stringify(formikBag, null, 2)}
        </pre>
      </CardCollapsible>
    );
  } else {
    return null;
  }
}
export default FormikDebugInfo;
