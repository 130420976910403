/* eslint-disable @typescript-eslint/naming-convention */
import * as Handlebars from "handlebars";
import { DateTime } from "luxon";

import {
  CustomFieldDTO,
  EmailCreateDTO,
  ICostDTO,
  IEmailsControllerClient,
  IFileDTO,
  ITenantDTO,
  ITrainingDTO,
  ITrainingsControllerClient,
  TenantAsAttendee,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { SendNotificationForm } from "../../../ClientApp/shared/components/modals/notificationForm";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import StringHelpers from "../../../Helpers/StringHelpers";
import { TrainingSettings } from "../../Models/Trainings/TrainingSettings";
import FileService from "../Files/fileService";
import { MeAsUser } from "../UserSettings/models/me-as-user";

// Please do not delete. This is required to find a customer customization
// const customizationId = CustomerCustomizations.Maximator_TrainingOrderTemplate;

interface CustomFieldForTemplate {
  name: string;
  value: string;
}

interface MaximatorTemplateContent {
  training: ITrainingDTO;
  dateFormatted: string;
  attendees: TenantAsAttendee[];
  customFields: CustomFieldForTemplate[];
  costs: ICostDTO[];
  selfUrl: string;
  files: IFileDTO[];
  hostUrl: string;
  doneBy: ITenantDTO;
}

export default class CustomerCustomizationService {
  private readonly trainingsApi: ITrainingsControllerClient;
  private readonly emailsApi: IEmailsControllerClient;
  fileService: FileService;
  constructor(fileService: FileService, emailsApi: IEmailsControllerClient, trainingApi: ITrainingsControllerClient) {
    this.fileService = fileService;
    this.emailsApi = emailsApi;
    this.trainingsApi = trainingApi;
  }

  /**
   * Download and compile the template from the backend
   * @returns the compiled template
   */
  private async _getMaximatorTemplate(): Promise<Handlebars.TemplateDelegate<MaximatorTemplateContent>> {
    const response = await fetch(this.fileService.getHostUrl() + "/templates/maximator/orderEmail.html").then((x) =>
      x.text(),
    );
    return Handlebars.compile<MaximatorTemplateContent>(response);
  }

  async maximator_getTrainingOrderTemplate(
    training: ITrainingDTO,
    meAsUser: MeAsUser,
    moduleSettings: TrainingSettings,
    url: string,
  ) {
    const files = await this.trainingsApi.getConnectedFiles(training.id);
    const attendees = await this.trainingsApi.getAttendeesByTrainingId(training.id);
    const costs = await this.trainingsApi.getCosts(training.id);

    const customFieldsToInclude = ["Hotelreservierung nötig", "Anreise am Vortag"];
    const customFieldsForMail = moduleSettings.customFields.filter((x) => customFieldsToInclude.includes(x.name));
    const cfForTemplate: CustomFieldForTemplate[] = [];
    for (const c of customFieldsForMail) {
      const trainingCf = training.customFields?.find((x) => x.id == c.id);
      cfForTemplate.push({ name: c.name, value: trainingCf?.value ?? "Nicht festgelegt" });
    }

    const template = await this._getMaximatorTemplate();

    //Set proper absoluteURL for each file
    files.forEach((f) => (f.absoluteUrl = this.fileService.getFileDownloadURL(f.id)));

    const templateData: MaximatorTemplateContent = {
      training: training,
      dateFormatted: `${DateTimeLuxonHelpers.convertUtcToDate(training.plannedAt)}${
        training.plannedAtEnd ? ` - ${DateTimeLuxonHelpers.convertUtcToDate(training.plannedAtEnd)}` : ""
      }`,
      customFields: cfForTemplate,
      attendees: attendees,
      doneBy: meAsUser.tenant,
      costs: costs,
      selfUrl: url,
      files: files,
      hostUrl: this.fileService.getHostUrl(),
    };

    const messageTemplate = template(templateData);
    const htmlWithoutUmlaute = StringHelpers.replaceUmlaute(messageTemplate);
    const sentMail: SendNotificationForm = {
      to: "aplessmann@maximator.de",
      subject: "Bitte Schulung für folgende(n) Mitarbeiter(in) bestellen",
      message: htmlWithoutUmlaute,
    };
    return sentMail;
  }

  async submitEmailNotification(dto: SendNotificationForm, item: ITrainingDTO, trainingSettings: TrainingSettings) {
    const customFieldToUpdate = trainingSettings.customFields.find((x) => x.name == "Bestellung an Einkauf ausgelöst");
    if (customFieldToUpdate == null) {
      alert(`Custom Field "Bestellung an Einkauf ausgelöst" not found. It will not be updated.`);
    }

    const resp = await this.emailsApi.create(
      new EmailCreateDTO({
        to: dto.to ?? "",
        subject: dto.subject,
        body: dto.message,
      }),
    );
    //TODO: Error handling
    if (customFieldToUpdate) {
      const cf = new CustomFieldDTO({
        id: customFieldToUpdate.id,
        value: `Ja - @${DateTimeLuxonHelpers.convertUtcToDateTime(DateTime.now())}`,
      });
      try {
        if (item.customFields?.find((x) => x.id == customFieldToUpdate.id)) {
          await this.trainingsApi.editCustomField(item.id, cf.id, cf);
        } else {
          await this.trainingsApi.addCustomField(item.id, cf);
        }
      } catch (ex) {
        alert("CustomField not update");
      }
    }
    return resp;
  }
}
