import React from "react";
import { useTranslation } from "react-i18next";
import { Button, CardHeader, CardTitle } from "reactstrap";

import { qmBaseIcons } from "../../../shared/components/icons/qmBaseIcons";

interface IProps {
  itemToUpdate?: unknown;
  onDelete?: () => void;
  disableDeleteButton?: boolean;
}

const BaseSettingsFormHeader: React.FC<IProps> = (props) => {
  const { itemToUpdate, onDelete, disableDeleteButton } = props;
  const { t } = useTranslation();
  return (
    <CardHeader>
      <div className="d-flex justify-content-between">
        <CardTitle tag="h5">{itemToUpdate ? <>{t("Edit")}</> : t("New")}</CardTitle>
        <div>
          {itemToUpdate != null && onDelete && (
            <Button
              onClick={onDelete}
              color="outline-secondary"
              disabled={disableDeleteButton}
              className="m-1"
              title={t("Recycle bin")}
            >
              <qmBaseIcons.Delete />
            </Button>
          )}
        </div>
      </div>
    </CardHeader>
  );
};

export default BaseSettingsFormHeader;
