import { useMemo } from "react";
import useSWR from "swr";

import {
  ITenantDTO,
  ITenantQualificationRequirementDTO,
  QualificationStatus,
} from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import SwrApiHelpers from "../../../pbdServices/services/Api/swr-api-helpers";
import { TenantQueryParameters } from "../../../pbdServices/services/Tenants/models/query-parameters";

export function useTenantsHook(query?: TenantQueryParameters, override?: TenantQueryParameters) {
  const { tenantsApi } = useAPIs();
  const combinedQuery = { ...query, ...override };
  const { data, mutate, isLoading, isValidating } = useSWR(
    [`/api/tenants/`, combinedQuery],
    () => tenantsApi.getAllQuery(combinedQuery),
    SwrApiHelpers.defaultIndexPageOptions(),
  );

  return {
    data,
    isValidating,
    isLoading,
    mutate,
  };
}

export function useTenantsMemo(
  data: ITenantDTO[] | undefined,
  connectedElements: { id: number }[] | undefined,
  preloadedData: ITenantDTO[] | undefined,
  requirements: ITenantQualificationRequirementDTO[] | undefined,
  localQuery?: Record<string, unknown>,
) {
  const filtered = useMemo(() => {
    if (data && connectedElements && !preloadedData) {
      return data
        .filter((x) => !connectedElements.map((y) => y.id).includes(x.id))
        .map((x) => {
          return { ...x, qualificationRequirements: requirements?.filter((f) => f.tenantId == x.id) };
        })
        .filter((x) =>
          localQuery?.isQualificationRequired == true
            ? x.qualificationRequirements?.find((q) => q.qualificationStatus != QualificationStatus.Ok)
            : true,
        );
    } else if (preloadedData) {
      return preloadedData;
    } else {
      return undefined;
    }
  }, [connectedElements, data, localQuery?.isQualificationRequired, preloadedData, requirements]);

  return filtered;
}
