import { ProductId } from "../generatedCode/pbd-core/pbd-core-api";

import { SupportedThemes } from "../Models/Enums/SupportedThemes";

// export type ProductId = "qmBase" | "Kroschke" | "DocuPro" | "imsBase" | "CareNavigator";
type ProductDomains = "qmbase.com" | "kroschke.com" | "docupro.de" | "imsbase.de" | "carenavigator.de";

export interface IHelpLinks {
  changelog: { url: string };
  homePage: { url: string };
  feedbackPolicy: { url: string };
  access: { url: string };
  tags: { url: string };
  integrations: { url: string };
  apps: { ideaManagement: { url: string }; talentManager: { url: string }; articleShared: { url: string } };
}

export interface IProductConfigInterface {
  /**This info is always available and will be awaited in the appContext */
  productId: ProductId;
  iconUrl: string;
  primaryColor: string;
  secondaryColor: string;
  vendorUrl: string;
  vendorName: string;
  imprintUrl: string;
  dataPrivacyUrl: string;
  landingPageUrl: string;
  productDisplayName: string;
  domain: ProductDomains;
  defaultTheme: SupportedThemes;
  helpLinks: IHelpLinks;
}

const getHelpLinks = (baseUrl = "https://help.qmbase.com"): IHelpLinks => {
  const item: IHelpLinks = {
    changelog: {
      /**This is the url of blog home page */
      url: `${baseUrl}/blog`,
    },
    homePage: { url: baseUrl },
    feedbackPolicy: { url: `${baseUrl}/docs/feedback` },
    tags: { url: `${baseUrl}/docs/common-features/#schlagworte` },
    integrations: { url: `${baseUrl}/docs/technical/integrations` },
    apps: {
      ideaManagement: { url: `${baseUrl}/docs/apps/idea-management` },
      talentManager: { url: `${baseUrl}/docs/apps/talent-manager` },
      articleShared: { url: `${baseUrl}/docs/apps/article-shared` },
    },
    access: {
      url: `${baseUrl}/docs/faqs/56/`,
    },
  };
  return item;
};

export const getProductConfig = (product: ProductId): IProductConfigInterface => {
  let config: IProductConfigInterface;
  switch (product) {
    case "DocuPro":
      config = docuProConfig;
      break;
    case "CareNavigator":
      config = careNavigatorConfig;
      break;
    case "imsBase":
      config = imsBaseConfig;
      break;
    default:
      config = defaultConfig;
  }
  return config;
};

/**
 * The default config is the qmBase config
 */
const defaultConfig: IProductConfigInterface = {
  productId: ProductId.QmBase,
  vendorName: "qmBase",
  vendorUrl: "https://qmBase.com",
  landingPageUrl: "https://qmBase.com",
  iconUrl: "https://qmbasesupport.blob.core.windows.net/public-icons/qmBase/navbarLogo.png",
  primaryColor: "#65ACE0",
  secondaryColor: "#29375F",
  imprintUrl: "https://qmbase.com/impressum",
  dataPrivacyUrl: "https://qmbase.com/datenschutz",
  productDisplayName: "qmBase",
  domain: "qmbase.com",
  defaultTheme: SupportedThemes.Standard,
  helpLinks: getHelpLinks(),
};

const docuProConfig: IProductConfigInterface = {
  ...defaultConfig,
  productId: ProductId.DocuPro,
  productDisplayName: "DocuPro",
  iconUrl: "https://caqadmin.blob.core.windows.net/public/DocuPro/Assets/NavbarLogo.png",
  imprintUrl: "https://jowecon.de/impressum-jowecon/",
  dataPrivacyUrl: "https://jowecon.de/datenschutz/",
};

const imsBaseConfig: IProductConfigInterface = {
  ...defaultConfig,
  productId: ProductId.ImsBase,
  productDisplayName: "imsBase",
  imprintUrl: "https://netzlink.com/impressum/",
  dataPrivacyUrl: "https://imsbase.de/datenschutzerklaerung/",
};

const careNavigatorConfig: IProductConfigInterface = {
  ...defaultConfig,
  productId: ProductId.CareNavigator,
  productDisplayName: "DSGVO - protect",
  iconUrl: "https://caqadmin.blob.core.windows.net/public/CareNavigator/images/navbarLogoNewSmall.png",
  imprintUrl: "https://www.carenavigator.de/impressum.php",
  dataPrivacyUrl: "https://www.carenavigator.de/datenschutzerklaerung.php",
  defaultTheme: SupportedThemes.Dark,
};
