import { DashboardItemType, IDashboardItemDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import JsonHelpers from "../../Json/jsonHelpers";
import { DashboardItemContentData, ViewAs } from "./dashboard-item-types";
import { ExternalChartSourceConfigExtension } from "./external-chart-source-config-extension";
import { GoalProgressDashboardItemConfigExtension } from "./goal-progress-dashboard-item-config-extension";
import { OpportunityProgressDashboardItemConfigExtension } from "./opportunityProgressDashboardItemConfigExtension";

// export class DashboardItemContent {
//   constructor(dto: IDashboardItemDTO) {
//     this.type = dto.type;
//     this.viewAs = ViewAs.Chart;
//     // if (dto.content) {
//     //   console.log(dto.content);
//     //   console.log(JSON.parse(dto.content));
//     //   this.data = JSON.parse(dto.content);
//     // }
//     if (dto.type == DashboardItemType.External) {
//       this.data = dto.content ? JsonHelpers.parse<ExternalChartSource>(dto.content) : new ExternalChartSource();
//     } else if (dto.type == DashboardItemType.GoalProgress) {
//       this.data = dto.content
//         ? JsonHelpers.parse<GoalProgressDashboardItemConfig>(dto.content)
//         : new GoalProgressDashboardItemConfig();
//     }
//   }
//   type: DashboardItemType;
//   data: DashboardItemContentData;
//   viewAs: ViewAs;
// }

export class BaseDashboardItemConfig {
  constructor(dto: IDashboardItemDTO) {
    const contentAsObj = dto.content ? JsonHelpers.parse<BaseDashboardItemConfig>(dto.content) : null;
    this.type = dto.type;
    this.viewAs = contentAsObj?.viewAs ?? ViewAs.Chart;
    if (dto.type == DashboardItemType.External) {
      this.extension = contentAsObj?.extension ?? new ExternalChartSourceConfigExtension();
    } else if (dto.type == DashboardItemType.GoalProgress) {
      this.extension = contentAsObj?.extension ?? new GoalProgressDashboardItemConfigExtension();
    } else if (dto.type == DashboardItemType.OpportunityProgress) {
      this.extension = contentAsObj?.extension ?? new OpportunityProgressDashboardItemConfigExtension();
    } else {
      throw Error("Not implemented");
    }
  }
  type: DashboardItemType;
  extension: DashboardItemContentData;
  viewAs: ViewAs;
}

export class GoalProgressDashboardItemConfig extends BaseDashboardItemConfig {
  declare extension: GoalProgressDashboardItemConfigExtension;
  constructor(dto: IDashboardItemDTO) {
    super(dto);
    this.extension = dto.content ? JSON.parse(dto.content) : new GoalProgressDashboardItemConfigExtension();
  }

  static isGoalProgressContent(obj: BaseDashboardItemConfig): obj is GoalProgressDashboardItemConfig {
    return obj.type == DashboardItemType.GoalProgress;
  }
}

export class OpportunityProgressDashboardItemConfig extends BaseDashboardItemConfig {
  declare extension: OpportunityProgressDashboardItemConfigExtension;
  constructor(dto: IDashboardItemDTO) {
    super(dto);
    this.extension = dto.content ? JSON.parse(dto.content) : new OpportunityProgressDashboardItemConfigExtension();
  }

  static isOpportunityProgressContent(obj: BaseDashboardItemConfig): obj is OpportunityProgressDashboardItemConfig {
    return obj.type == DashboardItemType.OpportunityProgress;
  }
}

export class ExternalDashboardItemConfig extends BaseDashboardItemConfig {
  declare extension: ExternalChartSourceConfigExtension;
  constructor(dto: IDashboardItemDTO) {
    super(dto);
    this.extension = dto.content ? JSON.parse(dto.content) : new ExternalChartSourceConfigExtension();
  }

  /**TODO: Complete checks */
  static isExternalDashboardItemContent(obj: BaseDashboardItemConfig): obj is ExternalDashboardItemConfig {
    return obj.type == DashboardItemType.External;
  }
}
