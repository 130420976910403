import { useTranslation } from "react-i18next";

import { ICommentDTO } from "../../../generatedCode/pbd-core/pbd-core-api";

import CommentItemComponent from "../../shared/components/comments/commentItemComponent";
import { ICommentRequiredBaseApi } from "../../shared/components/fileUploaderCommentsTab/baseApi";
import { IBaseItem } from "../../shared/components/fileUploaderCommentsTab/baseItem";

interface IProps {
  comments: ICommentDTO[];
  baseApi: ICommentRequiredBaseApi;
  item: IBaseItem;
}

function CommentsComponent(props: IProps) {
  const { comments, baseApi, item } = props;
  const { t } = useTranslation();

  return (
    <>
      <h5>
        {t("Comments")} ({comments.length})
      </h5>
      <hr />
      <ul className="list-unstyled">
        {comments.map((c) => (
          <CommentItemComponent key={c.id} comment={c} refreshParent={() => void 0} baseApi={baseApi} item={item} />
        ))}
      </ul>
    </>
  );
}
export default CommentsComponent;
