import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button, FormGroup } from "reactstrap";

import { ICapabilitiesDTO, ITenantMinDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import TenantSelectAsync from "../inputControl/select/tenantSelectAsync";
import AvatarSpanWithName from "../tenants/avatarSpanWithName";
import AvatarSpanWithoutTenant from "../tenants/avatarSpanWithoutTenant";

interface IItem {
  capabilities?: ICapabilitiesDTO;
  responsible?: ITenantMinDTO;
}

interface IProps {
  item: IItem;
  onSubmit?: (tenant?: ITenantMinDTO) => Promise<void>;
  isClearable?: boolean;
}

function CardResponsibleComponent(props: IProps) {
  const { isClearable = false, onSubmit, item } = props;
  const { t } = useTranslation();
  const [editMode, setEditMode] = React.useState(false);
  const [update, setUpdate] = React.useState<ITenantMinDTO>();

  const toggleEditMode = item.capabilities?.canEdit ? () => setEditMode(!editMode) : undefined;

  const onChangeResponsibleId = (item?: ITenantMinDTO | ITenantMinDTO[]) => {
    if (item && Array.isArray(item)) throw new Error("Array not allowed");
    //@ts-expect-error goes away in strict mode
    setUpdate(item);
  };

  const handleChangeResponsible = async () => {
    if (onSubmit) {
      const resp = await onSubmit(update);
      toggleEditMode?.();
    } else {
      toast.error(t("This action is not available"));
    }
  };

  return (
    <>
      <small className="text-dark-emphasis">{t("Responsible")}</small>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          {editMode && (
            <>
              <FormGroup>
                <TenantSelectAsync
                  onChange={onChangeResponsibleId}
                  defaultValue={item.responsible}
                  isClearable={isClearable}
                />
              </FormGroup>
              <Button color="link" className="m-1" size="sm" onClick={toggleEditMode}>
                {t("Cancel")}
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={handleChangeResponsible}
                disabled={
                  (!update && !isClearable) || !item.capabilities?.canEdit || item.responsible?.id == update?.id
                }
              >
                {t("Save")}
              </Button>
            </>
          )}
          {!editMode && (
            <>
              {item.responsible ? (
                <AvatarSpanWithName tenant={item.responsible} onClick={toggleEditMode} />
              ) : (
                <AvatarSpanWithoutTenant onClick={toggleEditMode} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CardResponsibleComponent;
