import {
  CapabilitiesDTO,
  EntityPermissionAddOrUpdateDTO,
  EntityPermissionCreateDTO,
  EntityPermissionDTO,
  ICapabilitiesDTO,
  ICompanyFunctionDTO,
  IDepartmentPositionWithChildrenDTO,
  IEntityPermissionAddOrUpdateDTO,
  IEntityPermissionDTO,
  IEntityPermissionsControllerClient,
  IQualificationDTO,
  ITenantCompanyFunctionDTO,
  ITenantDTO,
  ITenantQualificationDTO,
  ITenantQualificationDoneDTO,
  ITenantsControllerClient,
  ITrainingDTO,
  PbdModule,
  TenantDTO,
  TenantQualificationDTO,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { DateTimeLuxonHelpers } from "../../../Helpers/DateTimeLuxonHelpers";
import { CompanyFunctionsConnectedToTenant } from "../../../Models/CompanyFunctions/CompanyFunctionsConntectedToTenant";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import { hasRole } from "../../../services/Authz/authService";
import { EntityPermissionService } from "../../../services/EntityPermission/entityPermissionService";
import { QualificationsMockData } from "../../__tests__/config/mockData/qualificationsMockData";
import { QualificationVm } from "../Qualifications/models/qualification-vm";
import { TenantQueryParameters } from "../Tenants/models/query-parameters";
import { MeAsUser } from "../UserSettings/models/me-as-user";
import { QualificationStatus } from "./models/qualificationStatus";
import { ITenantQualificationVm } from "./models/tenant-qualification-vm";
import { TenantForQualificationVM } from "./models/tenantForQualificationsVM";
import { TenantQualification } from "./models/tenantQualification";

export class QualificationMatrixService {
  entityPermissionApi: IEntityPermissionsControllerClient;
  tenantsApi: ITenantsControllerClient;
  entityPermissionService: EntityPermissionService;

  constructor(entityPermissionApi: IEntityPermissionsControllerClient, tenantsApi: ITenantsControllerClient) {
    this.entityPermissionApi = entityPermissionApi;
    this.entityPermissionService = new EntityPermissionService();
    this.tenantsApi = tenantsApi;
  }

  async getById(id: number, meAsUser: MeAsUser) {
    const entityPermissions = await this.getEntityPermissions();
    const tenant = await this.tenantsApi.getById(id);
    const permission = this.getPermissionByTenant(tenant.id, entityPermissions);
    const capabilities = this.getCapabilities(meAsUser, permission);
    tenant.capabilities = capabilities;
    return tenant;
  }

  async getAllTenants(meAsUser: MeAsUser, query?: TenantQueryParameters) {
    const entityPermissions = await this.getEntityPermissions();
    const queryCombined = query ?? {};
    const tenants = await this.tenantsApi.getAllQuery({
      ...queryCombined,
      pageSize: GlobalQmBaseConstants.DefaultPageSize_DuringMigration,
    });
    const availableTenants: TenantDTO[] = [];
    const t0 = performance.now();
    for (const element of tenants.data) {
      const permission = this.getPermissionByTenant(element.id, entityPermissions);
      const capabilities = this.getCapabilities(meAsUser, permission);
      element.capabilities = capabilities;

      if (permission) {
        const canAccess = this.entityPermissionService.canAccess(
          meAsUser,
          permission,
          PbdRoles.QualificationMatrix_ModuleAdmin,
        );
        if (!canAccess) continue;
      }
      element.entityPermission = permission;
      element.isPrivate = permission?.type?.isPrivate ?? false;
      availableTenants.push(element);
    }
    const t1 = performance.now();
    // console.log(`Call to getAllTenants took ${((t1 - t0) / 1000).toFixed(4)} seconds.`);
    return availableTenants;
  }

  getTenantQualifications(
    tenantQualifications: ITenantQualificationDTO[],
    qualifications: QualificationVm[],
  ): ITenantQualificationVm[] {
    const t0 = performance.now();
    const list: ITenantQualificationVm[] = [];
    for (const element of tenantQualifications) {
      const qualification =
        qualifications.find((x) => x.id == element.qualificationId) ??
        QualificationsMockData.getDeletePlaceholder(element.qualificationId);
      const vm = this._calculateNextInspectionDate(element, qualification);
      list.push(vm);
    }
    const t1 = performance.now();
    // console.log(`Call to getTenantQualifications took ${((t1 - t0) / 1000).toFixed(4)} seconds.`);
    return list;
  }

  private _calculateNextInspectionDate(
    element: ITenantQualificationDTO,
    qualification: QualificationVm,
  ): ITenantQualificationVm {
    const vm: ITenantQualificationVm = { ...element, qualification, qualificationStatus: QualificationStatus.Ok };
    if (!qualification.isRecurring || !vm.lastInspection) return vm;

    if (qualification.monitoringDuration) {
      vm.nextInspection = vm.lastInspection.plus(qualification.monitoringDuration);
      vm.isExpired = DateTimeLuxonHelpers.inPast(vm.nextInspection);
      if (qualification.warningDuration) {
        vm.warningTime = vm.nextInspection.minus(qualification.warningDuration);
        vm.isWarningTimeExpired = DateTimeLuxonHelpers.inPast(vm.warningTime);
      }
      if (vm.isExpired) {
        vm.qualificationStatus = QualificationStatus.Expired;
      } else if (vm.isWarningTimeExpired) {
        vm.qualificationStatus = QualificationStatus.WarningTime;
      }
    }
    return vm;
  }

  mapDataToVM(
    tenants: ITenantDTO[],
    companyFunctions: ICompanyFunctionDTO[],
    departmentPositions: IDepartmentPositionWithChildrenDTO[],
    qualifications: QualificationVm[],
    tenantCompanyFunctions: ITenantCompanyFunctionDTO[],
    tenantQualifications: TenantQualificationDTO[],
  ) {
    const t0 = performance.now();
    const list: TenantForQualificationVM[] = [];
    const tenantQualificationsVm = this.getTenantQualifications(tenantQualifications, qualifications);
    for (const element of tenants) {
      const vm = new TenantForQualificationVM(
        element,
        tenantQualificationsVm.filter((x) => x.tenantId == element.id),
        tenantCompanyFunctions.filter((x) => x.tenantId == element.id),
        departmentPositions,
        companyFunctions,
        qualifications,
        // permission,
      );
      list.push(vm);
    }
    const t1 = performance.now();
    // console.log(`Call to mapDatToVM took ${((t1 - t0) / 1000).toFixed(4)} seconds.`);
    return list;
  }

  getCapabilities(meAsUser: MeAsUser, entityPermission?: IEntityPermissionDTO) {
    const capabilities = new CapabilitiesDTO();
    if (hasRole(meAsUser, [PbdRoles.QualificationMatrix_ModuleAdmin])) {
      capabilities.canEdit = true;
    }
    const permissionForUser = entityPermission?.users.find((x) => x.id == meAsUser.user.id);
    if (permissionForUser) {
      if (permissionForUser.capabilities?.includes("canEdit")) {
        console.log(permissionForUser);
        capabilities.canEdit = true;
      }
    }
    return capabilities;
  }

  getPermissionByTenant(tenantId: number, entityPermissions: EntityPermissionDTO[]) {
    return entityPermissions.find(
      (x) => x.type && x.type.tableName == "Tenant" && x.type.keyValue == tenantId.toString(),
    );
  }

  mapDataToTenantQualificationTable(
    items: TenantForQualificationVM[],
    qualifications: IQualificationDTO[],
    qualificationsDone?: ITenantQualificationDoneDTO[],
    plannedTrainings?: ITrainingDTO[],
  ): TenantQualification[] {
    const list: TenantQualification[] = [];
    for (const tenant of items) {
      for (const missing of tenant.qualificationsMissing.filter(
        (x) => !tenant.qualificationsAvailable.map((a) => a.id).includes(x.id),
      )) {
        const toAdd: TenantQualification = {
          tenant: tenant,
          id: `${tenant.id}_${missing.id}`,
          isRequired: true,
          requiredQualification: missing,
          qualificationTitle: missing.title,
          qualification:
            qualifications.find((x) => x.id == missing.id) ?? QualificationsMockData.getDeletePlaceholder(missing.id),
          type: PbdModule.QualificationMatrix,
          qualificationsDone: qualificationsDone?.filter((x) => x.qualificationId == missing.id),
          plannedTrainings: plannedTrainings?.filter((x) => x.qualifications?.map((q) => q.id).includes(missing.id)),
          connectionStatus: QualificationStatus.AdditionalRequired,
          isActionRequired: true,
        };
        list.push(toAdd);
      }

      for (const availableQualification of tenant.qualificationsAvailable) {
        const toAdd: TenantQualification = {
          tenant: tenant,
          id: `${tenant.id}_${availableQualification.id}`,
          isRequired: tenant.qualificationsRequired.map((x) => x.id).includes(availableQualification.id),
          availableQualification: availableQualification,
          lastInspection: availableQualification.lastInspection,
          nextInspection: availableQualification.nextInspection,
          qualificationTitle: availableQualification.title,
          qualification:
            qualifications.find((x) => x.id == availableQualification.id) ??
            QualificationsMockData.getDeletePlaceholder(availableQualification.id),
          type: PbdModule.QualificationMatrix,
          qualificationsDone: qualificationsDone?.filter((x) => x.qualificationId == availableQualification.id),
          plannedTrainings: plannedTrainings?.filter((x) =>
            x.qualifications?.map((q) => q.id).includes(availableQualification.id),
          ),
          connectionStatus: availableQualification.qualificationStatus,
          isActionRequired: availableQualification.isExpired ?? false,
        };
        list.push(toAdd);
      }
    }

    return list;
  }

  getEntityPermissions() {
    //{ typeContains: 'app":"QualificationMatrix' }
    return this.entityPermissionApi.getAllQuery({ app: PbdModule.QualificationMatrix });
  }

  async setEntityPermission(tenantId: number, dto: IEntityPermissionAddOrUpdateDTO) {
    if (!dto.id) {
      const resp = await this.entityPermissionApi.create(
        new EntityPermissionCreateDTO({
          app: PbdModule.QualificationMatrix,
          tableName: "Tenant",
          keyName: "TenantId",
          keyValue: tenantId.toString(),
          isPrivate: dto.isPrivate,
        }),
      );
      dto.id = resp.id;
    }

    return this.entityPermissionApi.edit(dto.id, new EntityPermissionAddOrUpdateDTO(dto));
  }

  static getAllAssignedCompanyFunctions(tenants: TenantForQualificationVM[]): CompanyFunctionsConnectedToTenant[] {
    const assignedCompanyFunctionIds = tenants
      .map((x) => x.connectedCompanyFunctions)
      .reduce((pv, cv) => pv.concat(cv), []);
    return assignedCompanyFunctionIds;
  }

  static getBadgeColor(data: { qualificationStatus: QualificationStatus }) {
    switch (data.qualificationStatus) {
      case QualificationStatus.Ok:
        return "success";
      case QualificationStatus.AdditionalRequired:
        return "danger";
      case QualificationStatus.Expired:
        return "danger";
      case QualificationStatus.WarningTime:
        return "warning";
      default:
        return "secondary";
    }
  }
  static PermissionLevels: (keyof ICapabilitiesDTO)[] = ["canAccess", "canEdit"];
}
