/* eslint-disable @typescript-eslint/naming-convention */
import { isArray, isString } from "lodash";
import { DateTime, Duration, Interval } from "luxon";
import queryString from "query-string";
import {
  DecodedValueMap,
  EncodedValueMap,
  QueryParamConfig,
  QueryParamConfigMap,
  createEnumArrayParam,
  createEnumParam,
  decodeQueryParams,
  encodeQueryParams,
} from "use-query-params";

import {
  ApplicationUserQueryField,
  ArticleRevisionType,
  ArticleStatus,
  AuditActionType,
  ClaimType,
  EmployeeIdeaNamedQuery,
  EntityKey,
  Functioning,
  ITagDTO,
  MapImportToTable,
  OpportunityType,
  OrderBy,
  PbdEventType,
  PbdModule,
  PbdStatus,
  ToDoQueryField,
} from "../generatedCode/pbd-core/pbd-core-api";

import { TimeSpans } from "../Models/Enums/TimeSpans";
import { EmployeeIdeaStartEvent } from "../pbdServices/services/IdeaManagement/models/employeeIdeaStartEvent";
import { DateTimeLuxonHelpers } from "./DateTimeLuxonHelpers";
import { DateRange } from "./date-frame-helpers";

type InputPossibilities = string | (string | null)[] | null | undefined;

function decodeISODateTime(value: InputPossibilities) {
  if (value == null) return value;
  if (!isString(value)) return null;

  // This leads to problems transferring back to local time
  // const dateTime = DateTime.fromISO(value, { zone: "utc" });

  const dateTime = DateTime.fromISO(value);
  if (!dateTime.isValid) return null;
  return dateTime;
}

/**TODO: What happens if date contains time components? */
function decodeISODate(value: InputPossibilities) {
  if (value == null) return value;
  if (!isString(value)) return null;

  // This leads to problems transferring back to local time
  const dateTime = DateTime.fromISO(value, { zone: "utc" });

  // const dateTime = DateTime.fromISO(value);
  if (!dateTime.isValid) return null;
  return dateTime;
}

function decodeISODuration(value: string | (string | null)[] | null | undefined) {
  if (value == null) return value;
  if (!isString(value)) return null;
  const dur = Duration.fromISO(value);
  if (!dur.isValid) return null;
  return dur;
}

export const LuxonDateTimeParam: QueryParamConfig<DateTime | null | undefined, DateTime | null | undefined> = {
  encode(value: DateTime | null | undefined): string | (string | null)[] | null | undefined {
    return value?.toUTC().toISO();
  },
  decode(value: string | (string | null)[] | null | undefined): DateTime | null | undefined {
    //@ts-expect-error goes away in strict mode
    return decodeISODateTime(value);
  },
  equals(valueA: DateTime | null | undefined, valueB: DateTime | null | undefined) {
    if (valueA === valueB) return true;
    if (valueA == null || valueB == null) return valueA === valueB;
    return valueA.equals(valueB);
  },
};

export const LuxonDateParam: QueryParamConfig<DateTime | null | undefined, DateTime | null | undefined> = {
  encode(value: DateTime | null | undefined): string | null | undefined {
    return value?.toUTC().toISODate();
  },
  decode(value: InputPossibilities): DateTime | null | undefined {
    //@ts-expect-error goes away in strict mode
    return decodeISODate(value);
  },
  equals(valueA: DateTime | null | undefined, valueB: DateTime | null | undefined) {
    if (valueA === valueB) return true;
    if (valueA == null || valueB == null) return valueA === valueB;
    return DateTimeLuxonHelpers.isSameDate(valueA, valueB);
  },
};

export const LuxonDurationTimeParam: QueryParamConfig<Duration | null | undefined, Duration | null | undefined> = {
  encode(value: Duration | null | undefined): string | (string | null)[] | null | undefined {
    return value?.toISO();
  },
  decode(value: InputPossibilities): Duration | null | undefined {
    //@ts-expect-error goes away in strict mode
    return decodeISODuration(value);
  },
  equals(valueA: Duration | null | undefined, valueB: Duration | null | undefined) {
    if (valueA === valueB) return true;
    if (valueA == null || valueB == null) return valueA === valueB;
    return valueA.equals(valueB);
  },
};

export const SPAN_DATE_RANGE_PREFIX = "SPAN";

function decodeDateRange(value: string | (string | null)[] | null | undefined): DateRange | null | undefined {
  //@ts-expect-error goes away in strict mode
  if (value == null) return value;
  if (!isString(value)) return null;
  if (value.startsWith(SPAN_DATE_RANGE_PREFIX)) {
    const v = value.substring(SPAN_DATE_RANGE_PREFIX.length);
    return {
      kind: "span",
      span: v as TimeSpans,
    };
  }

  const iv = Interval.fromISO(value);
  if (!iv.isValid) return null;

  return {
    kind: "custom",
    interval: iv,
  };
}

export const LuxonDateRangeParam: QueryParamConfig<DateRange | null | undefined, DateRange | null | undefined> = {
  encode(value: DateRange | null | undefined): string | (string | null)[] | null | undefined {
    //@ts-expect-error goes away in strict mode
    if (value == null) return value;

    if (value.kind === "span") {
      return `${SPAN_DATE_RANGE_PREFIX}${value.span}`;
    }
    return value.interval.toISODate();
  },
  decode(value: string | (string | null)[] | null | undefined): DateRange | null | undefined {
    return decodeDateRange(value);
  },
  equals(valueA: DateRange | null | undefined, valueB: DateRange | null | undefined) {
    if (valueA == null || valueB == null) return valueA === valueB;
    if (valueA.kind != valueB.kind) return false;

    return valueA === valueB;
  },
};

export const OrderByParam = createEnumParam(Object.values(OrderBy));

export const ClaimTypeParam = createEnumParam(Object.values(ClaimType));

export const ClaimTypeArrayParam = createEnumArrayParam(Object.values(ClaimType));

export const OpportunityTypeArrayParam = createEnumArrayParam(Object.values(OpportunityType));

function encodeTag(tag: ITagDTO): string {
  return tag.id.toString();
}

function decodeTag(txt: string): ITagDTO {
  const tagId = Number(txt);
  return {
    id: tagId,
    //TODO
    title: "?",
  } as ITagDTO;
}

export const TagDTOParam: QueryParamConfig<ITagDTO | null | undefined, ITagDTO | null | undefined> = {
  encode(value: ITagDTO | null | undefined): string | (string | null)[] | null | undefined {
    //@ts-expect-error goes away in strict mode
    return value == null ? value : encodeTag(value);
  },
  decode(value: string | (string | null)[] | null | undefined): ITagDTO | null | undefined {
    //@ts-expect-error goes away in strict mode
    if (value == null) return value;
    if (isArray(value)) return undefined;

    //TODO fix this
    return decodeTag(value);
  },
};

export const TagArrayParam: QueryParamConfig<ITagDTO[], ITagDTO[]> = {
  encode(value: ITagDTO[]): string | (string | null)[] | null | undefined {
    return value.map((v) => encodeTag(v));
  },
  decode(value: string | (string | null)[] | null | undefined): ITagDTO[] {
    if (value == null) return [];

    if (isString(value)) return [decodeTag(value)];

    return value.filterMap((v) => (v == null ? undefined : decodeTag(v)));
  },
};

export const StatusArrayParam = createEnumArrayParam(Object.values(PbdStatus));

export const TodoFieldArrayParam = createEnumArrayParam(Object.values(ToDoQueryField));

export const ArticleStatusArrayParam = createEnumArrayParam(Object.values(ArticleStatus));

export const ApplicationUserQueryFieldArrayParam = createEnumArrayParam(Object.values(ApplicationUserQueryField));

export const FunctioningArrayParam = createEnumArrayParam(Object.values(Functioning));

export const MapImportToTableParam = createEnumParam(Object.values(MapImportToTable));

export const ArticleRevisionTypeParam = createEnumParam(Object.values(ArticleRevisionType));

export const EmployeeIdeaNamedQueryParam = createEnumParam(Object.values(EmployeeIdeaNamedQuery));

export const AuditActionTypeQueryParam = createEnumParam(Object.values(AuditActionType));

export const PbdEventTypeQueryParam = createEnumParam(Object.values(PbdEventType));

export const TimespanQueryParam = createEnumParam(Object.values(TimeSpans));

export const EmployeeIdeaStartEventQueryParam = createEnumParam(Object.values(EmployeeIdeaStartEvent));

export const PbdModuleArrayParam = createEnumArrayParam(Object.values(PbdModule));

export const PbdModuleParam = createEnumParam(Object.values(PbdModule));

export const EntityKeyArrayParam = createEnumArrayParam(Object.values(EntityKey));

function parseInt(value: string) {
  if (/^(-|\+)?(\d+)$/.test(value)) return Number(value);
  return undefined;
}

export const NumberArrayParam: QueryParamConfig<number[] | null | undefined, number[] | null | undefined> = {
  encode(value: number[] | null | undefined): string | (string | null)[] | null | undefined {
    return value?.map((v) => v.toString());
  },
  decode(value: string | (string | null)[] | null | undefined): number[] | null | undefined {
    //@ts-expect-error goes away in strict mode
    if (value == null) return value;

    if (isString(value)) {
      const val = parseInt(value);
      return val === undefined ? undefined : [val];
    }

    return value.filterMap((v) => (v != null ? parseInt(v) : undefined));
  },
};

function decodeStringArray(value: string | (string | null)[] | null | undefined): string[] | null | undefined {
  //@ts-expect-error goes away in strict mode
  if (value == null) return value;

  if (isString(value)) return [value];

  return value.filterMap((v) => (v != null ? v : undefined));
}

export const StringArrayParam: QueryParamConfig<string[] | null | undefined, string[] | null | undefined> = {
  encode(value: string[] | null | undefined): string | (string | null)[] | null | undefined {
    return value?.map((v) => v.toString());
  },
  decode(value: string | (string | null)[] | null | undefined): string[] | null | undefined {
    return decodeStringArray(value);
  },
};

export const QmQueryParams = {
  PbdModule: PbdModuleParam,
  Date: LuxonDateParam,
  DateTime: LuxonDateTimeParam,
  Duration: LuxonDurationTimeParam,
  DateRange: LuxonDateRangeParam,
};

export function encodeQueryString<T extends Partial<DecodedValueMap<QPCMap>>, QPCMap extends QueryParamConfigMap>(
  paramConfigMap: QPCMap,
  query: T,
) {
  const params = encodeQueryParams(paramConfigMap, query);
  const queryStr = queryString.stringify(params);
  return queryStr.length ? `?${queryStr}` : "";
}

export function decodeQueryString<QPCMap extends QueryParamConfigMap>(paramConfigMap: QPCMap, queryStr: string) {
  const query = queryString.parse(queryStr);
  return decodeQueryParams(paramConfigMap, query as Partial<EncodedValueMap<QPCMap>>);
}
