import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import invariant from "tiny-invariant";

import {
  CustomFieldCreateDTO,
  ICustomFieldCreateDTO,
  PbdModule,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import CustomFieldService from "../../../../pbdServices/services/CustomFields/customFieldService";
import { IResponse } from "../../../shared/components/forms/createFormMinimal";
import { useModuleSettingsHook } from "../../../shared/hooks/useModuleSettingsHook";
import BaseSettingsFormHeader from "../../components/editPage/baseSettingsFormHeader";
import { SettingsRoutePaths } from "../../settingsRoutePaths";
import CustomFieldCreateForm from "../components/customFieldCreateForm";

function CreatePageCustomField() {
  const { module } = useParams<{ module: PbdModule }>();
  invariant(module, "Missing module");
  const { customFieldsApi } = useAPIs();

  const {
    /**Use this function to make sure that the appSettings are refreshed. It must be used on Create, Edit, Delete.*/
    mutate: mutateModuleSettings,
  } = useModuleSettingsHook();
  const navigate = useNavigate();
  const handleCreate = (dto: ICustomFieldCreateDTO) => customFieldsApi.create(module, new CustomFieldCreateDTO(dto));
  const redirectToIndex = () => navigate(SettingsRoutePaths.IndexPageCustomFields.replace(":module", module));
  const afterSubmit = (dto: IResponse<string>) => {
    mutateModuleSettings();
    navigate(SettingsRoutePaths.EditPageCustomFields.replace(":module", module).replace(":id", dto.id.toString()));
  };
  return (
    <Card className="mb-3">
      <BaseSettingsFormHeader />
      <Card.Body>
        <CustomFieldCreateForm
          onCancel={redirectToIndex}
          onSubmit={handleCreate}
          onSuccess={afterSubmit}
          excludedCustomFieldTypes={CustomFieldService.excludedCustomFieldTypes}
        />
      </Card.Body>
    </Card>
  );
}
export default CreatePageCustomField;
