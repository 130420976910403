import React from "react";
import { Accordion } from "react-bootstrap";

import { useLocalStorage } from "../../hooks/useLocalStorage";

interface IProps {
  /**This must be a unique id to store the toggle state in local storage */
  cardId: string;
  cardTitle: React.ReactNode;
  /**Top right corner of the card title */
  cardStatus?: React.ReactNode;
  onToggle?: () => void;
  children: React.ReactNode;
}

function CardCollapsible(props: IProps) {
  const { cardId, cardTitle, cardStatus, onToggle } = props;
  const [isOpen, setIsOpen] = useLocalStorage<boolean>(cardId, false);
  const toggle = () => {
    onToggle?.();
    setIsOpen(!isOpen);
  };

  return (
    <Accordion onSelect={() => toggle()} defaultActiveKey={isOpen ? cardId : undefined}>
      <Accordion.Item eventKey={cardId}>
        <Accordion.Header>
          <div className="d-flex justify-content-between">
            <div>{cardTitle}</div>
            {cardStatus && <div className="ms-1">{cardStatus}</div>}
          </div>
        </Accordion.Header>
        <Accordion.Body>{props.children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
export default CardCollapsible;
