import { ErrorMessage, FieldHookConfig, FieldProps, getIn, useField } from "formik";
import { upperFirst } from "lodash";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormFeedback, FormText, Input } from "reactstrap";

import { IBaseFormElementProps } from "./baseFormElementProps";

interface FormTextProps {
  formText?: string;
  multiple?: boolean;
}

export const FormikSelectInput = ({
  field,
  form: { touched, errors },
  formText,
  ...props
}: FieldProps<string> & FormTextProps) => (
  <div>
    <Input invalid={getIn(errors, field.name) && getIn(touched, field.name)} {...field} {...props} type="select" />
    <ErrorMessage component={FormFeedback} className="order-last" {...field} />
    {formText && <FormText color="muted">{formText}</FormText>}
  </div>
);

export function FormikSelectInputGroup(
  props: FieldHookConfig<string> &
    IBaseFormElementProps & {
      hideLabel?: boolean;
      textarea?: boolean;
      options: string[];
    },
) {
  const { formText, label, id, hideLabel, options } = props;
  const [field, meta] = useField(props);
  const { t } = useTranslation();

  const localId = id ?? props.name;

  const invalid = meta.error != undefined && meta.touched;

  return (
    <Form.Group className="mb-3" controlId={localId}>
      {!hideLabel && <Form.Label>{label ?? t(upperFirst(props.name))}</Form.Label>}
      <Form.Select {...field} isInvalid={invalid} autoComplete={props.autoComplete}>
        {options.map((x) => (
          <option key={x} value={x}>
            {t(x)}
          </option>
        ))}
      </Form.Select>
      <ErrorMessage {...field}>
        {(msg) => (
          <Form.Control.Feedback type="invalid" className="order-last">
            {msg}
          </Form.Control.Feedback>
        )}
      </ErrorMessage>
      {formText && <Form.Text muted>{formText}</Form.Text>}
    </Form.Group>
  );
}
