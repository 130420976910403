import useSWR from "swr";

import { PbdModule } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIServices } from "../../../pbdServices/services/service-context";

import { AppNotifications } from "../../../pbdServices/Models/Notifications/AppNotifications";
import { MeAsUser } from "../../../pbdServices/services/UserSettings/models/me-as-user";

const articleNotification = [PbdModule.DocumentManagement, PbdModule.Blog, PbdModule.KnowledgeBase];

interface ReturnTypes<T = Record<string, unknown>> {
  appNotifications: AppNotifications<T> | undefined;
  loading: boolean;
}

export const useAppNotificationHook = <T = Record<string, unknown>>(
  app: PbdModule,
  meAsUser: MeAsUser,
): ReturnTypes<T>[] => {
  const { articleService, goalService, ideaManagementService } = useAPIServices();
  const { data: articleNotifications, isLoading: loadingArticles } = useSWR(
    articleNotification.includes(app) ? `/api/articles/notifications/?app=${app}` : null,
    () => articleService.getAllNotificationsAsAppNotification(app, meAsUser.tenant.id),
  );

  const { data: goalNotifications, isLoading: loadingGoals } = useSWR(
    app == PbdModule.GoalManagement ? `/api/goals/notifications/?app=${app}` : null,
    () => goalService.getAllNotificationsAsAppNotification(meAsUser.tenant.id),
  );

  const { data: ideaNotifications, isLoading: loadingIdeas } = useSWR(
    app == PbdModule.IdeaManagement ? `/api/employeeIdeas/notifications` : null,
    () => ideaManagementService.getAllNotificationsAsAppNotification(meAsUser.tenant.id),
  );

  const notificationsToReturn = articleNotifications || goalNotifications || ideaNotifications;
  const loading = loadingArticles || loadingGoals || loadingIdeas;

  return [
    {
      //@ts-expect-error TODO: Fix with better typings
      appNotifications: notificationsToReturn,
      loading,
    },
  ];
};
