import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, Card, CardBody, CardHeader, CardTitle, Collapse } from "reactstrap";

import { IOpportunityCategoryDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";

import CollapseChevron from "../../../shared/components/icons/collapseChevron";

interface IProps {
  category: IOpportunityCategoryDTO;
}

const SummaryCard: React.FC<IProps> = (props) => {
  const { category } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Card className="mb-3">
      <CardHeader>
        <CardTitle tag="h5">
          <a href="#" onClick={toggle}>
            <CollapseChevron isOpen={isOpen} /> {t("Summary")}
          </a>
        </CardTitle>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>
          <dl>
            <dt>{t("Title")}</dt>
            <dd>{category.title}</dd>
          </dl>
          <dl>
            <dt>{t("Surveillance interval")}</dt>
            <dd>
              {category.monitoringInterval?.value} {category.monitoringInterval?.type}
            </dd>
          </dl>
          <dl>
            <dt>{t("Variables")}</dt>
            <dd>
              {category.variables.length == 0 && <Badge color="info">{t("Action required")}</Badge>}
              {category.variables?.map((x) => (
                <li key={x.variableId}>
                  {x.variableName} - Min:{x.scaleMinimum} Max:
                  {x.scaleMaximum}
                </li>
              ))}
            </dd>
          </dl>
          <dl>
            <dt>{t("Formula")}</dt>
            <dd>
              {category.formulaInfo?.formula}
              {category.formulaInfo && (
                <React.Fragment>
                  <li>
                    {t("Minimum value of formula")}: {category.formulaInfo.minResult}
                  </li>
                  <li>
                    {t("Maximum value of formula")}: {category.formulaInfo.maxResult}
                  </li>
                </React.Fragment>
              )}
            </dd>
          </dl>
          <dl>
            <dt>{t("Critical values")}</dt>
            <dd>
              {category.boundaries?.map((x) => (
                <li key={x.boundaryId}>
                  {x.boundaryName} - {t("Value")}:{x.boundaryValue}
                </li>
              ))}
            </dd>
          </dl>
        </CardBody>
      </Collapse>
    </Card>
  );
};

export default SummaryCard;
