import { Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import {
  AddressRoomCreateDTO,
  AddressRoomEditDTO,
  IAddressRoomDTO,
  IAddressRoomEditDTO,
} from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { useFormikAPISubmitter } from "../../../../pbdServices/services/Api/api-formik-submitter";
import FormikSubmitButton from "../../../shared/components/forms/formik/formikSubmitButton";
import { FormikTextInput } from "../../../shared/components/forms/formik/formikTextInput";

const ValidationSchema: yup.ObjectSchema<IAddressRoomEditDTO> = yup.object({
  title: yup.string().required(),
  id: yup.number().required(),
});

interface IProps {
  baseObject: { id: number };
  itemToUpdate?: IAddressRoomDTO;
  afterSubmit: () => void;
}

const RoomsForm: React.FC<IProps> = (props) => {
  const { itemToUpdate, afterSubmit, baseObject } = props;
  const { addressesApi } = useAPIs();
  const { t } = useTranslation();

  const submitter = useFormikAPISubmitter<IAddressRoomEditDTO>(
    (values) => {
      if (itemToUpdate) {
        return addressesApi.editAddressRoom(baseObject.id, itemToUpdate.id, new AddressRoomEditDTO(values));
      } else {
        return addressesApi.createAddressRoom(baseObject.id, new AddressRoomCreateDTO(values));
      }
    },
    [addressesApi, baseObject.id, itemToUpdate],
    () => afterSubmit(),
  );

  const initialValues: IAddressRoomEditDTO = {
    title: itemToUpdate?.title ?? "",
    id: itemToUpdate?.id ?? 0,
  };

  return (
    <React.Fragment>
      <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={ValidationSchema}>
        {(formikBag) => (
          <Form>
            <FormGroup>
              <Label for="title">{t("Title")}</Label>
              <Field name="title" component={FormikTextInput} />
            </FormGroup>
            <FormikSubmitButton formikBag={formikBag} />
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default RoomsForm;
