import React from "react";
import { Button, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useSWR from "swr";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { ITenantDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { GlobalQmBaseConstants } from "../../../Constants/GlobalQmBaseConstants";
import { StringArrayParam } from "../../../Helpers/QueryHelpers";
import { PbdRoles } from "../../../services/Authz/PbdRoles";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import AvatarSpanWithName from "../../shared/components/tenants/avatarSpanWithName";
import { useAppContext } from "../../shared/contexts/appContext";
import { useNotificationContext } from "../../shared/contexts/notificationContext";
import ErrorPageLayout from "../../shared/layouts/errors/errorPageLayout";
import ErrorPageContentDefault from "../../shared/pages/errorPageContentDefault";

function AccessDeniedPage() {
  const { meAsUser } = useAppContext();
  const { t } = useTranslation();
  const { userApi, tenantsApi } = useAPIs();
  const { addNotification } = useNotificationContext();
  const [query, setQuery] = useQueryParams({
    returnUrl: withDefault(StringParam, undefined),
    requiredRoles: withDefault(StringArrayParam, undefined),
  });
  const location = useLocation();
  const [refreshedSignIn, setRefreshedSignIn] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const requestedUrl = query.returnUrl ?? location.pathname + location.search;

  const { data } = useSWR("/api/tenants/?role=Admin_Admin", () => tenantsApi.getAllQuery({ roleName: PbdRoles.Admin }));

  const refreshCredentials = async () => {
    await userApi.refreshClaims();
    setRefreshedSignIn(true);
  };

  const prepareNotificationToAdmin = (dto: ITenantDTO) => {
    addNotification(
      {
        to: dto.email,
        subject: t("Hello, please provide access to the following area"),
        message:
          t("Hello, please provide access to the following area") +
          `<br/><br/> Url: ${requestedUrl} <br/><br/>${t("Thanks")}<br/>${meAsUser.tenant.fullName}<br/> ${
            meAsUser.user.email
          }`,
      },
      undefined,
      () => setSuccess(true),
    );
  };

  return (
    <ErrorPageLayout>
      <ErrorPageContentDefault code={"403"}>
        <p>
          {t("Requested url:")} {requestedUrl}
        </p>
        <GenericAlert>
          <p>
            {t(
              "If you have just received new permissions it might take up to 5 minutes until they work. To refresh your credentials click here.",
            )}
          </p>
          <Button variant="secondary" onClick={refreshCredentials} disabled={refreshedSignIn}>
            {refreshedSignIn ? (
              <span>
                <qmBaseIcons.Check /> {t("Refreshed")}
              </span>
            ) : (
              t("Refresh credentials")
            )}
          </Button>
          {refreshedSignIn && (
            <>
              <p>{t("You can now try to access the requested page again")}</p>
              <Button variant="link" href={requestedUrl}>
                {t("Go to requested page")}
              </Button>
            </>
          )}
        </GenericAlert>
        {query.requiredRoles && (
          <Row className="text-start">
            <Col md={6}>
              <h5>{t("You must be in one the following roles")}</h5>
              <ul>
                {query.requiredRoles.map((x: string) => (
                  <li key={x}>{x}</li>
                ))}
              </ul>
            </Col>
            <Col>
              <h5>{t("Your current roles")}</h5>
              <ul>{meAsUser.user.roles?.map((x) => <li key={x.id}>{x.name}</li>)}</ul>
            </Col>
          </Row>
        )}
        {success && <GenericAlert type="success" heading={t("Submitted")} />}
        <p>{t("If you need access to this page just contact any of your admins.")}</p>
        <ListGroup variant="flush" className="mb-3">
          {data?.data
            .filter((x) => x.email && x.email.toUpperCase() != GlobalQmBaseConstants.SupportMail)
            .map((x) => (
              <ListGroup.Item key={x.id}>
                <div className="d-flex justify-content-between">
                  <AvatarSpanWithName tenant={x} />
                  <div>
                    <Button variant="outline-primary" onClick={() => prepareNotificationToAdmin(x)}>
                      {t("Request for access")}
                    </Button>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
        </ListGroup>
      </ErrorPageContentDefault>
    </ErrorPageLayout>
  );
}

export default AccessDeniedPage;
