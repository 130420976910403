import { countBy, map } from "lodash";

import {
  IManyToManyConnectionDTO,
  ITagDTO,
  ITagDTOWithCount,
  TagDTO,
  TagDTOWithCount,
} from "../../../generatedCode/pbd-core/pbd-core-api";

import { WithCount } from "../../Models/Shared/with-count";

export const NotFoundTag = new TagDTO({
  title: "Not found",
  id: 0,
});

export default class TagService {
  groupTags(tags: ITagDTO[]): WithCount<ITagDTO>[] {
    const grouped = countBy(tags, (x) => x.id);
    const data: WithCount<ITagDTO>[] = map(grouped, function (value, key) {
      const tag = tags.find((x) => x.id.toString() == key)!;
      return { ...tag, count: value };
    });
    return data;
  }

  static groupAndSortTagArrays(tagArrays: ITagDTO[][]): ITagDTOWithCount[] {
    const tags = tagArrays.reduce((a, b) => a.concat(b), []);
    const grouped = countBy(tags, (x) => x.id);
    const data = map(grouped, function (value, key) {
      const tag = tags.find((x) => x.id.toString() == key) ?? NotFoundTag;
      return new TagDTOWithCount({ ...tag, count: value });
    });
    return data.sort((a, b) => (a.count ?? -1) - (b.count ?? -1));
  }

  static getDataGroupedConnectionsByModule(data: IManyToManyConnectionDTO[]): WithCount<IManyToManyConnectionDTO>[] {
    const groupedData: WithCount<IManyToManyConnectionDTO>[] = [];
    const dict = countBy(data.map((r) => r.module));
    Object.keys(dict).forEach((x) => {
      groupedData.push({
        title: x,
        count: dict[x],
        id: 0,
        module: x,
      });
    });
    return groupedData;
  }

  static getAdministrativeActions(tags?: ITagDTOWithCount[]) {
    const cleanUpPossible = (tags?.filter((x) => x.count != undefined && x.count == 0).length ?? 0) > 0;
    return { available: cleanUpPossible, actions: { cleanUpPossible } };
  }
}
