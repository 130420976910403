import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { NumberParam, useQueryParam } from "use-query-params";

import { useAPIs } from "../../../pbdServices/services/service-context";

import { ValidArticleApps } from "../../../pbdServices/services/Articles/articleService";
import { ArticleRoutePaths } from "../../articles/articleRoutePaths";
import LinkifyStringComponent from "../../shared/components/linkifyString/linkifyStringComponent";
import { useAppContext } from "../../shared/contexts/appContext";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { useTypedParams } from "../../shared/hooks/useTypedParams";
import ArticlePrintout from "../components/articlePrintout";
import ArticleWaterMark from "../components/articleWaterMark";
import PrintoutIncludeOptions, { PrintOptions } from "../components/printoutIncludeOptions";
import QrCodeContainer from "../components/qrCodeContainer";

function PrintArticlePage() {
  const { id } = useTypedParams(["id"]);
  const { t } = useTranslation();
  const { appSettings } = useAppContext();
  const { articlesApi, articleRevisionsApi, moduleSettingsApi } = useAPIs();
  const [articleRevisionId, setArticleRevisionId] = useQueryParam("articleRevisionId", NumberParam);
  const { data: article } = useSWR(["/api/articles", id], () => articlesApi.getById(id));
  const { data: revision } = useSWR(
    (articleRevisionId ?? article?.latestRevisionId)
      ? ["/api/articleRevisions", articleRevisionId ?? article?.latestRevisionId]
      : null,
    () => {
      const idToQuery = articleRevisionId ?? article?.latestRevisionId;
      if (idToQuery) {
        return articleRevisionsApi.getById(idToQuery);
      }
      return undefined;
    },
  );
  const articleSettings = article ? appSettings[article.module as ValidArticleApps] : null;

  const [options, setOptions] = useLocalStorage<PrintOptions>("printOptions", new PrintOptions());

  const handleOptionsChange = (value: PrintOptions) => setOptions(value);

  return (
    <>
      <PrintoutIncludeOptions
        availableOptions={["showQrCode", "showChangeLog", "showReviewers"]}
        onChange={handleOptionsChange}
        initialOptions={options}
      />
      {articleSettings?.articleWatermarkSettings && (
        <ArticleWaterMark articleWatermarkSetting={articleSettings.articleWatermarkSettings} />
      )}
      {article && <ArticlePrintout article={article} revision={revision} showReviewers={options.showReviewers} />}
      {article && options.showChangeLog && revision && (
        <div>
          <h5>{t("Changelog")}</h5>
          <LinkifyStringComponent content={revision.changelog} />
        </div>
      )}
      {article && options.showQrCode && <QrCodeContainer url={ArticleRoutePaths.DetailsPageFn(article)} />}
    </>
  );
}

export default PrintArticlePage;
