import { Handle, NodeProps, NodeResizer, Position } from "reactflow";

import { FlowChartHelpers } from "../../../../../Helpers/FlowChartHelpers";
import FormattedUserInput from "../../text/formattedUserInput";
import { NodeOrEdgeData } from "../models/pbd-flow-utils";

function CustomOutputNode({ data, selected }: NodeProps<NodeOrEdgeData>) {
  return (
    <>
      <NodeResizer isVisible={selected} />
      <div style={FlowChartHelpers.getStylesForCustomNodes(data)}>
        <Handle type="target" position={Position.Top} />
        <FormattedUserInput content={data.label} />
      </div>
    </>
  );
}

export default CustomOutputNode;
