import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label } from "reactstrap";
import * as yup from "yup";

import { EightDReportStepDataValue } from "../../../Models/EightDReports/EightDReportStep";
import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import CancelButton from "../../shared/components/buttons/cancelButton";
import { FormikHtmlInput } from "../../shared/components/forms/formik/formikHtmlInput";
import FormikValidationSummary from "../../shared/components/forms/formik/formikValidationSummary";
import { qmBaseIcons } from "../../shared/components/icons/qmBaseIcons";
import { useToggle } from "../../shared/hooks/useToggle";

interface IProps {
  onSubmit: (values: DescriptionFormValues) => Promise<void>;
  toggleEditMode: () => void;
  valueToUpdate: string | undefined;
  onSuccess: () => void;
  heading: string;
  step: EightDReportStepDataValue;
}

export interface DescriptionFormValues {
  description?: string;
}

const ValidationSchema: yup.ObjectSchema<DescriptionFormValues> = yup.object({
  description: yup.string(),
});

function EightDReportDescriptionForm(props: IProps) {
  const { onSubmit, toggleEditMode, step, onSuccess } = props;
  const { t } = useTranslation();
  const [editDescriptionMode, toggleEditDescriptionMode] = useToggle();

  const submitter = useFormikAPISubmitter<DescriptionFormValues>(
    (val) => onSubmit(val),
    [onSubmit],
    () => onSuccess(),
  );

  const initialValues: DescriptionFormValues = { description: props.valueToUpdate || "" };
  return (
    <Formik onSubmit={submitter} initialValues={initialValues} validationSchema={ValidationSchema}>
      {(formikBag) => (
        <Form>
          {editDescriptionMode || props.valueToUpdate ? (
            <FormGroup>
              <Label for="description">{t(props.heading)}</Label>
              <Field name="description" component={FormikHtmlInput} customTextEditorId={step.step} />
            </FormGroup>
          ) : (
            <FormGroup>
              <Button color="link" onClick={toggleEditDescriptionMode}>
                <qmBaseIcons.Plus /> {t("Add description")}
              </Button>
            </FormGroup>
          )}
          <FormGroup>
            <CancelButton onClick={toggleEditMode} />
            <Button color="primary" type="submit" disabled={formikBag.isSubmitting || !formikBag.isValid}>
              {t("Save")}
            </Button>
          </FormGroup>
          <FormikValidationSummary formikBag={formikBag} />
        </Form>
      )}
    </Formik>
  );
}
export default EightDReportDescriptionForm;
