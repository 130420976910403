import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useSWR from "swr";

import { useAPIs } from "../../../pbdServices/services/service-context";

import { BaseTable } from "../../boilerplate/baseTable";
import { AdminRoutePaths } from "../adminRoutePaths";

function CostTemplateConfigurationComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { costTemplatesApi } = useAPIs();
  const { data } = useSWR("/api/costTemplates", () => costTemplatesApi.getAll());
  return (
    <>
      <h5>{t("Cost templates")}</h5>
      <hr />
      <BaseTable
        onCreateClick={() => navigate(AdminRoutePaths.CreateCostTemplate)}
        tableRows={data?.map((x) => {
          return { title: x.title, id: x.id, linkTo: AdminRoutePaths.EditCostTemplate.replace(":id", x.id) };
        })}
      />
    </>
  );
}
export default CostTemplateConfigurationComponent;
