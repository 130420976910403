/* eslint-disable @typescript-eslint/naming-convention */
import { TFunction } from "i18next";
import { isNumber } from "lodash";

import { ProblemDetails } from "../../generatedCode/pbd-core/pbd-core-api";

enum HttpStatus {
  NotFound = "NotFound",
  Error = "Error",
  Found = "Found",
  Unauthorized = "Unauthorized",
  Forbidden = "Forbidden",
  BadRequest = "BadRequest",
  UnprocessableEntity = "UnprocessableEntity",
  Moved = "Moved",
  TooManyRequests = "TooManyRequests",
}

const ErrorMap2: ProblemDetails[] = [
  new ProblemDetails({ status: 301, title: HttpStatus.Moved, detail: "Moved permanently" }),
  new ProblemDetails({ status: 302, title: HttpStatus.Found, detail: "Moved temporarily" }),
  new ProblemDetails({ status: 400, title: HttpStatus.BadRequest }),
  new ProblemDetails({
    status: 401,
    title: HttpStatus.Unauthorized,
    detail: "You must be logged in to view this content",
  }),
  new ProblemDetails({
    status: 403,
    title: HttpStatus.Forbidden,
    detail: "You do not have sufficient rights to access this page.",
  }),
  new ProblemDetails({ status: 404, title: HttpStatus.NotFound }),
  new ProblemDetails({ status: 422, title: HttpStatus.UnprocessableEntity }),
  new ProblemDetails({ status: 429, title: HttpStatus.TooManyRequests }),
  new ProblemDetails({ status: 500, title: HttpStatus.Error }),
];

export default class ErrorService {
  static getErrorCodeDescription(
    status: string | number,
    t: TFunction<"errorCodes">,
  ): Pick<ProblemDetails, "title" | "detail" | "type" | "status" | "instance"> {
    if (isNumber(Number(status))) {
      const statusAsNumber = Number(status);
      const err = ErrorMap2.find((x) => x.status == statusAsNumber);
      if (err) {
        return err;
      }
    }
    return new ProblemDetails({ code: status, title: status.toString(), detail: t("Unknown error") });
  }

  static getContextType(code: string | number): "success" | "info" | "danger" {
    const codeAsNumber = isNumber(code) ? code : Number(code);
    if (codeAsNumber < 199) {
      return "info";
    } else if (codeAsNumber < 299) {
      return "success";
    } else if (codeAsNumber < 399) {
      return "info";
    } else {
      return "danger";
    }
  }
}
