import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import { ICategoryDTO } from "../../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../../pbdServices/services/service-context";

import { wrapApiCallWithToast } from "../../../../../pbdServices/services/Api/api-wrapper";
import { mapAuditTypeToSettingsDTO } from "../../../../settings/auditTypes/pages/indexPageAuditTypes";
import { BaseSettingsTable } from "../../../../settings/components/baseSettingsTable";
import { SettingsRoutePaths } from "../../../../settings/settingsRoutePaths";
import EnhancedSubmitButton from "../../buttons/enhancedSubmitButton";
import { BaseModalProps } from "../../modals/baseModalProps";

interface IProps extends BaseModalProps {
  connectedElements: Pick<ICategoryDTO, "id">[];
  onSubmit: (selectedIds: number[]) => Promise<void>;
  canSubmit: boolean;
  refreshParent: () => void;
}

/**
 * Generic Modal to connect claims with anything else. For example defects
 * */
function ConnectAuditTypesModal(props: IProps) {
  const { modal, toggle, onSubmit, connectedElements } = props;
  const { t } = useTranslation();
  const { auditTypesApi } = useAPIs();
  const [submitting, setSubmitting] = React.useState(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const navigate = useNavigate();
  const { data } = useSWR(modal ? "api/auditTypes" : null, () =>
    auditTypesApi.getAll().then((resp) => mapAuditTypeToSettingsDTO(resp)),
  );

  const filtered = React.useMemo(() => {
    if (data) {
      return data.filter((x) => !connectedElements.map((y) => y.id).includes(Number(x.id)));
    } else {
      return undefined;
    }
  }, [connectedElements, data]);

  async function handleSubmit() {
    setSubmitting(true);

    const resp = await wrapApiCallWithToast(() => onSubmit(selected));
    if (resp.isOk) {
      setSubmitting(false);
      setSelected([]);
      toggle();
      props.refreshParent();
    }
  }

  return (
    <Modal show={modal} onHide={toggle} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("Audit types")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BaseSettingsTable
          tableRows={filtered}
          setSelected={(ids) => setSelected(ids.map((x) => Number(x.id)))}
          isAlwaysSelectable
          onCreateClick={() => navigate(SettingsRoutePaths.CreatePageAuditTypes)}
          includeTitleLink={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <EnhancedSubmitButton
          onClick={handleSubmit}
          submitting={submitting}
          disabled={selected.length == 0}
          canSubmit={props.canSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConnectAuditTypesModal;
