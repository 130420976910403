import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IAddressDTO } from "../../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../../pbdServices/services/service-context";

import { ErrorMessage } from "../../../../Models/Errors/ErrorMessage";
import { SettingsIndexDTO } from "../../../../Models/Settings/SettingsIndexDTO";
import AddressService from "../../../../pbdServices/services/Address/addressService";
import { wrapApiCallWithToast } from "../../../../pbdServices/services/Api/api-wrapper";
import { PbdRoles } from "../../../../services/Authz/PbdRoles";
import { hasRole } from "../../../../services/Authz/authService";
import RequiredRolesComponent from "../../../admin/roles/components/requiredRolesComponent";
import EntryCleanUpComponent from "../../../shared/components/buttons/entryCleanUp/entryCleanUpComponent";
import { TableClickCommand } from "../../../shared/components/tables/TableClickCommand";
import { useAppContext } from "../../../shared/contexts/appContext";
import { useConfirmation } from "../../../shared/contexts/modalConfirmationContext";
import { useRefreshHook } from "../../../shared/hooks/useRefreshHook";
import BaseSettingsIndexCard from "../../components/baseSettingsIndexCard";
import { BaseSettingsTable } from "../../components/baseSettingsTable";
import { SettingsRoutePaths } from "../../settingsRoutePaths";

function IndexPagesPlaces() {
  const { t } = useTranslation();
  const { meAsUser, setErrorMessage, handleApiError } = useAppContext();
  const navigate = useNavigate();
  const { addressesApi } = useAPIs();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<IAddressDTO[]>();
  const [selected, setSelected] = React.useState<SettingsIndexDTO[]>([]);
  const [{ refresh, handleRefresh }] = useRefreshHook();
  const confirm = useConfirmation();

  React.useEffect(() => {
    async function getData() {
      const addressFirstQuery = await addressesApi.getAllQuery({ includeCount: true });
      setData(addressFirstQuery.data);
      setLoading(false);
      if (addressFirstQuery.data.length < addressFirstQuery.pagination.totalItems) {
        const addressSecondQuery = await addressesApi.getAllQuery({
          includeCount: true,
          pageSize: addressFirstQuery.pagination.totalItems,
        });
        setData(addressSecondQuery.data);
      }
    }
    getData();
  }, [addressesApi, refresh]);

  const mappedData = React.useMemo(() => {
    if (!data) return undefined;
    return AddressService.mapDataToSettingsDTO(data);
  }, [data]);
  const adminActions = AddressService.getAdministrativeActions(data);

  const handleClick = (command: TableClickCommand) => {
    if (command.action == "Delete") {
      confirm({ itemsToDelete: selected }).then(() => submitDeleteRequest(selected.map((x) => Number(x.id))));
    }
  };

  const submitDeleteRequest = async (items: number[]) => {
    if (!hasRole(meAsUser, [PbdRoles.Settings_Standard])) {
      setErrorMessage(new ErrorMessage(403));
    } else {
      for (const s of items) {
        await wrapApiCallWithToast(() => addressesApi.delete(Number(s)), { handleApiError });
      }
      handleRefresh();
    }
  };

  const handleCleanUpUnconnected = async () => {
    const addresses = await addressesApi.getAllQuery({ includeCount: true });
    const filtered = addresses.data.filter((x) => x.count == 0);
    confirm({ itemsToDelete: filtered }).then(() => submitDeleteRequest(filtered.map((x) => x.id)));
  };

  const handleCleanUpUnusable = () => {
    const unusableEntries = data?.filter((x) => x.fullAddress == "") ?? [];
    confirm({ itemsToDelete: unusableEntries }).then(() => submitDeleteRequest(unusableEntries.map((x) => x.id)));
  };

  return (
    <BaseSettingsIndexCard
      data={data}
      cardTitle="Places"
      pathToCreate={SettingsRoutePaths.CreatePagePlaces}
      loading={loading}
    >
      <EntryCleanUpComponent
        name={"addresses"}
        availableAuthority={adminActions.available}
        onClickUnconnectedCleanUp={handleCleanUpUnconnected}
        unconnectedCleanUpPossible={adminActions.actions.cleanUpPossible}
        unconnectedDisabled={(data?.filter((x) => x.count == 0).length ?? 0) == 0}
        onClickUnusableCleanUp={handleCleanUpUnusable}
        unusableCleanUpPossible={true}
        unusableDisabled={(data?.filter((x) => x.line1 == null).length ?? 0) == 0}
      />
      <div className="d-flex justify-content-between">
        <h3>{t("Manage places")}</h3>
        <RequiredRolesComponent roles={[PbdRoles.Settings_Standard]} />
      </div>
      <hr />
      <BaseSettingsTable
        tableRows={mappedData}
        onCreateClick={() => navigate(SettingsRoutePaths.CreatePagePlaces)}
        setSelected={(ids) => setSelected(ids.map((x) => x))}
        availableTableActions={["Delete"]}
        onClick={handleClick}
      />
    </BaseSettingsIndexCard>
  );
}

export default IndexPagesPlaces;
