import { Formik } from "formik";
import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { IApplicationUserDTO, IRegisterDTO, RegisterDTO } from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import { useFormikAPISubmitter } from "../../../pbdServices/services/Api/api-formik-submitter";
import GenericAlert from "../../shared/components/alerts/genericAlert";
import { useExternalLoginHook } from "../../shared/hooks/useExternalLoginHook";
import { AccountRoutePaths } from "../accountRoutePaths";
import CardFooterAccount from "../components/cardFooterAccount";
import RegisterForm, { getRegisterValidationSchema } from "../components/registerForm";

function RegisterPage() {
  const { t } = useTranslation();
  const { data } = useExternalLoginHook();
  const { accountApi } = useAPIs();
  const [success, setSuccess] = React.useState(false);
  const [query, setQuery] = useQueryParams({
    returnUrl: withDefault(StringParam, undefined),
  });

  const submitter = useFormikAPISubmitter<IRegisterDTO, IApplicationUserDTO>(
    (values) => accountApi.register(query.returnUrl, new RegisterDTO(values)),
    [accountApi, query],
    () => setSuccess(true),
  );

  const initialValues: IRegisterDTO = { email: "", password: "", confirmPassword: "" };

  return (
    <Card className="accountCard">
      <Card.Body>
        <h1 className="text-center">{t("Register")}</h1>
        <hr />
        {!data?.isSelfServiceAccountCreateActivated && (
          <GenericAlert type="info">
            {t("Creating accounts in self service is disabled. Please contact your administrator.")}
          </GenericAlert>
        )}
        {success && (
          <GenericAlert type="success" heading={t("Registration successful!")}>
            <p>
              {t("Thank you for your registration.")}{" "}
              {t("Please confirm your email address by clicking on the link that has been send to your email address.")}
            </p>
            <p>
              {t(
                "It may take 2-3 minutes till you receive the e-mail. If you do not receive the e-mail please check your spam folder first and contact the support afterwards.",
              )}
            </p>
          </GenericAlert>
        )}
        {data?.isSelfServiceAccountCreateActivated && (
          <Formik initialValues={initialValues} onSubmit={submitter} validationSchema={getRegisterValidationSchema(t)}>
            {(formikBag) => <RegisterForm formikBag={formikBag} />}
          </Formik>
        )}
        <div className="text-center">
          <p>
            {t("Already have an account?")} <Link to={AccountRoutePaths.Login}>{t("Sign in!")}</Link>
          </p>
          {data?.isAccountRequestActivated && (
            <p>
              {t("Don't have a company email?")} <Link to={AccountRoutePaths.Request}>{t("Request account!")}</Link>
            </p>
          )}
        </div>

        <CardFooterAccount />
      </Card.Body>
    </Card>
  );
}

export default RegisterPage;
