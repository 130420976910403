import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  IAuditDTO,
  IQualificationDTO,
  ITenantMinDTO,
  ITrainingDTO,
  PbdModule,
} from "../../../generatedCode/pbd-core/pbd-core-api";
import { useAPIs } from "../../../pbdServices/services/service-context";

import FormattedUserInput from "../../shared/components/text/formattedUserInput";
import PlannedDateComponent from "../../shared/components/timeAndPlace/plannedDateComponent";
import { useAppContext } from "../../shared/contexts/appContext";
import { useLocalStorage } from "../../shared/hooks/useLocalStorage";
import { TrainingRoutePaths } from "../../trainings/trainingRoutePaths";
import AttendeeTablePrintView from "../components/attendeeTablePrintView";
import PrintoutIncludeOptions, { PrintOptions } from "../components/printoutIncludeOptions";
import QrCodeContainer from "../components/qrCodeContainer";
import QualificationTablePrintView from "../components/qualificationTablePrintView";

function PrintAttendeesPage() {
  const { t } = useTranslation();
  const { auditsApi, trainingsApi } = useAPIs();
  const {
    appSettings: { Employees },
  } = useAppContext();
  const [event, setEvent] = React.useState<ITrainingDTO | IAuditDTO>();
  const [attendees, setAttendees] = React.useState<ITenantMinDTO[]>();
  const [qualifications, setQualifications] = React.useState<IQualificationDTO[]>();
  const [options, setOptions] = useLocalStorage<PrintOptions>("printOptions", new PrintOptions());
  const { id, module } = useParams<{ id: string; module: PbdModule }>() as { id: string; module: PbdModule };

  React.useEffect(() => {
    async function getData() {
      if (module == PbdModule.TrainingManagement) {
        const trainingToPrint = await trainingsApi.getById(Number(id));
        setEvent(trainingToPrint);
        const resp = await trainingsApi.getAttendeesByTrainingId(Number(id));
        setAttendees(resp.sort((a, b) => a.fullName.localeCompare(b.fullName)));
        const qual = await trainingsApi.getQualifications(Number(id));
        setQualifications(qual);
      } else if (module == PbdModule.AuditManagement) {
        const auditToPrint = await auditsApi.getById(Number(id));
        setEvent(auditToPrint);
        const auditAttendees = await auditsApi.getAttendees(Number(id));
        setAttendees(auditAttendees);
      }
    }
    getData();
  }, [id, module]);

  const handleOptionsChange = (value: PrintOptions) => setOptions(value);
  return (
    <>
      <PrintoutIncludeOptions
        availableOptions={
          module == PbdModule.TrainingManagement ? ["showQrCode", "showQualifications"] : ["showQrCode"]
        }
        onChange={handleOptionsChange}
        initialOptions={options}
      />
      {event && attendees && (
        <div>
          <h2>
            {event.title} {event.title != event.category?.title && event.category?.title}
          </h2>
          <hr />
          <dl>
            <dt>{t("Planned")}</dt>
            <dd>
              <PlannedDateComponent {...event} />
            </dd>
            <dt>{t("Responsible")}</dt>
            <dd>{event.responsible?.fullName}</dd>
            <dt>{t("Description")}</dt>
            <dd>
              <FormattedUserInput content={event.description} />
            </dd>
          </dl>
          {qualifications && options.showQualifications && <QualificationTablePrintView items={qualifications} />}
          <AttendeeTablePrintView attendees={attendees} settings={Employees} />
        </div>
      )}
      {options.showQrCode && <QrCodeContainer url={TrainingRoutePaths.EditPage.replace(":id", id)} />}
    </>
  );
}

export default PrintAttendeesPage;
