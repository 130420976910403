import _ from "lodash";
import React, { DependencyList } from "react";

import { useAppContext } from "../../../ClientApp/shared/contexts/appContext";
import { useConfirmation } from "../../../ClientApp/shared/contexts/modalConfirmationContext";
import { ApiCall1, wrapApiCall1 } from "./api-wrapper";
import { ApiError } from "./models/api-error";

export interface DTOWithTitle<TKey = number> {
  id?: TKey;
  title?: string;
  name?: string;
  fullName?: string;
}

interface ConfirmationModalOptions {
  description?: string;
  additionalDescription?: string;
  variant?: "danger" | "info";
  title?: string;
  confirmButtonLabel?: string;
  mustUseAdminRights?: boolean;
}

/**
 * Shows a modal to confirm the delete request
 * and then deletes the dto with the onDelete function
 * @param onDelete
 * @param onSuccess
 * @returns
 */
export function useTryDeleteWrapped<T extends DTOWithTitle<TKey>, TKey = number>(
  onDelete: ApiCall1<void | unknown, T>,
  onSuccess?: () => Promise<void> | void,
  handleError?: (err: ApiError) => void,
  modalOptions?: ConfirmationModalOptions,
) {
  const confirm = useConfirmation();
  const { handleApiError } = useAppContext();

  const result = React.useCallback(
    async (dto: T) => {
      try {
        await confirm({
          itemsToDelete: [{ id: _.toString(dto.id), title: dto.title ?? dto.name ?? dto.fullName }],
          ...modalOptions,
        });
      } catch {
        //Confirm failed
        return;
      }

      //Do delete
      const result = await onDelete(dto);
      if (result.isOk) {
        if (onSuccess) await onSuccess();
        return;
      }
      if (result.isErr) {
        if (handleError) handleError(result.error);
        else handleApiError(result.error);
        return;
      }
    },
    [confirm, handleApiError, handleError, onDelete, onSuccess],
  );

  return result;
}

export function useTryDeleteWrappedSimple<T = unknown>(
  onDelete: ApiCall1<void | unknown, T>,
  modalOptions: ConfirmationModalOptions,
  onSuccess?: () => Promise<void> | void,
  handleError?: (err: ApiError) => void,
) {
  const confirm = useConfirmation();
  const { handleApiError } = useAppContext();

  const result = React.useCallback(
    async (dto: T) => {
      try {
        await confirm(modalOptions);
      } catch {
        //Confirm failed
        return;
      }

      //Do delete
      const result = await onDelete(dto);
      if (result.isOk) {
        if (onSuccess) await onSuccess();
        return;
      }
      if (result.isErr) {
        if (handleError) handleError(result.error);
        else handleApiError(result.error);
        return;
      }
    },
    [confirm, handleApiError, handleError, onDelete, onSuccess],
  );

  return result;
}

/**
 * Call useTryDelete with a function which
 * might throw an ApiException such as every ControllerClient function
 * @param onDelete
 * @param deps
 * @param onSuccess
 * @param handleError
 * @returns
 */
export function useTryDelete<T extends DTOWithTitle<TKey>, TKey = number>(
  onDelete: (dto: T) => Promise<void | unknown>,
  deps: DependencyList,
  onSuccess?: () => Promise<void> | void,
  handleError?: (err: ApiError) => void,
  modalOptions?: ConfirmationModalOptions,
) {
  const wrapped: ApiCall1<void | unknown, T> = React.useCallback(
    (dto: T) => wrapApiCall1(onDelete, dto),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
  return useTryDeleteWrapped<T, TKey>(wrapped, onSuccess, handleError, modalOptions);
}

const baseModalOptions: ConfirmationModalOptions = {
  variant: "info",
  title: "Confirm",
  confirmButtonLabel: "Confirm",
  // description: "Please confirm that the following effects will take place and cannot be undone.",
};

export function useTryConfirm<T = unknown>(
  onDelete: (dto: T) => Promise<void | unknown>,
  deps: DependencyList,
  onSuccess?: () => Promise<void> | void,
  modalOptions?: ConfirmationModalOptions,
) {
  const wrapped: ApiCall1<void | unknown, T> = React.useCallback(
    (dto: T) => wrapApiCall1(onDelete, dto),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps],
  );
  return useTryDeleteWrappedSimple<T>(wrapped, { ...baseModalOptions, ...modalOptions }, onSuccess);
}
