import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import { useAPIServices } from "../../../../pbdServices/services/service-context";

import { ICategoryEntityTemplate } from "../../../../Models/EntityTemplates/CategoryEntityTemplate";
import { TemplateNames } from "../../../../services/EntityTemplates/entityTemplateService";
import { FormRoutePaths } from "../../../forms/formRoutePaths";
import { QualificationMatrixRoutePaths } from "../../../qualificationMatrix/qualificationMatrixRoutePaths";
import { SettingsRoutePaths } from "../../../settings/settingsRoutePaths";
import { qmBaseIcons } from "../icons/qmBaseIcons";
import TemplateDataModal from "./entityTemplateModal";

const urlsWhereVisible = [
  FormRoutePaths.CreatePageCustomForm,
  SettingsRoutePaths.CreatePageEmployeeIdeaCategories,
  SettingsRoutePaths.CreatePageInventoryInspections,
  SettingsRoutePaths.CreatePageInventoryStatus,
  SettingsRoutePaths.CreatePageInventoryCategories,
  QualificationMatrixRoutePaths.CreatePageQualification,
];

interface ReturnType {
  id: number;
  title?: string;
}

export interface IEntityTemplateButtonActions<T extends ICategoryEntityTemplate = ICategoryEntityTemplate> {
  onSelect: (item: T) => Promise<ReturnType>;
  onSuccess: (dto: ReturnType) => void;
}

const showTemplateButton = (url: string) => urlsWhereVisible.includes(url);

interface IProps<T extends ICategoryEntityTemplate = ICategoryEntityTemplate> extends IEntityTemplateButtonActions<T> {
  templateName: TemplateNames;
}

/**
 * Render this button automatically of the corresponding file with template data exists
 */
function EntityTemplatesButton<T extends ICategoryEntityTemplate = ICategoryEntityTemplate>(props: IProps<T>) {
  const { templateName } = props;
  const { t } = useTranslation();
  const { entityTemplateService } = useAPIServices();
  const location = useLocation();
  const [modal, setModal] = React.useState(false);
  const { data } = useSWRImmutable(modal ? `/github/templates/${templateName}` : null, () =>
    entityTemplateService.getAll<T>({ entity: templateName }),
  );
  const toggleModal = () => setModal(!modal);

  if (!showTemplateButton(location.pathname)) return null;

  return (
    <>
      <Button variant="outline-primary" onClick={toggleModal} title={t("Browse our templates")}>
        <qmBaseIcons.FolderRegular />
      </Button>
      <TemplateDataModal data={data?.content} modal={modal} toggle={toggleModal} {...props} />
    </>
  );
}

export default EntityTemplatesButton;
